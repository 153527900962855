<div class="container">
  <div class="card flex">
    <div class="left" style="padding-top: 35px;">
      <div class="profile-content-area">
        <img *ngIf="!userDetails?.photo" src="../../../assets/images/avatar-user.png" alt="" />
        <img *ngIf="userDetails?.photo" src="{{ this.photo }}" alt="" class="profile-img" />
        <div class="profile-content">
          <button mat-raised-button color="primary" (click)="Openupload()">
            Change Photo
          </button>
          <p>File size maximum 20kb with JPG and JPEG format.</p>
        </div>
      </div>

      <div  class="profile-content-area" *ngIf="!userDetails?.allocated">
        <span class="dark-text bold-fonts">A/C Balance:
          <span class="amount-color" *ngIf="!userDetails?.pointsEligible">{{
            userDetails?.acBalance | currency }}
          </span>
          <span class="amount-color" *ngIf="userDetails?.pointsEligible">{{userDetails?.acPoint }} points</span>
        </span>

        <div class="profile-content" *ngIf="(!(userDetails?.cpc && userDetails?.allocated)) && userDetails?.userType === 'Super user'">
          <button mat-raised-button color="primary" (click)="OpenPopup()" [disabled]="userDetails?.userType != 'Super user'">
            Add to Balance
          </button>
          <p *ngIf="!(userDetails?.cpc && userDetails?.allocated)">Top up your balance to pay for your task.</p>
          <p style="padding-bottom: 15px;" *ngIf="userDetails?.userType === 'Super user'">
            
              If you need a quote, click <b (click)="requestQuotation()">here</b>.
           
          </p>
        </div>
      </div>
      <div class="profile-content-area" *ngIf="userDetails?.allocated">
        <span>Number Of Tasks:
          <span style="color: #1059a9" class="emails">{{
            totalTasking
            }}</span></span>

        <!-- <div class="profile-content">
          <button mat-raised-button color="primary" (click)="toggleAllocation()">
            Allocation Details
          </button>
        
        </div> -->
      </div>
    </div>

    <div class="rightside" id="scrollcustom" class="scrollbar" style="width: 65%;">
      <mat-accordion>
        <mat-expansion-panel [expanded]="isFirst">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="main-head">Profile Settings</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
    
          <div class="personal-details">
            <mat-accordion class="sub-accordian">
              <mat-expansion-panel [expanded]="isFirst">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="panel-head">Personal Details</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div style="margin-left: 15px !important;margin-bottom: -34px;">
                  <form action="">
                    <div class="profile-id-form form-group">
                      <div class="id"  [ngClass]="{ opacity: disableTextbox }">
                        <div class="leftone">
                          <div>
                            <label for="id"> ID </label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.id }}" [disabled]="disableTextbox" />
                          </div>
                        </div>
                      </div>
                      <div class="wrapper">
                        <div class="leftone">
    
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Full Name </label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.name }} {{ userDetails?.lastName }}"
                              [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableEmail }">
                            <label for="id"> Email
                              <span style="padding-left: 12px;" [matTooltip]="disableEmail ? 'Change email' : ''" matTooltipPosition="right"><img
                                  [src]="darkTheme ? '../../../assets/images/edit-dark.svg' : '../../../assets/images/edit.svg'"
                                  (click)="toggleEmailEdit()" style="cursor: pointer;width: 15px;" 
                                  *ngIf="userDetails?.userType === 'Super user'"/>
                              </span></label>
                            <input type="email" class="form-control disable-input" [readonly]="disableEmail"
                              [value]="userDetails?.email" name="email" #email
                              (keyup)="updateEmail(email.value)"/>
                              <mat-error *ngIf="invalidEmail" class="email-alert">
                                Please use an official email address
                              </mat-error>
                              <mat-error *ngIf="exist" class="email-alert">
                                User already registered with this email
                              </mat-error>
                          </div>
                       
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Website </label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.website }}" [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Department</label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.department }}" [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Mobile Number </label>
                            <input type="number" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.phone }}" [disabled]="disableTextbox" />
                          </div>

                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label> Country </label>
                            <input appearance="outline" readonly type="text" class="form-control disable-input"
                              [disabled]="disableTextbox" [value]="userDetails?.country" />
                          </div>
                        </div>
    
                        <div class="rightone">
                          <div  [ngClass]="{ opacity: disableName }">
                            <label for="id"> Display Name
                              <span style="padding-left: 12px;" [matTooltip]="disableName ? 'Change display name' : ''" matTooltipPosition="right"><img
                                  [src]="darkTheme ? '../../../assets/images/edit-dark.svg' : '../../../assets/images/edit.svg'"
                                  (click)="toggleDisplayName()" style="cursor: pointer;width: 15px;" />
                              </span></label>
                            <input type="text" class="form-control disable-input" [readonly]="disableName"
                              [value]="userDetails?.displayname" name="dispname" #dispname
                              (keyup)="updateDetails('displayname', dispname.value)" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Organisation Name </label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.company }}" [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Organisation Type </label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.organisationType?.name }}" [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Role/Position</label>
                            <input type="text" readonly class="form-control disable-input"
                              placeholder="{{ userDetails?.role }}" [disabled]="disableTextbox" />
                          </div>
                          <div [ngClass]="{ opacity: disableTextbox }">
                            <label for="id"> Address </label>
                            <textarea readonly type="text" class="form-control disable-input message"
                              [disabled]="disableTextbox" [value]="this.address"></textarea>
    
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!disableName || !disableEmail" class="profile-btn" style="padding-bottom: 20px;padding-top: 10px;">
                      <button class="custom-button" (click)="cancelChanges()">Cancel</button>
                      <button mat-flat-button color="primary" (click)="editPersonalSettings()"
                        [disabled]="demoView || invalidEmail || exist || noChange">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
    
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header (click)="toggleShow()">
                  <mat-panel-title>
                    <span class="panel-head">Change Password</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div style="margin-left: 15px !important;">
                  <form action="" [formGroup]="userForm" class="form-vertical">
                    <div class="password-checked">
                      <div class="flex">
                        <div class="form-group">
                          <label for="id"> Old Password </label>
                          <input required formControlName="oldPassword" placeholder="Old password" type="password"
                            [type]="showPassword ? 'password' : 'text'" class="disable-input" (keydown)="Space($event)"
                            (keypress)="keyPressIn($event)" />
                          <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{ showPassword ? "visibility_off" : "visibility" }}
                          </mat-icon>
                          <div *ngIf="f['oldPassword'].touched && f['oldPassword'].invalid" class="alert alert-danger">
                            <div *ngIf="f['oldPassword'].errors?.['required']">
                              Password is required.
                            </div>
                          </div>
                          <div class="alert alert-danger">
                            {{oldmsg}}
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="id"> New Password </label>
                          <input required formControlName="newPassword" placeholder="New password" type="password"
                            [type]="newPassword ? 'password' : 'text'" class="disable-input" (keypress)="keyPress($event)"
                            (keydown)="Space($event)" />
                          <mat-icon matSuffix (click)="toggleconfirmPasswordVisibility()">
                            {{ newPassword ? "visibility_off" : "visibility" }}
                          </mat-icon>
                          <div *ngIf="f['newPassword'].touched && f['newPassword'].invalid" class="alert alert-danger">
                            <div *ngIf="f['newPassword'].errors?.['required']">
                              Password is required.
                            </div>
    
                            <div class="passerror"
                              *ngIf="f['newPassword'].errors?.['minlength'] || f['newPassword'].errors?.['pattern']">
                              Must contain <span *ngIf="f['newPassword'].errors?.['minlength']"> mimimum of 8 characters
                              </span>
    
                              <span *ngIf="f['newPassword'].errors?.['pattern']"> with at least one capital letter and a
                                special
                                character </span>
                            </div>
    
                          </div>
                          <div class="alert alert-danger">
                            {{newmsg}}
                          </div>
                        </div>
                      </div>
                      <div class="profile-btn">
                        <button class="custom-button" type="reset">Cancel</button>
                        <button mat-flat-button color="primary" (click)="submit()"
                          [disabled]="userForm.invalid || demoView">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
    
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header (click)="toggleShowaddress()">
                  <mat-panel-title>
                    <span class="panel-head">Change Address</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div style="margin-left: 15px !important;">
                  <form class="form-vertical" [formGroup]="addressForm">
                    <div class="password-checked">
                      <div class="form-group" style="width: 100%">
                        <div class="wrapper">
                          <div class="leftone">
                            <div style="margin-bottom: 10px;">
                              <label>Address line one<span>*</span></label>
                              <input required formControlName="address1" placeholder="Address line 1" type="text"
                                class="disable-input"
                                [ngClass]="{ 'is-invalid': sign.address1.errors && sign.address1.touched }" />
                              <div *ngIf="sign.address1.errors && sign.address1.touched" class="alert alert-danger">
                                <div *ngIf="sign.address1.errors.required"> Address is required</div>
                              </div>
                            </div>
                            <div>
                              <label>City<span>*</span></label>
                              <div class="wrap" style="width: 100%;">
                                <div>
                                  <input required formControlName="city" placeholder="City" type="text"
                                    class="disable-input"
                                    [ngClass]="{ 'is-invalid': sign.city.errors && sign.city.touched }" />
                                  <div *ngIf="sign.city.errors && sign.city.touched" class="alert alert-danger">
                                    <div *ngIf="sign.city.errors.required"> City is required</div>
                                  </div>
                                </div>
    
                              </div>
                            </div>
                            <div>
    
                            </div>
                          </div>
                          <div class="rightone">
                            <div style="margin-bottom: 10px;">
                              <label>Address line two</label>
                              <input formControlName="address2" placeholder="Address line 2" type="text"
                                class="disable-input" />
                            </div>
                            <div>
                              <label>Zip Code</label>
                              <input type="number" placeholder="Zip code" class="disable-input"
                                formControlName="zip_code" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <div class="profile-btn">
                      <button class="custom-button" type="reset">Cancel</button>
                      <button mat-flat-button color="primary" (click)="changeAddress()"
                        [disabled]="addressForm.invalid || demoView || userDetails?.userType != 'Super user'">
                        Save Changes
                      </button>
                    </div>
    
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>
    
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="main-head">Platform Settings</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
    
          <div class="platform-settings">
            <mat-accordion class="sub-accordian">
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header (click)="toggleS3()">
                  <mat-panel-title>
                    <span class="panel-head">Target Data Delivery</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div style="margin-left: 15px !important;">
                  <form [formGroup]="targetForm" class="form-vertical" style="margin-bottom: -5px;">
                    <div class="password-checked">
                      <div class="form-group" style="width: 100%">
                        <div class="wrapper">
                          <div class="leftone" style="margin-top: 9px;">
                            <mat-label>Storage Type<span>*</span></mat-label>
                            <mat-form-field appearance="outline" style="width: 100% !important;">
                              <mat-select placeholder="Choose type" formControlName="location" panelClass="target"
                                [disableOptionCentering]="true">
                                <mat-option *ngFor="let location of locations" [value]="location.name"
                                  (click)="storageType(location.value)">
                                  {{ location.displayValue }}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="target.location.errors && target.location.touched"
                                style="font-weight: 600; font-size: 10px;">
                                <mat-error *ngIf="target.location.errors.required" style="color: #ef3061">Storage Location
                                  is
                                  required</mat-error>
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="rightone">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
    
                  <form [formGroup]="awsForm" class="form-vertical" *ngIf="awsType">
                    <div class="password-checked">
                      <div class="form-group" style="width: 100%">
                        <div class="wrapper">
                          <div class="leftone">
                            <div style="margin-bottom: 10px;">
                              <label>Access Key<span>*</span></label>
                              <input required formControlName="accessKey" placeholder="Access key" type="text"
                                class="disable-input" />
                              <div *ngIf="aws.accessKey.errors && aws.accessKey.touched" class="alert alert-danger">
                                <div *ngIf="aws.accessKey.errors.required">Access Key is required</div>
                              </div>
                            </div>
                            <div>
                              <label>Region<span>*</span></label>
                              <div class="wrap" style="width: 100%;">
                                <div>
                                  <input required formControlName="region" placeholder="Region" type="text"
                                    class="disable-input" />
                                  <div *ngIf="aws.region.errors && aws.region.touched" class="alert alert-danger">
                                    <div *ngIf="aws.region.errors.required">Region is required</div>
                                  </div>
                                </div>
    
                              </div>
                            </div>
                          </div>
                          <div class="rightone">
                            <div style="margin-bottom: 10px;">
                              <label>Secret Key<span>*</span></label>
                              <input required formControlName="secretKey" placeholder="Secret key" type="text"
                                class="disable-input" />
                              <div *ngIf="aws.secretKey.errors && aws.secretKey.touched" class="alert alert-danger">
                                <div *ngIf="aws.secretKey.errors.required">Secret Key is required</div>
                              </div>
                            </div>
                            <div>
                              <label>Bucket Name<span>*</span></label>
                              <input required formControlName="bucketName" placeholder="Bucket name" type="text"
                                class="disable-input" />
                              <div *ngIf="aws.bucketName.errors && aws.bucketName.touched" class="alert alert-danger">
                                <div *ngIf="aws.bucketName.errors.required">Bucket Name is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <div class="profile-btn">
                      <button class="custom-button" type="reset">Cancel</button>
                      <button mat-flat-button color="primary" (click)="saveStorage('awsForm')"
                        [disabled]="awsForm.invalid || demoView || isawsForm || userDetails?.userType != 'Super user'">
                        Save Changes
                      </button>
                    </div>
                  </form>
    
                  <form [formGroup]="azureForm" class="form-vertical" *ngIf="azureType">
                    <div class="password-checked">
                      <div class="form-group" style="width: 100%">
                        <div class="wrapper">
                          <div class="leftone">
                            <div style="margin-bottom: 10px;">
                              <label>Account Name<span>*</span></label>
                              <input required formControlName="azureAccountName" placeholder="Account name" type="text"
                                class="disable-input" />
                              <div *ngIf="azure.azureAccountName.errors && azure.azureAccountName.touched"
                                class="alert alert-danger">
                                <div *ngIf="azure.azureAccountName.errors.required">Account Name is required</div>
                              </div>
                            </div>
                            <div>
                              <label>SAS Token<span>*</span></label>
                              <input required formControlName="azureSasToken" placeholder="SAS token" type="text"
                                class="disable-input" />
                              <div *ngIf="azure.azureSasToken.errors && azure.azureSasToken.touched"
                                class="alert alert-danger">
                                <div *ngIf="azure.azureSasToken.errors.required">SAS Token is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="rightone">
                            <div style="margin-bottom: 10px;">
                              <label>Azure Container Name<span>*</span></label>
                              <input required formControlName="azureContainer" placeholder="Azure container name"
                                type="text" class="disable-input" />
                              <div *ngIf="azure.azureContainer.errors && azure.azureContainer.touched"
                                class="alert alert-danger">
                                <div *ngIf="azure.azureContainer.errors.required">Azure Container Name is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <div class="profile-btn">
                      <button class="custom-button" type="reset">Cancel</button>
                      <button mat-flat-button color="primary" (click)="saveStorage('azureForm')"
                        [disabled]="azureForm.invalid || demoView || isazureForm || userDetails?.userType != 'Super user'">
                        Save Changes
                      </button>
                    </div>
                  </form>
    
                  <form [formGroup]="sftpForm" class="form-vertical" *ngIf="sftpType">
                    <div class="password-checked">
                      <div class="form-group" style="width: 100%">
                        <div class="wrapper">
                          <div class="leftone">
                            <div style="margin-bottom: 10px;">
                              <label>Hostname<span>*</span></label>
                              <input required formControlName="sftpHostname" placeholder="Hostname" type="text"
                                class="disable-input" />
                              <div *ngIf="sftp.sftpHostname.errors && sftp.sftpHostname.touched" class="alert alert-danger">
                                <div *ngIf="sftp.sftpHostname.errors.required">Hostname is required</div>
                              </div>
                            </div>
                            <div>
                              <label>Password<span>*</span></label>
                              <input required formControlName="sftpPassword" placeholder="Password" type="text"
                                class="disable-input" />
                              <div *ngIf="sftp.sftpPassword.errors && sftp.sftpPassword.touched" class="alert alert-danger">
                                <div *ngIf="sftp.sftpPassword.errors.required">Password is required</div>
                              </div>
                            </div>
                          </div>
                          <div class="rightone">
                            <div>
                              <label>Username<span>*</span></label>
                              <input required formControlName="sftpUsername" placeholder="Username" type="text"
                                class="disable-input" />
                              <div *ngIf="sftp.sftpUsername.errors && sftp.sftpUsername.touched" class="alert alert-danger">
                                <div *ngIf="sftp.sftpUsername.errors.required">Username is required</div>
                              </div>
                            </div>
    
                            <div style="padding-top: 9px;">
                              <label>Port<span>*</span></label>
                              <input required formControlName="port" placeholder="Port" type="number"
                                (input)="onInputChange($event)" class="disable-input" />
                              <div *ngIf="sftp.port.errors && sftp.port.touched" class="alert alert-danger">
                                <div *ngIf="sftp.port.errors.required">Port is required</div>
                                <div *ngIf="sftp.port.errors.pattern">Invalid Port number</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <div class="profile-btn">
                      <button class="custom-button" type="reset">Cancel</button>
                      <button mat-flat-button color="primary" (click)="saveStorage('sftpForm')"
                        [disabled]="sftpForm.invalid || demoView || issftpForm || userDetails?.userType != 'Super user'">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
    
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                *ngIf="userDetails?.allocated">
                <mat-expansion-panel-header (click)="toggleAllocation()">
                  <mat-panel-title>
                    <span class="panel-head">Allocation Details</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div style="margin-left: 15px !important;">
                  <table mat-table [dataSource]="dataSource" matSort class="table-class">
    
                    <ng-container matColumnDef="sensorType">
                      <th mat-header-cell *matHeaderCellDef>Sensor Type</th>
                      <td mat-cell *matCellDef="let row">{{ row.sensor }}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="sensorName">
                      <th mat-header-cell *matHeaderCellDef class="sensor-name-header">Sensor Name</th>
                      <td mat-cell class="sensor-name-cell" *matCellDef="let row">{{ row.operator }} {{row.sensor ===
                        "Stereo" && row.isTristereo ? ' - Tristereo ' : row.sensor === "Stereo" ? ' - Stereo ': ''}}
                        <span *ngIf="row.resolution"> ({{
                          row.resolution
                          }}) </span></td>
                    </ng-container>
    
                    <ng-container matColumnDef="taskingTier">
                      <th mat-header-cell *matHeaderCellDef>Tasking Tier</th>
                      <td mat-cell *matCellDef="let row" style="text-transform: capitalize;"> {{row.taskingTier}}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>Amount</th>
                      <td mat-cell *matCellDef="let row">
                        <span>$ {{ row.balance | customNumberFormat }}</span>
                      </td>
                    </ng-container>
    
                    <ng-container matColumnDef="totalTasks">
                      <th mat-header-cell *matHeaderCellDef>Number of Tasks</th>
                      <td mat-cell *matCellDef="let row">{{ row.totalTasks }}</td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
    
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>