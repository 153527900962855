<div class="container payment" style="padding-top: 8px;">
  <div class="flex">
    <div class="left">
      <div class="task-heads">
        <h4>Payment Options</h4>
      </div>

      <div class="tabset">
        <!-- Tab 1 -->
        <input
          type="radio"
          name="tabset"
          id="tab1"
          aria-controls="now"
          [checked] = '!isInIframe && !currentUser?.pointsEligible'
          (change)="chngPayOption('Pay now')"
          [disabled]="isInIframe || currentUser?.pointsEligible || currentUser?.allocated"
        />
        <label for="tab1" [ngClass]="{ 'cursor-style': isInIframe, 'disabled-cursor' : currentUser?.allocated }">Pay Now</label>
        <!-- Tab 2 -->
        <input
          type="radio"
          name="tabset"
          id="tab2"
          aria-controls="account"
          [checked] = 'isInIframe || currentUser?.pointsEligible || currentUser?.allocated'
          (change)="chngPayOption('Debit account')"
        />
        <label for="tab2" (click)="balanceCheck()">Debit Account</label>
        <!-- Tab 3 -->
        <input
          type="radio"
          name="tabset"
          id="tab3"
          aria-controls="later"
          (change)="chngPayOption('Pay later')"
          [disabled]="currentUser?.pointsEligible || currentUser?.allocated"
        />

        <label for="tab3" [ngClass]="{ 'disabled-cursor' : currentUser?.allocated }">Pay Later</label>

        <div class="tab-panels">
          <!------------------------------------------ Pay-Now-start ----------------------------------------------------->

          <section id="recent" class="tab-panel">
            <div class="thanks">
              <p>Thank you for your selection.</p>
            </div>
            <div class="left-align" style="padding-top: 15px">
              <p *ngIf="observationTasking">As part of this transaction, we will be temporarily holding the cost of the tipping sensor, which is <span class="currency">{{ tippingSensorCost | currency }}</span>.<br/>
                 If the tipping of the sensor could not be performed,
                 the amount would be returned to yo your account balance.</p>
              <p>Please read and accept the EULA before proceeding to order.</p>
              <div class="wrapper">
                <div class="set">
                  <div *ngFor="let oprtr of operatorsEula; let i = index">
                    <p style="text-align: left" fxLayout="row" fxLayoutGap="6px">
                      <mat-checkbox #eula [checked]="operatorsEula[i].checked" color="primary" (change)="
                          operatorsEula[i].checked = !operatorsEula[i].checked;
                          checkEula();
                          openEulaCheck(i, operatorsEula[i].checked)
                        ">
                        <span>I accept </span>
                      </mat-checkbox>
                      <span style="cursor: pointer" class="eula" (click)="openEula(i)">
                        {{ (currentUser.obfuscated && oprtr.obfuscateName) ? oprtr.obfuscateName : oprtr.name }} EULA
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="left-align storage">
              <p>Where do you want to receive the tasked data?</p>
              <span *ngFor="let item of storageOptions">
                <mat-checkbox style="padding-right: 15px" [(ngModel)]="item.checked"
                  (change)="onCheckboxChange($event, item)">{{ item?.label }}</mat-checkbox>
              </span>
            </div>

            <div class="left-align" style="padding-top: 15px">
              <span class="option">Select the pay now method : </span>
              <mat-radio-group [(ngModel)]="selectedPaymentOption" (change)="select()">
                <mat-radio-button value="PayPal" style="padding-right: 10px;">
                  <img width="73px" src="../../../assets/images/paypal-logo.png" alt="" style="cursor: default;" />
                </mat-radio-button>
                <mat-radio-button value="Stripe">
                  <img width="45px" src="../../../assets/images/stripe-logo.png" alt="" style="cursor: default;" />
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="padding-top: 10px">
              <p id="iframe">
                <mat-label *ngIf="paynowSubmit === true"
                  style="font-size: 17px; color: #1059a9; font-weight: 500" class="emails">Connecting to payment gateway..
                </mat-label>
              </p>

              <!-- <iframe id="pg" class="iframe" #pgdiv *ngIf="payUrl" style="height: 100vh; width: 100%" #iframe
                type="text/javascript" frameborder="0" [src]="payUrl | safe" (load)="isLoading = true; uploadDone()"
                sandbox="allow-modals allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation">
              </iframe> -->
            </div>

            <div class="btns">
              <button routerLink="/cart" class="custom-button">Back</button>
              <button class="proceed-button" mat-flat-button (click)="submit()" [disabled]="
                  !agreedTnC || clicked || storage || methodSelected || demoView
                ">
                Proceed
              </button>
            </div>
          </section>

          <!------------------------------------------ Pay-Now-end ----------------------------------------------------->

          <!------------------------------------------ debit-account-start ----------------------------------------------------->

          <section id="completed" class="tab-panel">
            <div class="thanks">
              <p>Thank you for your selection.</p>
            </div>
            <div class="left-align" style="padding-top: 15px">
              <div class="accbox">
                <mat-card class="mat" fxLayout="column" fxLayoutGap="15px" *ngIf="!currentUser.allocated">
                  <div class="sub">A/C Balance:</div>
                  <div class="currency">
                    <span *ngIf="!currentUser?.pointsEligible">{{ userDetails?.acBalance | currency }}</span>
                    <span *ngIf="currentUser?.pointsEligible">{{ userDetails?.acPoint }} points</span>
                  </div>
                </mat-card>
                <mat-card class="mat" fxLayout="column" fxLayoutGap="15px" *ngIf="currentUser.allocated">
                  <div class="sub">Number of Tasks:</div>
                  <div class="currency">
                    <span>{{ totalTasking }}</span>
                  </div>
                </mat-card>
              </div>

              <div *ngIf="userDetails?.pointsEligible && !currentUser?.allocated">
                <p *ngIf="(totalPoint > userDetails?.acPoint || isSufficient > 0 || totalPointWithGST > userDetails?.acPoint) && !observationTasking" style="padding-top: 10px">
                  <span *ngIf="!userDetails?.allocated">
                    You do not have sufficient balance in your account with us. <br />
                    Want to add some amount in your account?</span>
  
                  <span *ngIf="userDetails?.allocated && isSufficient > 0">
                    You have insufficient balance to task one or more preferred sensors.<br />
                    Please add money and allocate it to sensors with insufficient balance before paying.
                  </span>
  
                  <a *ngIf="!(userDetails?.cpc && userDetails?.allocated)" (click)="addMoney()" class="button-add-money">Add Money</a>
                  <a *ngIf="userDetails?.cpc && userDetails?.allocated" (click)="insufficientNotification(isSufficient)" class="button-add-money">Add Money</a>
                </p>

                <p *ngIf="userDetails?.acPoint >= totalPoint && ( !userDetails?.allocated || (userDetails?.allocated && isSufficient === 0)) && !observationTasking
                  && userDetails?.acPoint >= totalPointWithGST"
                  style="padding-top: 10px">
                  Your receipt will be emailed to
                  {{ userDetails.email }} after successful debit of your account
                  for the order amount. You will receive an email when your order
                  is ready.
                </p>
              </div>

              <div *ngIf="!userDetails?.pointsEligible && !currentUser.allocated">
                <p *ngIf="(totalSensorCost > userDetails?.acBalance || isSufficient > 0 || grandTotal > userDetails?.acBalance) && !observationTasking" style="padding-top: 10px">
                  <span *ngIf="!userDetails?.allocated">
                    You do not have sufficient balance in your account with us. <br />
                    Want to add some amount in your account?</span>

                  <span *ngIf="userDetails?.allocated && isSufficient > 0">
                    You have insufficient balance to task one or more preferred sensors.<br />
                    Please add money and allocate it to sensors with insufficient balance before paying.
                  </span>
  
                  <a *ngIf="!(userDetails?.cpc && userDetails?.allocated)" (click)="addMoney()" class="button-add-money">Add Money</a>
                  <a *ngIf="userDetails?.cpc && userDetails?.allocated" (click)="insufficientNotification(isSufficient)" class="button-add-money">Add Money</a>
                </p>
  
                <p *ngIf="userDetails?.acBalance >= totalSensorCost && ( !userDetails?.allocated || (userDetails?.allocated && isSufficient === 0)) && !observationTasking
                  && userDetails?.acBalance >= grandTotal"
                  style="padding-top: 10px">
                  Your receipt will be emailed to
                  {{ userDetails?.email }} after successful debit of your account
                  for the order amount. You will receive an email when your order
                  is ready.
                </p>
                <p *ngIf="observationTasking" style="padding-top: 10px">
                  <span *ngIf="totalTaskCost < userDetails?.acBalance">
                    We are going to temporarily deduct the cost of the tipping sensor, which is <span class="currency">{{ tippingSensorCost | currency }}</span> from the deposit balance. <br />
                    If tipping of the sensor could not be performed, the amount would be returned to the account balance.
                  </span>
                  <span *ngIf="totalTaskCost > userDetails?.acBalance">
                    We are going to temporarily deduct the cost of the tipping sensor, which is <span class="currency">{{ tippingSensorCost | currency }}</span> from the deposit balance. <br />
                    You do not have sufficient balance in your account with us. <br />
                    Want to add some amount in your account?
                  </span>
  
                  <a *ngIf="totalTaskCost > userDetails?.acBalance" (click)="addMoney()" class="button-add-money">Add Money</a>
  
                </p>
              </div>

              <!-- Allocated Users Start-->
              <div *ngIf="!userDetails?.pointsEligible && currentUser.allocated">
                <p *ngIf=" isSufficient > 0" style="padding-top: 10px">
      
                  <span>
                    You have insufficient balance to task one or more preferred sensors.<br />
                    Please add money and allocate it to sensors with insufficient balance before paying.
                  </span>
  
                  <a *ngIf="!(userDetails?.cpc && userDetails?.allocated)" (click)="addMoney()" class="button-add-money">Add Money</a>
                  <a *ngIf="userDetails?.cpc && userDetails?.allocated" (click)="insufficientNotification(isSufficient)" class="button-add-money">Add Money</a>
                </p>
  
                <p *ngIf="isSufficient === 0"
                  style="padding-top: 10px">
                  Your receipt will be emailed to
                  {{ userDetails?.email }} after successful debit of your account
                  for the order amount. You will receive an email when your order
                  is ready.
                </p>
              </div>
               <!-- Allocated Users End -->

              <p>Please read and accept the EULA before proceeding to order.</p>
            </div>
            <div class="wrapper">
              <div class="set">
                <div *ngFor="let oprtr of operatorsEula; let i = index">
                  <p style="text-align: left" fxLayout="row" fxLayoutGap="6px">
                    <mat-checkbox #eula [checked]="operatorsEula[i].checked" color="primary" (change)="
                        operatorsEula[i].checked = !operatorsEula[i].checked;
                        checkEula();
                        openEulaCheck(i, operatorsEula[i].checked)
                      ">
                      <span class="a">I accept </span>
                    </mat-checkbox>
                    <span style="cursor: pointer" class="eula" (click)="openEula(i)">
                      {{ (currentUser.obfuscated && oprtr.obfuscateName) ? oprtr.obfuscateName : oprtr.name }} EULA
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="left-align storage">
              <p>Where do you want to receive the tasked data?</p>
              <span *ngFor="let item of storageOptions">
                <mat-checkbox style="padding-right: 15px" [(ngModel)]="item.checked"
                  (change)="onCheckboxChange($event, item)">{{ item?.label }}</mat-checkbox>
              </span>
            </div>

            <div class="btns">
              <button routerLink="/cart" class="custom-button">Back</button>
              <button mat-flat-button (click)="debitAcount()" class="proceed-button" [disabled]="isDebitDisable()">
                Proceed
              </button>
            </div>
          </section>
          <!------------------------------------------ debit-account-end ----------------------------------------------------->

          <!------------------------------------------ Pay-Later-start ----------------------------------------------------->

          <section id="cancelled" class="tab-panel">
            <div class="thanks">
              <p>Thank you for your selection.</p>
            </div>
            <div class="left-align" style="padding-top: 15px">
              <p *ngIf="observationTasking">As part of this transaction, we will be temporarily holding the cost of the tipping sensor, which is <span class="currency">{{ tippingSensorCost | currency }}</span>.<br/>
                If the tipping of the sensor could not be performed,
                the amount would be returned to yo your account balance.</p>
              <p>
                If you are authorised with a credit term, your invoice will be
                posted to orders page. You will receive an email when your order
                is ready.
              </p>
              <p>Please read and accept the EULA before proceeding to order.</p>
            </div>
            <div class="wrapper">
              <div class="set">
                <div *ngFor="let oprtr of operatorsEula; let i = index">
                  <p style="text-align: left" fxLayout="row" fxLayoutGap="6px">
                    <mat-checkbox #eula [checked]="operatorsEula[i].checked" color="primary" (change)="
                        operatorsEula[i].checked = !operatorsEula[i].checked;
                        checkEula();
                        openEulaCheck(i, operatorsEula[i].checked)
                      ">
                      <span class="a">I accept </span>
                    </mat-checkbox>
                    <span style="cursor: pointer" class="eula" (click)="openEula(i)">
                      {{ (currentUser.obfuscated && oprtr.obfuscateName) ? oprtr.obfuscateName : oprtr.name }} EULA
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="left-align storage">
              <p>Where do you want to receive the tasked data?</p>
              <span *ngFor="let item of storageOptions">
                <mat-checkbox style="padding-right: 15px" [(ngModel)]="item.checked"
                  (change)="onCheckboxChange($event, item)">{{ item?.label }}</mat-checkbox>
              </span>
            </div>

            <div class="btns">
              <button routerLink="/cart" class="custom-button">Back</button>
              <button class="proceed-button" mat-flat-button (click)="submit()" [disabled]="
                  !agreedTnC ||
                  clicked ||
                  userDetails.paylater === false ||
                  storage ||
                  demoView
                ">
                Proceed
              </button>
            </div>
          </section>
          <!------------------------------------------ Pay-Later-end ----------------------------------------------------->
        </div>
      </div>
    </div>
    <div class="right">
      <div class="card">
        <div class="task-heads">
          <h4>
            Order number:
            <span class="orderlink" style="color: #1059a9">{{ orderId }}</span>
          </h4>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <p class="taskStyle" style="margin-left: -7px;width: max-content; font-size: 14px">
              Task Name: {{ selectedTask?.taskname }}
            </p>
          </thead>
          <thead>
            <tr>
              <th scope="col" class="tdhead">Sensor Name</th>
              <th scope="col" class="tdhead">Sensor ID</th>
              <th scope="col" class="tdhead" style="text-align: right;" [ngStyle]="{'padding-right': currentUser?.allocated ? '20px' : '52px'}">
                <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else otherLabel">
                  Task Number
                </ng-container>
                <ng-template #otherLabel>
                 Total
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let item of TASKDATA; let i = index" class="itemRows">
            <tr *ngFor="let el of TASKDATA[i].cartdata">
              <td class="noBorder">
                <span
                  *ngIf="el?.operatorKey !== OPERATORS.SPIRE"
                >
                  {{ (currentUser.obfuscated && el.obfuscateName) ? el.obfuscateName : el.name }} - {{ el.sensor }} <span *ngIf="el.resolution"> ({{
                    el.resolution
                    }}) </span>
                  <img
                  *ngIf="el?.resolutionTooltip"
                  style="margin-left: 5px; margin-top: -4px"
                  width="14px"
                  [src]="tooltipImagePath"
                  [matTooltip]="el.resolutionTooltip"
                  />
                </span>
                <span
                  *ngIf="el?.operatorKey === OPERATORS.SPIRE"
                >
                  {{ (currentUser.obfuscated && el.obfuscateName) ? el.obfuscateName : el.name }} - {{ el.sensor }}
                </span>
              </td>
              <td class="noBorder" id="taskField">{{ el.uuid }}</td>
              <td class="noBorder" style="padding-right: 21px">
                <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{ el.cost | customNumberFormat }}</span>
                <span *ngIf="currentUser?.pointsEligible">{{ el.point }} points</span>
                <div *ngIf="!currentUser?.pointsEligible && currentUser.allocated" style="display: flex;justify-content: end;">
                  <span>{{ el.taskNumber }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="costamt flex cost" fxLayout="row" fxLayoutGap="25px" style="font-family: Barlow; font-size: 14px">
          <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else elseTemplate">
            <span> Total data number</span>
          </ng-container>
          <ng-template #elseTemplate>
            <span> Total data cost </span>
          </ng-template>
          <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{ totalSensorCost | customNumberFormat }}</span>
          <span *ngIf="currentUser?.pointsEligible">{{ pointTotal}} points</span>
          <span *ngIf="!currentUser?.pointsEligible && currentUser.allocated">{{ taskTotalNumber }}</span>
        </div>
        <div *ngIf="TASKDATA && TASKDATA[0]?.mlTasks?.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="tdhead">
                  <span *ngIf="(TASKDATA[0]?.taskStrategy != 'Tip-and-Cue' && TASKDATA[0]?.taskStrategy != 'Tip-and-Cue-Outcome')"> Information Feeds</span>
                  <span *ngIf="(TASKDATA[0]?.taskStrategy === 'Tip-and-Cue' || TASKDATA[0]?.taskStrategy === 'Tip-and-Cue-Outcome')"> Tip-and-Cue (Observation)</span>
                </th>
                <th scope="col" class="tdhead" style="text-align: right; padding-right: 52px">
                  Total
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let item of TASKDATA; let i = index" class="itemRows">
              <tr *ngFor="let el of TASKDATA[i].mlTasks">
                <td class="noBorder">
                  {{(currentUser?.obfuscated && el?.obfuscateName) ? el.obfuscateName : el.name}}
                </td>
                <td class="noBorder" style="text-align: right" [ngClass]="{'cost-align': (TASKDATA[0]?.taskStrategy === 'Tip-and-Cue' || TASKDATA[0]?.taskStrategy === 'Tip-and-Cue-Outcome')}">
                  <span *ngIf="!currentUser?.pointsEligible">{{ currencySign }}{{ el.price | customNumberFormat }}</span>
                  <span *ngIf="currentUser?.pointsEligible">{{ el.point }} points</span>
                  <span *ngIf="(TASKDATA[0]?.taskStrategy != 'Tip-and-Cue' && TASKDATA[0]?.taskStrategy != 'Tip-and-Cue-Outcome')" style="cursor: pointer;padding-left: 6px;" (click)="removeService(el, TASKDATA[i])"
                    ><img src="../../../assets/images/delete.png" alt=""
                  /></span>
                </td>
              </tr>
              <tr>
                <td class="costamt">
                  <span *ngIf="(TASKDATA[0]?.taskStrategy != 'Tip-and-Cue' && TASKDATA[0]?.taskStrategy != 'Tip-and-Cue-Outcome')"> Total information feeds cost</span>
                  <span *ngIf="(TASKDATA[0]?.taskStrategy === 'Tip-and-Cue' || TASKDATA[0]?.taskStrategy === 'Tip-and-Cue-Outcome')"> Total observation cost</span>
                </td>
                <td class="costamt" style="text-align: right; padding-right: 21px">
                  <span style="padding-right: unset !important">
                    <span *ngIf="!currentUser?.pointsEligible">{{ currencySign }}{{ totalFeedCost | customNumberFormat }}</span>
                    <span *ngIf="currentUser?.pointsEligible">{{ totalFeedCost > 0 ? getPoints(totalFeedCost) : 0 }} points</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Value Added Array (DEM) -->
        <div *ngIf="valueAddedOptionTotal > 0">
          <table class="table">
            <thead>
              <p class="taskStyle" style="margin-left: -7px;width: max-content; font-size: 14px; color: #262626;">
                Value Added Data Products
              </p>
            </thead>
            <tbody>
              <ng-container *ngFor="let valArr of valueAddedProducts; let i = index">
                  <table class="table itemArr" style="margin-bottom: 0px;">
                    <thead (click)="toggleVisibility(i)">
                      <tr>
                        <th scope="col" class="tdhead">
                         <span>{{ ((currentUser.obfuscated && valArr.obfuscateName) ? valArr.obfuscateName : valArr.name) + ' (' + valArr.sensor + ' - ' + valArr.resolution + ')' }}</span>
                        </th>
                        <th scope="col" class="tdhead" style="text-align: right; padding-right: 2px;" *ngIf="!currentUser?.pointsEligible">
                          {{ currencySign }}{{ valArr.total | customNumberFormat }}
                          <mat-icon style="padding-top: 8px;">{{ toggleStates[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </th>
                        <th scope="col" class="tdhead" style="text-align: right; padding-right: 2px;" *ngIf="currentUser?.pointsEligible">
                          {{ valArr.demTotalPoint }} points
                          <mat-icon style="padding-top: 8px;">{{ toggleStates[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="toggleStates[i]">
                        <tr *ngFor="let ele of valArr.items" style="background-color: white;">
                          <td class="noBorder">
                            <span *ngIf="ele?.valueAddedDataProduct === 'indices'">{{ ele.actionProvider }}</span>
                            <span *ngIf="ele?.valueAddedDataProduct !== 'indices'">{{ ele.actionProvider + ' ( ' + ele.providerResolution + ' )' }}</span>
                          </td>
                          <td class="noBorder" style="text-align: right; padding-right: 29px">
                            <span *ngIf="!currentUser?.pointsEligible">{{ currencySign }}{{ ele.totalareacost | customNumberFormat }}</span>
                            <span *ngIf="currentUser?.pointsEligible">{{ ele?.valueAddedDataProduct === 'indices'? ele.totalareapoints : ele.point }} points</span>
          
                          </td>
                        </tr>
                    </tbody>
                  </table>
              </ng-container>
              <tr>
                <td class="costamt" style="text-align: right; padding-right: 21px">
                  <span style="float: left;"> Total value added data products cost</span>
                  <span style="padding-right: unset !important">
                    <span *ngIf="!currentUser?.pointsEligible">{{ currencySign }}{{ valueAddedOptionTotal | customNumberFormat }}</span>
                    <span *ngIf="currentUser?.pointsEligible">{{ valueAddedOptionTotal > 0 ? getPoints(valueAddedOptionTotal) : 0 }} points</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Value Added Array (DEM) -->

        <div class="costamt flex cost" fxLayout="row" fxLayoutGap="25px" style="
            font-family: Barlow;
            font-size: 20px;
            font-weight: 800;
            margin-top: 20px;
          ">
          <span>Grand Total ({{ taxType }})</span>
          <span *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">{{ currencySign }}{{ grandTotal | customNumberFormat }}</span>
          <span *ngIf="currentUser?.pointsEligible">{{ grandTotal > 0 ? getPoints(grandTotal) : 0 }} points</span>
          <span *ngIf="!currentUser?.pointsEligible && currentUser.allocated">{{ taskTotalNumber }}</span>
        </div>
      </div>
      <hr style="padding: 6px; border-color: #EAECF0; border-top: 0;">
    </div>
  </div>
</div>
