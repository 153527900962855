import { Component, OnInit, ElementRef, Input, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import * as Cesium from 'cesium';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionPopupComponent } from './collection-popup/collection-popup.component';
import * as JSZipUtils from '../../../assets/script/jszip-utils.js';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
var turf = require('@turf/turf');
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { PopupService } from 'src/app/services/popup.service';
import { DownloadService } from 'src/app/services/download.service';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { OPERATORS } from 'src/app/services/constants';
import { BehaviorSubject } from 'rxjs';
import TIFFImageryProvider from 'tiff-imagery-provider';
import { DatePipe, formatDate } from '@angular/common';
import html2canvas from 'html2canvas';
import { ReturnRefundComponent } from './return-refund/return-refund.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';
import ScreenSpaceEventHandler from 'cesium/Source/Core/ScreenSpaceEventHandler';
import { WarningPopupComponent } from './warning-popup/warning-popup.component';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { CreateTaskConfirmDialog } from './confirm-dialog/confirmdialog';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

interface LegendItem {
  color: string;
  value?: number;
}

interface LegendData {
  minValue: number;
  maxValue: number;
  items: LegendItem[];
}

interface CustomEntity extends Cesium.Entity {
  isOriginal?: boolean;
  type?: string;
}



interface CustomGeoJsonDataSource extends Cesium.GeoJsonDataSource {
  sensorId?: string;
  sceneId?: string;
  refreshId?: string;
}

interface AnnotatedDivElement extends HTMLDivElement {
  sceneId?: string;
}


@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {
  vessel =false;
  nonVesselFeeds: string[] = ['Flood detection', 'Tree crown detection and count',
    'Deforestation detection and area'
  ];
  offset = 0.01;
  opportunityId: any;
  feasibilityArray: any = [];
  selectedSites: any = [];
  projectSelected: any;
  dateRange: boolean = false;
  metaDataTooltip: any;
  taskSelected: boolean = false;
  uslBB: any;
  USLscnLyr: any = [];
  SpirescnLyr: any = [];
  RF: boolean = false;
  sceneBands: any;
  signalBands: any;
  bandTaskId: any;
  bandSensorId: any;
  signalSensorId: any;
  bandSelected: string = 'both';
  selectedStartDate: any;
  selectedEndDate: any;
  recurrentTask: boolean = false;
  isInIframe: boolean;
  sensorsIS: boolean = true;
  sceneSelected: any;
  leftBand: boolean = false;
  rightBand: boolean = false;
  taskCreatedDate: any;
  displayedIS: any;
  spire: boolean = false;
  sliderSelected: any;
  AISdata: boolean;
  imageLoaded: boolean = false;
  currentLongitude: number;
  currentLatitude: number;
  annotateClicked: boolean = false;
  currentTaskId: any;
  currentSensorId: any;
  sceneId: any;
  recurrentSlider: boolean = false;
  selectedMetric: string = 'kg';
  sourceRateArray: any = [];
  usl: boolean = false;
  imageryLayerMap = new Map();
  boundingBoxMap = new Map();
  datePlace: boolean = false;
  monthSelected: any;
  siteSelected: any;
  selectedSite: any;
  selecetedData: any;
  selectedDropdownType: string;
  currentCollectionIndex: any;
  currentSensorIndex: any;
  currentSceneIndex: any;
  currentEvent: { checked: boolean; };
  currentType: any;
  currentTask: any;
  currentSites: any;
  selectedType: any;
  dateDisable: boolean = false;
  downloadTooltip: any = '';
  tooltipDisabled: boolean = true;
  downloadedImage: string;
  createNewTaskHandler: any;
  selectedScene: any;
  noScene: boolean = true;
  audioAvailable: boolean = false;
  currentRfSignals: any = [];
  sensorLength: number = 0;
  selectedRecurrencyArray: any;
  demoView: boolean = false;
  legendContainer = document.getElementById('legend-container');
  dateSelected: boolean;
  feedInfo: boolean = false;
  feedData: string;
  currentSignal: any;
  filterStart: any;
  filterEnd: any;
  toggleOptions(select: MatSelect): void {
    select.open();
  }
  isFirst = true;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource: any[] = [];
  tableDataLength = 0;
  color = '#1059a9';
  CARTDATA: any[] = [];
  isVisible = false;
  isDisabledAnottationBtn:boolean = true;
  expandedElement: any | null;
  currentUser: any;
  curOption: any;
  NAVLISTRT: any;
  tasktabs: any;
  tasksCount: any;
  userid: any;
  selectedSensors: any = [];
  selectedCost = 0;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50];
  showFirstLastButtons = true;
  interval: any;
  refreshCount = 0;
  roll: any = 0;
  leftLayer: any;
  rightLayer: any;
  activeSliderLayer = null;
  preStatus = '00Nolayer';
  layer = 0;
  curStatus = '';
  imgLyrIndx = -1;
  baseLayer: any;
  hs: any;
  imageryViewModels: any = [];
  terrainViewModels = [];
  baseLayers: any = [];
  sliderLayers: any = [];
  showMe = true;
  @Input() accept = '.topojson, .geojson, .kml';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('bandSelect') bandSelect: MatSelect;
  @ViewChild('slider') slider: MatSlider;
  fileAttr = 'Choose File';
  acceptFile = '.topojson, .geojson, .kml';
  selectedItems = 0;
  selectedSubDailyItems: any = [];
  selectedSubDailyItemValue = 1;
  collections: any;
  checked = true;
  drawingOn = false; // AT21 = false; satllogic = false;
  viewer: any;
  viewer2: any;
  scene: any;
  longLatStr: string = '';
  compassOverlay: any;
  latlonOverlay: any = document.createElement('div');
  sliderOverlay: any;
  acc: any;
  satEntity: any;
  datafile: any;
  imgUrls: any;
  dataForm: FormGroup | any;
  options: any;
  LEFTOPTIONS: any = [];
  RIGHTOPTIONS: any = [];
  selectedLeft: string;
  selectedRight: string;
  baseAlpha: any;
  FP: any;
  metadata: any;
  jump = false;
  panelOpenState = false;
  isExpanded: boolean[] = [];
  previousSelectionText: any = null;
  previousEndText: any = null;
  subDailyCount: any = null
  previousSelection: any = null;
  previousSelectedProject: any = null;
  previousSelectedTask: any = null;
  downloadedScene: any = [];
  downloadedSensor: any = [];
  refundRequestScenes: any = [];
  infoserviceModelType = 'Feature detection';
  BASELAYERS = [
    {
      name: 'Bing Maps Aerial',
      url : new Cesium.ProviderViewModel({
        name: 'Bing Maps Aerial',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingAerial.png'
        ),
        tooltip: 'Bing Maps Aerial',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 2 });
        },
      })
    },
    {
      name: 'Sentinel-2',
      url: new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      }),
    },
    {
      name: 'Natural Earth',
      url: new Cesium.ProviderViewModel({
        name: 'Natural Earth',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/naturalEarthII.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3813 });
        },
      }),
    },
    {
      name: 'Bing Maps Road',
      url: new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      }),
    },
    {
      name: 'OSM',
      url: new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      }),
    },
  ];
  scnLyr: any = [];
  scnLyr2: any = [];
  collectionsData: any = undefined;
  collection: any;
  subDailyArray: any = [];
  subDailyFinalArray: any = [];
  sensorList: any
  collectionIndex: any;
  taskName: any;
  taskid: any;
  taskUuid: any;
  splitPosition: number;
  overlay: any = [100, 100];
  baseOverlay: any = 100;
  projectData: any;
  projectName: any;
  fail: number;
  cesiumToken: any;
  recurrencyOption: any;
  progress: any = 0;
  progressSubscription: Subscription;
  disable: boolean = true;
  freeze: boolean = false;
  mapLoad: boolean = false;
  fullCollection: any;
  filteredCollection: any = [];
  defaultSubdailyCount = 1;
  subDailyCountarray: any = [];
  OPERATOR: any;
  downloadObj: any;
  downloadClick: boolean;
  operatorArray: any = [];
  showDailyRecurrencyCheckboxes: boolean = false;
  maxSelections = 2;
  recurrencyArray: any = [];
  selectedValueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedSensorSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedcompareSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedValue$ = this.selectedValueSubject.asObservable();
  selectedSensor$ = this.selectedSensorSubject.asObservable();
  selectedcompare$ = this.selectedcompareSubject.asObservable();
  selectedCheckboxes: any = [];
  SensorIndex: any = 0;
  selectionChange = new BehaviorSubject<any>('');
  subSelectionSelectionChange = new BehaviorSubject<any>('');
  selection: string = '14/06/2023 - 20/06/2023';

  selectionLabel: any = null;
  selectionLabelEnd: any = null;
  selectionLabels: any = '14/06/2023 - 20/06/2023';
  selectedSubdaily: any;
  selectedIndex: any = 0;
  left = 65;
  entitywidth1 = 65;
  entitywidth2 = 65;
  isDragging = false;
  initialX: number;
  initialY: number;
  public emissionMessage: string = '';
  showSubDailyList = false
  splitRightPostion = false
  splitLeftPostion = false
  taskCollection: any;
  selectedIS: boolean[] = [];
  selectedBand = 'X band';
  legendData: LegendData;
  ghglegend: string;
  scaleArray: any = [];
  legendColor: string;
  restoreRequestedSensors: any = [];
  darkTheme: boolean;
  tooltipImagePath: string;
  downloadImage: string;
  refundImage: string;
  feedLoaded: boolean = false;
  vesselData: any;
  timestamps: string[] = [];
  selectedData: any;
  sliderShow: boolean = false;
  days: number = 0;
  dateRangeArray: any[] = [];
  historicalVesselData: any[] = [];
  currentDataSources: { [key: string]: Cesium.GeoJsonDataSource } = {};
  currentVessels: { [key: string]: Cesium.GeoJsonDataSource } = {};
  currentClydeVessels: { [key: string]: Cesium.GeoJsonDataSource } = {};
  previousIndex: number = -1;
  handler: ScreenSpaceEventHandler;
  handlers: ScreenSpaceEventHandler;
  rfHandler: ScreenSpaceEventHandler;
  signalhHandlers: ScreenSpaceEventHandler;
  audioClickHandler: ScreenSpaceEventHandler;
  imageType: string = 'png';
  clickedEntity: any = null;
  sliderView: boolean = false;
  timeseries: boolean = false;
  groupedScenes: any;
  sliderDateRangeArray: any[] = [];
  overlayVessel: any;
  currentIndex = 0;
  currentSliderValue: number = 0;
  overlayClydeVessel: any;
  activePopups: { entity: any, element: HTMLElement }[] = [];
  activeTooltips: any[] = [];
  collectionContainer:any = [];
  scenePos:any;
  private lastAnnotation: Cesium.Entity | null = null;
  private lastTooltip: HTMLDivElement | null = null;
  newTaskButtonClicked: boolean = false;
  selectedOptions: any[] = []; // Value added option
  currentProductLayer:any = [];
  currentEntity = null;
  currentDownloadingVADP: any;
  refundRequestVADP: any = [];
  refundRequestSR: any = [];
  currentDownloadingSR:any;
  selectedSR:any = null;
  audio = new Audio(); // Create an Audio object
  signalSelected: any = 'All';
  currentDownloadingIndices:any;
  selectedIndices:any = null;
  colorCode = {
    'ClydeSpace': 'Orange',
    'USL': 'Red',
    'Spire': 'Yellow',
    'Spire-RF': 'Yellowgreen'
  };
  recurrentColorCode = {
    0: 'YELLOWGREEN',
    1: 'ROSYBROWN',
  };
  refreshHistoryColor = {
    'ClydeSpace': 'AQUA',
    'Spire': 'HOTPINK'
  };
  selectedRecurrency: any = null;

  constructor(
    private httpClient: HttpClient,
    private taskService: TaskService,
    private apiService: ApiService,
    private el: ElementRef,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private popupService: PopupService,
    private downloadService: DownloadService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private matdialog: MatDialog,
    private localStorageService: DataService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService

  ) {
    
    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
    this.authService.getCurrentUser();
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        castUser.unsubscribe();
        this.loadUserDetails(this.currentUser?.id);
      }
    );

    this.cesiumToken = environment.cesiumAccessToken;
    this.OPERATOR = OPERATORS;
    this.spinnerService.hide();
    this.disable = true;
    this.downloadObj = this.downloadService.getProgress();

    if (this.downloadObj?.progress && this.downloadObj?.downloadClick) {
      this.progress = this.downloadObj?.progress;
      this.downloadProgress(this.downloadObj);
    }
    this.progressSubscription = this.downloadService.getProgressObservable().subscribe(
      progress => {
        this.progress = progress?.progress;
        this.downloadProgress(progress);
      }
    );
    localStorage.removeItem('refund-request');
    localStorage.removeItem('scene-downloaded');
    localStorage.removeItem('restore-request');
    localStorage.removeItem('sensor-downloaded');

    this.isInIframe = this.themeService.isInIframe();

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

    this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    this.downloadImage = this.darkTheme ? '../../../assets/images/icon-dark.svg' : '../../../assets/images/icon-img.svg';
    this.refundImage = this.darkTheme ? '../../../assets/images/refund-dark.png' : '../../../assets/images/refund.png';
    this.downloadedImage = this.darkTheme ? '../../../assets/images/cloud_done_dark.svg' : '../../../assets/images/cloud_done.svg';
    });
  }

  ngOnInit(): void {

    this.selectionChange.subscribe((selectedValue: any) => {
      if (selectedValue && selectedValue?.start) {
        const start = this.formatDate(new Date(selectedValue?.start))
        const end = this.formatDate(new Date(selectedValue?.end));
        const combinedDate = `${start} - ${end}`;
        this.selectionLabel = new Date(selectedValue?.start);
        this.selectionLabelEnd = new Date(selectedValue?.end);
        this.selectionLabels = combinedDate;
      }

    });
    this.subSelectionSelectionChange.subscribe((selectedValue: any) => {
      this.selectedSubDailyItemValue = selectedValue
    });
    localStorage.removeItem('taskData');

    this.compassOverlay = document.getElementById('compass');
    this.baseLayers = [];
    this.baseAlpha = 1;
    this.BASELAYERS.forEach((elem) => {
      this.baseLayers.push(elem);
      this.imageryViewModels.push(elem.url);
    });
    this.dataForm = this.fb.group({
      datafile: ['', [Validators.required]],
    });
    var west = 40.0;
    var south = -20.0;
    var east = 120.0;
    var north = 70.0;
    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    Cesium.Ion.defaultAccessToken = this.cesiumToken;
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

    //Sentinel data
    // const worldTerrain =  Cesium.createWorldTerrain();
    const worldTerrain = Cesium.createWorldTerrain({ requestWaterMask: false }); 
    var credit = new Cesium.Credit(
      "<a style='padding-left: 3px; display:none;' class='credit'  target='_blank' href='https://tasking.eartheye.space'><img width='120px' src='assets/images/white-logo.png' alt='EarthEye'></a>"
    );
    const cesiumContainerNew = document.createElement('div');

    cesiumContainerNew.id = 'cesiumContainer1';
    document.getElementsByTagName('body')[0].appendChild(cesiumContainerNew);
    var credit = new Cesium.Credit(
      "<a style='padding-left: 3px' class='credit' target='_blank' href='https://tasking.eartheye.space'><img width='120px' src='assets/images/white-logo.png' alt='EarthEye'></a>"
    );




    // Fit a SingleTileImageryProvider inside the cutout on the lowest layer




    const viewer = new Cesium.Viewer('cesiumContainer1', {
      imageryProviderViewModels: this.imageryViewModels,
      terrainProviderViewModels: [],
      // imageryProvider: baseLayer,
      // terrainExaggeration: 2.0,
      // terrainProvider: worldTerrain,
      baseLayerPicker: true,
      geocoder: true,
      homeButton: true,
      // navigationHelpButton: false,
      fullscreenButton: true,
      selectionIndicator: true,
      shouldAnimate: true,
      animation: false,
      timeline: false,
      sceneModePicker: true,
      sceneMode: Cesium.SceneMode.SCENE2D,
    });

    this.viewer = viewer
    this.viewer.scene.globe.depthTestAgainstTerrain = true;
    this.viewer.scene.globe.enableLighting = true;

    const homeButton = this.el.nativeElement.querySelector('.cesium-home-button');
           
    if (homeButton) {
      this.renderer.listen(homeButton, 'click',  () => {        
        this.zoomToLocation();
      });
    }

    this.options = {
      camera: this.viewer.scene.camera,
      canvas: this.viewer.scene.canvas,
    };
    this.showLatLon();
    this.sliderOverlay = document.getElementById('slider');
    this.acc = document.getElementsByClassName('accordion');

    this.sliderOverlay.id = 'slider';
    this.sliderOverlay.className = 'noSlider';
    this.sliderOverlay.style['z-index'] = '0';
    this.viewer.container.appendChild(this.sliderOverlay);

    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }

    if (!this.isInIframe) {
    this.viewer.scene.frameState.creditDisplay.addDefaultCredit(credit);
    }

    this.viewer.imageryProviderViewModels = this.imageryViewModels;

    this.options = {
      camera: this.viewer.scene.camera,
      canvas: this.viewer.scene.canvas,
    };
    this.showLatLon();
    this.sliderOverlay = document.getElementById('slider');
    this.acc = document.getElementsByClassName('accordion');
    this.sliderOverlay.id = 'slider';
    this.sliderOverlay.className = 'noSlider';
    this.sliderOverlay.style['z-index'] = '0';
    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }

    if (!this.isInIframe) {
    this.viewer.scene.frameState.creditDisplay.addDefaultCredit(credit);
    }
    // For split screen
    const slider = document.getElementById('sliders') as HTMLCanvasElement;
    this.splitPosition = slider!.offsetLeft / slider!.parentElement!.offsetWidth
    viewer.scene.splitPosition = slider!.offsetLeft / slider!.parentElement!.offsetWidth;
    let handler = new Cesium.ScreenSpaceEventHandler(slider);


    let moveActive: boolean = false;

    let move = ((movement: any) => {
      const rightDiv = document.getElementsByClassName("split-heading-right");
      const leftDiv = document.getElementsByClassName("split-heading-left");
      const spliterDiv = document.getElementsByClassName("slider");

      if (!moveActive)
        return;
      const relativeOffset = movement.endPosition.x;

      if (slider.offsetLeft + relativeOffset >= 0 && slider.offsetLeft + relativeOffset <= slider!.parentElement!.offsetWidth) {

        const splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement!.offsetWidth;
        slider.style.left = `${100.0 * splitPosition}%`;
        this.splitPosition = splitPosition;
        viewer.scene.splitPosition = splitPosition;

        // Hide or show entities based on split position

        if (viewer.scene.splitPosition > 0.5) {
          // Hide entities for the left side
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = true;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }

          const rect1 = spliterDiv[0]?.getBoundingClientRect();
          const rect2 = leftDiv[0]?.getBoundingClientRect();
          if (((rect1?.left === 0 || rect1?.left > rect2?.left) && viewer.scene.splitPosition > 0.6)) {
            this.splitRightPostion = true
            this.splitLeftPostion = false
          } else {
            this.splitLeftPostion = true
          }


          // Hide more entities as needed
        } else if (viewer.scene.splitPosition < 0.5) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = false;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = true;
          }
          const rect1 = spliterDiv[0]?.getBoundingClientRect();
          const rect2 = rightDiv[0]?.getBoundingClientRect();
          if ((rect2?.right === 0 || rect1?.right < rect2?.right) && viewer.scene.splitPosition < 0.3) {
            this.splitRightPostion = false
            this.splitLeftPostion = true
          } else {
            this.splitRightPostion = true
          }
        }
      }

    });

    handler.setInputAction(() => { moveActive = true }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

    handler.setInputAction(() => { moveActive = true }, Cesium.ScreenSpaceEventType.PINCH_START);

    handler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);

    handler.setInputAction(() => { moveActive = false }, Cesium.ScreenSpaceEventType.LEFT_UP);

    handler.setInputAction(() => { moveActive = false }, Cesium.ScreenSpaceEventType.PINCH_END);


    viewer.screenSpaceEventHandler.setInputAction(function(click) {
      // Disable double-click behavior
      click.preventDefault();
  }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

  }

  ngOnDestroy(): void {

    this.selectionChange.unsubscribe();
  }
  getProjects() {
    this.apiService.getProjectName().subscribe((res: any) => {
      this.projectData = res.result;
      this.projectName = this.projectData[0]?.project;
      this.getCollections(res.result[0]?.projectid,0);
      this.projectSelected = res.result[0]?.projectid;
      this.collectionIndex = 0;
    });
  }

  getCollectionData(userId: any) {
    this.tableDataLength = 0;
    this.taskService.getCollections(userId).subscribe(
      (result: any) => {
        this.tableDataLength = result.results.length > 0 ? result.results.length : 0;
        this.dataSource = result.results;
        if (result.results.length > 0) {
          this.collectionsData = result.results;
          this.collections = [];
          let operators = [];
          result.results.forEach((item, index) => {
            const project = item.project;
            const taskname = item.taskname;
            const taskUuid = item.sensors[0]?.sensor_uuid;
            const taskId = item.id;
            let sensorName = item?.sensors?.[0]?.sensor;
            const target = JSON.parse(item.target);
            const sensors: any = [];

            item.sensors.forEach((sensor, i) => {
              const scenes: any = [];
              if (sensor?.scenes?.length > 0) {


                sensor?.scenes?.forEach((scene, index) => {
                  scene.checked = false;
                  let thumbnailUrl;

                  if (scene?.id != null) {
                    sensor.scenes[index] = scene;
                    thumbnailUrl =
                      environment.eeTaskServer +
                      '/' +
                      scene.thumbnailUrl || '';


                    const exists = scenes.find((sn: any) => sn.scene_id === scene?.scene_id)
                    if (!exists) {
                      scenes.push({
                        id: scene.id,
                        name: `Scene ${index + 1}`,
                        sceneIdx: index + 1,
                        footprint: scene.footprint,
                        metadata: scene.metadata,
                        metadata_tooltip: scene?.metadata_tooltip,
                        thumbnailUrl: thumbnailUrl,
                        previewUrl: scene.previewUrl,
                        downloadUrl: scene.downloadUrl,
                        checked: false,
                        color: 'primary',
                        scene_id: scene?.scene_id,
                        operatorKey: sensor?.operatorKey,
                        isName: scene?.isName,
                        png_footprint: scene?.png_footprint,
                        tif_footprint: scene?.tif_footprint,
                        legendScale: scene?.legendScale,
                        legend_min: scene?.legend_min,
                        legend_max: scene?.legend_max,
                        legend_long: scene?.legend_long,
                        legend_lat: scene?.legend_lat
                      });
                    }
                  }
                });
                if (scenes.length > 0) {
                  sensors.push({ name: sensor.sensor, scenes, checked: false, id: sensor?.sensor_uuid, downloadUrl: sensor?.downloadUrl,});
                }


              }
            });
            let taskCollection = item?.taskcollection;
            let captures = item?.captures;
            let sensorsName = item?.sensorsName;
            let info = item?.info;
            if (sensors.length > 0) {
              this.collections.push({
                project,
                taskname,
                target,
                sensors,
                taskId,
                sensorName,
                taskCollection,
                captures,
                sensorsName,
                info,
              });
              this.collection = this.collections[0];
              this.collectionIndex = 0;
              this.taskName = this.collections[0].taskname;
              this.taskid = this.collections[0].taskId;
              this.selectionChange.next(this.collection.recurrencyArray[0])

            }

          });
        } else {
          this.collectionsData = [];
        }
      },
      (err) => {
        return false;
      }
    );
  }

  showLatLon() {
    const viewer = this.viewer;
    const latLongOverlay = this.latlonOverlay;
    const canvas = this.viewer.canvas;
    viewer.container.appendChild(latLongOverlay);
    latLongOverlay.style.position = 'absolute';
    latLongOverlay.style['z-index'] = '10';
    latLongOverlay.style.right = '3px';
    latLongOverlay.style.bottom = '5px';
    latLongOverlay.style.height = '30px';
    latLongOverlay.style.width = 'fit-content';
    latLongOverlay.style['pointer-events'] = 'none';
    latLongOverlay.style.textAlign = 'center';
    latLongOverlay.style.justifyContent = 'center';
    latLongOverlay.style.padding = '5px 4px 0 4px';
    latLongOverlay.style.color = 'rgba(255, 255, 255, 1)';
    let mousePosition;
    const handler = new Cesium.ScreenSpaceEventHandler(canvas);
    const accuracy = 15;

    handler.setInputAction((movement) => {
      mousePosition = movement.endPosition;
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);

        const longString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(accuracy);
        const latString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(accuracy);
        const latitude = parseFloat(latString);
        const longitude = parseFloat(longString);

        const latitudeDMS = convertDecimalToDMS(latitude) + (latitude >= 0 ? 'N' : 'S');
        const longitudeDMS = convertDecimalToDMS(longitude) + (longitude >= 0 ? 'E' : 'W');

        function convertDecimalToDMS(decimal) {
          const degrees = Math.floor(Math.abs(decimal));
          const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
          const minutes = Math.floor(minutesDecimal);
          const seconds = (minutesDecimal - minutes) * 60;
                
          const formattedDegrees = degrees !== 0 ? `${degrees}°` : '';
          const formattedMinutes = minutes !== 0 ? `${minutes}'` : '';
          const formattedSeconds = `${seconds.toFixed(1)}"`;
        
          return `${formattedDegrees}${formattedMinutes}${formattedSeconds}`;
        }

        this.longLatStr = 'Lng: ' + longitudeDMS + ', ' + 'Lat: ' + latitudeDMS;
        
        latLongOverlay.innerHTML =
          "<div style='padding:0px; padding-right: 30px'>" + this.longLatStr + '</div>';
      } else {
        this.longLatStr = '';
        latLongOverlay.innerHTML = "<div style='padding:0px;padding-right: 25px'>Out of Globe</div>";
      }
      this.roll = Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(0);
      if (this.roll > 359.5) this.roll = 0;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  togglePanel() {
    this.showMe = !this.showMe;
  }

  zoomIn() {
    var height = this.viewer.camera.positionCartographic.height;
    this.viewer.camera.zoomIn(height / 2);
  }
  zoomOut() {
    var height = this.viewer.camera.positionCartographic.height;
    this.viewer.camera.zoomOut(height * 2);
  }
  moveUp() {
    var ellipsoid = this.viewer.scene.globe.ellipsoid;
    var cameraHeight = ellipsoid.cartesianToCartographic(
      this.viewer.camera.position
    ).height;
    var moveRate = cameraHeight / 10.0;
    this.viewer.camera.moveUp(moveRate);
  }
  moveDown() {
    var ellipsoid = this.viewer.scene.globe.ellipsoid;
    var cameraHeight = ellipsoid.cartesianToCartographic(
      this.viewer.camera.position
    ).height;
    var moveRate = cameraHeight / 10.0;
    this.viewer.camera.moveDown(moveRate);
  }
  moveLeft() {
    var ellipsoid = this.viewer.scene.globe.ellipsoid;
    var cameraHeight = ellipsoid.cartesianToCartographic(
      this.viewer.camera.position
    ).height;
    var moveRate = cameraHeight / 10.0;
    this.viewer.camera.moveLeft(moveRate);
  }
  moveRight() {
    var ellipsoid = this.viewer.scene.globe.ellipsoid;
    var cameraHeight = ellipsoid.cartesianToCartographic(
      this.viewer.camera.position
    ).height;
    var moveRate = cameraHeight / 10.0;
    this.viewer.camera.moveRight(moveRate);
  }

  ngAfterViewInit() {
    this.LEFTOPTIONS = [];
    this.RIGHTOPTIONS = [];
  }
  getViewArea() {
    // const ellipsoid = Cesium.Ellipsoid.WGS84;
    // let result = //new Cesium.Rectangle(0, 0, 1, 1);
    // this.viewer.camera.computeViewRectangle(ellipsoid);
    // console.log(result)
  }
  topView() {
    const camera = this.viewer.camera;
    this.viewer.camera.setView({
      destination: this.viewer.camera.positionWC,
      orientation: {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: Cesium.Math.toRadians(0),
      },
    });
  }

  removeTarget() {
    this.jump = !this.jump
    if (!this.jump) {
      this.viewer.entities.values.forEach((entity) => {
        if (entity.id === 'target') {
          this.viewer.entities.remove(entity);
        }
      });
    }
  }

  jump2target(indx: any, j: any, k: any) {
    if (!this.jump) {
      this.viewer.entities.values.forEach((entity) => {
        if (entity.id === 'target') {
          this.viewer.entities.remove(entity);
        }
      });
    } else {
    let fpPos: any, pts, dispName;
    if (j === undefined) {
      dispName = '';
      if (this.collections[indx]?.target?.type === 'Point') {
        pts = this.collections[indx]?.target?.coordinates;
      } else {
        pts = this.collections[indx]?.target?.coordinates[0];
      }
      // pts = JSON.parse(this.collections[indx].target).coordinates[0];
    } else {
      if (this.collections[indx]?.sensors[j]?.scenes[k]?.footprint === undefined)
        return;
      dispName = 'Scene ' + (k + 1);
      pts =
        this.collections[indx]?.sensors[j]?.scenes[k]?.footprint?.coordinates[0];
    }
    fpPos = pts.toString().split(',').map(Number);
    if (this.collections[indx]?.target?.type === 'Point') {
      var centroid = pts;
    } else {
      const polygon = turf.polygon([pts]);
      var centroid = turf.centroid(polygon).geometry.coordinates;
    }
    // this.viewer.entities.removeAll();
    var label = this.viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(centroid[0], centroid[1]),
      label: {
        text: dispName,
        font: '20px sans-serif',
        pixelOffset: new Cesium.Cartesian2(0.0, 20),
      },
    });
    if (pts?.length > 2) {
      this.FP = this.viewer.entities.add({
        id: 'target',
        name: dispName,
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
          clampToGround: true,
          material: new Cesium.PolylineOutlineMaterialProperty({
            outlineWidth: 3,
            outlineColor: Cesium.Color.WHITE,
          }),
        },
      });
    } else {
      return
      this.FP = this.viewer.entities.add({
        id: 'BB',
        position: Cesium.Cartesian3.fromDegrees(pts[0], pts[1]), //  bbPosition,
        billboard: {
          image: 'assets/images/marker-icon.png',
          show: true, // default
          // pixelOffset: new Cesium.Cartesian2(0, 5), // default: (0, 0)
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
          // scale: 0.75, // default: 1.0
          // color: Cesium.Color.LIME, // default: WHITE
          // rotation: Cesium.Math.PI_OVER_FOUR, // default: 0.0
          alignedAxis: Cesium.Cartesian3.ZERO, // default
          width: 20, // default: undefined
          height: 30, // default: undefined
        },
      });
    }

    if (this.jump) this.viewer.zoomTo(this.FP);
    // if (this.jump || k) this.viewer.flyTo(this.FP, {duration: 3});
  }
  }

  chngAlpha(indx: any, alpha: any, sensor: any) {
    var allImageryLayers = this.viewer.imageryLayers._layers;
    var leftImageryLayers: any = [];
    var rightImageryLayers: any = [];
    let imageLayerCount = 0;
    let unShowSensor = sensor.scenes[0]?.operatorKey != OPERATORS.CLYDE && sensor.scenes[0]?.operatorKey != OPERATORS.SPIRE && sensor.scenes[0]?.operatorKey != OPERATORS.USL && sensor.scenes[0]?.operatorKey != OPERATORS.LBAND;

    for (var index = 0; index < allImageryLayers.length; index++) {
      var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url

      var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
      var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
      if (!isLeft && !isRight) {
        imageLayerCount = index
      }
      if (url !== undefined && isLeft) {
        leftImageryLayers.push(allImageryLayers[index]);
      }
      if (url !== undefined && isRight) {
        rightImageryLayers.push(allImageryLayers[index]);
      }

    }

    alpha = alpha / 100;
    const findSensor = this.selectedSensors
      .filter(function (el) {
        return el.index === indx;
      })
    if (indx < 0) {
      this.baseAlpha = alpha;
      if (imageLayerCount > 0) {
        let imageLayerCountSensorClick = 0;
        var count = this.viewer.imageryLayers.length - 1;
        this.selectedSensors.forEach((item: any, index: any) => {
          const findIndexsensor = item.scenes
            .filter(function (el) {

              return el.checked;
            })
          if (findIndexsensor.length > 0)
            imageLayerCountSensorClick = + findIndexsensor.length

        })
        this.viewer.imageryLayers.get(count - imageLayerCountSensorClick < 1 ? 0 : (count - imageLayerCountSensorClick)).alpha = alpha;

      } else
        this.viewer.imageryLayers.get(imageLayerCount).alpha = alpha;
    } else if (unShowSensor) {
       if (indx === 0) {
        for (var index = 0; index < leftImageryLayers.length; index++) {
          leftImageryLayers[index].alpha = alpha;
          // Do something with the entity
        }
      } else {
        for (var index = 0; index < rightImageryLayers.length; index++) {
          rightImageryLayers[index].alpha = alpha;
        }
  
      }
    }

  }
  raise(indx: any) {
    var element = this.collections[indx];
    this.collections.splice(indx, 1);
    this.collections.splice(indx - 1, 0, element);
  }
  lower(indx: any) {
    var element = this.collections[indx];
    this.collections.splice(indx, 1);
    this.collections.splice(indx + 1, 0, element);
  }
  baselayerChng(indx: any) {
    this.viewer._baseLayerPicker._viewModel.selectedImagery =
      this.imageryViewModels[indx];
    this.viewer.imageryLayers.get(0).alpha = this.baseAlpha;
  }

  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    const res = await this.httpClient
      .get(url, httpOptions)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        const error = err.error;
        return error;
      });
    return res;
  }
  async downloadAndZip(url: string): Promise<JSZip> {
    const response = await fetch(url);
    const data = await response.blob();
    const fileName: any = url.split('/').pop();
    const zip = new JSZip();
    zip.file(fileName, data);
    return zip;
  }
  async createZip(zipArray: JSZip[], zipName: string): Promise<void> {
    const zip = new JSZip();
    zipArray.forEach((z) => {
      const files = z.files;
      for (const fileName in files) {
        if (Object.prototype.hasOwnProperty.call(files, fileName)) {
          zip.file(fileName, files[fileName].async('blob'));
        }
      }
    });
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${zipName}.zip`);
  }
  getImage(taskId: any, sensorObj) {

    this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {

      return res?.result;
    });
  }

  displayIS(scene: any, feed: any) {
    let feedName = feed.feedName ? feed.feedName : feed.name;
    this.vessel = true;
    this.feedInfo = true;
    this.feedData = '';
    // Check if the feed exists in the array
    if (this.nonVesselFeeds.includes(feedName)) {
       this.vessel = false;
    } else {
      this.vessel = true;
    }

    let colorCode = {
      'Flood detection': 'RED',
      'Deforestation detection and area': 'SANDYBROWN'
    };
    
    let insightFootprint = scene?.insightFootprint ? scene?.insightFootprint : scene;
    if (insightFootprint?.features?.length > 0) {
    this.viewer.scene.globe.depthTestAgainstTerrain = false;
    let filteredCollection = this.getFilteredData(insightFootprint,'wake');
    if (feedName === 'Flood detection') filteredCollection = this.getFilteredData(insightFootprint,'Waterbody');
    let data: any;

    if(feedName === 'Tree crown detection and count') {
      this.feedData = 'Count : ' + filteredCollection.features?.length;
    } else if (feedName === 'Deforestation detection and area') {
      this.feedData = 'Area of deforestation : ' + 4.38 + ' sqkm';
    } else if (this.vessel) {
      this.feedData = 'Count : ' + filteredCollection.features?.length;
    } 

    if(this.vessel) data = this.viewer.dataSources.add(Cesium.GeoJsonDataSource.load(filteredCollection));
    else if (!this.vessel) {
      const isMultiPoint = filteredCollection.features.some(
        (feature) => (feature.geometry?.type === "MultiPoint" || feature.geometry?.type === "Point")
      );
    
      if (isMultiPoint) {
        // Apply specific style for MultiPoint
        data = this.viewer.dataSources.add(
          Cesium.GeoJsonDataSource.load(filteredCollection, {
            clampToGround: true, // Makes the points stick to the ground
            markerSize: 0, // Disables default markers
            markerSymbol: undefined, // Disables default symbols
            markerColor: Cesium.Color.TRANSPARENT, // Makes default markers invisible
          })
        );
        
        data.then((geoJsonDataSource) => {
          const entities = geoJsonDataSource.entities.values;
        
          entities.forEach((entity) => {
            if (entity.position) {
              // Apply custom point graphics for MultiPoint geometries
              entity.point = new Cesium.PointGraphics({
                pixelSize: 10, // Size of the circle
                color: Cesium.Color.GREEN, // Color of the point
                outlineWidth: 0, // No outline
              });
            }
          });
        });       
        
      } else {
      let feedColor = colorCode[feedName];
      data = this.viewer.dataSources.add(Cesium.GeoJsonDataSource.load(filteredCollection, {
        stroke: Cesium.Color[feedColor].withAlpha(0.5),// Outline color
        strokeWidth: 0,// Outline width
        fill: Cesium.Color[feedColor] // Fill color
      }));
    }
    }
    this.viewer.flyTo(data, { duration: 3 });
    this.feedLoaded = true;
    this.spinnerService.hide();
    this.mapLoad = false;
    
      let viewer = this.viewer, _this = this;;
      let tooltip = document.getElementById('feedTooltip');

      viewer.screenSpaceEventHandler.setInputAction(function (movement) {
        var pickedObject = viewer.scene.pick(movement.endPosition);
        if (Cesium.defined(pickedObject) && Cesium.defined(pickedObject.id)) {
          let entity = pickedObject.id;
          if (entity.properties?.confidence && entity?.polyline?.positions) {

            let positions = entity.polyline.positions.getValue();
            let location = _this.getCentroid(positions);

            let confidence = entity.properties.confidence.getValue();
            let feedData = `<b>Location  :</b>  ${location}<br><b>Confidence  :</b>  ${confidence}`;
            if (entity.properties.vessel_length) {
              let vesselLength = entity.properties.vessel_length.getValue();
              feedData = `<b>Location  :</b>  ${location}<br><b>Vessel Length  :</b>  ${vesselLength}<br><b>Confidence  :</b>  ${confidence}`;
            }

            let positionCartesian = entity.polyline.positions.getValue()[1];
            let positionScreen = Cesium.SceneTransforms.wgs84ToWindowCoordinates(viewer.scene, positionCartesian);   

            if (tooltip) {
              tooltip.style.display = 'block';
              tooltip.style.left = positionScreen.x + 4 + 'px';
              tooltip.style.top = (positionScreen.y - 40) + 'px';
              tooltip.style.fontSize = '14px';
              tooltip.style.fontFamily = 'Barlow", sans-serif';
              tooltip.style.lineHeight = '1.7';     
              tooltip.innerHTML = feedData;
            }
          }
        } else {
          if (tooltip) {
            tooltip.style.display = 'none';
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    
  } else {
    const formattedDate = formatDate(this.taskCreatedDate, 'dd/MM/yyyy', 'en-US');

    const message = `There is no information extracted from the tasked data for ${this.taskName} submitted on ${formattedDate}  as the observation of interest is not present.`;
    this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
  }

  }

  getCentroid(positions: any) {
    let totalX = 0, totalY = 0, totalZ = 0;
    let numPositions = positions.length;

    for (let i = 0; i < numPositions; i++) {
      totalX += positions[i].x;
      totalY += positions[i].y;
      totalZ += positions[i].z;
    }

    // Compute the average for each coordinate
    let centroidX = totalX / numPositions;
    let centroidY = totalY / numPositions;
    let centroidZ = totalZ / numPositions;
    let centroid = new Cesium.Cartesian3(centroidX, centroidY, centroidZ);

    // Geographic coordinates (longitude, latitude, height)
    let centroidCartographic = Cesium.Cartographic.fromCartesian(centroid);
    let longitude = Cesium.Math.toDegrees(centroidCartographic.longitude);
    let latitude = Cesium.Math.toDegrees(centroidCartographic.latitude);
    let height = centroidCartographic.height;

    return `(${longitude.toFixed(10)}, ${latitude.toFixed(10)})`
  }
  
  selectedSensor: any;
  vesselPolygon: any;
  displayAIS(scene: any, type: any, sensor: any) {
    let refreshCount;
    let timeExpired;
    let sensorId;
    let taskId
    let USLcoverage: any, BBpoints: any, isHistorical: boolean;

    sensorId = sensor.id;
    timeExpired = sensor.timeExpired;
    refreshCount = sensor.updatedRefreshes == 0 ? 0 : sensor.updatedRefreshes || sensor.noOfRefreshes;
    isHistorical = sensor?.isHistorical;

    if(!scene.aisAvailable) {
      const message = "AIS data is not available until the start of the time of interest (TOI).";
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    }
    else if(timeExpired) {
      const message = 'The time of interest (TOI), along with the seven days of access to the historical data, has ended. Please re-task if the area of interest (AOI) continues to be the same.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    }
    else if(sensor.operatorKey === OPERATORS.SPIRE && refreshCount > 0) {
        const coordinates = scene?.footprint?.coordinates[0]
        taskId = this.collection?.taskId;
    
        let newCoordinates = coordinates.map(coord => {
          return [coord[0], coord[1]];
        });
    
        newCoordinates = [newCoordinates];
        newCoordinates = JSON.stringify(newCoordinates);
    
        const encodedCoordinates = encodeURIComponent(newCoordinates);
        const startTime = encodeURIComponent(scene.openWindow)
        const endTime = encodeURIComponent(scene.closeWindow)
    
    
        const aisReq = {
          polygon: encodedCoordinates,
          startTime: startTime,
          endTime: endTime,
          type: type
        }
        if(type !== 'download') {
          this.spinnerService.show();
          this.mapLoad = true;
        }
    
        this.apiService.getAISData(aisReq, taskId).subscribe((res: any) => {
          if(type !== 'download') {
            this.spinnerService.show();
            this.mapLoad = true;
          }
          
          if (res?.msg === 'failed') {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            this.spinnerService.hide();
            this.mapLoad = false;
            this.freeze = false;
            this.disable = false;
            const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
            this.downloadService.setProgress(sensorObj);
          } else if (res?.msg === 'success') {
            if(res?.data?.vessels?.nodes?.length === 0) {
              this.spinnerService.hide();
              this.mapLoad = false;
              this.freeze = false;
              this.disable = false;
              const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
              this.downloadService.setProgress(sensorObj);
              const message = 'There is no AIS data in the area tasked. If there were to be vessels during the time of interest (TOI), those observations would become available when refreshed.';
              const type = 'AIS'
              this.popupService.openPopup(WarningPopupComponent, message, type, '40%');
            } else {
              const updatedAis = this.convertToGeoJSON(res.data,'display');
              if(type === 'display')  {
                const sensorIndex = this.collection.sensors.findIndex(sensor => sensor.id === sensorId);
                if (sensorIndex !== -1) {
                  if (!this.collection.sensors[sensorIndex].refreshHistory) {
                    this.collection.sensors[sensorIndex].refreshHistory = []; // Initialize if not present
                  }
                  this.collection.sensors[sensorIndex].refreshHistory.push(res.newRefreshHistory);
                }
                const selectData = Cesium.GeoJsonDataSource.load(updatedAis);
                const currentCrossImage = 'assets/images/yellow-cross.png';
                selectData.then((dataSource: CustomGeoJsonDataSource) => {
                  dataSource.sensorId = sensor?.id;
                  dataSource.name = 'spireDataSource';
                  const entities = dataSource.entities.values;
                  for (let j = 0; j < entities.length; j++) {
                    const entity = entities[j] as CustomEntity;
                    entity.name = 'vessel';
                    entity.type = 'spire';
                    entity.billboard = new Cesium.BillboardGraphics({
                      image: currentCrossImage,
                      show: true,
                      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
                      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                      alignedAxis: Cesium.Cartesian3.ZERO,
                      width: 20,
                      height: 20,
                    });
                  }
        
                  this.spinnerService.hide();
                  this.mapLoad = false;
      
                  this.collection.sensors.map((sensor) => {
                    if (sensor.operatorKey === OPERATORS.SPIRE) {
                      if(sensor.updatedRefreshes) sensor.updatedRefreshes -= 1
                      else sensor.updatedRefreshes = sensor.noOfRefreshes - 1
                    }
                  })
        
                  this.viewer.dataSources.add(dataSource);
                  this.viewer.scene.globe.depthTestAgainstTerrain = false;
                  this.viewer.flyTo(dataSource, { duration: 3 });
                });
                
                let tooltip = document.getElementById('feedTooltip');
                if (this.handler) {
                  this.handler.destroy();
                }
                // Add click event listener                
                let previousEntity = null;
                this.handler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
                this.handler.setInputAction((click) => {
                  const pickedObject = this.viewer.scene.pick(click.position);
                  if (Cesium.defined(pickedObject)) {
                    const entity = pickedObject.id;
                    if (entity && entity.isOriginal !== false && entity?.type === 'spire') {
                      if (previousEntity) {
                        this.resetEntity(previousEntity);
                      }
                      this.previousIndex = -1;
                      this.handleClick(entity,isHistorical,taskId, sensor);
                      this.highlightEntity(entity);
                      this.clickedEntity = entity;
                      previousEntity = entity;
                    }
                  }
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  
                this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
  
                // Add hover event listener for tooltip
                this.handler.setInputAction((movement) => {
                  const pickedObject = this.viewer.scene.pick(movement.endPosition);
                  const entity = pickedObject?.id;
                  
                  if (entity && entity.properties && entity?.name != 'annotation' && entity?.name != 'signal') {
                    if (tooltip) {                    
                    let content = '';
                    entity.properties.propertyNames.forEach(propertyName => {                      
                      content += `<strong>${propertyName} : </strong> ${entity.properties[propertyName]}<br>`;
                    });
                    tooltip.innerHTML = content;
                    tooltip.style.left = movement.endPosition.x + 10 + 'px';
                    tooltip.style.top = movement.endPosition.y + 10 + 'px';
                    tooltip.style.fontSize = '15px';
                    tooltip.style.fontFamily = 'Barlow", sans-serif';
                    tooltip.style.lineHeight = '1.8';
                    tooltip.style.display = 'block';
                  }
                } else if (tooltip) {
                  tooltip.style.display = 'none';
                }
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  
                if (scene?.operatorKey === this.OPERATOR.SPIRE) {
                  USLcoverage = scene?.footprint?.coordinates[0]
                  BBpoints = USLcoverage.toString().split(',').map(Number);
            
                  this.uslBB = this.viewer.entities.add({
                    sensorId: sensor?.id,
                    polyline: {
                      positions: Cesium.Cartesian3.fromDegreesArray(BBpoints),
                      clampToGround: true,
                      material: new Cesium.PolylineOutlineMaterialProperty({
                        outlineWidth: 3,
                        outlineColor: Cesium.Color.fromRandom({
                          alpha: 1.0,
                        }),
                      }),
                    },
                  });
                  this.uslBB.index = scene?.sceneIdx;
                  this.SpirescnLyr.push(this.uslBB);
                  this.setJsonData(scene,taskId,sensor?.id);
                }
            
                if (this.uslBB) {
                  this.viewer.zoomTo(this.uslBB);
                }
              } else if(type === 'download') {
                this.collection.sensors.map((sensor) => {
                  if (sensor.operatorKey === OPERATORS.SPIRE) {
                    if(sensor.updatedRefreshes) sensor.updatedRefreshes -= 1
                    else sensor.updatedRefreshes = sensor.noOfRefreshes - 1
                  }
                })
                const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick };
                this.downloadService.setProgress(sensorObj);
                const blob = new Blob([JSON.stringify(updatedAis)], {type: 'application/json'});
                const url = window.URL.createObjectURL(blob);
            
                const link = document.createElement('a');
                link.href = url;
                link.download = 'spire.geojson'; 
                link.click();
            
                // Clean up
                window.URL.revokeObjectURL(url);
                this.progress = 0;
                this.spinnerService.hide();
                this.freeze = false;
                this.disable = false;
              }
            }
          }
        });
      
    }
    else if(scene?.operatorKey !== this.OPERATOR.CLYDE) {
      const message = 'Your refresh count has been exhausted. Please re-task to continue receiving latest AIS information.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    }
  }

  displayAISClyde(scene: any, type: any, sensor: any) {
    let refreshCount;
    let timeExpired;
    let sensorId;
    let taskId
    let USLcoverage: any, BBpoints: any, isHistorical: boolean;

    sensorId = sensor.id;
    timeExpired = sensor.timeExpired;
    isHistorical = sensor?.isHistorical; 

    if(!scene.aisAvailable) {
      const message = "AIS data is not available until the start of the time of interest (TOI).";
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else if(timeExpired) {
      const message = 'The time of interest (TOI) has ended. Please re-task if the area of interest (AOI) continues to be the same.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    } else if(sensor.operatorKey === OPERATORS.CLYDE) {
        const coordinates = scene?.footprint?.coordinates[0]
        taskId = this.collection?.taskId;
    
        let newCoordinates = coordinates.map(coord => {
          return [coord[1], coord[0]];
        });
    
        newCoordinates = [newCoordinates];
        newCoordinates = JSON.stringify(newCoordinates);
    
        const encodedCoordinates = encodeURIComponent(newCoordinates);
        const startTime = encodeURIComponent(scene.openWindow)
        const endTime = encodeURIComponent(scene.closeWindow)
    
    
        const aisReq = {
          polygon: encodedCoordinates,
          startTime: startTime,
          endTime: endTime,
          type: type
        }
        if(type !== 'download') {
          this.spinnerService.show();
          this.mapLoad = true;
        }
  
          const input = JSON.parse(decodeURIComponent(aisReq.polygon)); 
          this.vesselPolygon = input[0]
            .map(pair => pair.reverse().join(' '))
            .join(', '); 
          aisReq.polygon = this.vesselPolygon  
    
          this.apiService.getClydeAISData(aisReq, taskId).subscribe((res: any) => {           
          if(type !== 'download') {
            this.spinnerService.show();
            this.mapLoad = true;
          }
          
          if (res?.msg === 'failed') {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            this.spinnerService.hide();
            this.mapLoad = false;
            this.freeze = false;
            this.disable = false;
            const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
            this.downloadService.setProgress(sensorObj);
          } else if (res?.msg === 'Success') {
            if(res?.data?.length === 0) {             
              this.spinnerService.hide();
              this.mapLoad = false;
              this.freeze = false;
              this.disable = false;
              const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
              this.downloadService.setProgress(sensorObj);
              const message = 'There is no AIS data in the area tasked. If there were to be vessels during the time of interest (TOI), those observations would become available when refreshed.';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            } else {                            
              const updatedAis = this.convertToGeoJSON(res.data, 'CLYDE');
              if(type === 'display')  {
                const sensorIndex = this.collection.sensors.findIndex(sensor => sensor.id === sensorId);
                if (sensorIndex !== -1) {
                  if (!this.collection.sensors[sensorIndex].refreshHistory) {
                    this.collection.sensors[sensorIndex].refreshHistory = []; // Initialize if not present
                  }
                  this.collection.sensors[sensorIndex].refreshHistory.push(res.newRefreshHistory);
                }
                const selectData = Cesium.GeoJsonDataSource.load(updatedAis);
                const currentCrossImage = 'assets/images/orange-cross.png';
                selectData.then((dataSource: CustomGeoJsonDataSource) => {
                  dataSource.name = 'clydeDataSource';
                  dataSource.sensorId = sensor?.id;
                  const entities = dataSource.entities.values;
                  for (let j = 0; j < entities.length; j++) {
                    const entity = entities[j] as CustomEntity;
                    entity.name = 'vessel';
                    entity.type = 'clyde';
                    entity.billboard = new Cesium.BillboardGraphics({
                      image: currentCrossImage,
                      show: true,
                      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
                      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                      alignedAxis: Cesium.Cartesian3.ZERO,
                      width: 20,
                      height: 20,
                    });
                  }
        
                  this.spinnerService.hide();
                  this.mapLoad = false;
        
                  this.viewer.dataSources.add(dataSource);
                  this.viewer.scene.globe.depthTestAgainstTerrain = false;
                  this.viewer.flyTo(dataSource, { duration: 3 });
                });

                let tooltip = document.getElementById('feedTooltip');
                if (this.handlers) {
                  this.handlers.destroy();
                }
                // Add click event listener
                let previousEntity = null;
                this.handlers = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
                this.handlers.setInputAction((click) => {

                  const pickedObject = this.viewer.scene.pick(click.position);
                  if (Cesium.defined(pickedObject)) {                    
                    const entity = pickedObject.id;
                    if (entity && entity.isOriginal !== false && entity?.type === 'clyde') {
                      if (previousEntity) {
                        this.resetEntity(previousEntity);
                      }
                      this.previousIndex = -1;
                      this.handleClydeClick(entity,isHistorical,taskId,sensor);
                      this.highlightEntity(entity);
                      this.clickedEntity = entity;
                      previousEntity = entity;
                    }
                  }
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  
                this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
  
               if (scene?.operatorKey === this.OPERATOR.CLYDE) {
                  USLcoverage = scene?.footprint?.coordinates[0]
                  BBpoints = USLcoverage.toString().split(',').map(Number);
            
                  this.uslBB = this.viewer.entities.add({
                    sensorId: sensor?.id,
                    polyline: {
                      positions: Cesium.Cartesian3.fromDegreesArray(BBpoints),
                      clampToGround: true,
                      material: new Cesium.PolylineOutlineMaterialProperty({
                        outlineWidth: 3,
                        outlineColor: Cesium.Color.fromRandom({
                          alpha: 1.0,
                        }),
                      }),
                    },
                  });
                  this.uslBB.index = scene?.sceneIdx;
                  this.SpirescnLyr.push(this.uslBB);
                  this.setJsonData(scene,taskId,sensor?.id);
                }
            
                if (this.uslBB) {
                  this.viewer.zoomTo(this.uslBB);
                }
              } else if(type === 'download') {
                const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
                this.downloadService.setProgress(sensorObj);
                const blob = new Blob([JSON.stringify(updatedAis)], {type: 'application/json'});
                const url = window.URL.createObjectURL(blob);
            
                const link = document.createElement('a');
                link.href = url;
                link.download = 'clyde.geojson'; 
                link.click();
            
                // Clean up
                window.URL.revokeObjectURL(url);
                this.progress = 0;
                this.spinnerService.hide();
                this.freeze = false;
                this.disable = false;
              }
            }
          }
          });  
    }
  }

  displayImage(scene) {
    let selectData: any, USLcoverage: any, BBpoints: any;
    let geoJsonFiles: any, crossImages: any;
    let footprint = scene?.usl_footprint ? scene.usl_footprint : scene.uslFootprint;
    this.leftBand = false;
    this.rightBand = false;
    
    if (scene?.x_band && scene?.operatorKey === this.OPERATOR.USL) {
      if (this.bandSelected === 'xband') {
        geoJsonFiles = [scene.x_band];
        crossImages = ['assets/images/cross.png'];
      }
      else if (this.bandSelected === 'sband') {
        geoJsonFiles = [scene.s_band];
        crossImages = ['assets/images/cross-S.png'];
      } else {
        this.leftBand = true;
        this.rightBand = false;
        geoJsonFiles = [scene.x_band, scene.s_band];
        crossImages = ['assets/images/cross.png', 'assets/images/cross-S.png'];
      }
      for (let i = 0; i < geoJsonFiles.length; i++) {
        const currentGeoJsonFile = geoJsonFiles[i];
        const currentCrossImage = crossImages[i];
        const selectData = Cesium.GeoJsonDataSource.load(currentGeoJsonFile);

        selectData.then((dataSource: CustomGeoJsonDataSource) => {
          dataSource.sceneId = scene?.id;
          dataSource.sensorId = this.bandSensorId;
           const entities = dataSource.entities.values;
          for (let j = 0; j < entities.length; j++) {
            let formattedProperties = this.setProperties(entities[j].properties)            
            const entity = entities[j];
            entity.name = 'broRf';
            entity.billboard = new Cesium.BillboardGraphics({
              image: currentCrossImage,
              show: true,
              eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              alignedAxis: Cesium.Cartesian3.ZERO,
              width: 20,
              height: 20,
            });
            entity.properties = new Cesium.PropertyBag(formattedProperties);
          }

          this.viewer.dataSources.add(dataSource);
          this.viewer.scene.globe.depthTestAgainstTerrain = false;
          if (i === geoJsonFiles.length - 1) {
            // Fly to the last loaded data source
            this.viewer.flyTo(dataSource, { duration: 3 });
          }
        });

      }
    } else if (scene?.operatorKey != this.OPERATOR.LBAND) {
      selectData = Cesium.GeoJsonDataSource.load(footprint);
      selectData.then((dataSource: CustomGeoJsonDataSource) => {
        dataSource.sceneId = scene?.id;
        dataSource.sensorId = this.bandSensorId;
        const entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let formattedProperties = this.setProperties(entities[i].properties)            
          const entity = entities[i];
          entity.name = 'broRf';
          entity.billboard = new Cesium.BillboardGraphics({
            image: 'assets/images/cross.png',
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 20,
          });
          entity.properties = new Cesium.PropertyBag(formattedProperties);
        }

        this.viewer.flyTo(dataSource, { duration: 3 });
        this.viewer.dataSources.add(dataSource);
        this.viewer.scene.globe.depthTestAgainstTerrain = false;
      });
    }

    if (scene?.metadata?.features && (scene?.operatorKey === this.OPERATOR.USL)) {
      USLcoverage = scene.metadata.features[0].geometry.coordinates[0];
      BBpoints = USLcoverage.toString().split(',').map(Number);

      this.uslBB = this.viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(BBpoints),
          clampToGround: true,
          material: new Cesium.PolylineOutlineMaterialProperty({
            outlineWidth: 3,
            outlineColor: Cesium.Color.fromRandom({
              alpha: 1.0,
            }),
          }),
        },
      });
      this.uslBB.index = scene?.sceneIdx;
      this.USLscnLyr.push(this.uslBB);      
      this.setJsonData(scene,this.bandTaskId,this.bandSensorId);
    }
    this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.displayProperties(this.currentSignal);

    if (this.uslBB) {
      this.viewer.zoomTo(this.uslBB);
    }

  }

  setProperties(data) {
    let properties = {
      ID: data?.ID.getValue(),
      'Timestamp UTC': data?.timestamp_utc.getValue(),
      'RF Frequency MHz': data?.RF_Frequency_MHz.getValue(),
      Lng: data?.longitude_deg.getValue(),
      Lat: data?.latitude_deg.getValue(),
      'Accuracy Level': data?.accuracy_level.getValue(),
      'Pulses Duration ns': data?.pulses_duration_ns.getValue(),
      'Pulses Repetition Frequency Hz': data?.pulses_repetition_frequency_Hz.getValue(),
  }

  return properties;
  }

  convertToGeoJSON(vesselsData,type: any) {
    // Assuming vesselsData is the JSON object from the API response
    let AISdata: any; 
    if(type === 'overlay' || type === 'CLYDE') AISdata = vesselsData;
    else AISdata = vesselsData.vessels.nodes
    
    if(type == 'CLYDE') {
      const features = AISdata.map(vessel => ({
        type: 'Feature',
        id: vessel.id,
        geometry: {
            type: 'Point',
            coordinates: [vessel.location.longitude, vessel.location.latitude]
        },
        properties: {
            ID: vessel.id,
            MMSI: vessel.mmsi,
            'Timestamp UTC': vessel.timestamp,
            Lng: vessel.location.longitude.toString(),
            Lat: vessel.location.latitude.toString(),
        }
    }));

    return {
        type: 'FeatureCollection',
        features: features
    };
    }
    else {
    const features = AISdata.map(vessel => ({
        type: 'Feature',
        id: vessel.id,
        geometry: {
            type: 'Point',
            coordinates: [vessel.lastPositionUpdate.longitude, vessel.lastPositionUpdate.latitude]
        },
        properties: {
            ID: vessel.id,
            MMSI: vessel.staticData.mmsi,
            Name: vessel.staticData.name,
            'Timestamp UTC': vessel.lastPositionUpdate.timestamp,
            Lng: vessel.lastPositionUpdate.longitude.toString(),
            Lat: vessel.lastPositionUpdate.latitude.toString(),
            Flag: vessel.staticData.flag,
            'Ship Type': vessel.staticData?.shipType,
            IMO: vessel.staticData?.imo,
            'ETA UTC': vessel?.currentVoyage?.eta,
            'Port Name': vessel?.currentVoyage?.matchedPort?.port?.name,
            Speed: vessel?.speed ? vessel.speed : 'N/A',
            'Vessel Length': vessel?.staticData?.dimensions?.length ? vessel?.staticData?.dimensions?.length : 'N/A',
            'Vessel Width': vessel?.staticData?.dimensions?.width ? vessel?.staticData?.dimensions?.width : 'N/A'
        }
    }));

    return {
        type: 'FeatureCollection',
        features: features
    };
  }
}
  

  displayRFImage(scene, sensorIndex, sensorId, taskId) {
    let selectData: any, USLcoverage: any, BBpoints: any;
    let image: string;
    let geoJsonFiles: any, crossImages: any;    
    let footprint = scene?.usl_footprint ? scene.usl_footprint : scene.uslFootprint;
    selectData = Cesium.GeoJsonDataSource.load(footprint);

    if (scene?.x_band && scene?.operatorKey === this.OPERATOR.USL) {
      if (this.bandSelected === 'xband') {
        geoJsonFiles = [scene?.x_band];
        if (sensorIndex === 0) {
          crossImages = ['assets/images/cross.png'];
        } else {
          crossImages = ['assets/images/green-cross.png'];
        }

        this.leftBand = false;
        this.rightBand = false;
      } else if (this.bandSelected === 'sband') {
        geoJsonFiles = [scene?.s_band];
        if (sensorIndex === 0) {
          crossImages = ['assets/images/cross-S.png'];
        } else {
          crossImages = ['assets/images/green-cross-S.png'];
        }

        this.leftBand = false;
        this.rightBand = false;
      } else {
        geoJsonFiles = [scene?.x_band, scene?.s_band];
        if (sensorIndex === 0) {
          this.leftBand = true;
          this.rightBand = false;
          crossImages = ['assets/images/cross.png', 'assets/images/cross-S.png'];
        } else {
          this.rightBand = true;
          crossImages = ['assets/images/green-cross.png', 'assets/images/green-cross-S.png'];
        }
      }

      if (geoJsonFiles.length > 0) {
        for (let i = 0; i < geoJsonFiles.length; i++) {
          const currentGeoJsonFile = geoJsonFiles[i];
          const currentCrossImage = crossImages[i];
          const selectData = Cesium.GeoJsonDataSource.load(currentGeoJsonFile);

          selectData.then((dataSource) => {
            const entities = dataSource.entities.values;
            for (let j = 0; j < entities.length; j++) {
              let formattedProperties = this.setProperties(entities[j].properties)            
              const entity = entities[j];
              entity.name = 'broRf';
              entity.billboard = new Cesium.BillboardGraphics({
                image: currentCrossImage,
                show: true,
                eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                alignedAxis: Cesium.Cartesian3.ZERO,
                width: 20,
                height: 20,
              });
              entity.properties = new Cesium.PropertyBag(formattedProperties);
            }

            this.viewer.dataSources.add(dataSource);
            this.viewer.scene.globe.depthTestAgainstTerrain = false;
            if (i === geoJsonFiles.length - 1) {
              // Fly to the last loaded data source
              this.viewer.flyTo(dataSource, { duration: 3 });
            }
          });
        }
      }
    } else if (scene?.operatorKey != this.OPERATOR.LBAND) {
      selectData.then((dataSource) => {
        const entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          const entity = entities[i];
          let formattedProperties = this.setProperties(entities[i].properties)            
          if (sensorIndex === 0) {
            image = 'assets/images/cross.png';
          } else {
            image = 'assets/images/green-cross.png';
          }
          entity.name = 'broRf';
          entity.billboard = new Cesium.BillboardGraphics({
            image: image,
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 20,
          });
          entity.properties = new Cesium.PropertyBag(formattedProperties);
        }
  
        this.viewer.flyTo(dataSource, { duration: 3 });
        this.viewer.dataSources.add(dataSource);
        this.viewer.scene.globe.depthTestAgainstTerrain = false;
      });
    }

    this.handleAnnotation();

    if (scene?.metadata?.features && scene?.operatorKey === this.OPERATOR.USL) {
      let bbColor: any;
      if (sensorIndex === 0) {
        bbColor = Cesium.Color.RED;
      } else {
        bbColor = Cesium.Color.GREEN;
      }
      USLcoverage = scene.metadata.features[0].geometry.coordinates[0];
      BBpoints = USLcoverage.toString().split(',').map(Number);
      this.uslBB = this.viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(BBpoints),
          clampToGround: true,
          material: new Cesium.PolylineOutlineMaterialProperty({
            outlineWidth: 10,
            outlineColor: bbColor,
          }),
        },
      });
      this.uslBB.index = scene?.sceneIdx;
      this.USLscnLyr.push(this.uslBB);
    }
    this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.displayProperties(this.currentSignal);

    if (this.uslBB) {
      this.viewer.zoomTo(this.uslBB);
    }

  }

  RFOverlay(i: any, j: any, k: any, e: any, type: any, task: any,operatorKey) {
    const taskId = this.collections[i]?.taskId;
    if (e?.checked) {
      this.showSubDailyList = false;
      this.selectedSubDailyItems = [];
      this.selectOption(task?.recurrencyArray[j], 'split');
      this.overlay[j] = 100;

      if (this.selectedCheckboxes.length < 2) {
        this.collection.recurrencyArray[j].index = j;
        this.collection.recurrencyArray[j].scenes.forEach((scene, index) => {
          if (scene.status !== "failed")
            scene.checked = true
        });
        this.collection.recurrencyArray[j].name = (this.collection.sensors[0].name);
        this.collection.recurrencyArray[j].resolution_tooltip = (this.collection.sensors[0]?.resolution_tooltip);
        this.collection.recurrencyArray[j].isAdditionalProducts = (this.collection.sensors[0]?.isAdditionalProducts);
        this.collection.recurrencyArray[j].isAdditionalCSIProducts = (this.collection.sensors[0]?.isAdditionalCSIProducts);
        this.collection.recurrencyArray[j].downloaded = (this.collection.sensors[0]?.downloaded);
        this.collection.recurrencyArray[j].status = (this.collection.sensors[0]?.status);
        this.collection.recurrencyArray[j].restoreStatus = (this.collection.sensors[0]?.restoreStatus);
        this.collection.recurrencyArray[j].restoreOption = (this.collection.sensors[0]?.restoreOption);
        // Only allow selection if less than 2 checkboxes are already selected
        this.selectedCheckboxes.push(this.collection.recurrencyArray[j]);
        this.setPreviousSelectiontext();
        this.selectionChange.next(this.collection.recurrencyArray[j])
      } else {
        if (this.selectedCheckboxes.length >= 2) {
          this.selectedCheckboxes.shift();
          this.collection.recurrencyArray[j].index = j;
          this.collection.recurrencyArray[j].name = (this.collection.sensors[0].name);
          this.collection.recurrencyArray[j].resolution_tooltip = (this.collection.sensors[0]?.resolution_tooltip);
          this.collection.recurrencyArray[j].isAdditionalProducts = (this.collection.sensors[0]?.isAdditionalProducts);
          this.collection.recurrencyArray[j].isAdditionalCSIProducts = (this.collection.sensors[0]?.isAdditionalCSIProducts);
          this.collection.recurrencyArray[j].downloaded = (this.collection.sensors[0]?.downloaded);
          this.collection.recurrencyArray[j].status = (this.collection.sensors[0]?.status);
          this.collection.recurrencyArray[j].restoreStatus = (this.collection.sensors[0]?.restoreStatus);
          this.collection.recurrencyArray[j].restoreOption = (this.collection.sensors[0]?.restoreOption);
          // Only allow selection if less than 2 checkboxes are already selected
          this.selectedCheckboxes.push(this.collection.recurrencyArray[j]);
          this.setPreviousSelectiontext();
          this.selectionChange.next(this.collection.recurrencyArray[j])
          this.collection.recurrencyArray.forEach((sensor, index) => {
            const indexToRemove = this.selectedCheckboxes.findIndex((item) => item.index === sensor.index);
            if (indexToRemove === -1) {
              sensor.checked = false
            }
          });

        }
        //  If already 2 checkboxes are selected, prevent this selection
      }
      if (this.selectedSensors.length === 0) {
        this.collection.sensors[0].index = j;
        this.collection.sensors[0].start = e.start;
        this.selectedSensors.push(this.collection.sensors[0])
        if (j === 0) {
          this.sensorList[j] = this.collection.sensors[0]
        }
      } else {
        const findIndex = this.selectedSensors
          .filter(function (el) {
            return el.index === j;
          })
        if (findIndex.length === 0) {
          this.collection.sensors[0].index = j;
          this.collection.sensors[0].start = e.start;
          this.selectedSensors.push(this.collection.sensors[0])
          this.sensorList.push(this.collection.sensors[0])
        } else {
          this.collection.sensors[0].index = j;
          this.collection.sensors[0].start = e.start;
          this.selectedSensors.push(this.collection.sensors[0])
        }

      }
      if (k === undefined) {
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
          sensors.checked = true
          if (operatorKey === OPERATORS.LBAND) {
            this.selectSignals(sensors, this.signalSelected)
          } else {
            for (const [index, scn] of sensors?.scenes.entries()) {
              if (scn?.id && scn?.status !== 'failed') {
                scn.checked = true;
                this.displayRFImage(scn, sensorIndex, sensors.id, taskId);
              }
            }
          }
        }

        if (this.selectedSensors.length === 0)
          this.collection.recurrencyArray[j].checked = e?.checked;
      } else {
        this.selectedCheckboxes[j].scenes[k].checked = e?.checked;
        const scn = this.selectedCheckboxes[j].scenes[j]?.scenes[k];
      }
    } else {

      if (k === undefined) {
        this.overlay[j] = 100
        const indexToRemove = type === "dropdown" ? this.selectedCheckboxes.findIndex((item) => item.start === this.collection.recurrencyArray[j].start) : j;
        const indexToRemoveSelectedSensor = this.selectedCheckboxes.findIndex((item) => item.index === j);
        if (indexToRemove !== -1) {

          this.selectedCheckboxes.splice(indexToRemove, 1);

          this.selectionChange.next(this.selectedCheckboxes[0])
          this.subSelectionSelectionChange.next(1)
          if (this.collection.recurrencyArray.filter(item => item.checked).length > 0) {

            this.collection.recurrencyArray.forEach((sensor, index) => {
              const indexTouncheck = this.selectedCheckboxes.findIndex((item) => item.start === sensor.start);
              if (indexTouncheck === -1) {
                sensor.checked = false
                sensor.scenes.forEach((scene) => {
                  scene.checked = false

                });
              }
            });
          }
        }

        if (indexToRemoveSelectedSensor !== -1) {
          this.selectedSensors.splice(indexToRemoveSelectedSensor, 1);
        }

        if (this.selectedCheckboxes?.length === 0) {
          this.viewer.entities.removeAll();
          this.clearBandLegends();
          this.toggleDailyRecurrency(e)
        } else {

          this.viewer.entities.removeAll();
          this.clearBandLegends();
          for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
            sensors.checked = true
            if (operatorKey === OPERATORS.LBAND) {
              this.selectSignals(sensors, this.signalSelected)
            } else {
            for (const [index, scn] of sensors?.scenes.entries()) {
              if (scn?.id && scn?.status !== 'failed') {
                if (scn.checked) {
                  this.displayRFImage(scn, sensorIndex, sensors.id, taskId);
                }
              }
            }
          }
          }
        }

      } else {
        if (this.selectedCheckboxes?.length === 0) {
          this.toggleDailyRecurrency(e)
        } else {
          this.viewer.entities.removeAll();
          this.clearBandLegends();
          if (operatorKey === OPERATORS.LBAND) {
            this.selectSignals(this.selectedCheckboxes, this.signalSelected)
          }
          this.selectedCheckboxes[0].scenes.forEach(scene => {
            if (scene.checked) {
              if (operatorKey === OPERATORS.USL) {
                this.displayRFImage(scene, this.selectedCheckboxes?.length, 'none', taskId);
              }
            }
          });
        }       
      }
    }
  }

  async toggleRecurrentSensor(i: any, j: any, k: any, e: any, type: any, task: any) {
    let unShowSensor = this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.USL && 
    this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.LBAND;

    if (task?.recurrencyArray && (task?.recurrencyArray[j]?.scenes[0]?.operatorKey === this.OPERATOR.USL ||
      task?.recurrencyArray[j]?.scenes[0]?.operatorKey === this.OPERATOR.LBAND) && k === undefined) {
      let operatorKey = task?.recurrencyArray[j]?.scenes[0]?.operatorKey;
      this.RFOverlay(i, j, k, e, type, task, operatorKey);
      this.RF = true;
    } else if ((task?.operatorKey === this.OPERATOR.USL || task?.operatorKey === this.OPERATOR.LBAND) && k === undefined) {
      let operatorKey = task?.operatorKey;
      this.RFOverlay(i, j, k, e, type, task, operatorKey);
      this.RF = true;
    } else {

    this.RF = false;
    let sceneId: any;
    let sensorId: any;
    let action: any;
    const taskId = this.collections[i]?.taskId;
    this.fail = 0;
    let selectedDropdown: any
    if (type === "dropdown" && this.collection.recurrencyArray[j]) {

      this.collection.recurrencyArray[j].checked = e?.checked;
      selectedDropdown = this.collection.recurrencyArray[j]
    }

    if (this.collection.recurrencyArray.filter(item => item.checked).length > 0) {
      this.isDisabledAnottationBtn = false;
      this.collection.sensors.forEach((sensor, index) => {
        sensor.checked = true
        sensor.scenes.forEach((scene) => {
          if (scene?.status != "failed")
            scene.checked = true

        });
      });
    }

    if (e?.checked && k === undefined) {
      if (this.collection?.recurrencyArray[0]?.repeatFrequency === 'GHG-Monthly') {
        this.selectSite(this.selectedSites[0].value);
      }     
      else if (this.collection?.recurrencyArray?.length > 1) {
        this.selectOption(this.collection?.recurrencyArray[j], 'split');
      } else {
        this.selectOption(this.collection?.recurrencyArray[0], 'split');
      }
      this.overlay[j] = 100;

      if (this.selectedCheckboxes.length < 2) {
        this.collection.recurrencyArray[j].index = j;
        this.collection.recurrencyArray[j].scenes.forEach((scene, index) => {
          if (scene.status !== "failed")
            scene.checked = true
        });
        this.collection.recurrencyArray[j].name = (this.collection.sensors[0].name);
        this.collection.recurrencyArray[j].resolution_tooltip = (this.collection.sensors[0]?.resolution_tooltip);
        this.collection.recurrencyArray[j].isAdditionalProducts = (this.collection.sensors[0]?.isAdditionalProducts);
        this.collection.recurrencyArray[j].isAdditionalCSIProducts = (this.collection.sensors[0]?.isAdditionalCSIProducts);
        this.collection.recurrencyArray[j].downloaded = (this.collection.sensors[0]?.downloaded);
        this.collection.recurrencyArray[j].status = (this.collection.sensors[0]?.status);
        this.collection.recurrencyArray[j].restoreStatus = (this.collection.sensors[0]?.restoreStatus);
        this.collection.recurrencyArray[j].restoreOption = (this.collection.sensors[0]?.restoreOption);
        // Only allow selection if less than 2 checkboxes are already selected
        this.selectedCheckboxes.push(this.collection.recurrencyArray[j]);
        this.setPreviousSelectiontext();
        this.selectionChange.next(this.collection.recurrencyArray[j])
      } else {
        if (this.selectedCheckboxes.length >= 2) {
          this.selectedCheckboxes.shift();
          this.collection.recurrencyArray[j].index = j;
          this.collection.recurrencyArray[j].name = (this.collection.sensors[0].name);
          this.collection.recurrencyArray[j].resolution_tooltip = (this.collection.sensors[0]?.resolution_tooltip);
          this.collection.recurrencyArray[j].isAdditionalProducts = (this.collection.sensors[0]?.isAdditionalProducts);
          this.collection.recurrencyArray[j].isAdditionalCSIProducts = (this.collection.sensors[0]?.isAdditionalCSIProducts);
          this.collection.recurrencyArray[j].downloaded = (this.collection.sensors[0]?.downloaded);
          this.collection.recurrencyArray[j].status = (this.collection.sensors[0]?.status);
          this.collection.recurrencyArray[j].restoreStatus = (this.collection.sensors[0]?.restoreStatus);
          this.collection.recurrencyArray[j].restoreOption = (this.collection.sensors[0]?.restoreOption);
          // Only allow selection if less than 2 checkboxes are already selected
          this.selectedCheckboxes.push(this.collection.recurrencyArray[j]);
          this.setPreviousSelectiontext();
          this.selectionChange.next(this.collection.recurrencyArray[j])
          this.collection.recurrencyArray.forEach((sensor, index) => {
            const indexToRemove = this.selectedCheckboxes.findIndex((item) => item.index === sensor.index);
            if (indexToRemove === -1) {
              sensor.checked = false
            }
          });

        }
        //  If already 2 checkboxes are selected, prevent this selection
      }

      this.selecetedData = JSON.parse(JSON.stringify(this.selectedCheckboxes));
      
      if (this.datePlace) {  
        this.showSubDailyList = false;
        this.subDailyCount = '';
        this.selectedSubdaily = '';

        if (this.selectedCheckboxes[0].name === undefined && this.subDailyFinalArray?.length > 0) {
          this.selectedCheckboxes[0].name = this.subDailyFinalArray[0].name;
          this.selectedCheckboxes[0].id = this.subDailyFinalArray[0].id;
          this.selectedCheckboxes[0].status = this.subDailyFinalArray[0].status;
        }
       
        if (this.selectedSite || this.selectedSites[0]) {
          let site = this.selectedSite ? this.selectedSite : this.selectedSites[0]          
        this.selectedCheckboxes = this.selectedCheckboxes.map(checkbox => {
          if (checkbox.checked) {
            checkbox.scenes = checkbox.scenes.filter(scene => scene.siteId === site.siteId);
          }
          return checkbox;
        });
        this.subDailyCount = 'Site Name : ' + site?.siteName;
        this.selectedSubdaily = 'Site Name : ' + site?.siteName;        
      }

      } else this.selectedCheckboxes = this.selecetedData;      
      
      if (this.selectedSensors.length === 0) {
        this.collection.sensors[0].index = j;
        this.collection.sensors[0].start = e.start;
        this.selectedSensors.push(this.collection.sensors[0])
        if (j === 0) {
          this.sensorList[j] = this.collection.sensors[0]
        }
      } else {
        const findIndex = this.selectedSensors
          .filter(function (el) {
            return el.index === j;
          })
        if (findIndex.length === 0) {
          this.collection.sensors[0].index = j;
          this.collection.sensors[0].start = e.start;
          this.selectedSensors.push(this.collection.sensors[0])
          this.sensorList.push(this.collection.sensors[0])
        } else {
          this.collection.sensors[0].index = j;
          this.collection.sensors[0].start = e.start;
          this.selectedSensors.push(this.collection.sensors[0])
        }

      }
      if (k === undefined) {


        this.viewer.entities.removeAll();
         this.clearBandLegends();
        this.resetBackgroundWithChangeInBaseLayer();
        this.scnLyr = [];
        this.scnLyr2 = [];
       for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
          sensors.checked = true

          for (const [index, scn] of sensors?.scenes.entries()) {
            if (scn?.id && scn?.status !== 'failed') {
              scn.checked = true

              if (scn.footprint) {
                const sensorId = sensors?.id;
                const sceneId = scn?.id;
                const action = "sensor"
                const taskId = this.collection?.taskId;
                const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
                this.spinnerService.show();
                this.mapLoad = true;
                this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
                  if (res?.msg === 'Failed') {
                    this.fail = this.fail + 1;
                    if (this.fail === 1) {
                      const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                    }
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  } else if (res?.msg === 'user storage') {
                    this.fail = this.fail + 1;
                    if (this.fail === 1) {
                      const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                    }
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  }
                  scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
                  this.displayFootPrint(scn, sensorIndex, res.result,sensorId,taskId);
                  this.spinnerService.hide();
                  this.mapLoad = false;
                });

              }
            }
          }


        }
        if (this.selectedCheckboxes.length === 2 && unShowSensor) {
          this.isVisible = true
          this.splitRightPostion = true
          this.splitLeftPostion = true
          this.splitPosition = 0.5;
          this.clearAnnotations();
        }
        this.selectedSensors = this.selectedCheckboxes
        if (this.selectedSensors.length === 0)
          this.collection.recurrencyArray[j].checked = e?.checked;
      } else {
        this.selectedCheckboxes[j].scenes[k].checked = e?.checked;
        const scn = this.selectedCheckboxes[j].scenes[j]?.scenes[k];
        if (e?.checked) {
          if (scn.footprint) {
            sensorId = this.selectedSensors[j]?.id;
            action = "scene"
            sceneId = scn?.id;
            this.spinnerService.show();
            this.mapLoad = true;
            const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
            this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
              if (res?.msg === 'Failed') {
                this.spinnerService.hide();
                this.mapLoad = false;
                const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              } else if (res?.msg === 'user storage') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              }
              if (this.selectedSensors[j]?.scenes[k]?.thumbnailUrl) {
                this.selectedSensors[j].scenes[k].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
              }
              scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
              this.displayFootPrint(scn, j, res.result,sensorId,taskId);
              this.spinnerService.hide();
              this.mapLoad = false;
            });
          }
        }


      }
    } else if (e?.checked && k !== undefined) {
      const scn = this.selectedCheckboxes[j].scenes[k];

      if (scn?.operatorKey === this.OPERATOR.USL || scn?.operatorKey === this.OPERATOR.LBAND) {
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
          if (scn?.operatorKey === OPERATORS.LBAND) {
            this.selectSignals(sensors, this.signalSelected)
          } else {
            for (const item of sensors.scenes) {
              if (item.checked && item.operatorKey === this.OPERATOR.USL) {
                this.displayRFImage(item, sensorIndex, sensors.id,taskId);
              }
            }
          }
        }
      }

      if (e?.checked) {
        if (scn.footprint) {
          sensorId = this.selectedSensors[j]?.id;
          action = "scene"
          sceneId = scn?.id;
          this.spinnerService.show();
          this.mapLoad = true;
          const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
          this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
            if (res?.msg === 'Failed') {
              this.spinnerService.hide();
              this.mapLoad = false;
              const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            } else if (res?.msg === 'user storage') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            }
            scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
            this.displayFootPrint(scn, j, res.result,sensorId,taskId);
            this.spinnerService.hide();
            this.mapLoad = false;
          });
        }
      }
    } else {
      // Checkbox is being deselected


      if (k === undefined) {
        this.overlay[j] = 100
        const indexToRemove = type === "dropdown" ? this.selectedCheckboxes.findIndex((item) => item.start === this.collection.recurrencyArray[j].start) : j;
        const indexToRemoveSelectedSensor = this.selectedCheckboxes.findIndex((item) => item.index === j);
        if (indexToRemove !== -1) {

          this.selectedCheckboxes.splice(indexToRemove, 1);

          this.selectionChange.next(this.selectedCheckboxes[0])
          this.subSelectionSelectionChange.next(1)
          if (this.collection.recurrencyArray.filter(item => item.checked).length > 0) {

            this.collection.recurrencyArray.forEach((sensor, index) => {
              const indexTouncheck = this.selectedCheckboxes.findIndex((item) => item.start === sensor.start);
              if (indexTouncheck === -1) {
                sensor.checked = false
                sensor.scenes.forEach((scene) => {
                  scene.checked = false

                });
              }
            });
          }
        }

        if (indexToRemoveSelectedSensor !== -1) {
          this.selectedSensors.splice(indexToRemoveSelectedSensor, 1);
        }
      }
      if (j === 0) {

        var allImageryLayers = this.viewer.imageryLayers._layers;
        var leftImageryLayers: any = [];
        var rightImageryLayers: any = [];
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url

          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            leftImageryLayers.push(allImageryLayers[index]);
          }
          if (url !== undefined && isRight) {
            rightImageryLayers.push(allImageryLayers[index]);
          }
        }
        if (k === undefined) {

         if (this.selectedCheckboxes.length === 0 && rightImageryLayers.length === 1) {
            for (let rightIndex = 0; rightIndex < rightImageryLayers.length; rightIndex++) {
              this.viewer.imageryLayers.remove(rightImageryLayers[rightIndex]);
            }
            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
             this.scnLyr[indexToRemoveEntity].show = false;
            }
            for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
           }

            this.scnLyr2 = []
            this.scnLyr = []
          }
          else {
            for (var leftIndex = 0; leftIndex < leftImageryLayers.length; leftIndex++) {
              this.viewer.imageryLayers.remove(leftImageryLayers[leftIndex]);
            }

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr[indexToRemoveEntity].show = false;
             }

          }


        } else {
          const findSensor = this.selectedCheckboxes[j]
          if (leftImageryLayers.length === 1) {
            this.viewer.imageryLayers.remove(leftImageryLayers[0]);

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              const item = this.scnLyr[indexToRemoveEntity];              
              if (item.index === findSensor.scenes[k].sceneIdx) {
                this.scnLyr[indexToRemoveEntity].show = false;
                this.scnLyr.splice(indexToRemoveEntity, 1);
              }
            }

          } else {

            if (leftImageryLayers.length === 0) {
              for (let rightIndex = 0; rightIndex < rightImageryLayers.length; rightIndex++) {
                this.viewer.imageryLayers.remove(rightImageryLayers[rightIndex]);
              }
              for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                  this.scnLyr2[indexToRemoveEntity].show = false;
                  this.scnLyr2.splice(indexToRemoveEntity, 1);
              }
            }
             else {
              if (allImageryLayers.length === 1 && rightImageryLayers.length === 1) {
                for (let rightIndex = 0; rightIndex < rightImageryLayers.length; rightIndex++) {
                  this.viewer.imageryLayers.remove(rightImageryLayers[rightIndex]);

                }
                for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                  this.scnLyr2[indexToRemoveEntity].show = false;
                  this.scnLyr2.splice(indexToRemoveEntity, 1);
              }
              }
              else {
                for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                  if (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl)) {
                    this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
                  }
                }
                for (
                  let indexToRemoveEntity = this.scnLyr?.length - 1;
                  indexToRemoveEntity >= 0;
                  indexToRemoveEntity--
                ) {
                  const item = this.scnLyr[indexToRemoveEntity];
                  if (item.index === findSensor.scenes[k].sceneIdx) {
                    this.scnLyr[indexToRemoveEntity].show = false;
                    this.scnLyr.splice(indexToRemoveEntity, 1);
                  }
                }
              }

            }

          }
          if (this.selectedCheckboxes[j].scenes.filter(item => item.checked).length === 0) {
            this.selectedCheckboxes[j].checked = false
            this.selectedCheckboxes.splice(j, 1);
          }

        }


      }
      else {
        var allImageryLayers = this.viewer.imageryLayers._layers;
        var leftImageryLayers: any = [];
        var rightImageryLayers: any = [];
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url;
          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            leftImageryLayers.push(allImageryLayers[index]);
          }
          if (url !== undefined && isRight) {
            rightImageryLayers.push(allImageryLayers[index]);
          }
        }
        if (k === undefined) {

          for (let rightIndex = 0; rightIndex < rightImageryLayers.length; rightIndex++) {
            this.viewer.imageryLayers.remove(rightImageryLayers[rightIndex]);
          }
          if (leftImageryLayers.length > 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = true;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }
        }

        } else {
          const findSensor = this.selectedCheckboxes[j]
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            const item = this.scnLyr2[indexToRemoveEntity];              
            if (item.index === findSensor.scenes[k].sceneIdx) {
              this.scnLyr2[indexToRemoveEntity].show = false;
            }
          }
          if (rightImageryLayers.length === 1) {
            this.viewer.imageryLayers.remove(rightImageryLayers[0]);
          } else {


            if (rightImageryLayers.length === 0) {
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                if (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl)) {
                  this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
                }
              }
             } else {
              for (var index = 0; index < rightImageryLayers.length; index++) {
                if (rightImageryLayers[index]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (rightImageryLayers[index]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl) ) {
                  this.viewer.imageryLayers.remove(rightImageryLayers[index]);
                }
              }
            }
            if (this.selectedCheckboxes[j].scenes.filter(item => item.checked).length === 0) {
              this.selectedCheckboxes[j].checked = false
              this.selectedCheckboxes.splice(j, 1);
            }

          }
          if (this.selectedCheckboxes[j]?.scenes.filter(item => item.checked).length === 0) {
            this.selectedCheckboxes[j].checked = false
            this.selectedCheckboxes.splice(j, 1);
          }
        }
        if (leftImageryLayers.length === 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = false;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = true;
          }
          
        }
        if (rightImageryLayers.length === 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = true;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }
        }

        if (leftImageryLayers.length === 0 && rightImageryLayers.length === 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = false;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }
        }


      }
      const uniqueArray: any = [];
      const seenIds = new Set();

      for (const item of this.selectedSensors) {
        if (!seenIds.has(item.index && item.start)) {
          seenIds.add(item.index && item.start);
          uniqueArray.push(item);
        }
      }

      this.selectedSensors = uniqueArray
      if (this.selectedCheckboxes.length < 2) {
        this.isVisible = false
        this.splitRightPostion = false
        this.splitLeftPostion = false
        this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
        this.viewer.scene.imagerySplitPosition = undefined;
        this.splitPosition = 0.0;
      }
      
      if (this.collection.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL || 
        this.collection.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.LBAND
      ) {
        let operatorKey = this.collection.sensors[0]?.scenes[0]?.operatorKey;
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
          if (operatorKey === OPERATORS.LBAND) {
            this.selectSignals(sensors, this.signalSelected)
          } else {
            for (const item of sensors.scenes) {
              if (item.checked && item.operatorKey === this.OPERATOR.USL) {
                this.displayRFImage(item, sensorIndex, sensors.id,taskId);
              }
            }
          }
        }
      }

      if (this.selectedCheckboxes?.length === 0 && !this.datePlace) {
        this.toggleDailyRecurrency(e)
      }

      if (this.selectedCheckboxes?.length === 0 && this.datePlace) {
        this.currentEvent = {
          checked : true
         };

         let site = this.subDailyCountarray.filter(item => item.checked);
         this.selectedSites = [];
        this.selectSiteRecurrent(this.currentEvent,site[0]);        
      }

    }

    const findIndexImage = this.collection.recurrencyArray[j]?.scenes
      .filter(function (el) {

        return el.checked;
      })

    if (findIndexImage.length === 0) {

    } else {
      var bothSensorSelected: any = []
      this.selectedCheckboxes.forEach((item: any, index: any) => {
        const findIndexsensor = item.scenes
          .filter(function (el) {

            return el.checked;
          })
        if (findIndexsensor.length > 0)
          bothSensorSelected.push(item)

      });



    }

    if (this.selectedCheckboxes.length === 2 && unShowSensor) {
      this.isVisible = true
      this.splitRightPostion = true
      this.splitLeftPostion = true
      this.splitPosition = 0.5;
      this.clearAnnotations();
    } else {
      this.isVisible = false;
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.splitPosition = 0.0;
    }

    this.collection.sensor = this.selectedSensors;
    if (this.selectedCheckboxes.length > 0) {
      this.collection.sensors.forEach((sensor, index) => {
        sensor.checked = true
        sensor.scenes.forEach((scene) => {
          scene.checked = true

        });
      });
      if (this.selectedCheckboxes.length === 1)
        this.SensorIndex = this.selectedCheckboxes[0].index
    }
    if (this.collection.recurrencyArray.filter(item => item.checked).length > 0) {
      this.collection.sensors.forEach((sensor, index) => {
        sensor.checked = true
        sensor.scenes.forEach((scene) => {
          scene.checked = true

        });
      });
    }
    if (this.selectedCheckboxes.length === 1) {
      this.selectedIndex = this.selectedCheckboxes[0].index
    }

    this.monthSelected = null;
    this.selectedType = null;
    const filteredCheckboxes = this.selectedCheckboxes.filter(item => item.checked);
    if (this.datePlace && filteredCheckboxes[0]) {
      this.monthSelected = filteredCheckboxes[0];
      if (filteredCheckboxes?.length === 1) {
        this.selectedType = 'dateDropdown';
        this.selectRecurrency(filteredCheckboxes[0], 'split');
        if (this.selectedSite) {
          this.subDailyCountarray = this.subDailyCountarray.map(site => {
            if (site.value === this.selectedSite.value) {
              return { ...site, checked: true };
            }
            return site;
          });
        }   
      }

      const filteredCheckbox = this.subDailyCountarray.filter(item => item.checked);      
      if (filteredCheckbox?.length === 1) {
        this.dateDisable = false;
      }

    }

    if(this.selectedCheckboxes.filter(item => item.checked).length >= 1) {
      this.imageLoaded = true;
    }
  }   

  if (this.selectedCheckboxes?.length === 2 && this.datePlace) {
    if (this.selectedSites.length > 0) {
      this.selectedSites[0].checked = true;
    }
  }

  const checkedItemsLength = this.subDailyCountarray.filter(item => item.checked).length;  
  if (checkedItemsLength === 0 && this.datePlace) {        
    for (const count of this.subDailyCountarray) {
      if (count.value === this.selectedSites[0].value) {
        count.checked = true;
        this.siteSelected = count;        
      }
    }
  }

  }

  setPreviousSelectiontext() {
    if (this.selectedCheckboxes[0] && this.selectedCheckboxes[0]?.start) {
      const start = this.formatDate(new Date(this.selectedCheckboxes[0]?.start))
      const end = this.formatDate(new Date(this.selectedCheckboxes[0]?.end));
      const combinedDate = `${start} - ${end}`;
      if (this.dateRange) {
        this.previousSelectionText = new Date(this.selectedCheckboxes[0]?.start);
        this.previousEndText = new Date(this.selectedCheckboxes[0]?.end);
      } else
        this.previousSelectionText = new Date(this.selectedCheckboxes[0]?.start)
    }
  }

  formatDate(date) {
    const day = String(date.getDate() - 1).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note that months are 0-based.
    const year = String(date.getFullYear()).slice(-4);

    return `${day}/${month}/${year}`;
  }

  async toggleSensor(i: any, j: any, k: any, e: any, scenes: any) {
    this.feedInfo = false;
    if(this.selectedSR !== null) {
      this.resetSROptions(i, j, e);
      if (this.currentProductLayer) {
        this.viewer.imageryLayers.remove(this.currentProductLayer);
        this.currentProductLayer = null;
      }
    }  
    if(k === undefined) 
      this.selectedOptions = [];    
    let sceneId: any;
    let sensorId: any;
    let action: any;
    const taskId = this.collections[i]?.taskId;
    this.fail = 0;
    let count = 0;
    let previouseSceneId: any;
    this.selectedRecurrencyArray = [];

    if(scenes?.operatorKey === this.OPERATOR.CLYDE && e?.checked && this.collections[i].sensors[j].status === 'canceled') {
      const message = 'The user has cancelled the task, and the data is unavailable.';
      this.popupService.openPopup(WarningPopupComponent, message, '', '40%');
    } else {
    if (e?.checked) {
      this.operatorArray.push(scenes?.operatorKey);
    } else {
      const index = this.operatorArray.findIndex(selectedItem => selectedItem === scenes?.operatorKey);
      this.operatorArray.splice(index, 1);
    }

    if (this.timeseries) {
      if (this.slider) this.slider.value = 0;
      this.currentSliderValue = 0;
      this.collections[i].sensors[j].checked = e?.checked
      this.clearBandLegends();
      this.removeImages();
      this.viewer.entities.removeAll();
      this.sliderShow = false;
      this.sourceRateArray = [];
      this.previousIndex = -1;
      this.clearAnnotations();
      this.annotateClicked = false;
      this.newTaskButtonClicked = false;
  
      if (this.collections[i]?.recurrencyArray?.length > 1 && !this.spire) {
        this.selectedRecurrencyArray = this.collections[i]?.recurrencyArray;
        this.groupedScenes = this.groupRecurrencyCollections(this.collections[i]?.recurrencyArray);
      }
      else this.groupedScenes = this.groupCollections();
      if (this.operatorArray?.length === 0) {
        this.sliderDateRangeArray = [];
      } else this.sliderView = true;
      if ((scenes?.operatorKey === this.OPERATOR.SPIRE || scenes?.operatorKey === this.OPERATOR.CLYDE) && !e?.checked) {
        this.sliderShow = false;
        this.currentSliderValue = 0;
      }
    } 
    else {
    if (scenes?.operatorKey === this.OPERATOR.USL) {

      const indexToRemoveEntity = this.USLscnLyr.findIndex((item) => item.index === this.uslBB.index);
      if (indexToRemoveEntity !== -1) {
        this.USLscnLyr[indexToRemoveEntity].show = false
        this.USLscnLyr.splice(indexToRemoveEntity, 1);
      }
      this.USLscnLyr = [];
      this.isVisible = false;
      this.splitPosition = 0.0;
      this.leftBand = false;
      this.rightBand = false;
      this.feedLoaded = false;

      if (e?.checked) {
        this.sceneBands = scenes;
        this.bandTaskId = taskId;
        this.bandSensorId = this.collections[i].sensors[j].id;        
        
        if (k === undefined) {
        this.collections[i].sensors[j].checked = true
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          if (scn?.id && scn?.status !== 'failed') {
            scn.checked = e?.checked;
          }
        })
      } else { 
        this.collections[i].sensors[j].scenes[k].checked = true;
      }
      this.displayImage(scenes);
      } else {
        this.sceneBands = '';
        this.bandTaskId = '';
        this.bandSensorId = '';
        if (k === undefined) {
        this.collections[i].sensors[j].checked = false
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          scn.checked = false
          this.clearAnnotationsByScene(scn.id);
          this.removeDataSourceById("scene", scn.id);
        })
      } else {
        this.collections[i].sensors[j].scenes[k].checked = false;
        let sceneId = this.collections[i].sensors[j].scenes[k]?.id;
        this.clearAnnotationsByScene(scenes.id);
        this.removeDataSourceById("scene", sceneId);
      }

      if (this.selectedSensors?.length === 2) {
        this.isVisible = true
        this.splitRightPostion = true
        this.splitLeftPostion = true
        this.splitPosition = 0.5;
        this.clearAnnotations();
      }
      }
    } else if(scenes?.operatorKey === this.OPERATOR.SPIRE || scenes?.operatorKey === this.OPERATOR.CLYDE) {
      let sensor = this.collections[i]?.sensors[j];
      this.clearSilder();
      this.isVisible = false;
      this.splitPosition = 0.0;
      if (e?.checked) {
        if (scenes?.operatorKey === this.OPERATOR.SPIRE) this.displayAIS(scenes, "display",sensor);
        else if (scenes?.operatorKey === this.OPERATOR.CLYDE) this.displayAISClyde(scenes, "display",sensor);

        if (k === undefined) {
        this.collections[i].sensors[j].checked = true;
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          if (scn?.id && scn?.status !== 'failed') {
            scn.checked = e?.checked;
          }
        })
      } else this.collections[i].sensors[j].scenes[0].checked = true;
      } 
      else {
        this.overlayVessel = [];
        this.overlayClydeVessel = [];
        this.removeDataSourceBySensorId(sensor?.id);
        const indexToRemoveEntity = this.SpirescnLyr.findIndex((item) => item.index === this.uslBB.index);
        if (indexToRemoveEntity !== -1) {
          this.SpirescnLyr[indexToRemoveEntity].show = false
          this.SpirescnLyr.splice(indexToRemoveEntity, 1);
        }
        this.SpirescnLyr = [];

        if (k === undefined) {
        this.collections[i].sensors[j].checked = false;
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          scn.checked = false
          this.clearAnnotationsByScene(scn.id);
        })
      } else {
        this.collections[i].sensors[j].scenes[0].checked = false;
        this.clearAnnotationsByScene(scenes.id);
      }

      if (this.selectedSensors?.length === 2) {
        this.isVisible = true
        this.splitRightPostion = true
        this.splitLeftPostion = true
        this.splitPosition = 0.5;
        this.clearAnnotations();
      }      
      }
    } else if (scenes?.operatorKey === this.OPERATOR.LBAND) {
      this.isVisible = false;
      this.splitPosition = 0.0;
      if (e?.checked) {
        this.signalBands = scenes;
        this.bandTaskId = taskId;
        this.signalSensorId = this.collections[i].sensors[j].id;        
        
        if (k === undefined) {
        this.collections[i].sensors[j].checked = true
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          if (scn?.id && scn?.status !== 'failed') {
            scn.checked = e?.checked;
          }
        })
      } else this.collections[i].sensors[j].scenes[k].checked = true;

       this.selectSignals(this.collections[i].sensors[j], this.signalSelected)

      } else {
        this.signalBands = '';
        this.bandTaskId = '';
        this.signalSensorId = '';

        if (k === undefined) {
        this.collections[i].sensors[j].checked = false
        this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
          scn.checked = false
          this.clearAnnotationsByScene(scn.id);
        })
      } else {
        this.collections[i].sensors[j].scenes[k].checked = false;
        this.clearAnnotationsByScene(scenes.id);
      }

      if (this.selectedSensors?.length === 2) {
        this.isVisible = true
        this.splitRightPostion = true
        this.splitLeftPostion = true
        this.splitPosition = 0.5;
        this.clearAnnotations();
      }

      if (scenes?.operatorKey === this.OPERATOR.LBAND) {
        this.selectSignals(this.collections[i].sensors[j], this.signalSelected)
       }
      }
    } else {
      
      if (e?.checked) {
        this.overlay[j] = 100
        if (this.selectedSensors.length === 0) {
          this.collections[i].sensors[j].index = j;
          this.selectedSensors.push(this.collections[i].sensors[j])
        } else {
          const findIndex = this.selectedSensors
            .filter(function (el) {
              return el.index === j;
            })
          if (findIndex.length === 0) {
            this.collections[i].sensors[j].index = j;
            this.selectedSensors.push(this.collections[i].sensors[j])
          }
        }
        const uniqueArray: any = [];
        const seenIds = new Set();

        for (const item of this.selectedSensors) {
          if (!seenIds.has(item.index)) {
            seenIds.add(item.index);
            uniqueArray.push(item);
          }
        }
        this.selectedSensors = uniqueArray        
      } else {
        if (k === undefined) {
          this.overlay[j] = 100
          this.selectedSensors = this.selectedSensors
            .filter(function (el) {
              return el.index !== j;
            })
        } else {
          if (this.showDailyRecurrencyCheckboxes) {
            this.collection.sensors[0].scenes[k].checked = e?.checked;
            const collectionUnselectedFilter = this.collection.sensors[0].scenes.filter(item => item?.checked);
            if (collectionUnselectedFilter.length === 0) {
              this.collection.sensors[0].checked = e?.checked;
              let filterIndex = 0

              this.collection.recurrencyArray.forEach((sensor, index) => {
                sensor.scenes.forEach((scene) => {
                  if ((new Date(scene.predicted_date)).getTime() === (new Date(this.collection.sensors[0].scenes[k].predictedDate)).getTime()) {
                    sensor.checked = false
                    filterIndex = index;
                  }
                });
              });

              this.toggleDailyRecurrency(e)
              return
            }

          }

        }

        var allImageryLayers = this.viewer.imageryLayers._layers;
        var leftImageryLayers: any = [];
        var rightImageryLayers: any = [];
        var assetImageryLayers: any = [];
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url
          var ionImageryProvider = allImageryLayers[index]?.imageryProvider;
          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            leftImageryLayers.push(allImageryLayers[index]);
          }
          if (url !== undefined && isRight) {
            rightImageryLayers.push(allImageryLayers[index]);
          }
        }

        if (j === 0) {
          if (k === undefined) {
            for (var index = 0; index < leftImageryLayers.length; index++) {
              this.viewer.imageryLayers.remove(leftImageryLayers[index]);
            }

            for (var index = 0; index < assetImageryLayers.length; index++) {
              this.viewer.imageryLayers.remove(assetImageryLayers[index]);
            }

            if (leftImageryLayers.length === 1) {
              const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

              for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr[indexToRemoveEntity];              
                if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                  this.scnLyr[indexToRemoveEntity].show = false;
                  this.scnLyr.splice(indexToRemoveEntity, 1);
                }
              }
            } else {
              const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

              for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr[indexToRemoveEntity];              
                if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                  this.scnLyr[indexToRemoveEntity].show = false;
                  this.scnLyr.splice(indexToRemoveEntity, 1);
                }
              }
            }
            
          } else {
            if (leftImageryLayers.length === 1) {
              for (var index = 0; index < leftImageryLayers.length; index++) {
                this.viewer.imageryLayers.remove(leftImageryLayers[index]);
              }
              const findSensor = this.selectedSensors
              .filter(function (el) {

                return el.index === j;
              })

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              const item = this.scnLyr[indexToRemoveEntity];              
              if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                this.scnLyr[indexToRemoveEntity].show = false;
                this.scnLyr.splice(indexToRemoveEntity, 1);
              }
            }
            } else {
              const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

              for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr[indexToRemoveEntity];              
                if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                  this.scnLyr[indexToRemoveEntity].show = false;
                  this.scnLyr.splice(indexToRemoveEntity, 1);
                }
              }

              for (var index = 0; index < leftImageryLayers.length; index++) {

                if ((leftImageryLayers[index]?.imageryProvider?._source?.source?.source?.client?.url === findSensor[0].scenes[k].thumbnailUrl) || (leftImageryLayers[index]?.imageryProvider.url === findSensor[0].scenes[k].thumbnailUrl)) {
                  this.viewer.imageryLayers.remove(leftImageryLayers[index]);
                }
              }
              for (var index = 0; index < assetImageryLayers.length; index++) {
                if (assetImageryLayers[index]._imageryProvider._imageryProvider._resource._url.includes(findSensor[0].scenes[k].thumbnailUrl.assetId))
                  this.viewer.imageryLayers.remove(assetImageryLayers[index]);
              }
            }

          }


        } else {
          if (k === undefined) {
            for (var index = 0; index < rightImageryLayers.length; index++) {
              this.viewer.imageryLayers.remove(rightImageryLayers[index]);
            }
            for (var index = 0; index < assetImageryLayers.length; index++) {
              this.viewer.imageryLayers.remove(assetImageryLayers[index]);
            }

            if (rightImageryLayers.length === 1) {
              const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

              for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr2[indexToRemoveEntity];              
                if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                  this.scnLyr2[indexToRemoveEntity].show = false;
                  this.scnLyr2.splice(indexToRemoveEntity, 1);
                }
              }
            } else {
              const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

              for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr2[indexToRemoveEntity];              
                if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                  this.scnLyr2[indexToRemoveEntity].show = false;
                  this.scnLyr2.splice(indexToRemoveEntity, 1);
                }
              }
            }
            
          } else {
            if (k === undefined) {
              for (var index = 0; index < rightImageryLayers.length; index++) {
                this.viewer.imageryLayers.remove(rightImageryLayers[index]);
              }
              
            } else {
              if (rightImageryLayers.length === 1) {
                const findSensor = this.selectedSensors
                  .filter(function (el) {

                    return el.index === j;
                  })
                this.viewer.imageryLayers.remove(rightImageryLayers[0]);

                for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                  const item = this.scnLyr2[indexToRemoveEntity];              
                  if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                    this.scnLyr2[indexToRemoveEntity].show = false;
                    this.scnLyr2.splice(indexToRemoveEntity, 1);
                  }
                }
              } else {
                const findSensor = this.selectedSensors
                .filter(function (el) {

                  return el.index === j;
                })

                for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                  const item = this.scnLyr2[indexToRemoveEntity];              
                  if (item.index === findSensor[0]?.scenes[k]?.sceneIdx) {
                    this.scnLyr2[indexToRemoveEntity].show = false;
                    this.scnLyr2.splice(indexToRemoveEntity, 1);
                  }
                }
              for (var index = 0; index < rightImageryLayers.length; index++) {
                if ((rightImageryLayers[index]?.imageryProvider?._source?.source?.source?.client?.url === findSensor[0].scenes[k].thumbnailUrl) || (rightImageryLayers[index]?.imageryProvider.url === findSensor[0].scenes[k].thumbnailUrl)) {                  
                  this.viewer.imageryLayers.remove(rightImageryLayers[index]);
                }
              }
              }

            }
          }
        }

        if (leftImageryLayers.length === 0) {
        for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
          this.scnLyr[indexToRemoveEntity].show = false;
        }
        for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr2[indexToRemoveEntity].show = true;
        }
      }

        if (rightImageryLayers.length === 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = true;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }
        }
        if (leftImageryLayers.length === 0 && rightImageryLayers.length === 0) {
          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = false;
          }
          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr2[indexToRemoveEntity].show = false;
          }

        }
        this.selectedSensors = this.selectedSensors.filter(item => (item?.scenes[0]?.operatorKey !== OPERATORS.USL && item?.scenes[0]?.operatorKey !== OPERATORS.LBAND));

        if (this.selectedSensors.length < 2) {
          this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
          this.viewer.scene.imagerySplitPosition = undefined;
          var imageryLayers = this.viewer.imageryLayers;
          this.splitPosition = 0.0;
          this.isVisible = false
          this.splitRightPostion = false
          this.splitLeftPostion = false
        }

      }

    if (k === undefined) {
      var allImageryLayers = this.viewer.imageryLayers._layers;
      if(j === 0) {
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url
          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            allImageryLayers.forEach(() => {
              this.viewer.imageryLayers.remove(allImageryLayers[index]);
            })
          }
        }

        this.scnLyr.forEach(scn => {
          scn.show = false
        })
      } else {
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url
          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          
          if (url !== undefined && isRight) {
            allImageryLayers.forEach(() => {
              this.viewer.imageryLayers.remove(allImageryLayers[index]);
            })
          }
        }
        this.scnLyr2.forEach(scn => {
          scn.show = false
        })
      }


      // this.scnLyr = [];
      // this.scnLyr2 = [];
      this.collections[i].sensors[j].scenes.forEach(async (scn: any, index: any) => {

        const checked = this.collections[i].sensors[j].checked;
        if (scn.id && e?.checked === true)
          if (scn?.id && scn?.status !== 'failed') {
            scn.checked = e?.checked;
          }
        if (e?.checked) {
          if (scn.footprint && (scn.operatorKey !== OPERATORS.SPIRE || scn.operatorKey !== OPERATORS.CLYDE)) {
            sensorId = this.collections[i].sensors[j]?.id;
            sceneId = scn?.id;
            action = "sensor";
            this.sceneSelected = sceneId;
            const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
            this.spinnerService.show();
            this.mapLoad = true;
            this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
              if (res?.msg === 'Failed') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                  this.popupService.openPopup(CollectionPopupComponent, message,'', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              } else if (res?.msg === 'user storage') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              }
              if (this.selectedSensors?.length === 1 && this.selectedSensors[0]?.scenes[index]?.thumbnailUrl) {
                  this.selectedSensors[0].scenes[index].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;  
              } else if (this.selectedSensors[j]?.scenes[index]?.thumbnailUrl){
                this.selectedSensors[j].scenes[index].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;  
              }
              this.displayFootPrint(scn, j, res.result,sensorId,taskId);
              this.spinnerService.hide();
              this.mapLoad = false;
            });

            if(this.collections[i].sensors[j]?.InfoServices.length > 0 && this.collections[i].sensors[j].scenes.filter((scen) => scen.checked == true).length == 1 && this.collections[i].sensors[j]?.InfoServices[0]?.modelType == this.infoserviceModelType) {
              let payload = {
                "action": "ISDisplay",
                "type": "json",
                "sceneId" : sceneId,
                "sensorId" : sensorId
              } 
              this.apiService.getCollectionImage(this.collection.taskId, payload).subscribe((res: any) => {
                const geoJsonRequests = res?.result?.feedData.map((url) => this.httpClient.get(url));

                forkJoin(geoJsonRequests).subscribe({
                  next: (geoJsonDataArray: any) => {
                    const combinedGeoJson = this.combineGeoJsonFeatures(geoJsonDataArray);
                    this.collections[i].sensors[j]?.InfoServices.forEach((info) => {
                      info.insightDatas.forEach((insightData) => {
                        if (insightData.sceneId === sceneId) {
                          insightData.insightFootprint = combinedGeoJson;
                        }
                      });
                    });
                  },
                  error: (error) => {
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  },

                })
        
                
                
              
              
            })}

          } else if (scn?.uslFootprint) {
            this.displayFootPrint(scn, j, scn?.uslFootprint,this.collections[i].sensors[j].id,taskId);
          }
        } else {
          this.collections[i].sensors[j]?.scenes.forEach(async (scn: any, index: any) => {
            scn.checked = false;
            this.clearAnnotationsByScene(scn.id);
          })
          // this.viewer.entities.removeAll();
          if (scn?.uslFootprint) {
            this.clearBandLegends();
            const indexToRemoveEntity = this.USLscnLyr.findIndex((item) => item.index === this.uslBB.index);
            if (indexToRemoveEntity !== -1) {
              this.USLscnLyr[indexToRemoveEntity].show = false
              this.USLscnLyr.splice(indexToRemoveEntity, 1);
            }
            this.USLscnLyr = [];
          }
        }
      });
      this.collections[i].sensors[j].checked = e?.checked;
    } else {
      const checkedScnes = this.collections[i].sensors[j].scenes.filter(scene => scene.checked === true);
      if (checkedScnes?.length > 0) {
      previouseSceneId = checkedScnes[0].id;
      }

      this.collections[i].sensors[j].scenes[k].checked = e?.checked;
      const scn = this.collections[i].sensors[j].scenes[k];
      if (e?.checked) {
        if (scn.footprint && (scn.operatorKey !== OPERATORS.SPIRE || scn.operatorKey !== OPERATORS.CLYDE)) {
          sensorId = this.collections[i].sensors[j]?.id;
          action = "scene"
          sceneId = scn?.id;
          this.sceneSelected = sceneId;
          this.spinnerService.show();
          this.mapLoad = true;
          const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
          if(this.collections[i].sensors[j]?.InfoServices.length > 0 && this.collections[i].sensors[j]?.InfoServices[0]?.modelType == this.infoserviceModelType) {
            let payload = {
              "action": "ISDisplay",
              "type": "json",
              "sceneId" : sceneId,
              "sensorId" : sensorId
            } 
            this.apiService.getCollectionImage(this.collection.taskId, payload).subscribe((res: any) => {
              const geoJsonRequests = res?.result?.feedData.map((url) => this.httpClient.get(url));
                forkJoin(geoJsonRequests).subscribe({
                  next: (geoJsonDataArray: any) => {
                    const combinedGeoJson = this.combineGeoJsonFeatures(geoJsonDataArray);
                    this.collections[i].sensors[j]?.InfoServices.forEach((info) => {
                      info.insightDatas.forEach((insightData) => {
                        if (insightData.sceneId === sceneId) {
                          insightData.insightFootprint = combinedGeoJson;
                        }
                      });
                    });
                  },
                  error: (error) => {
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  },

                })
              
              
            })
            
          }
          
          this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
            if (res?.msg === 'Failed') {
              this.spinnerService.hide();
              this.mapLoad = false;
              const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            } else if (res?.msg === 'user storage') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            }


            
            if (this.selectedSensors?.length === 1 && this.selectedSensors[0]?.scenes[k]?.thumbnailUrl) {
              this.selectedSensors[0].scenes[k].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;  
            } else if (this.selectedSensors[j]?.scenes[k]?.thumbnailUrl){
              this.selectedSensors[j].scenes[k].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;  
            }
            
            this.displayFootPrint(scn, j, res.result,sensorId,taskId);
            this.spinnerService.hide();
            this.mapLoad = false;
          });
        } else if (scn?.uslFootprint) {
          this.displayFootPrint(scn, j, scn?.uslFootprint,this.collections[i].sensors[j].id,taskId);
        }
      } else {
        this.clearAnnotationsByScene(scn.id);
        // this.viewer.entities.removeAll();
        if (scn?.uslFootprint) {
          this.clearBandLegends();
          const indexToRemoveEntity = this.USLscnLyr.findIndex((item) => item.index === this.uslBB.index);
          if (indexToRemoveEntity !== -1) {
            this.USLscnLyr[indexToRemoveEntity].show = false
            this.USLscnLyr.splice(indexToRemoveEntity, 1);
          }
          this.USLscnLyr = [];
        }
      }
    }

    const findIndexImage = this.collections[i].sensors[j].scenes
      .filter(function (el) {

        return el.checked;
      })

    if (findIndexImage.length === 0) {
      this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
      this.viewer.scene.imagerySplitPosition = undefined;
      var imageryLayers = this.viewer.imageryLayers;
      this.splitPosition = 0.0;
      // Get the layer you want to remove the split position from
      var layer = imageryLayers.get(0); // Change 0 to the index of your layer
      this.isVisible = false
      // Remove the imagery split position
      // layer.imagerySplitPosition = undefined;
      this.splitRightPostion = false
      this.splitLeftPostion = false

    } else {
      var bothSensorSelected: any = []
      this.selectedSensors.forEach((item: any, index: any) => {
        const findIndexsensor = item.scenes
          .filter(function (el) {

            return el.checked;
          })
        if (findIndexsensor.length > 0)
          bothSensorSelected.push(item)

      });

      if (bothSensorSelected.length === 2) {
        let noSplit = this.operatorArray.includes(OPERATORS.USL) || this.operatorArray.includes(OPERATORS.SPIRE) || this.operatorArray.includes(OPERATORS.LBAND);
        if (noSplit) {
          this.isVisible = false;
          this.splitPosition = 0.0;
        } else {
          this.isVisible = true
          this.splitRightPostion = true
          this.splitLeftPostion = true
          this.splitPosition = 0.5;
          this.clearAnnotations();
        }
      } else {
        this.isVisible = false;
        this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
        this.viewer.scene.imagerySplitPosition = undefined;
        var imageryLayers = this.viewer.imageryLayers;
        // Get the layer you want to remove the split position from
        var layer = imageryLayers.get(0); // Change 0 to the index of your layer
        // Remove the imagery split position
        // layer.imagerySplitPosition = undefined;
        this.splitRightPostion = false
        this.splitLeftPostion = false
        this.splitPosition = 0.0;
      }

    }

    if (this.collections[i]?.sensors[j]?.InfoServices?.length > 0 && this.collections[i]?.sensors[j]?.operatorKey != OPERATORS.AXELSPACE) {
      const scenesArray = this.collections[i].sensors[j].scenes;
      const checkedScenes = scenesArray.filter(scene => scene.checked === true);
      const checkedInfo = this.collections[i].sensors[j].InfoServices.filter(info => info.checked === true);
      count = checkedScenes.length;
      const checkedSensors = this.collections[i].sensors.filter(scene => scene.checked === true);

      const selectedScenes: any = [], sceneList: any = [];
      for (const sensors of checkedSensors) {
        for (const item of sensors.scenes) { 
          if (item.checked) {
            selectedScenes.push(item);
          }
        }
      }

      if (count != 2) {
        for (const sensors of  this.collections[i].sensors) {
          for (const item of sensors.scenes) {
            if (item.checked) {
              sceneList.push(item);
            }
          }
        }
      }

      if (sceneList?.length > 0) this.sceneSelected = sceneList[0]?.id;

      if (checkedSensors?.length > 1 || sceneList?.length > 1) {
        this.sensorsIS = true;
        this.clearBandLegends();
        for (const [sensorIndex, sensors] of this.collections[i].sensors.entries()) {
          for (const item of sensors.InfoServices) {
            item.checked = false;
          }
        }

      } else {
        if (count === 1 || selectedScenes?.length === 1 || sceneList?.length === 1) {
          this.sensorsIS = false;
        } else if (count === 0 && selectedScenes?.length === 0) {
        this.sensorsIS = true;
        this.clearBandLegends();
        for (const item of checkedInfo) {
            item.checked = false;
        }
      } else if ((count > 1 && k === undefined) || (count > 1 && checkedInfo?.length === 0)) {
        this.sensorsIS = true;
        this.clearBandLegends();
        for (const item of checkedInfo) {
          item.checked = false;
        }
      }
      }

      if (checkedInfo?.length > 0  && k != undefined && count === 2) {
          const selectedSceneIS = checkedInfo[0]?.insightDatas?.filter(scene => scene.sceneId === this.sceneSelected);
          this.clearBandLegends();
          if(e.checked) {
            let payload = {
              "action": "ISDisplay",
              "type": "json",
              "sceneId" : this.sceneSelected,
              "sensorId" : this.collections[i].sensors[j].id
            }    
            
              this.apiService.getCollectionImage(this.collection.taskId, payload).subscribe((res: any) => {
                // let insightFootprint = res?.result?.jsonData;

                const geoJsonRequests = res?.result?.feedData.map((url) => this.httpClient.get(url));

                forkJoin(geoJsonRequests).subscribe({
                  next: (geoJsonDataArray: any) => {
                    const combinedGeoJson = this.combineGeoJsonFeatures(geoJsonDataArray);
                    this.displayIS(combinedGeoJson, checkedInfo[0]);
                    this.displayedIS = JSON.parse(JSON.stringify(combinedGeoJson));  
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  },
                  error: (error) => {
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  },

                })
                  
              }) 
            
          } else {
            this.clearBandLegends();
          }
          e.checked = false;
          const index = this.collections[i].sensors[j].scenes.findIndex(item => item.id === previouseSceneId);
          this.toggleSensor(i,j,index,e,'');


      }  

    } else if(this.collections[i]?.sensors[j]?.operatorKey === OPERATORS.AXELSPACE) {
      const scenesArray = this.collections[i].sensors[j].scenes;
      const checkedScenes = scenesArray.filter(scene => scene.checked === false);
      if (checkedScenes?.length > 0) {
        this.clearBandLegends();
        for (const [sensorIndex, sensors] of this.collections[i].sensors.entries()) {
          for (const item of sensors.InfoServices) {
            item.checked = false;
          }
        }
      }
    }

    /** Toggle Scenes if Value Added Options Already Selected */
    if(this.selectedOptions.length > 0)
    {
      this.selectedOptions = [];
      if (previouseSceneId) {
        const index = this.collections[i].sensors[j].scenes.findIndex(item => item.id === previouseSceneId);
        this.collections[i].sensors[j].scenes[index].checked = false;
      }
    }

    }
   }
  }

  if(this.selectedIndices) {
    if (k === undefined && !e.checked) this.viewer.entities.removeAll();
    this.resetIndicesOptions(i, j, e);
  }

  }

  toggle(i: number, j: number) {
    const checked = this.collections[i].sensors[j].checked;
    this.collections.forEach((item: any, index: any) => {
      if (index === i) this.collections[index].sensors[j].checked = checked;
    });
    this.showSlider();
  }
  showSlider() {
    this.selectedItems = 0;
    this.LEFTOPTIONS = [];
    this.RIGHTOPTIONS = [];
    const sliderOverlay = this.sliderOverlay;

    this.collections.forEach((item: any, index: any) => {
      if (item.checked) {
        this.selectedItems += 1;
        this.LEFTOPTIONS.push(item);
      }
    });

    this.LEFTOPTIONS.forEach((item: any, index: any) => {
      if (index > 0) this.RIGHTOPTIONS.push(item);
    });

  }
  downloadScn(url: any) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    // link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  zipDownload(urls: any, taskId: any, sensorId: any, sceneId: any) {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = 'zipFilename.zip';
    urls.forEach((url: any) => {
      const filename = url.split('/')[url.split('/').length - 1].split('?')[0];
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
        if (err) {
          this.progress = 0;
          this.spinnerService.hide();
          this.freeze = false;
          this.downloadClick = false;
          const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          // this.popupService.openPopup(CollectionPopupComponent, message, '40%');
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        const totalCount = urls.length;
        const percentComplete = Math.round((count / totalCount) * 100);
        this.progress = percentComplete;
        const sensorObj = { progress: this.progress, taskId: taskId, sensorId: sensorId, sceneId: sceneId, download: this.downloadClick };
        this.downloadService.setProgress(sensorObj);

        if (count == urls.length) {
          this.progress = 0;
          this.spinnerService.hide();
          this.freeze = false;
          this.disable = false;
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            const objectUrl: string = URL.createObjectURL(content);
            const link: any = document.createElement('a');

            link.download = `${taskId}.zip`;
            link.href = objectUrl;
            link.click();
          });
        }
      });
    });
  }

  /**
   *
   * @param i
   * @param j
   * @param k
   */
  fullDownload(i: any, j: any, k: any) {
    var urls: any = [];
    this.collections[i]?.taskCollection?.map((item: any, index: any) => {
      if (this.collections[i]?.sensorName.includes('Capella')) {
        [
          { path: 'HH', ext: '.tiff' },
          { path: 'metadata', ext: '.json' },
          { path: 'thumbnail', ext: '.png' },
          { path: 'preview', ext: '.tiff' },
        ]?.map((downlaodItem) => {
          urls.push(environment.eeDownloadUrl +
            `collections/orders/${this.collections[i].taskId}/${downlaodItem?.path}/${item}${downlaodItem?.ext}`
          );
        });
      } else {
        if (this.collections[i]?.sensorName.includes('Satellogic')) {
          urls.push(
            environment.eeDownloadUrl + `collections/orders/${this.collections[i].taskId}/delivery_zip/${item}.zip`
          );
        }
      }
    });
    if (this.collections[i]?.sensorName.includes('21AT')) {
      urls.push(
        environment.eeDownloadUrl + `collections/${this.collections[i].sensors[j].downloadUrl}`
      );
    }
  }
  download(i: any, j: any, k: any, scene: any) {
    let sceneId: any = [], scenesId: any = [];
    let sensorId: any, infoId: any, sensorObj: any;
    let action: any;
    let urls: any = [];
    let sensorDownloadId: any;
    const taskId = this.collections[i]?.taskId;

    let refCount;
    const selcetedSensor = this.collection.sensors[j];
    if(selcetedSensor?.operatorKey === this.OPERATOR.CLYDE && selcetedSensor.status === 'canceled') {
      const message = 'The user has cancelled the task, and the data is unavailable.';
      this.popupService.openPopup(WarningPopupComponent, message, '', '40%');
      return
    }

    if(this.collection.sensors[j].operatorKey === OPERATORS.SPIRE) {
      refCount = this.collection.sensors[j].updatedRefreshes == 0 ? 0 : this.collection.sensors[j].updatedRefreshes || this.collection.sensors[j].noOfRefreshes
    }
    if( (refCount > 0 && scene.operatorKey === OPERATORS.SPIRE) || scene.operatorKey !== OPERATORS.SPIRE) {
    const endDate = new Date(this.collections[i].closeWindow);
    const currentDate = new Date();
    
      if (this.freeze) {
        const message = 'A download is in progress. Please wait until the current download finishes to continue downloading others.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        return
      } else if (scene?.downloaded || this.checkDownloaded(scene) || this.checkSensorDownloaded(scene)) {
        const message = 'download';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        return
      } else if (scene.operatorKey === OPERATORS.CLYDE && (endDate.getTime() < currentDate.getTime())) {    
          const message = 'The time of interest (TOI) has been passed. Download is possible only during the TOI.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          return
      } else
      this.spinnerService.show();
      this.freeze = true;
      this.fail = 0;
      this.progress = 0;
      this.downloadClick = true;
      const sensor = this.collections[i].sensors[j];
      if (scene?.insightDatas) {
        sensorId = scene?.sensorId;
        action = "ISDownload";
        infoId = scene?._id;
        sceneId = this.sceneSelected;
        if (this.collections[i]?.sensors[j]?.operatorKey === OPERATORS.AXELSPACE) sceneId = scene?.sensorId;
        sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, infoId: infoId};
      } else if (scene?.refreshId) {        
        sensorId = scene?.sensorId;
        action = "download";
        sceneId = sensor.scenes[0].id;
        sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, refreshId: scene?.refreshId};
      } else if (k === undefined) {
        sensor.scenes.forEach(async (scn: any, index: any) => {
          sceneId.push(scn.scene_id);
          scenesId.push(scn.scene_id);
        });
        sensorId = sensor?.id;
        action = "sensorDownload";
        sensorDownloadId = sensor?.id
        sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action};
      } else {
        sceneId = scene?.id;
        scenesId.push(scene?.id);
        action = "sceneDownload";
        sensorId = sensor?.id;
        sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType};
      }

      const downloadObj = { progress: this.progress, taskId: this.collections[i].taskId, sensorId: sensorDownloadId, sceneId: scenesId, download: this.downloadClick };
      
      this.downloadService.setProgress(downloadObj);
  
    }

    if (scene?.refreshId) {
      const sensor = this.collections[i].sensors[j];
      this.displayRefreshData('$event',sensor.scenes[0],sensor, action, scene);
    } else if((scene.operatorKey === OPERATORS.SPIRE && scene.aisDownloadable) || (scene.operatorKey === OPERATORS.CLYDE && scene.aisAvailable)) {
      const sensors = this.collections[i].sensors[j];
      this.AISDownload(sensors);
    } else {
      this.apiService.getCollectionImage(taskId, sensorObj).subscribe(async (res: any) => {
        if (res?.msg === 'Failed') {
          this.spinnerService.hide();
          this.freeze = false;
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }
        } else if (res?.msg === 'user storage') {
          this.spinnerService.hide();
          this.freeze = false;
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }
        } else {
          for (const [index, item] of res.result.entries()) {
          let url = item;
  
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.responseType = 'blob';
  
          xhr.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentComplete = ((event.loaded / event.total) * 100);
              this.progress = percentComplete.toFixed(2);
              const sensorObj = { progress: this.progress, taskId: this.collections[i].taskId, sensorId: sensorDownloadId, sceneId: scenesId, download: this.downloadClick };
              this.downloadService.setProgress(sensorObj);
            }
          };
  
          xhr.onload = () => {
            if (xhr.status === 200) {
              const blob = xhr.response;
              const fileName = this.getFileNameFromS3SignedUrl(url);
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
  
              // Reset progress after download
              this.progress = 0;
              this.spinnerService.hide();
              this.freeze = false;
              this.disable = false;
            }
          };
  
          xhr.onerror = () => {
            // Handle errors
          };
          xhr.send();
        }
      }
      });
    }
    return
  }

  getFileNameFromS3SignedUrl(signedUrl) {
    const filename = signedUrl.split('/')[signedUrl.split('/').length - 1].split('?')[0];
    return `${filename}`;
  }

  async displayFootPrint(scn, index, urlData,sensorId, taskId) {
    let assetId: any, imageUrl: any, COGUrl: any;
    if (urlData?.assetId) {
      assetId = urlData?.assetId;
    } else if (urlData?.imageUrl) {
      imageUrl = urlData?.imageUrl;
    } else if (urlData?.COGUrl) {
      COGUrl = urlData?.COGUrl;
    } else if (scn?.operatorKey != this.OPERATOR.USL) {
      this.displayImage(scn);
      return
    }

    this.emissionMessage = '';
    if (scn?.operatorKey === this.OPERATOR.GHG) {
      this.offset = 0.02;
      this.emissionMessage = scn.metadata.emission ? '' : 'No Emission';
    } else {
      this.offset = 0.25;
      this.emissionMessage = '';
      this.selectedMetric = 'ppb';
    }

    if (!scn.footprint) return;
    const scnPreview = urlData?.imageUrl;
    let pt: any, fpPos: any;
    let scnRectangle: any;
    let pngFp: any;
    let legendUnit = '';

    pt = scn.footprint?.coordinates[0];
    fpPos = pt.toString().split(',').map(Number);
    let boundingBox = scn?.tif_footprint;
    let sumLatitude = 0;
    let sumLongitude = 0;

    for (const coordinate of pt) {
      sumLatitude += coordinate[1];
      sumLongitude += coordinate[0];
    }

    const centroidValue = [
      sumLongitude / pt.length,
      sumLatitude / pt.length,
    ];

    const currentMode = this.viewer.scene.mode;
    let centroid: any;

    var bbox = turf.bbox(scn.footprint);
    if (scn?.png_footprint) {
      pngFp = scn?.png_footprint[0];
      scnRectangle = Cesium.Rectangle.fromDegrees(
        pngFp[0],
        pngFp[1],
        pngFp[2],
        pngFp[3]
      );
    } else {
      scnRectangle = Cesium.Rectangle.fromDegrees(
        bbox[0],
        bbox[1],
        bbox[2],
        bbox[3]
      );
    }

    // Calculate the center of the rectangle
    let centerLongitude = (Cesium.Math.toDegrees(scnRectangle.east) + Cesium.Math.toDegrees(scnRectangle.west)) / 2;
    let centerLatitude = (Cesium.Math.toDegrees(scnRectangle.north) + Cesium.Math.toDegrees(scnRectangle.south)) / 2;
    
    if (this.selectedMetric === 'kg') {
      centroid = Cesium.Cartesian3.fromDegrees(centerLongitude, centerLatitude);      
      if (scn.metadata.emission) {
        this.emissionMessage = scn?.ch4srData?.sourceRate;

        const centroidWithId = {
          centroid: centroid,
          sceneId: scn.id
        };

        this.sourceRateArray.push(centroidWithId);
      }
    } else {
      centroid = Cesium.Cartesian3.fromDegrees(centroidValue[0], centroidValue[1]);
    }

    if (scn?.legendScale) {      
      this.legendData = {
        minValue: scn?.legend_min,
        maxValue: scn?.legend_max,
        items: scn?.legendScale
      };

      if (scn?.operatorKey === this.OPERATOR.GHG) {
       legendUnit = 'ppb';
      } else if (scn?.operatorKey === this.OPERATOR.ORORATECH) {
        legendUnit = 'W/sr/m²';
      }

      const minTenthMultiple = Math.floor(this.legendData.minValue / 10) * 10;
      const maxTenthMultiple = Math.ceil(this.legendData.maxValue / 10) * 10;
      const range = maxTenthMultiple - minTenthMultiple;
      const step = range / this.legendData.items.length;
  
      this.scaleArray = [];
      let legendItems: any = [];
  
      for (const [index, item] of this.legendData.items.entries()) {
        const value = minTenthMultiple + step * index;
        item.value = value;
        legendItems.push(item.color);
      }
  
      const groupSize = this.legendData.items.length / 5;
      const numValues = 5;
      const steps = (maxTenthMultiple - minTenthMultiple) / numValues;
      const displayValues: number[] = [];
  
      for (let i = 0; i <= numValues; i++) {
        const value = minTenthMultiple + i * steps;
        displayValues.push(value);
      }
  
      for (let i = 0; i < this.legendData.items.length; i += groupSize) {
        const group = this.legendData.items.slice(i, i + groupSize);
        this.scaleArray.push(group);
      }
  
      this.scaleArray.forEach((group, index) => {
        if (index < displayValues.length) {
          group.displayValue = displayValues[index];
        }
      });
      
      const colors = [{color: 'rgba(0,0,0,0)'}]
      this.scaleArray.push({
        colors, displayValue: maxTenthMultiple
      });

      // this.legendData.items.reverse();
      this.scaleArray.reverse();
      legendItems.reverse();
      const gradientColors = legendItems.join(', ');
      this.legendColor = `linear-gradient(to bottom, ${gradientColors})`;
    }
    

    var layers = this.viewer.scene.imageryLayers;
    const URL = 'https://stafs-cogs.s3.ap-southeast-2.amazonaws.com/Whian_Whian_TCI_4326_cog.tif';
    if (index === 0) {
      let layer1: any;
      if (urlData?.assetId) {
        layer1 = new Cesium.ImageryLayer(
          new Cesium.IonImageryProvider({ assetId: assetId }), {
          splitDirection: Cesium.SplitDirection.LEFT,
          alpha: 1
        });
        this.viewer.imageryLayers.add(layer1);

      } else if (urlData?.COGUrl) {
        const provider = await TIFFImageryProvider.fromUrl(COGUrl);
        layer1 = this.viewer.imageryLayers.addImageryProvider(provider);
        layer1.alpha = 1;
        layer1.splitDirection = Cesium.SplitDirection.LEFT;
      }
      else {
        layer1 = new Cesium.ImageryLayer(
          new Cesium.SingleTileImageryProvider({
            url: scnPreview,
            rectangle: scnRectangle,
          }), {
          splitDirection: Cesium.SplitDirection.LEFT,
          alpha: 1
        });
        this.viewer.imageryLayers.add(layer1);
        this.imageryLayerMap.set(layer1, { sceneId: scn?.id, rectangle: scnRectangle,
          taskId: taskId, sensorId: sensorId
         });
      }

    } else {
      let layer2: any;
      if (urlData?.assetId) {
        layer2 = new Cesium.ImageryLayer(
          new Cesium.IonImageryProvider({ assetId: assetId }), {
          splitDirection: Cesium.SplitDirection.RIGHT,
          alpha: 1
        });
        this.viewer.imageryLayers.add(layer2);
      } else if (urlData?.COGUrl) {
        const provider = await TIFFImageryProvider.fromUrl(COGUrl);
        layer2 = this.viewer.imageryLayers.addImageryProvider(provider);
        layer2.splitDirection = Cesium.SplitDirection.RIGHT;
      } else {
        layer2 = new Cesium.ImageryLayer(
          new Cesium.SingleTileImageryProvider({
            url: scnPreview,
            rectangle: scnRectangle,
          }), {
          splitDirection: Cesium.SplitDirection.RIGHT,
        });
        this.viewer.imageryLayers.add(layer2);
        this.imageryLayerMap.set(layer2, { sceneId: scn?.id, rectangle: scnRectangle,
          taskId: taskId, sensorId: sensorId
         });
      }

    }

    this.selectedSensors = this.selectedSensors.filter(item => (item?.scenes[0]?.operatorKey !== OPERATORS.USL || item?.scenes[0]?.operatorKey !== OPERATORS.LBAND));
    if (this.selectedSensors.length === 2 && !this.operatorArray.includes(OPERATORS.USL) && !this.operatorArray.includes(OPERATORS.LBAND)) {
      this.viewer.scene.splitPosition = this.splitPosition;
      this.viewer.scene.imagerySplitPosition = this.splitPosition;
      const slider = document.getElementById('sliders') as HTMLCanvasElement;
      slider.style.left = `${100.0 * this.splitPosition}%`;
    }
    if (this.selectedSensors.length < 2) {
      this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
      this.viewer.scene.imagerySplitPosition = undefined;
      this.splitPosition = 0.0;
    }
    let entitylLeft: any, entityRight: any, entitylLeftBB: any, entityRightBB: any,legendLeft: any, legendRight: any;
    const localHandlerEvent = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
    const tooltips = new Map();

    // Function to handle hover event and display tooltip
    const addHoverEventHandler = (entity, scn) => {
      localHandlerEvent.setInputAction((movement) => {
        const pickedObject = this.viewer.scene.pick(movement.endPosition);

        // Check if the picked object matches the current entity
        if (Cesium.defined(pickedObject) && pickedObject.id === entity) {
          if (scn?.ch4srData) {
            let sceneId = scn.sceneId;

            // Retrieve or create tooltip for this sceneId
            let tooltip = tooltips.get(sceneId);

            if (!tooltip) {
              tooltip = createTooltip();
              tooltips.set(sceneId, tooltip);
            }

            // Update tooltip content
            let content = `<b>Acquisition Time  :</b>  ${scn?.ch4srData?.acquisitiontTime ? scn?.ch4srData?.acquisitiontTime : 'N/A'}<br>
                       <b>Lng:</b> ${scn.ch4srData?.longitude ? scn.ch4srData?.longitude : 'N/A'}<br>
                       <b>Lat:</b> ${scn.ch4srData?.latitude ? scn.ch4srData?.latitude : 'N/A'}<br>
                       <b>Wind Speed:</b> ${scn.ch4srData.windSpeed ? scn.ch4srData.windSpeed : 'N/A'}<br>
                       <b>Source Rate:</b> ${scn.ch4srData.sourceRate ? scn.ch4srData.sourceRate : 'N/A'}`;

            tooltip.innerHTML = content;
            tooltip.style.left = movement.endPosition.x + 700 + 'px';
            tooltip.style.top = movement.endPosition.y + 80 + 'px';
            tooltip.style.fontSize = '15px';
            tooltip.style.fontFamily = 'Barlow, sans-serif';
            tooltip.style.lineHeight = '1.8';
            tooltip.style.display = 'block';
          }
        } else {
          tooltips.forEach((tooltip, key) => {
            tooltip.style.display = 'none';
          });
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    };

    // Function to create a new tooltip element
    const createTooltip = () => {
      const tooltip = document.createElement('div');

      tooltip.style.position = 'absolute';
      tooltip.style.backgroundColor = 'black';
      tooltip.style.padding = '10px';
      tooltip.style.fontSize = '15px';
      tooltip.style.fontFamily = 'Barlow, sans-serif';
      tooltip.style.lineHeight = '1.8';
      tooltip.style.display = 'none';
      tooltip.style.color = 'white';
      tooltip.style.border = '1px solid black';
      tooltip.style.zIndex = '100';
      document.body.appendChild(tooltip);
      return tooltip;
    };

    if (index === 0) {
        entitylLeft = this.viewer.entities.add({
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
            clampToGround: true,
            material: new Cesium.PolylineOutlineMaterialProperty({
              outlineWidth: 10,
              outlineColor: Cesium.Color.GREEN
            }),
          },
          position: centroid,
          label: {
            text: this.emissionMessage,
            font: '17px barlow',
            fontweight: '800',
            lineHeight: '1.8',
            display: 'block',
            show: true,
            showBackground: true,
            backgroundColor: new Cesium.Color(0.165, 0.165, 0.165, 0.8), // Semi-transparent background
            backgroundPadding: new Cesium.Cartesian2(7, 5),
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
          }
        });
       
        if (boundingBox) {
          entitylLeftBB = this.viewer.entities.add({
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArray(boundingBox),
              clampToGround: true,
              material: new Cesium.PolylineOutlineMaterialProperty({
                outlineWidth: 8,
                outlineColor: Cesium.Color.YELLOW
              }),
            }
          });
          entitylLeftBB.index = scn?.sceneIdx;
          entitylLeftBB.sceneId = scn?.id;
          this.scnLyr.push(entitylLeftBB);
        }
        
      if (scn?.legendScale && this.selectedMetric === 'ppb') {
        const longitudes = boundingBox.filter((_, index) => index % 2 === 0);
        const latitudes = boundingBox.filter((_, index) => index % 2 !== 0);
        

        // Calculate the center latitude of the bounding box
        const minLatitude = Math.min(...latitudes);
        const maxLatitude = Math.max(...latitudes);
        const centerLatitude = ((minLatitude + maxLatitude) / 2) - 0.06;


        // Calculate the rightmost longitude of the bounding box
        const maxLongitude = Math.max(...longitudes);

        // Calculate the position for the legend
        const offset = 0.2; // Adjust this value as needed
        const legendLongitude = maxLongitude + this.offset; // Legend will be placed exactly at the right edge of the bounding box
        const legendLatitude = centerLatitude;

        // Create the legend entity
        legendLeft = this.viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(legendLongitude, legendLatitude),
          name: 'legend',
          billboard: {
            image: '',
            show: true,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            horizontalOrigin: Cesium.HorizontalOrigin.RIGHT,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });

      //creating legend image
    
        let htmlContent = `
        <style>
        .legend-containers {
          display: flex;
          flex-direction: column;
          height: 280px;
          width: 23px;
          background: linear-gradient(to bottom);
        }
        .color-stop {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          color: white;
          padding-bottom: 28px;
        } 
        
        .scale-value {
          position: absolute;
          font-size: 18px;
          color: white;
          left: 30px;
          font-weight: 600;
        }

        .legend-unit {
          font-size: 13px;
          color: white;
          font-weight: 500;
        }

        .unit {
          display: flex;
          flex-direction: column;
        }
      </style>
      <div class="unit">
        <div class="legend-unit">(${legendUnit})</div>
        <div style= "display: flex">
          <div class="legend-containers" style="background: ${this.legendColor};"></div>
          <div class="legend-containers">
            ${this.scaleArray.map(item => `
              <div class="color-stop" style="background-color: transparent">
                <span class="scales-value">${item.displayValue}</span>
              </div>
            `).join('')}
          </div>
        </div>
      </div>
      `;

      let entitiesToRemove: any = [];
      this.viewer.entities.values.forEach((entity) => {
        if (entity.name === 'legend') {
          entitiesToRemove.push(entity);
        }
      });
      
      if (this.timeseries || this.showDailyRecurrencyCheckboxes) {
        this.legendContainer = document.getElementById('legend-container');

        if(this.legendContainer) {              
          this.legendContainer.style.display = 'block';
          this.legendContainer.innerHTML = htmlContent;
        }
      } else {
    
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = htmlContent;
        tempContainer.style.position = 'absolute';
        document.body.appendChild(tempContainer);
              
        html2canvas(tempContainer,{backgroundColor: null}).then((canvas) => {
          const imageDataUrl = canvas.toDataURL('image/png');
          legendLeft.billboard.image = imageDataUrl;
          document.body.removeChild(tempContainer);
        });

        if (currentMode != 2) {
          legendLeft.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
          legendLeft.billboard.horizontalOrigin = Cesium.HorizontalOrigin.LEFT;
          legendLeft.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY;
        }
      }

        legendLeft.index = scn?.sceneIdx;
        legendLeft.sceneId = scn?.id;
        this.scnLyr.push(legendLeft);    
      }
        
      entitylLeft.index = scn?.sceneIdx;
      entitylLeft.sceneId = scn?.id;
      this.scnLyr.push(entitylLeft)
      this.viewer.zoomTo(entitylLeft);
      
      this.handleAnnotation();
      addHoverEventHandler(entitylLeft, scn);
    }
    else {
        entityRight = this.viewer.entities.add({
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
            clampToGround: true,
            material: new Cesium.PolylineOutlineMaterialProperty({
              outlineWidth: 10,
              outlineColor: Cesium.Color.RED
            }),
          },
          position: centroid,
          label: {
            text: this.emissionMessage,
            font: '17px barlow',
            fontweight: '800',
            lineHeight: '1.8',
            display: 'block',
            show: true,
            showBackground: true,
            backgroundColor: new Cesium.Color(0.165, 0.165, 0.165, 0.8), // Semi-transparent background
            backgroundPadding: new Cesium.Cartesian2(7, 5),
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
          }
        });

        if (boundingBox) {
          entityRightBB = this.viewer.entities.add({
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArray(boundingBox),
              clampToGround: true,
              material: new Cesium.PolylineOutlineMaterialProperty({
                outlineWidth: 8,
                outlineColor: Cesium.Color.WHITE
              }),
            }
          });
          entityRightBB.index = scn?.sceneIdx;
          entityRightBB.sceneId = scn?.id;
          this.scnLyr2.push(entityRightBB);
        }

        if (scn?.legendScale && this.selectedMetric === 'ppb') {      
          const longitudes = boundingBox.filter((_, index) => index % 2 === 0);
        const latitudes = boundingBox.filter((_, index) => index % 2 !== 0);

        // Calculate the center latitude of the bounding box
        const minLatitude = Math.min(...latitudes);
        const maxLatitude = Math.max(...latitudes);
        const centerLatitude = ((minLatitude + maxLatitude) / 2) - 0.06;


        // Calculate the rightmost longitude of the bounding box
        const maxLongitude = Math.max(...longitudes);

        // Calculate the position for the legend
        const offset = 0.2; // Adjust this value as needed
        const legendLongitude = maxLongitude + this.offset; // Legend will be placed exactly at the right edge of the bounding box
        const legendLatitude = centerLatitude;


        legendRight = this.viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(legendLongitude, legendLatitude),
          name: 'legend',
          billboard: {
            image: '',
            show: true,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            horizontalOrigin: Cesium.HorizontalOrigin.RIGHT,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
    
        //creating legend image
      
          let htmlContent = `
          <style>
          .legend-containers {
            display: flex;
            flex-direction: column;
            height: 280px;
            width: 23px;
            background: linear-gradient(to bottom);
          }
          .color-stop {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 28px;
          } 
          
          .scale-value {
            position: absolute;
            font-size: 18px;
            color: white;
            left: 30px;
            font-weight: 600;
          }
          .legend-unit {
            font-size: 13px;
            color: white;
            font-weight: 500;
          }
  
          .unit {
            display: flex;
            flex-direction: column;
          }
        </style>
        <div class="unit">
          <div class="legend-unit">(${legendUnit})</div>
          <div style= "display: flex">
            <div class="legend-containers" style="background: ${this.legendColor};"></div>
            <div class="legend-containers">
              ${this.scaleArray.map(item => `
                <div class="color-stop" style="background-color: transparent">
                  <span class="scales-value">${item.displayValue}</span>
                </div>
              `).join('')}
            </div>
          </div>
        </div>
        `;

        let entitiesToRemove: any = [];
        this.viewer.entities.values.forEach((entity) => {
          if (entity.name === 'legend') {
            entitiesToRemove.push(entity);
          }
        });
        
        if (this.timeseries || this.showDailyRecurrencyCheckboxes) {
          this.legendContainer = document.getElementById('legend-container');
  
          if(this.legendContainer) {              
            this.legendContainer.style.display = 'block';
            this.legendContainer.innerHTML = htmlContent;
          }
        } else {
      
          const tempContainer = document.createElement('div');
          tempContainer.innerHTML = htmlContent;
          tempContainer.style.position = 'absolute';
          document.body.appendChild(tempContainer);
      
          html2canvas(tempContainer,{backgroundColor: null}).then((canvas) => {
            const imageDataUrl = canvas.toDataURL('image/png');
            legendRight.billboard.image = imageDataUrl;
            document.body.removeChild(tempContainer);
          });

          if (currentMode != 2) {
            legendRight.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
            legendRight.billboard.horizontalOrigin = Cesium.HorizontalOrigin.LEFT;
            legendRight.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY;
          }
        }
    
          legendRight.index = scn?.sceneIdx;
          legendRight.sceneId = scn?.id;
          this.scnLyr2.push(legendRight);    
        }

      entityRight.index = scn?.sceneIdx;
      entityRight.sceneId = scn?.id;
      this.scnLyr2.push(entityRight)
      this.viewer.zoomTo(entityRight);

      this.handleAnnotation();
 
      addHoverEventHandler(entityRight, scn);
    }

    var allImageryLayers = this.viewer.imageryLayers._layers;
    var leftImageryLayers: any = [];
    var rightImageryLayers: any = [];
    // Adjust the alpha (opacity) of the layers based on the camera position
    for (var layerIndex = 0; layerIndex < allImageryLayers.length; layerIndex++) {
      var url = allImageryLayers[layerIndex].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[layerIndex].imageryProvider?._source.source.source.client.url : allImageryLayers[layerIndex]?.imageryProvider?.url

      var isLeft = allImageryLayers[layerIndex]?.splitDirection === Cesium.SplitDirection.LEFT;
      var isRight = allImageryLayers[layerIndex]?.splitDirection === Cesium.SplitDirection.RIGHT;
      if (url !== undefined && isLeft) {
        leftImageryLayers.push(allImageryLayers[layerIndex]);
      }
      if (url !== undefined && isRight) {
        rightImageryLayers.push(allImageryLayers[layerIndex]);
      }
    }

    var leftLayerExtent = leftImageryLayers[0]?.imageryProvider.rectangle;
    var rightLayerExtent = rightImageryLayers[0]?.imageryProvider.rectangle;

    if (leftLayerExtent && rightLayerExtent) {
      // Combine the extents of both layers
      var combinedExtent = Cesium.Rectangle.union(leftLayerExtent, rightLayerExtent);
    }
    this.viewer.render();
  }

  onLeftOptChange(option: string) {
    this.RIGHTOPTIONS = [];
    this.LEFTOPTIONS.forEach((item: any, index: any) => {
      if (item.name !== option) {
        this.RIGHTOPTIONS.push(item);
      }
    });
    this.selectedLeft = option;
    this.selectedRight = this.RIGHTOPTIONS[0].name;
    this.rightLayer.splitDirection =
      this.rightLayer.splitDirection === Cesium.SplitDirection.LEFT
        ? Cesium.SplitDirection.RIGHT
        : Cesium.SplitDirection.LEFT;
  }
  onRightOptChange(option: string) {
  }

  clickMenuItem(menuItem, index) {
    this.selectedOptions = [];
    this.taskSelected = false;
    this.selectedSubDailyItems = [];
    this.subDailyFinalArray = [];
    this.selectedCheckboxes = [];
    this.subDailyArray = [];
    this.showDailyRecurrencyCheckboxes = false
    this.spinnerService.hide();
    this.operatorArray = [];
    this.defaultSubdailyCount = 1;
    this.showDailyRecurrencyCheckboxes = false;
    this.subDailyCount = '';
    this.selectedSubdaily = '';
    this.scnLyr = []
    this.scnLyr2 = [];
    this.taskCollection = '';
    this.resetSelect();
    // Clear the emissionMessage when the project name changes
    this.emissionMessage = '';
    this.clearAnnotations();
    this.selectedIndices = null;

    if ((this.previousSelectedProject !== null && JSON.stringify(this.previousSelectedProject) !== JSON.stringify(menuItem)) || this.previousSelectedProject === null) {
      this.previousSelectedProject = menuItem;
      this.getCollections(menuItem?.projectid,0);
      this.projectName = menuItem?.project;
      this.collectionIndex = 0;
      this.overlay = [100, 100];
      this.baseOverlay = 100
      this.baseAlpha = 1
      this.selectedSensors = [];
      this.isVisible = false
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
      this.viewer.scene.imagerySplitPosition = undefined;
      // this.viewer.imageryLayers.removeAll();
      this.splitPosition = 0.0;

      this.viewer.entities.removeAll();
      this.clearBandLegends();
      this.USLscnLyr = [];
      this.SpirescnLyr = [];
    
      this.resetBackgroundWithChangeInBaseLayer();
      // Get the layer you want to remove the split position from
      // Change 0 to the index of your layer
      this.isVisible = false;
      this.splitPosition = 0.0;
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.collection.sensors.forEach((sensor, index) => {
        sensor.checked = false
        sensor.scenes.forEach((scene) => {
          scene.checked = false

        });
      });
    }
  }

  getCollections(item,idx) {
    this.sensorsIS = true;
    this.taskCollection = '';
    this.clearSilder();
    this.sliderView = false;
    this.timeseries = false;
    this.jump = false;
    this.imageLoaded = false;
    this.annotateClicked = false;
    this.clearAnnotations();
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    this.datePlace = false;
    this.selectedType = null;
    this.dateDisable = false;
    this.signalSelected = 'All';
    this.newTaskButtonClicked = false;
    this.audioAvailable = false;
    this.currentRfSignals = [];
    this.selectedRecurrencyArray = [];
    if(this.legendContainer) this.legendContainer.style.display = 'none';
    this.feedInfo = false;
    if(this.legendContainer) this.legendContainer.style.display = 'none';

    this.resetBackgroundWithChangeInBaseLayer();
    this.currentSliderValue = 0;
    if (!this.showDailyRecurrencyCheckboxes) {
      this.selectedMetric = 'kg';
      this.sourceRateArray = [];
      this.currentSites = null;
    }
    this.selectedOptions = [];
    this.resetBackgroundWithChangeInBaseLayer();
    this.apiService.getCollections(item).subscribe((res: any) => {
      this.dataSource = res.result;
      if (res.result?.length > 0) {
        this.collectionsData = res.result;
        this.collections = [];
        this.fullCollection = [];
        let count: any;
        res.result.forEach((item, index) => {
          const project = item.project;
          const taskname = item.taskname;
          const createdDate = item?.createdAt;
          const taskId = item.id;
          let sensorName = item?.sensors?.[0]?.sensor;
          const target = JSON.parse(item.target);
          const sensors: any = [];
          const start: Date = new Date(item?.openWindow);
          const end: Date = new Date(item?.closeWindow);
          let datesArray: any[] = [];

          if (item?.repeatFrequency === 'Daily' || item?.repeatFrequency === 'Sub-daily') { count = 1; this.dateRange = false; }
          else if (item?.repeatFrequency === 'Weekly') { count = 7; this.dateRange = true; }
          else if (item?.repeatFrequency === 'Monthly') { count = 30; this.dateRange = true; }


          function generateDateRangeArray(startDate, endDate, daysInterval) {
            const dateRangeArray: any = [];
            let currentDate = new Date(startDate);
            while (currentDate <= endDate) {
              let isDisabled = true;
              let scenesFilterarray: any = [], scenesList: any = [];
              const endDateCopy = new Date(currentDate);
              endDateCopy.setDate(endDateCopy.getDate() + daysInterval - 1);
          
              if (endDateCopy > endDate) {
                endDateCopy.setTime(endDate.getTime());
              }
              endDateCopy.setUTCHours(23, 59, 59, 0);
          
              const convertedStart = new Date(currentDate).toISOString();
              const convertedEnd = endDateCopy.toISOString();
              const startDateOnly = new Date(currentDate).toISOString().split('T')[0];
              
              for (const [sceneIndex, scenes] of item?.sensors?.[0].scenes.entries()) {
                scenes.operatorKey = item?.sensors[0]?.operatorKey;
                const datePredicted = scenes.predicted_date;
                let PredictedDateOnly;
                if (scenes.predicted_date != undefined) {
                  PredictedDateOnly = new Date(scenes.predicted_date).toISOString().split('T')[0];
                }
                
                if ((item?.repeatFrequency === 'Sub-daily' || item?.repeatFrequency === 'Daily') && PredictedDateOnly == startDateOnly && scenes.status !== 'error') {                  
                  const indexToRemove = scenesFilterarray.findIndex((item) => item.predicted_date === scenes.predicted_date);
                  if (indexToRemove === -1) {
                    scenesFilterarray.push(scenes)
                    isDisabled = false;
                  }
                } else if (datePredicted >= convertedStart && datePredicted <= convertedEnd && scenes.status !== 'error') {
                  const indexToRemove = scenesFilterarray.findIndex((item) => item.predicted_date === scenes.predicted_date);
                  if (indexToRemove === -1) {
                    scenesFilterarray.push(scenes)
                    isDisabled = false;
                  }
                }               
               }

              scenesFilterarray.forEach((element, index) => {
                element.sceneIdx = index + 1;
                if (element?.id != null && element?.status != 'failed') {
                  scenesList.push({ id: element.id });
  
                }
              });
  
              const unfailedScenes = scenesFilterarray.filter(scene => scene.status != 'failed');
              if (unfailedScenes.length === 0)
                isDisabled = true;

              dateRangeArray.push({
                start: convertedStart,
                end: convertedEnd,
                repeatFrequency: item?.repeatFrequency,
                checked: false,
                disabled: isDisabled,
                scenes: scenesFilterarray,
                scenesList: scenesList
              });
              currentDate.setDate(currentDate.getDate() + daysInterval);
            }
            return dateRangeArray;
          }

          function hasTimeReached(openWindow) {
            const targetTime = new Date(openWindow);
            const currentTime = new Date();
        
            return currentTime >= targetTime;
        }

        function hasCloseWindowPassed(closeWindow) {
          const closeTime = new Date(closeWindow);
          const currentTime = new Date();
      
          return currentTime > closeTime;
      }
      

          if (item?.frequency === 1) {
            const dateRange = generateDateRangeArray(start, end, count);
            datesArray = dateRange;            
          }

          item.sensors.forEach((sensor, i) => {
            const scenes: any = [];
            const scenesList: any = [];
            const startDate = new Date(item?.openWindow);
            const endDate = new Date(item?.closeWindow);
            let valueAddedOption:any;
            let superResolutions:any;
            let indicesDetails:any;

            if (sensor?.operatorKey === this.OPERATOR.GHG) {
              const daysInterval = 30;
              datesArray = [];
              item.repeatFrequency = 'GHG-Monthly';
              this.dateRange = true;
              this.datePlace = true;

              const dateRange = generateDateRangeArray(startDate, endDate, daysInterval);
              datesArray = dateRange; 

              function generateDateRangeArray(startDate, endDate, daysInterval) {
                const dateRangeArray: any = [];
                let currentDate = new Date(startDate);
                while (currentDate <= endDate) {
                  let isDisabled = true;
                  let scenesFilterarray: any = [], scenesList: any = [];
                  const endDateCopy = new Date(currentDate);
                  endDateCopy.setDate(endDateCopy.getDate() + daysInterval - 1);
              
                  if (endDateCopy > endDate) {
                    endDateCopy.setTime(endDate.getTime());
                  }
                  endDateCopy.setUTCHours(23, 59, 59, 0);
              
                  const convertedStart = new Date(currentDate).toISOString();
                  const convertedEnd = endDateCopy.toISOString();              
                  
                  for (const [sceneIndex, scenes] of item?.sensors?.[0].scenes.entries()) {
                    scenes.operatorKey = item?.sensors[0]?.operatorKey;
                    const datePredicted = scenes.predicted_date;
                    if (datePredicted >= convertedStart && datePredicted <= convertedEnd && scenes.status !== 'error') {
                      const indexToRemove = scenesFilterarray.findIndex((item) => item.predicted_date === scenes.predicted_date);
                      if (indexToRemove === -1) {
                        scenesFilterarray.push(scenes)
                        isDisabled = false;
                      }
                    }
                   }
    
                  scenesFilterarray.forEach((element, index) => {
                    element.sceneIdx = index + 1;
                    if (element?.id != null && element?.status != 'failed') {
                      scenesList.push({ id: element.id });
      
                    }
                  });
      
                  const unfailedScenes = scenesFilterarray.filter(scene => scene.status != 'failed');
                  if (unfailedScenes.length === 0)
                    isDisabled = true;
    
                  dateRangeArray.push({
                    start: convertedStart,
                    end: convertedEnd,
                    repeatFrequency: 'GHG-Monthly',
                    checked: false,
                    disabled: isDisabled,
                    scenes: scenesFilterarray,
                    scenesList: scenesList
                  });
                  currentDate.setDate(currentDate.getDate() + daysInterval);
                }
            
                return dateRangeArray;
              }
            }
            if (sensor?.valueAddedOption) {
              valueAddedOption = this.getValueAddedOption(sensor?.valueAddedOption);
            }
            if (sensor?.super_resolution) {
              superResolutions = sensor?.super_resolution;
            }
            if (sensor?.indices) {
              indicesDetails = sensor?.indices;
            }
            if (sensor?.scenes?.length > 0 && ((sensor?.status != 'canceled' && sensor?.operatorKey != OPERATORS.CLYDE) || (sensor?.operatorKey === OPERATORS.CLYDE)) && sensor?.status != 'rejected' && sensor?.status != 'approved' && sensor?.status != 'review' && sensor?.status != 'pending') {  
              sensor?.scenes?.forEach((scene, index) => {
                const predictedDate = new Date(scene?.predicted_date).getUTCDate();
                scene.checked = false;
                let thumbnailUrl;
                let aisAvailable = hasTimeReached(item?.openWindow)
                let aisDownloadable = hasCloseWindowPassed(item?.closeWindow);

                sensor.scenes[index] = scene;
                thumbnailUrl =
                  environment.eeTaskServer +
                  '/' +
                  scene.thumbnailUrl || '';
                if (scene.status != 'error') {
                  scenes.push({
                    id: scene.id,
                    name: `Scene ${index + 1}`,
                    sceneIdx: index + 1,
                    footprint: scene.footprint,
                    aisAvailable,
                    aisDownloadable,
                    openWindow: item?.openWindow,
                    closeWindow: item?.closeWindow,
                    metadata: scene.metadata,
                    metadata_tooltip: scene?.metadata_tooltip,
                    thumbnailUrl: thumbnailUrl,
                    previewUrl: scene.previewUrl,
                    downloadUrl: scene.downloadUrl,
                    checked: false,
                    color: 'primary',
                    scene_id: scene?.scene_id,
                    predictedDate: scene?.predicted_date,
                    status: scene?.status,
                    RRdeliveryDate: scene?.RRdeliveryDate,
                    sequenceNumber: scene?.sequence,
                    uslFootprint: scene?.usl_footprint,
                    s_band: scene?.s_band,
                    x_band: scene?.x_band,
                    operatorKey: sensor?.operatorKey,
                    downloaded: scene?.downloaded,
                    refundRequest: scene?.refundRequest,
                    returnRefundAccept: scene?.returnRefundAccept,
                    returnRefundReject: scene?.returnRefundReject,
                    siteId: scene?.siteId,
                    target: scene?.target,
                    isName: scene?.isName,
                    png_footprint: scene?.png_footprint,
                    tif_footprint: scene?.tif_footprint,
                    legendScale: scene?.legendScale,
                    legend_min: scene?.legend_min,
                    legend_max: scene?.legend_max,
                    legend_long: scene?.legend_long,
                    legend_lat: scene?.legend_lat,
                    ch4srData: scene?.ch4srData,
                    signals: this.getSignals(scene?.signalOfInterest),
                    signalOfInterest: scene?.signalOfInterest
                  });
                }

                for (const [idx, scene] of scenes.entries()) {
                  scene.name = `Scene ${idx + 1}`;
                  scene.sceneIdx = idx + 1;
                }

                if (scene?.id != null && scene?.status != 'failed') {
                  sensor.scenes[index] = scene;
                  scenesList.push({
                    id: scene.id
                  });
                }
              });

              if (scenes.length > 0) {
                sensors.push({
                  name: sensor.sensor, scenes, checked: false, id: sensor?.sensor_uuid, downloadUrl: sensor?.downloadUrl, scenesList, latency: sensor?.latency, downloaded: sensor?.downloaded, resolution_tooltip: sensor.resolution_tooltip, isAdditionalProducts: sensor.isAdditionalProducts,
                  isAdditionalCSIProducts: sensor?.isAdditionalCSIProducts,
                  siteData: sensor?.siteData, InfoServices: sensor.informationServices || [],
                  intervals: sensor?.intervals, status: sensor?.status, restoreStatus: sensor?.restoreStatus, restoreOption: sensor?.restoreOption, operatorKey : sensor?.operatorKey, noOfRefreshes: sensor?.noOfRefreshes, updatedRefreshes: sensor?.updatedRefreshes, timeExpired: sensor?.timeExpired,
                  isHistorical: sensor?.isHistorical, noOfHistoricalData: sensor?.noOfHistoricalData, updatedFetches: sensor?.updatedFetches, valueAddedOption : valueAddedOption, superResolutions: superResolutions, indicesDetails: indicesDetails,
                  refreshHistory: sensor?.refreshHistory,
                  unUsedRefreshes: sensor.updatedRefreshes == 0 ? 0 : sensor.updatedRefreshes || sensor.noOfRefreshes,
                  unUsedFetches: sensor.updatedFetches == 0 ? 0 : sensor.updatedFetches || sensor.noOfHistoricalData,
                  obfuscateName: sensor?.obfuscateName
                });
              }
            }
          });

          let taskCollection = item?.taskcollection;
          let captures = item?.captures;
          let sensorsName = item?.sensorsName;
          let info = item?.info;
          if (sensors.length > 0) {
            this.collections.push({
              project,
              taskname,
              target,
              sensors,
              taskId,
              sensorName,
              taskCollection,
              captures,
              sensorsName,
              info,
              frequency: item?.frequency,
              repeatFrequency: item?.repeatFrequency,
              noOfRepeats: item?.noOfRepeats,
              recurrencyArray: datesArray,
              subDailyCount: item?.subDailyCount,
              feasibilityTask: item?.feasibilityTask,
              opportunityList: item?.opportunityList,
              projectId: item?.projectid,
              returnRefund: item?.returnRefund,
              createdDate,
              openWindow: item?.openWindow,
              closeWindow: item?.closeWindow,
            });
          }
        });

        const sensorWithData = this.collections[0].sensors.find(
          (sensor) => sensor.InfoServices
        );
        if (sensorWithData) {
          const infoServicesLength = sensorWithData.InfoServices.length;
          this.selectedIS = Array(infoServicesLength).fill(false);
        }

        this.collection = this.collections[idx];
        this.taskName = this.collections[idx].taskname;
        this.taskid = this.collections[idx].taskId;
        this.sensorList = this.collections[idx].sensors;
        this.fullCollection = JSON.parse(JSON.stringify(this.collections[idx]));
        this.recurrencyArray = this.collections[idx].recurrencyArray;

        if ((this.collection?.frequency === 1  && this.collection?.recurrencyArray?.length > 0)
          || (this.collection?.recurrencyArray[0]?.repeatFrequency === 'GHG-Monthly') ||
          (this.collection?.feasibilityTask && this.collection?.opportunityList?.length > 0)) {
         this.recurrentTask = true;
        } else this.recurrentTask = false;

        this.taskCreatedDate = this.collections[idx].createdDate;
        for (const [sensorIndex, opprDates] of this.collection.opportunityList.entries()) {
          const givenDateObj = new Date(opprDates.date);
          givenDateObj.setHours(0, 0, 0, 0);
          opprDates.checked = false;
          opprDates.name = this.collections[idx].sensors[0].name;
          opprDates.id = this.collections[idx].sensors[0]?.id;
          opprDates.scenes = this.collections[idx].sensors[0].scenes.filter((scene) => {
            const dateObj = new Date(scene.predictedDate);
            dateObj.setHours(0, 0, 0, 0);
            return givenDateObj.getTime() === dateObj.getTime();
          });
        }
        if ((this.collections[idx]?.frequency === 1 && this.collections[idx]?.recurrencyArray?.length > 0)) {
          this.selectOption(this.collections[idx]?.recurrencyArray[0], 'normal');
        } else if (this.collections[idx]?.feasibilityTask && this.collections[idx]?.opportunityList?.length > 0) {
          this.selectOpportunity(this.collections[idx]?.opportunityList[0], 'normal');
        } else if (this.collections[idx]?.repeatFrequency === 'GHG-Monthly') {
          this.selectSite(1)
        }
        
      } else {
        this.collectionsData = [];
      }
      this.spire = false;
      this.usl = false;

      this.spire = this.collections[idx].sensors.some(sensor => 
        sensor?.operatorKey === OPERATORS.SPIRE || sensor?.operatorKey === OPERATORS.CLYDE
      );
      
      this.usl = this.collections[idx].sensors.some(sensor => 
        sensor?.operatorKey === OPERATORS.USL || sensor?.operatorKey === OPERATORS.LBAND
      );

      this.sensorLength = this.collections[idx].sensors.length;
    });
  }

  restTask() {
    this.selectedCheckboxes = [];
    this.showDailyRecurrencyCheckboxes = false;
  }

  clickTask(taskItem, index) {
    this.taskSelected = true;
    this.taskCollection = JSON.parse(JSON.stringify(taskItem));
    this.collectionIndex = JSON.parse(JSON.stringify(index));
    this.toggleDailyRecurrency(index);
    this.clearAnnotations();
    this.selectedOptions = [];
    this.selectedIndices = null;
  }

  clickTaskId(item, event) {
    return
    this.selectedSubDailyItems = [];
    this.subDailyFinalArray = [];
    this.selectedCheckboxes = [];
    this.feasibilityArray = [];
    this.subDailyArray = [];
    this.showDailyRecurrencyCheckboxes = false;
    this.subDailyCount = '';
    this.selectedSubdaily = '';
    this.taskid = item.taskId;
    event.checked = false;
    this.scnLyr = []
    this.scnLyr2 = []
    var allImageryLayers = this.viewer.imageryLayers._layers;
    for (var layerIndex = 0; layerIndex < allImageryLayers.length; layerIndex++) {
      this.viewer.imageryLayers.remove(allImageryLayers);
    }
    this.viewer.imageryLayers.addImageryProvider(
      new Cesium.IonImageryProvider({ assetId: 3954 })
    );
    this.collection.sensors.forEach((sensor, index) => {
      sensor.checked = false
      sensor.scenes.forEach((scene) => {
        scene.checked = false

      });
    });
  }

  selectOpportunity(event, type: any) {
    this.opportunityId = event.oppid[0];
    this.filteredCollection = [];
    let scenesList: any = [];
    const collection = this.fullCollection;
    let opportunityDate = new Date(event.date);
    opportunityDate.setHours(0, 0, 0, 0);
    this.selectedStartDate = event.date;
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false; 
    this.newTaskButtonClicked = false;

      for (const [sensorIndex, sensors] of collection.sensors.entries()) {
        let sceneArray: any = [];
        for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
          let predictedDate = new Date(scenes.predictedDate);
          predictedDate.setHours(0, 0, 0, 0);
          scenes.checked = false;
          if ((predictedDate.getTime() === opportunityDate.getTime())) {
            sceneArray.push(scenes);
          }
        }

        sceneArray.forEach((element, index) => {
          element.sceneIdx = index + 1;
          if (element?.id != null && element?.status != 'failed') {
            scenesList.push({ id: element.id });
          }
        });

        this.collection.sensors[sensorIndex].scenesList = scenesList;
        this.collection.sensors[sensorIndex].scenes = sceneArray;
      }

    this.collection.sensors.forEach((element, index) => {
      element.checked = false
    });
    this.splitPosition = 1;
    this.viewer.entities.removeAll();
    this.clearBandLegends();
    this.USLscnLyr = [];
    this.SpirescnLyr = [];
   
    this.resetBackgroundWithChangeInBaseLayer();
  }

  selectOption(event, type: any) {
    this.subSelectionSelectionChange.next(1)
    this.filteredCollection = [];
    let scenesList: any = [];
    const collection = this.fullCollection;
    this.sceneBands = '';
    this.signalBands = '';
    this.bandTaskId = '';
    this.bandSensorId = '';
    this.signalSensorId = '';
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    this.selectionChange.next(event);

    this.selectedStartDate = event.start;
    this.selectedEndDate = event.end;

    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false;
    this.newTaskButtonClicked = false;

      for (const [sensorIndex, sensors] of collection.sensors.entries()) {
        let sceneArray: any = [];

        for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
          let datePredicted = new Date(scenes.predictedDate);
          const predictedDate = scenes.predictedDate;

          scenes.checked = false;
          if (event?.repeatFrequency === 'Daily' && (datePredicted >= startDate && datePredicted <= endDate)) {
            sceneArray.push(scenes);
          } else if (event?.repeatFrequency === 'Sub-daily' && predictedDate !== undefined) {
            const PredictedDateOnly = new Date(scenes.predictedDate).toISOString().split('T')[0];
            const startDateOnly = new Date(startDate).toISOString().split('T')[0];
            const endDateOnly = new Date(endDate).toISOString().split('T')[0];
            if (PredictedDateOnly == startDateOnly && PredictedDateOnly == endDateOnly) {
              sceneArray.push(scenes);
            }
          } else if (datePredicted >= startDate && datePredicted <= endDate) {
            sceneArray.push(scenes);
          }
        }

        sceneArray.forEach((element, index) => {
          element.sceneIdx = index + 1;
          if (element?.id != null && element?.status != 'failed') {
            scenesList.push({ id: element.id });
          }
        });

        this.collection.sensors[sensorIndex].scenesList = scenesList;
        this.collection.sensors[sensorIndex].scenes = sceneArray;
      }

    if ((type !== 'split' && ((this.previousSelection !== null && JSON.stringify(this.previousSelection) !== JSON.stringify(event)) || this.previousSelection === null)) || type === 'split') {
      this.previousSelection = event;
      this.collection.sensors.forEach((element, index) => {
        element.checked = false
      });

      this.filteredCollection = JSON.parse(JSON.stringify(this.collection));
      if (event?.repeatFrequency === 'Sub-daily') {
        this.selectSubdaily(this.defaultSubdailyCount);
      } else if (event?.repeatFrequency === 'GHG-Monthly') {
        this.selectSite(this.defaultSubdailyCount);
      } else if (type != 'split') {
        this.splitPosition = 1;
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        this.USLscnLyr = [];
        this.SpirescnLyr = [];
      
        this.resetBackgroundWithChangeInBaseLayer();
      }
    } else {
      this.filteredCollection = JSON.parse(JSON.stringify(this.collection));
      if (event?.repeatFrequency === 'Sub-daily') {
        this.selectSubdaily(this.defaultSubdailyCount);
      } else if (event?.repeatFrequency === 'GHG-Monthly') {
        this.selectSites(this.defaultSubdailyCount);
      } else if (type != 'split') {
        this.splitPosition = 1;
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        this.USLscnLyr = [];
        this.SpirescnLyr = [];
        this.collection.sensors.forEach((element, index) => {
          element.checked = false
        });
        this.resetBackgroundWithChangeInBaseLayer();
      }
    }
  }

  selectSubdaily(count: number) {
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    if (this.selectedCheckboxes?.length > 1) return
    
    this.subDailyCountarray = []
    this.defaultSubdailyCount = count;
    let scenesList: any = [];
    for (const [sensorIndex, sensors] of this.filteredCollection.sensors.entries()) {
      let sceneArray: any = [];
      for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
        scenes.checked = false;
        if (scenes?.sequenceNumber == count) {
          sceneArray.push(scenes);
        }
      }

      sceneArray.forEach((element, index) => {
        element.sceneIdx = index + 1;
        if (element?.id != null && element?.status != 'failed') {
          scenesList.push({ id: element.id });
        }
      });

      this.collection.sensors[sensorIndex].checked = false;
      this.collection.sensors[sensorIndex].scenesList = scenesList;
      this.collection.sensors[sensorIndex].scenes = sceneArray;

    }
    for (let i = 0; i < this.collection?.subDailyCount; i++) {
      const countObject = { checked: false, value: i + 1, disabled: this.disabledCheck(i + 1) }
      this.subDailyCountarray.push(countObject)
    }
    this.splitPosition = 1;

    if (!this.showDailyRecurrencyCheckboxes && !this.RF) {
      this.viewer.entities.removeAll();
      this.clearBandLegends();
      this.USLscnLyr = [];
      this.SpirescnLyr = [];
      this.resetBackgroundWithChangeInBaseLayer();
    }
    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false;
    this.newTaskButtonClicked = false;
    if(this.annotateClicked) this.getAnnotations();
  }

  selectSites(item: number) {
    this.subDailyCountarray = []
    this.defaultSubdailyCount = item;
    let scenesList: any = [];
    this.collection.siteData = [];
    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false;
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    this.newTaskButtonClicked = false;
    
    for (const [sensorIndex, sensors] of this.filteredCollection.sensors.entries()) {
      let sceneArray: any = [];
      for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
        scenes.checked = false;
        if (scenes?.siteId == item) {
          sceneArray.push(scenes);
        }
      }

      sceneArray.forEach((element, index) => {
        element.sceneIdx = index + 1;
        if (element?.id != null && element?.status != 'failed') {
          scenesList.push({ id: element.id });
        }
      });

      if (!this.timeseries) this.collection.sensors[sensorIndex].checked = false;
      this.collection.sensors[sensorIndex].scenesList = scenesList;
      this.collection.sensors[sensorIndex].scenes = sceneArray;

      if (sensors.siteData) {
        this.collection.siteData = sensors.siteData;
      }
    }

    for (const [siteIndex, site] of this.collection?.siteData.entries()) {
      site.checked = false;
      site.value = siteIndex + 1;
      site.siteId = siteIndex + 1;
      site.disabled = this.disabledSiteCheck(site.siteId)
    }
    this.subDailyCountarray = this.collection?.siteData
    this.splitPosition = 1;
    this.clearBandLegends();
    this.USLscnLyr = [];
    this.SpirescnLyr = [];
    if (this.selectedType != 'dateDropdown') {
    this.viewer.entities.removeAll();
    this.resetBackgroundWithChangeInBaseLayer();
    }
  }

  disabledCheck(count: number) {
    let sceneArray: any = [];
    for (const [sensorIndex, sensors] of this.filteredCollection.sensors.entries()) {
      for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
        scenes.checked = false;

        if (scenes?.sequenceNumber == count) {
          sceneArray.push(scenes);
        }
      }
    }
    if (sceneArray.length === 0)
      return true
    else
      return false
  }

  disabledSiteCheck(count: number) {
    let sceneArray: any = [];
    for (const [sensorIndex, sensors] of this.filteredCollection.sensors.entries()) {
      for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
        scenes.checked = false;

        if (scenes?.siteId == count) {
          sceneArray.push(scenes);
        }
      }
    }
    if (sceneArray.length === 0)
      return true
    else
      return false
  }

  selectSubdailyRecurrent(event, data) {

    this.showSubDailyList = true
    this.subDailyArray = []
    if (event.checked) {
      this.selectedSubDailyItems.push(data.value)
      this.subSelectionSelectionChange.next(data.value);
      this.selectedSites.push(data.siteName);
    }
    else {
      let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(data.value));
      this.selectedSubDailyItems.splice(countIndex, 1)
      this.subSelectionSelectionChange.next(this.selectedSubDailyItems[this.selectedSubDailyItems.length - 1]);
      let siteNameIndex = this.selectedSubDailyItems.findIndex(item => (item) === (data.siteName));
      this.selectedSites.splice(siteNameIndex, 1)
    }
    if (this.selectedSubDailyItems.length > 2) {
      // If more than 2 items are selected, remove the first selected item
      this.selectedSubDailyItems.shift();
    }

    if (!data.siteName) {
      this.subDailyCount = 'Sub-daily Count : ' + this.selectedSubDailyItems[0];
      this.selectedSubdaily = 'Sub-daily Count : ' + this.selectedSubDailyItems[1];
    } else {
      this.subDailyCount = 'Site Name : ' + this.selectedSites[0];
      this.selectedSubdaily = 'Site Name : ' + this.selectedSites[1];
    }


    for (const count of this.subDailyCountarray) {
      if (count.value === data.value)
        count.checked = event.checked
      let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
      if (countIndex === -1)
        count.checked = false
    }
    let scenesList: any = [];
    const filterSubDailyArray = this.filteredCollection.sensors
    let filterArray: any = [];

    for (const [sensorIndex, sensors] of filterSubDailyArray.entries()) {

      for (const count of this.selectedSubDailyItems) {
        let sceneArray: any;
        if (sensors.siteData?.length > 0 && sensors?.operatorKey != OPERATORS.LBAND) {
          sceneArray = this.filteredCollection.sensors[sensorIndex].scenes.filter(scene => parseInt(scene?.siteId) === parseInt(count));
        } else {
          sceneArray = this.filteredCollection.sensors[sensorIndex].scenes.filter(scene => parseInt(scene?.sequenceNumber) === parseInt(count));
        }

        if (sceneArray.length > 0) {
          sceneArray.forEach((element, index) => {
            element.sceneIdx = index + 1;
            if (element?.id != null && element?.status != 'failed') {
              scenesList.push({ id: element.id });
            } else
              element.checked = false

          });
          filterArray.push({
            scenes: sceneArray, scenesList: scenesList, checked: sensors.checked,
            downloaded: sensors.downloaded, id: sensors.id, name: sensors.name, sequenceNumber: count,
            resolution_tooltip: sensors?.resolution_tooltip, downloadUrl: sensors?.downloadUrl,latency: sensors?.latency,
            status: sensors?.status, restoreStatus: sensors?.restoreStatus, restoreOption: sensors?.restoreOption, isAdditionalProducts: sensors?.isAdditionalProducts,
            isAdditionalCSIProducts: sensors?.isAdditionalCSIProducts,obfuscateName: sensors?.obfuscateName
          })
        }


      }

    }

    const uniqueArray: any = [];
    const seenIds = new Set();

    for (const item of filterArray) {
      if (!seenIds.has(item.sequenceNumber)) {
        seenIds.add(item.sequenceNumber);
        uniqueArray.push(item);
      }
    }
    filterArray = uniqueArray
    this.subDailyFinalArray = uniqueArray
    this.scnLyr = []
    this.scnLyr2 = []
    this.viewer.entities.removeAll();
    this.clearBandLegends();
    this.USLscnLyr = [];
    this.SpirescnLyr = [];
    this.resetBackgroundWithChangeInBaseLayer();
    for (const [sensorIndex, subDailyData] of this.subDailyFinalArray.entries()) {
      subDailyData.checked = true
      for (const [index, scn] of subDailyData?.scenes.entries()) {
        if (scn?.id && scn?.status !== 'failed') {
          scn.checked = true
          if (scn?.operatorKey === this.OPERATOR.USL) {
            this.displayRFImage(scn,sensorIndex,'none','none');
          } else if (scn?.operatorKey === OPERATORS.LBAND) {
            this.selectSignals(subDailyData, this.signalSelected)
          } else if (scn.footprint) {
            const sensorId = subDailyData?.id;
            const sceneId = scn?.id;
            const action = "sensor"
            const taskId = this.collection?.taskId;
            const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
            this.spinnerService.show();
            this.mapLoad = true;
            this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
              if (res?.msg === 'Failed') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              } else if (res?.msg === 'user storage') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              }
              scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
              this.displayFootPrint(scn, sensorIndex, res.result, sensorId, taskId);
              this.spinnerService.hide();
              this.mapLoad = false;
            });

          }
        }
      }


    }
    let unShowSensor = this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.USL && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.SPIRE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.CLYDE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.LBAND;
    if (this.selectedSubDailyItems.length === 2 && unShowSensor) {
      this.isVisible = true
      this.splitRightPostion = true
      this.splitLeftPostion = true
      this.splitPosition = 0.5;
      this.clearAnnotations();
    } else {
      this.isVisible = false
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.splitPosition = 0.0;
    }

    const countCheckbox = this.subDailyCountarray.filter(item => item.checked === true).length;

    if (countCheckbox === 0) {
     this.toggleDailyRecurrency(event);
    }
    
    
    // Force a redraw of the viewer's scene to apply the changes
    this.viewer.scene.requestRender();

  }
  selectSiteRecurrent(event, data) {
    this.showSubDailyList = true;
    this.selectedCheckboxes = [];
    if (event.checked) {

      if (this.selectedSites.length >= 2) {
        // If more than 2 items are selected, remove the first selected item
        this.selectedSites.shift();
        this.selectedSubDailyItems.shift();

      }

      this.selectedSubDailyItems.push(data)
      this.subSelectionSelectionChange.next(data.value);
      this.selectedSites.push(data)

      for (const count of this.subDailyCountarray) {
        let countIndex = this.selectedSites.findIndex(item => parseInt(item?.value) === parseInt(count.value));
        if (countIndex === -1)
          count.checked = false
        else
          count.checked = true
      }
      this.isDisabledAnottationBtn = false;
    }
    else {
      let countIndex = this.selectedSites.findIndex(item => parseInt(item?.value) === parseInt(data.value));
      if (countIndex != -1) {
        this.selectedSubDailyItems.splice(countIndex, 1);
        this.selectedSites.splice(countIndex, 1);
      }

      this.subSelectionSelectionChange.next(this.selectedSites[this.selectedSites.length - 1]);
      
      this.subDailyCountarray = this.subDailyCountarray.map(site => {
        if (site.value === data.value) {
          return { ...site, checked: false };
        }
        return site;
      });
    }

    this.subDailyCount = 'Site Name : ' + this.selectedSites[0]?.siteName;
    this.selectedSubdaily = 'Site Name : ' + this.selectedSites[1]?.siteName;

    let scenesList: any = [];
    const filterSubDailyArray = this.fullCollection.sensors
    let filterArray: any = [];
    let startDate, endDate;
    if(this.dateSelected) {
      startDate = new Date(this.collection.recurrencyArray[this.currentSensorIndex].start);
      endDate = new Date(this.collection.recurrencyArray[this.currentSensorIndex].end);
    }


    for (const [sensorIndex, sensor] of filterSubDailyArray.entries()) {
      for (const [countIndex, count] of this.selectedSites.entries()) {
        let sceneArray: any;
        sceneArray = this.fullCollection.sensors[sensorIndex].scenes.filter(scene => parseInt(scene?.siteId) === parseInt(count.value));
        if (this.dateSelected) {
          sceneArray = sceneArray.filter(scene => (new Date(scene.predictedDate) >= startDate &&
          new Date(scene.predictedDate) <= endDate));
        }
        
        if (sceneArray.length > 0) {
          sceneArray.forEach((element, index) => {
            element.sceneIdx = index + 1;
            if (element?.id != null && element?.status != 'failed'
            ) {
              scenesList.push({ id: element.id });
            } else
              element.checked = false

          });
          
          const filteredCheckboxes = this.collection?.recurrencyArray;
          
          if (countIndex === 0) {
            this.previousSelectionText = sceneArray[0].predictedDate;
            this.previousEndText = filteredCheckboxes[0].end;
          }

          else {
            this.selectionLabel = sceneArray[0].predictedDate;
            this.selectionLabelEnd = filteredCheckboxes[0].end;
          }
          filterArray.push({
            scenes: sceneArray, scenesList: scenesList, checked: sensor.checked,
            downloaded: sensor.downloaded, id: sensor.id, name: sensor.name, sequenceNumber: count,
            resolution_tooltip: sensor?.resolution_tooltip, downloadUrl: sensor?.downloadUrl,latency: sensor?.latency,
            status: sensor?.status, restoreStatus: sensor?.restoreStatus, restoreOption: sensor?.restoreOption, isAdditionalProducts: sensor.isAdditionalProducts,
            isAdditionalCSIProducts: sensor?.isAdditionalCSIProducts,obfuscateName: sensor?.obfuscateName
          })
        }


      }

    }

    const uniqueArray: any = [];
    const seenIds = new Set();

    for (const item of filterArray) {
      if (!seenIds.has(item.sequenceNumber)) {
        seenIds.add(item.sequenceNumber);
        uniqueArray.push(item);
      }
    }
    filterArray = uniqueArray
    this.subDailyFinalArray = uniqueArray
    this.scnLyr = []
    this.scnLyr2 = []
    this.viewer.entities.removeAll();
  
    this.resetBackgroundWithChangeInBaseLayer();
    for (const [sensorIndex, subDailyData] of this.subDailyFinalArray.entries()) {
      subDailyData.checked = true
      for (const [index, scn] of subDailyData?.scenes.entries()) {

        if (scn?.id && scn?.status !== 'failed') {
          scn.checked = true

          if (scn.footprint) {
            const sensorId = subDailyData?.id;
            const sceneId = scn?.id;
            const action = "sensor"
            const taskId = this.collection?.taskId;
            const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
            this.spinnerService.show();
            this.mapLoad = true;
            this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
              if (res?.msg === 'Failed') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              } else if (res?.msg === 'user storage') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              }
              scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
              this.displayFootPrint(scn, sensorIndex, res.result, sensorId, taskId);
              this.spinnerService.hide();
              this.mapLoad = false;
            });

          }

        }
      }


    }
    let unShowSensor = this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.USL && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.SPIRE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.CLYDE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.LBAND;
    if (this.selectedSubDailyItems.length === 2 && unShowSensor ) {
      this.isVisible = true
      this.splitRightPostion = true
      this.splitLeftPostion = true
      this.splitPosition = 0.5;
      this.clearAnnotations();
    } else {
      this.isVisible = false
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.splitPosition = 0.0;
    }

    this.siteSelected = null;
    this.selectedSite = null;
    
    const filteredCheckboxes = this.subDailyCountarray.filter(item => item.checked);
    if (this.datePlace && filteredCheckboxes[0]) {
      this.siteSelected = filteredCheckboxes[0];
      if (filteredCheckboxes?.length === 1) {
        this.selectedSite = filteredCheckboxes[0];
      }    
    }
    
    if (filteredCheckboxes?.length === 0) {
      this.selectedCheckboxes = [];
      const checkedData = this.collection.recurrencyArray.filter(item => item.checked);
     const index = checkedData[0].index ? checkedData[0].index : 0;

      this.currentEvent = {
        checked : true
       };
      this.toggleRecurrentSensor(0,index,undefined,this.currentEvent,'dropdown','Month');
      this.subDailyCount = '';
      this.selectedSubdaily = '';
    }

    if (filteredCheckboxes?.length > 1) {
      this.dateDisable = true;
    } else this.dateDisable = false;

    if(this.selectedSubDailyItems.length > 1) {
      this.isDisabledAnottationBtn = false;
    }

    // Force a redraw of the viewer's scene to apply the changes
    this.viewer.scene.requestRender();

    if (this.subDailyFinalArray?.length === 0) {
      this.toggleDailyRecurrency(event)
    }

    this.collection.recurrencyArray.forEach(item => {
      item.checked = false;
    });
    this.monthSelected = null;
    
    if (this.selectedSites?.length === 1) {
      this.fullCollection.recurrencyArray.forEach(item => {
        // Set `disabled` to `true` if none of the scenes match the `siteIdToCheck`
        item.disabled = !item.scenes.some(scene => scene.siteId === this.selectedSites[0].value);
      });
      this.collection.recurrencyArray = JSON.parse(JSON.stringify(this.fullCollection.recurrencyArray));
    }
    
  }

  compareOpportunity(i: any, j: any, k: any, event: any, type: any) {
    let unShowSensor =this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.USL && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.SPIRE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.CLYDE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.LBAND;

    if (type === "dropdown") {

      if (event.checked) {
        this.collection.opportunityList[j].checked = event.checked;
        this.feasibilityArray.push(this.collection.opportunityList[j]);
      }
      else {
        const indexToRemove = this.feasibilityArray.findIndex((item) => item.date === this.collection.opportunityList[j].date)
        this.feasibilityArray.splice(indexToRemove, 1);


      }
      this.viewer.entities.removeAll();
      this.clearBandLegends();
      this.USLscnLyr = [];
     
      this.resetBackgroundWithChangeInBaseLayer();
      for (var feasibilityIndex = 0; feasibilityIndex < this.feasibilityArray?.length; feasibilityIndex++) {

        this.feasibilityArray[feasibilityIndex].checked = true;
        this.showOpprtunityImage(feasibilityIndex)
      }

    }
    else
      if (event.checked) {

        if (j === 0) {
          if (k === undefined) {
            this.feasibilityArray.push(this.collection.opportunityList[j]);

            this.viewer.entities.removeAll();
            this.clearBandLegends();
            this.USLscnLyr = [];
            var allImageryLayers = this.viewer.imageryLayers._layers;


            this.showOpprtunityImage(j)
          } else {
            this.showOpprtunityScene(j, k)
          }
        } else {
          if (k === undefined) {
            this.feasibilityArray.push(this.collection.opportunityList[j]);

            var allImageryLayers = this.viewer.imageryLayers._layers;


            this.showOpprtunityImage(j)
          } else {
            this.showOpprtunityScene(j, k)
          }
        }
      }
      else {
        if (j === 0) {
          var allImageryLayers = this.viewer.imageryLayers._layers;
          var leftImageryLayers: any = [];
          var rightImageryLayers: any = [];
          for (var index = 0; index < allImageryLayers.length; index++) {
            var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url
            var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
            var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
            if (url !== undefined && isLeft) {
              leftImageryLayers.push(allImageryLayers[index]);
            }
            if (url !== undefined && isRight) {
              rightImageryLayers.push(allImageryLayers[index]);
            }
          }
          if (k === undefined) {
            if (leftImageryLayers.length === 1) {
              this.viewer.imageryLayers.remove(leftImageryLayers[0]);
            } else
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
              }
            this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
              scn.checked = false
            });
            this.feasibilityArray.splice(j, 1);
            for (const opportunity of this.collection.opportunityList) {
              let countIndex = this.feasibilityArray.findIndex(item => new Date(item.date).getTime() === new Date(opportunity.date).getTime());
              if (countIndex !== -1)
                opportunity.checked = true
              else
                opportunity.checked = false
            }
            if (this.feasibilityArray.length === 0) {
              this.subSelectionSelectionChange.next(1)
              for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
              }
              this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
                scn.checked = false
              })

            }

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr[indexToRemoveEntity].show = false;
            }

            if (rightImageryLayers.length > 0)
              for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                  this.scnLyr2[indexToRemoveEntity].show = true;
              }

          } else {
            if (leftImageryLayers.length === 1) {
              this.viewer.imageryLayers.remove(leftImageryLayers[0]);
            } else {
              const findSensor = this.feasibilityArray[j]
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                if (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl)) {
                  this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
                }

              }

            }
            this.feasibilityArray[j].scenes[k].checked = false
            if (this.feasibilityArray[j].scenes.filter(item => item.checked).length === 0) {
              this.feasibilityArray[j].checked = false
              this.feasibilityArray.splice(j, 1);
            }
            this.feasibilityArray = this.feasibilityArray.filter(item => item.checked)

            for (const count of this.subDailyCountarray) {
              let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
              if (countIndex !== -1)
                count.checked = true
              else
                count.checked = false
            }
            if (this.feasibilityArray.length === 0) {
              this.subSelectionSelectionChange.next(1)
              for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
              }
              this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
                scn.checked = false
              })
              this.viewer.entities.removeAll();
            }

          }

        }
        else {
          var allImageryLayers = this.viewer.imageryLayers._layers;
          var leftImageryLayers: any = [];
          var rightImageryLayers: any = [];
          for (var index = 0; index < allImageryLayers.length; index++) {
            var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url

            var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
            var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
            if (url !== undefined && isLeft) {
              leftImageryLayers.push(allImageryLayers[index]);
            }
            if (url !== undefined && isRight) {
              rightImageryLayers.push(allImageryLayers[index]);
            }
          }
          if (k === undefined) {
            if (rightImageryLayers.length === 1) {
              this.viewer.imageryLayers.remove(rightImageryLayers[0]);
            } else
              for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
              }

            this.feasibilityArray.splice(this.feasibilityArray.length > 1 ? j : 0, 1);
            for (const opportunity of this.collection.opportunityList) {
              let countIndex = this.feasibilityArray.findIndex(item => new Date(item.date).getTime() === new Date(opportunity.date).getTime());
              if (countIndex !== -1)
                opportunity.checked = true
              else
                opportunity.checked = false
            }
            if (this.feasibilityArray.length === 0) {
              this.subSelectionSelectionChange.next(1)
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
              }
              this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
                scn.checked = false
              })
              this.viewer.entities.removeAll();
            }
            if (leftImageryLayers.length > 0)
            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              this.scnLyr[indexToRemoveEntity].show = true;
            }
            for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr2[indexToRemoveEntity].show = true;
            }


          } else {
            if (rightImageryLayers.length === 1) {
              this.viewer.imageryLayers.remove(rightImageryLayers[0]);
            } else {

              const findSensor = this.feasibilityArray[j]


              for (let rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {

                if (rightImageryLayers[rightImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (rightImageryLayers[rightImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl) ) {
                  this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
                }

              }
            }
            this.feasibilityArray[j].scenes[k].checked = false
            if (this.feasibilityArray[j].scenes.filter(item => item.checked).length === 0) {
              this.feasibilityArray[j].checked = false

            }
            this.feasibilityArray = this.feasibilityArray.filter(item => item.checked)
            if (this.feasibilityArray.length === 0) {
              this.subSelectionSelectionChange.next(1)
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
              }
              this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
                scn.checked = false
              })
              this.viewer.entities.removeAll();
            }
          }


        }
        if (this.feasibilityArray.length === 2 && unShowSensor) {
          this.isVisible = true
          this.splitRightPostion = true
          this.splitLeftPostion = true
          this.splitPosition = 0.5;
          this.clearAnnotations();
        } else if (this.feasibilityArray.length === 0) {
          this.subSelectionSelectionChange.next(1)
        } else {
          this.subSelectionSelectionChange.next(this.selectedSubDailyItems[this.selectedSubDailyItems.length - 1])
          this.isVisible = false
          this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
          this.viewer.scene.imagerySplitPosition = undefined;
          this.splitPosition = 0.0;
          this.splitRightPostion = false
          this.splitLeftPostion = false
        }

      }
    if (this.feasibilityArray.length === 2 && unShowSensor) {
      this.isVisible = true
      this.splitRightPostion = true
      this.splitLeftPostion = true
      this.splitPosition = 0.5;
      this.clearAnnotations();
    } else {
      this.splitRightPostion = false
      this.splitLeftPostion = false
      this.isVisible = false
      this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
      this.viewer.scene.imagerySplitPosition = undefined;
      this.splitPosition = 0.0;
      this.splitRightPostion = false
      this.splitLeftPostion = false
    }
    this.viewer.render();

  }

  toggleSubDailyRecurrentSensor(j: any, k: any, event: any) {
    let unShowSensor = this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.USL && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.SPIRE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.CLYDE && this.collection.sensors[0]?.scenes[0]?.operatorKey != this.OPERATOR.LBAND;

    if (event.checked) {

      if (j === 0) {

        if (k === undefined) {
          this.viewer.entities.removeAll();
          this.clearBandLegends();
          this.USLscnLyr = [];
       
          this.resetBackgroundWithChangeInBaseLayer();
          this.showImageInMap(j)
        } else {
          this.showImageInMapScene(j, k)
        }

      } else {

        if (k === undefined) {

         
          this.resetBackgroundWithChangeInBaseLayer();
          this.showImageInMap(j)
        } else {
          this.showImageInMapScene(j, k)

        }

      }

    } else {
      if (j === 0) {

        var allImageryLayers = this.viewer.imageryLayers._layers;
        var leftImageryLayers: any = [];
        var rightImageryLayers: any = [];
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url

          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            leftImageryLayers.push(allImageryLayers[index]);
          }
          if (url !== undefined && isRight) {
            rightImageryLayers.push(allImageryLayers[index]);
          }
        }
         if (k === undefined) {
          if (leftImageryLayers.length === 1) {
            this.viewer.imageryLayers.remove(leftImageryLayers[0]);

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr[indexToRemoveEntity].show = false;
                this.scnLyr.splice(indexToRemoveEntity, 1);
            }
          } else

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr[indexToRemoveEntity].show = false;
                this.scnLyr.splice(indexToRemoveEntity, 1);
            }
          for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
            this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
          }
          this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
            scn.checked = false
          });
          this.subDailyFinalArray = this.subDailyFinalArray.filter(item => item.checked)
          this.selectedSubDailyItems.splice(j, 1);
          this.selectedSites.splice(j, 1);
          this.subDailyCount = 'Sub-daily Count : ' + this.selectedSubDailyItems[0];
          this.selectedSubdaily = 'Sub-daily Count : ' + this.selectedSubDailyItems[1];

          for (const count of this.subDailyCountarray) {
            let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
            if (countIndex !== -1)
              count.checked = true
            else
              count.checked = false
          }

          if (this.selectedSites[0] != undefined) {
            for (const count of this.subDailyCountarray) {
              let countIndex = this.selectedSites.findIndex(item => parseInt(item.value) === parseInt(count.value));
              if (countIndex === -1)
                count.checked = false
              else
                count.checked = true
            }
          }
          if (this.subDailyFinalArray.length === 0) {
            this.subSelectionSelectionChange.next(1);

            for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr2[indexToRemoveEntity].show = false;
            }

            for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
              this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);

            }
            this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
              scn.checked = false
            })
            this.scnLyr2 = []
          }

          for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr[indexToRemoveEntity].show = false;
          }
          this.scnLyr = []


        } else {
          if (leftImageryLayers.length === 1) {
            this.viewer.imageryLayers.remove(leftImageryLayers[0]);

            for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr[indexToRemoveEntity].show = false;
                this.scnLyr.splice(indexToRemoveEntity, 1);
            }

          } else {
            const findSensor = this.subDailyFinalArray[j]
            if (leftImageryLayers.length === 0) {
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < rightImageryLayers.length; leftImageryLayersIndex++) {
                if (rightImageryLayers[leftImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (rightImageryLayers[leftImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl)) {
                  this.viewer.imageryLayers.remove(rightImageryLayers[leftImageryLayersIndex]);
                }

              }

              for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr2[indexToRemoveEntity];              
                if (item.index === findSensor.scenes[k].sceneIdx) {
                  this.scnLyr2[indexToRemoveEntity].show = false;
                  this.scnLyr2.splice(indexToRemoveEntity, 1);
                }
              }
            } else {
              for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
                if (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || (leftImageryLayers[leftImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl)) {
                  this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
                }

              }

              for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                const item = this.scnLyr[indexToRemoveEntity];              
                if (item.index === findSensor.scenes[k].sceneIdx) {
                  this.scnLyr[indexToRemoveEntity].show = false;
                  this.scnLyr.splice(indexToRemoveEntity, 1);
                }
              }
            }


          }
          this.subDailyFinalArray[j].scenes[k].checked = false
          if (this.subDailyFinalArray[j].scenes.filter(item => item.checked).length === 0) {
            this.subDailyFinalArray[j].checked = false
            this.selectedSubDailyItems.splice(j, 1);
            this.selectedSites.splice(j, 1);
          }
          this.subDailyFinalArray = this.subDailyFinalArray.filter(item => item.checked)

          this.subDailyCount = 'Sub-daily Count : ' + this.selectedSubDailyItems[0];
          this.selectedSubdaily = 'Sub-daily Count : ' + this.selectedSubDailyItems[1];

          for (const count of this.subDailyCountarray) {
            let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
            if (countIndex !== -1)
              count.checked = true
            else
              count.checked = false
          }

          if (this.selectedSites[0] != undefined) {
            for (const count of this.subDailyCountarray) {
              let countIndex = this.selectedSites.findIndex(item => parseInt(item.value) === parseInt(count.value));
              if (countIndex === -1)
                count.checked = false
              else
                count.checked = true
            }
          }
          if (this.subDailyFinalArray.length === 0) {
            this.subSelectionSelectionChange.next(1)
            for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
              this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
            }
            this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
              scn.checked = false
            })
            this.viewer.entities.removeAll();
          }


        }

        if (this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL) {
          this.viewer.entities.removeAll();
          this.clearBandLegends();
          if (this.subDailyFinalArray[0]?.scenes[0]) {
            this.displayRFImage(this.subDailyFinalArray[0]?.scenes[0], 0, 'none','none');
          }
        } else if (this.collection?.sensors[0]?.scenes[0]?.operatorKey === OPERATORS.LBAND) {          
          for (const [sensorIndex, sensors] of this.subDailyFinalArray.entries()) {
            this.selectSignals(sensors, this.signalSelected)
          }
        }

        if (this.subDailyFinalArray?.length === 0) {
          this.toggleDailyRecurrency(event);
        }

      }
      else {


        var allImageryLayers = this.viewer.imageryLayers._layers;
        var leftImageryLayers: any = [];
        var rightImageryLayers: any = [];
        for (var index = 0; index < allImageryLayers.length; index++) {
          var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url

          var isLeft = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.LEFT;
          var isRight = allImageryLayers[index]?.splitDirection === Cesium.SplitDirection.RIGHT;
          if (url !== undefined && isLeft) {
            leftImageryLayers.push(allImageryLayers[index]);
          }
          if (url !== undefined && isRight) {
            rightImageryLayers.push(allImageryLayers[index]);
          }
        }
        if (k === undefined) {
          for (var rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {
            this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);

          }

          for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
            this.scnLyr2[indexToRemoveEntity].show = false;
          }

          this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
            scn.checked = false
          })
          this.scnLyr2 = []
          this.subDailyFinalArray = this.subDailyFinalArray.filter(item => item.checked)
          this.subDailyCountarray[j].checked = false;
          this.selectedSubDailyItems.splice(j, 1)
          this.selectedSites.splice(j, 1)
          this.subDailyCount = 'Sub-daily Count : ' + this.selectedSubDailyItems[0];
          this.selectedSubdaily = 'Sub-daily Count : ' + this.selectedSubDailyItems[1];

          for (const count of this.subDailyCountarray) {
            let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
            if (countIndex !== -1)
              count.checked = true
            else
              count.checked = false
          }
          if (this.selectedSites[0] != undefined) {
            for (const count of this.subDailyCountarray) {
              let countIndex = this.selectedSites.findIndex(item => parseInt(item.value) === parseInt(count.value));
              if (countIndex === -1)
                count.checked = false
              else
                count.checked = true
            }
          }



        } else {
          if (rightImageryLayers.length === 1) {
            this.viewer.imageryLayers.remove(rightImageryLayers[0]);
          } else {

            const findSensor = this.subDailyFinalArray[j]


            for (let rightImageryLayersIndex = 0; rightImageryLayersIndex < rightImageryLayers.length; rightImageryLayersIndex++) {

              if (rightImageryLayers[rightImageryLayersIndex]?.imageryProvider?._source?.source?.source?.client?.url === findSensor.scenes[k].thumbnailUrl || rightImageryLayers[rightImageryLayersIndex]?.imageryProvider.url === findSensor.scenes[k].thumbnailUrl) {
                this.viewer.imageryLayers.remove(rightImageryLayers[rightImageryLayersIndex]);
              }

            }
            this.subDailyFinalArray[j].scenes[k].checked = false
            for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
              const item = this.scnLyr2[indexToRemoveEntity];              
              if (item.index === findSensor.scenes[k].sceneIdx) {
                this.scnLyr2[indexToRemoveEntity].show = false;
                this.scnLyr2.splice(indexToRemoveEntity, 1);
              }
            }
          }

          if (this.subDailyFinalArray[j].scenes.filter(item => item.checked).length === 0) {
            this.subDailyFinalArray[j].checked = false
            this.selectedSubDailyItems.splice(j, 1);
            this.selectedSites.splice(j, 1);
            for (let indexToRemoveEntity = this.scnLyr2?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
                this.scnLyr2[indexToRemoveEntity].show = false;
                this.scnLyr2.splice(indexToRemoveEntity, 1);
            }

          }
          this.subDailyFinalArray = this.subDailyFinalArray.filter(item => item.checked)

          this.subDailyCount = 'Sub-daily Count : ' + this.selectedSubDailyItems[0];
          this.selectedSubdaily = 'Sub-daily Count : ' + this.selectedSubDailyItems[1];

          for (const count of this.subDailyCountarray) {
            let countIndex = this.selectedSubDailyItems.findIndex(item => parseInt(item) === parseInt(count.value));
            if (countIndex !== -1)
              count.checked = true
            else
              count.checked = false
          }
          if (this.selectedSites[0] != undefined) {
            for (const count of this.subDailyCountarray) {
              let countIndex = this.selectedSites.findIndex(item => parseInt(item.value) === parseInt(count.value));
              if (countIndex === -1)
                count.checked = false
              else
                count.checked = true
            }
          }
          if (this.subDailyFinalArray.length === 0) {
            this.subSelectionSelectionChange.next(1)
            for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
              this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
            }
            this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
              scn.checked = false
            })
            this.viewer.entities.removeAll();
          }
        }

        if (this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL) {
          this.viewer.entities.removeAll();
          this.clearBandLegends();
          if (this.subDailyFinalArray[0]?.scenes[0]) {
            this.displayRFImage(this.subDailyFinalArray[0]?.scenes[0], 0, 'none','none');
          }
        } else if (this.collection?.sensors[0]?.scenes[0]?.operatorKey === OPERATORS.LBAND) {          
          for (const [sensorIndex, sensors] of this.subDailyFinalArray.entries()) {
            this.selectSignals(sensors, this.signalSelected)
          }
        }

        if (this.subDailyFinalArray?.length === 0) {
          this.toggleDailyRecurrency(event);
        }

      }
      if (this.subDailyFinalArray.length === 2 && unShowSensor) {
        this.isVisible = true
        this.splitRightPostion = true
        this.splitLeftPostion = true
        this.splitPosition = 0.5;
        this.clearAnnotations();
      } else if (this.subDailyFinalArray.length === 0) {
        this.subSelectionSelectionChange.next(1)
      } else {
        this.subSelectionSelectionChange.next(this.selectedSubDailyItems[this.selectedSubDailyItems.length - 1])
        this.isVisible = false
        this.splitRightPostion = false
        this.splitLeftPostion = false
        this.viewer.scene.splitPosition = undefined; // or any value between 0 and 1 to position the split
        this.viewer.scene.imagerySplitPosition = undefined;
        this.splitPosition = 0.0;
      }

    }

    const filteredCheckbox = this.subDailyCountarray.filter(item => item.checked);
       
    if (filteredCheckbox[0] && this.datePlace) {
      this.dateDisable = false;
      this.siteSelected = filteredCheckbox[0];      
    }

    if (this.selectedSites?.length === 1 && this.datePlace) { 
      this.fullCollection.recurrencyArray.forEach(item => {
        // Set `disabled` to `true` if none of the scenes match the `siteIdToCheck`
        item.disabled = !item.scenes.some(scene => scene.siteId === this.selectedSites[0].value);
      });
      this.collection.recurrencyArray = JSON.parse(JSON.stringify(this.fullCollection.recurrencyArray));
    }

    if (this.datePlace && this.selectedSites?.length === 2) {
      this.subDailyCount = 'Site Name : ' + this.selectedSites[0]?.siteName;
      this.selectedSubdaily = 'Site Name : ' + this.selectedSites[1]?.siteName;
    }

    if(this.datePlace) this.selectedCheckboxes = [];

    // Force a redraw of the viewer's scene to apply the changes
    this.viewer.scene.requestRender();
  }

  showOpprtunityImage(j) {
    this.feasibilityArray[j]?.scenes.forEach(async (scn: any, index: any) => {
      if (scn?.id && scn?.status !== 'failed') {
        scn.checked = true
        if (scn.footprint) {
          const sensorId = this.feasibilityArray[j]?.id;
          const sceneId = scn?.id;
          const action = "sensor"
          const taskId = this.collection?.taskId;
          const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
          this.spinnerService.show();
          this.mapLoad = true;
          this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
            if (res?.msg === 'Failed') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            } else if (res?.msg === 'user storage') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            }
            if (this.selectedSensors[0]?.scenes) {
              this.selectedSensors[0].scenes[index].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
            }
            scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
            this.displayFootPrint(scn, j, res.result, sensorId,taskId);
            this.spinnerService.hide();
            this.mapLoad = false;
          });

        }
      }
    });
  }

  showOpprtunityScene(j, k) {
    const scn = this.feasibilityArray[j]?.scenes[k]

    if (scn.footprint) {
      const sensorId = this.feasibilityArray[j]?.id;
      const sceneId = scn?.id;
      const action = "sensor"
      const taskId = this.collection?.taskId;
      const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
      this.spinnerService.show();
      this.mapLoad = true;
      this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
        if (res?.msg === 'Failed') {
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }
          this.spinnerService.hide();
          this.mapLoad = false;
        } else if (res?.msg === 'user storage') {
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }
          this.spinnerService.hide();
          this.mapLoad = false;
        }
        scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
        this.displayFootPrint(scn, j, res.result,sensorId,taskId);
        this.spinnerService.hide();
        this.mapLoad = false;
      });

    }

  }

  showImageInMap(j) {
    this.subDailyFinalArray[j]?.scenes.forEach(async (scn: any, index: any) => {
      if (scn?.id && scn?.status !== 'failed') {
        scn.checked = true

        if (scn.footprint) {
          const sensorId = this.subDailyFinalArray[j]?.id;
          const sceneId = scn?.id;
          const action = "sensor"
          const taskId = this.collection?.taskId;
          const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
          this.spinnerService.show();
          this.mapLoad = true;
          this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
            if (res?.msg === 'Failed') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            } else if (res?.msg === 'user storage') {
              this.fail = this.fail + 1;
              if (this.fail === 1) {
                const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              }
              this.spinnerService.hide();
              this.mapLoad = false;
            }
            if (this.selectedSensors[0]?.scenes) {
              this.selectedSensors[0].scenes[index].thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
            }
            this.displayFootPrint(scn, j, res.result,sensorId,taskId);
            this.spinnerService.hide();
            this.mapLoad = false;
          });

        }
      }
    });
  }
  showImageInMapScene(j, k) {
    const scn = this.subDailyFinalArray[j]?.scenes[k]

    if (scn.footprint) {
      const sensorId = this.subDailyFinalArray[j]?.id;
      const sceneId = scn?.id;
      const action = "sensor"
      const taskId = this.collection?.taskId;
      const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
      this.spinnerService.show();
      this.mapLoad = true;
      this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
        if (res?.msg === 'Failed') {
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message,'', '40%');
          }
          this.spinnerService.hide();
          this.mapLoad = false;
        } else if (res?.msg === 'user storage') {
          this.fail = this.fail + 1;
          if (this.fail === 1) {
            const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }
          this.spinnerService.hide();
          this.mapLoad = false;
        }
        scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
        this.displayFootPrint(scn, j, res.result,sensorId,taskId);
        this.spinnerService.hide();
        this.mapLoad = false;
      });

    }
  }

  downloadProgress(progress: any) {
    
    if (progress?.sensorId != undefined && !progress?.product) {
      const sensor = this.fullCollection.sensors.find(sensor => sensor.id === progress?.sensorId);
      const nonEmptyIds = sensor.scenes
        .filter(scene => scene.id !== '')
        .map(scene => scene.id);

      progress.sceneId = nonEmptyIds;
    }
    const sensorObj = { taskId: progress.taskId, sensorId: progress?.sensorId, sceneId: progress?.sceneId, product: progress?.product };
    

    if (progress?.downloadClick && progress.progress > 0 && progress.progress < 100) {
      this.freeze = true;
      this.spinnerService.show();
    } else if (progress?.downloadClick && progress.progress >= 100 && progress?.action != 'aisDownload') {
      localStorage.setItem('scene-downloaded', progress?.sceneId);
      if (progress?.sensorId != undefined && !progress?.product) {
        localStorage.setItem('sensor-downloaded', progress?.sensorId);
      }
      if(!progress?.product) {
        this.downloadedScene = this.downloadedScene.concat(progress?.sceneId);
        this.downloadedSensor.push(localStorage.getItem('sensor-downloaded'));
      }

      this.freeze = false;
      this.downloadClick = false;
      this.spinnerService.hide()
      
      if (progress?.action === 'aisDownload') return
      this.apiService.updateCollectionDownload(sensorObj).subscribe(async (res: any) => {
        if(res.msg === 'Success' && progress?.product)
        {
          switch(progress?.product)
          {
            case 'Super Resolution':
              this.setDownloadedSR();
              break;
            case 'NDVI':
              this.setDownloadedIndices();
              break;
            default:
              this.setDownloadedVADP();
              break;    
          }
        }
       });
    }
  }


  toggleDailyRecurrency(event: any) {
    this.showDailyRecurrencyCheckboxes = !this.showDailyRecurrencyCheckboxes;
    this.feasibilityArray = []
    for (const opportunity of this.collection.opportunityList) {
      opportunity.checked = false
    }
    this.selectedSensors = [];
    this.recurrencyArray = [];
    this.selectedCheckboxes = [];
    this.subDailyFinalArray = [];
    this.subDailyArray = [];
    this.selectedSubDailyItems = [];
    this.selectedSites = [];
    this.splitPosition = 0.0;
    this.isVisible = false;
    this.splitRightPostion = false
    this.splitLeftPostion = false
    this.showSubDailyList = false
    this.viewer.entities.removeAll();
    this.clearBandLegends();
    this.USLscnLyr = [];
    this.SpirescnLyr = [];
    this.scnLyr = []
    this.scnLyr2 = []
    this.resetSelect();
    this.defaultSubdailyCount = 1;
    this.clearAnnotations();
    this.imageLoaded = false;
    this.selectedSite = null;
    this.monthSelected = null;
    this.selectedType = null;
    this.dateDisable = false;
    this.feedInfo = false;
    if(this.legendContainer) this.legendContainer.style.display = 'none';
    this.RF = false;
    this.filterStart = null;
    this.filterEnd = null;

    if (!this.showDailyRecurrencyCheckboxes) {
      this.selectedDropdownType = '';
      this.dateSelected = false;
      this.collections[0]?.recurrencyArray.forEach(item => {
        item.checked = false;
      });
      this.currentSensorIndex = null;
      this.currentCollectionIndex = null;
      this.currentSensorIndex = null;
      this.currentSceneIndex = null;
      this.currentSites = null;
    }
    
    this.resetBackgroundWithChangeInBaseLayer();
    this.collection.sensors.forEach((sensor, index) => {
      sensor.checked = false
      sensor.scenes.forEach((scene) => {
        scene.checked = false

      });
    });

    if (this.datePlace && this.showDailyRecurrencyCheckboxes) { 
      if (this.selectedDropdownType === 'sites') {
        if (this.dateSelected) {
          this.selectedCheckboxes.push(this.collection.recurrencyArray[this.currentSensorIndex]);
          this.collection.recurrencyArray[this.currentSensorIndex].checked = true;
          this.monthSelected = this.collection.recurrencyArray[this.currentSensorIndex];
        }        
        this.selectSiteRecurrent(this.currentEvent,this.currentSites);
      } else if (this.selectedDropdownType === 'dateSelection') {
        this.toggleRecurrentSensor(this.currentCollectionIndex,this.currentSensorIndex,undefined,this.currentEvent,'dropdown','Month');
        this.subDailyCount = '';
        this.selectedSubdaily = '';
      }
    }
    else if (this.taskSelected) {
      this.collections = [];
      this.getCollections(this.previousSelectedProject?.projectid ? this.previousSelectedProject?.projectid : this.projectSelected,this.collectionIndex);
      this.selectionChange.next(this.collection.recurrencyArray[0])
    } else {
      this.collections = []
      this.getCollections(this.previousSelectedProject?.projectid ? this.previousSelectedProject?.projectid : this.projectSelected,0);
      this.selectionChange.next(this.collection.recurrencyArray[0])
    }
  }

  countSelectedOptions(): number {
    return this.collection.recurrencyArray.filter(item => item.checked).length;
  }

  exceedsSelectionLimit(): boolean {
    return this.countSelectedOptions() >= this.maxSelections;
  }
  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.initialX = event.clientX - this.left;
  }

  selectIS(
    collectionIndex: number,
    sensorIndex: number,
    infoServiceIndex: number,
    event,
    info: any
  ): void {
    this.selectedIS.fill(false);
    let infoArray =
    this.collections[collectionIndex].sensors[sensorIndex].InfoServices;
    infoArray.forEach((info, index) => {
      info.checked = false
    });    
    this.clearBandLegends();
    this.feedInfo = false;

    if(event.checked) {  
      this.collections[collectionIndex].sensors[sensorIndex].InfoServices[infoServiceIndex].checked = true;
      if(this.collections[collectionIndex].sensors[sensorIndex].InfoServices[0]?.modelType == this.infoserviceModelType) {
        this.spinnerService.show();
      this.mapLoad = true;
      
      if (this.collections[collectionIndex].sensors[sensorIndex]?.operatorKey === OPERATORS.AXELSPACE) {

        let payload = {
          "action": "ISDisplay",
          "type": "json",
          "sceneId" : this.collections[collectionIndex].sensors[sensorIndex].id,
          "sensorId" : this.collections[collectionIndex].sensors[sensorIndex].id
        } 
        const selectedSceneIS = info.insightDatas;
        this.apiService.getCollectionImage(this.collection.taskId, payload).subscribe((res: any) => {
           
          this.fetchAndCombineGeoJson(res?.result?.feedData, info)
          }, (err) => {
            this.spinnerService.hide();
            this.mapLoad = false;
          }) 
      }  else {

        let payload = {
          "action": "ISDisplay",
          "type": "json",
          "sceneId" : this.sceneSelected,
          "sensorId" : this.collections[collectionIndex].sensors[sensorIndex].id
        } 
        const selectedSceneIS = info.insightDatas.filter(scene => scene.sceneId === this.sceneSelected);

        this.apiService.getCollectionImage(this.collection.taskId, payload).subscribe((res: any) => {
          this.fetchAndCombineGeoJson(res?.result?.feedData, info)
          }, (err) => {
            this.spinnerService.hide();
            this.mapLoad = false;
          }) 
        
      }
      
      } else {
        const selectedSceneIS = info.insightDatas;
        if (this.collections[collectionIndex].sensors[sensorIndex]?.operatorKey === OPERATORS.AXELSPACE) {
          this.displayIS(selectedSceneIS[0],info);
          this.displayedIS = JSON.parse(JSON.stringify(selectedSceneIS[0]));
        } else {
          const selectedSceneIS = info.insightDatas.filter(scene => scene.sceneId === this.sceneSelected);
          this.displayIS(selectedSceneIS[0],info);
          this.displayedIS = JSON.parse(JSON.stringify(selectedSceneIS[0])); 
        }
      }
    } else {
      this.clearBandLegends();
    }
  }

  fetchAndCombineGeoJson(urls: string[], info): void {
    const geoJsonRequests = urls.map((url) => this.httpClient.get(url));

    forkJoin(geoJsonRequests).subscribe({
      next: (geoJsonDataArray: any[]) => {
        const combinedGeoJson = this.combineGeoJsonFeatures(geoJsonDataArray);
        this.displayedIS = JSON.parse(JSON.stringify(combinedGeoJson))
        this.displayIS(combinedGeoJson, info);
      },
      error: (error) => {
            this.spinnerService.hide();
            this.mapLoad = false;
      },
    });
  }

  combineGeoJsonFeatures(geoJsonDataArray: any[]): any {
    const allFeatures = geoJsonDataArray.flatMap((geoJson) => geoJson.features);
    return {
      type: 'FeatureCollection',
      features: allFeatures,
    };
  }


  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    this.left = event.clientX - this.initialX;
    if (this.left < 63) {
      this.entitywidth1 -= this.left;
    } else {
      this.entitywidth1 += this.left;
    }


  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }

  flattenObject(obj: any): { key: string; value: any }[] {
    const result: { key: string; value: any }[] = [];

    function recurse(curr: any, prop?: string) {
      if (Object(curr) !== curr) {
        result.push({ key: prop || '', value: curr });
      } else if (Array.isArray(curr)) {
        for (let i = 0; i < curr.length; i++) {
          recurse(curr[i], prop ? prop + '[' + i + ']' : '[' + i + ']');
        }
        if (curr.length === 0) {
          result.push({ key: prop || '', value: [] });
        }
      } else {
        let isEmpty = true;
        for (const p in curr) {
          isEmpty = false;
          recurse(curr[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) {
          result.push({ key: prop, value: {} });
        }
      }
    }

    recurse(obj);
    return result;
  }


  getMetadata(metadata: any) {
    const flattenedJSON = this.flattenObject(metadata);
    this.metaDataTooltip = metadata;
  }

  transformDate(dates: string) {
    return this.datePipe.transform(dates, 'yyyy-MM-dd','UTC');
}

selectBands(bands: any) {
  this.bandSelected = bands;

  if (!this.showDailyRecurrencyCheckboxes && this.sceneBands) {
    this.removeDataSourceById("sensor",this.bandSensorId);
    const indexToRemoveEntity = this.USLscnLyr.findIndex((item) => item.index === this.uslBB.index);

    if (indexToRemoveEntity !== -1) {
      this.USLscnLyr[indexToRemoveEntity].show = false
      this.USLscnLyr.splice(indexToRemoveEntity, 1);
    }
    this.USLscnLyr = [];
    this.displayImage(this.sceneBands);
    this.setJsonData(this.sceneBands,this.bandTaskId,this.bandSensorId);

    if(this.annotateClicked) this.getAnnotations();

  } else if (this.showDailyRecurrencyCheckboxes && !this.showSubDailyList) {
    this.viewer.entities.removeAll();
    this.clearBandLegends();
    this.USLscnLyr = [];
    for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
      for (const item of sensors.scenes) {
        if (item.checked && item.operatorKey === this.OPERATOR.USL) {
          this.displayRFImage(item, sensorIndex, sensors.id,'none');
        }
      }
    }
  } else if (this.showDailyRecurrencyCheckboxes && this.showSubDailyList) {
    this.viewer.entities.removeAll();
    this.clearBandLegends();
    this.USLscnLyr = [];
    for (const [sensorIndex, subDailyData] of this.subDailyFinalArray.entries()) {
      subDailyData.checked = true
      for (const [index, scn] of subDailyData?.scenes.entries()) {
        if (scn?.id && scn?.status !== 'failed') {
          scn.checked = true
          this.displayRFImage(scn, sensorIndex, 'none','none');
        }
      }
    }
  }
}

resetSelect() {
  if (this.bandSelect) {
    this.bandSelect.value = 'both';
    this.sceneBands = '';
    this.bandSelected = 'both';
    this.bandTaskId = '';
    this.bandSensorId = '';
  }
}

  OpenReturnRequest(i: any, j: any, info: any, scene: any, action: any) {
    const sensor = this.collections[i].sensors[j];
    const taskId = this.collections[i]?.taskId;
    let sceneId: any = scene?.id;
    let sensorId: any = sensor?.id;
    let product = 'scene';
    let products = 'scene';
    let feedId: any;

    if (sensor?.superResolutions?.length > 0) product = 'Super Resolution';
    else if (sensor?.operatorKey === OPERATORS.SPIRE) {
      product = 'AIS';
      products = 'AIS';
    }
    else if (info?.insightDatas && action === 'feed') {
      product = "IS";
      feedId = info?._id;
      products = 'IS';
    }
    
    let sensorObj = {
      taskId, sensorId, sceneId, product: products, feedId
    }

    let sensorInfo = {
      scene, taskId, sensorId, sceneId, product,sensor,feedId
    }
  
    this.apiService.returnRefundStatus(sensorObj).subscribe((res: any) => {
      
      if (res?.status === 'found') {
        let message =  'return-refund-accepted';
        if (res?.returnRefundReject) {
          message = 'return-refund-rejected';
        }
  
        if (res?.returnRefundReject || (res?.returnRefundAccept)) {
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          return
        } else {
          this.handleRefundRequest(sensorInfo);
        }
      } else if (res?.refundRequest && product === 'IS') {
        const message = 'return-refund';
        const type = {msg: product}
        this.popupService.openPopup(CollectionPopupComponent, message, type, '40%');
        return
      } else {
        this.handleRefundRequest(sensorInfo);
      }
    });
  }
  
  handleRefundRequest(sensorInfo: any) {
    let scene = sensorInfo.scene;
    let taskId = sensorInfo.taskId;
    let sensorId = sensorInfo.sensorId;
    let sceneId = sensorInfo.sceneId;
    let product = sensorInfo.product;

    if (scene?.refundRequest || this.refundRequestScenes.includes(scene?.id) && product != 'IS') {
      const message = 'return-refund';
      const type = {msg: product}
      this.popupService.openPopup(CollectionPopupComponent, message, type, '40%');
      return
    } else {
      const dialog = this.matdialog.open(ReturnRefundComponent, {
        minWidth: '50%',
        data: {type: 'return', taskId: taskId, sensorId: sensorId, sceneId: sceneId,
          product: product, action: 'scene', feedId: sensorInfo?.feedId
        },
        backdropClass: 'blurred',
        disableClose: true
      });
  
      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          const message = 'return-refund-success';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.refundRequestScenes.push(localStorage.getItem('refund-request'));
        }
      });
    }
  }
  

  refundStatus(scene: any) {
    if (scene?.refundRequest && (scene?.returnRefundAccept || scene?.returnRefundReject)) {
      let message = 'return-refund-accepted';
      if (scene?.returnRefundReject) {
        message = 'return-refund-rejected';
      }
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    }
  }

  shouldDisplayScene(scene: any, collection: any, downloadedScene: any[], sensor: any): boolean {
    const isSceneValid = scene.id && scene.status !== 'failed';
    const isDownloaded = downloadedScene.includes(scene.id) || scene.downloaded;
    const isReturnRefund = collection?.returnRefund;
    const isNotArchive = sensor?.status != 'archived';
  
    return isSceneValid && isDownloaded && isReturnRefund && isNotArchive;
  }

  shouldNotDisplayScene(scene: any, collection: any, downloadedScene: any[], sensor: any): boolean {
    const hasValidIdAndStatus = scene.id && scene.status !== 'failed';
    const isNotDownloadedAndNotInDownloadedScenes = !scene.downloaded && !downloadedScene.includes(scene.id);
    const isArchived = sensor?.status === 'archived' && collection?.returnRefund;
  
    return (hasValidIdAndStatus && isNotDownloadedAndNotInDownloadedScenes) 
            || isArchived;
  }

  openRestoreRequest(i,j,sensor) {
    const sensors = this.collections[i].sensors[j];
    const taskId = this.collections[i]?.taskId;
    let sensorId: any;
    sensorId = sensors?.id;
    let statusObj = {taskId: taskId, sensorId: sensorId};

    this.apiService.restoreStatus(statusObj).subscribe(async (res: any) => {      
      if (res?.restoreStatus === 'Submit') {
        const message = 'restore';
        const type = {msg: 'restore-prerequested'}
        this.popupService.openPopup(CollectionPopupComponent, message, type, '40%');
        return
      } else if (res?.restoreStatus === 'Rejected') {
        const message = 'restore';
        const type = {msg: 'rejected', taskname: res?.taskname, reason: res?.reason}
        this.popupService.openPopup(CollectionPopupComponent, message, type, '40%');
      } else {
        const dialog = this.matdialog.open(ReturnRefundComponent, {
          minWidth: '50%',
          data: {type: 'restore'},
          backdropClass: 'blurred',
          disableClose: true 
        });
        dialog.afterClosed().subscribe((result) => {
          if (result != 'close') {
            const message = 'restore';
            const type = {msg: 'restore-success'}
            this.popupService.openPopup(CollectionPopupComponent, message, type, '40%');
            const restoreRequestReason = result.restoreReason;
            const restoreOption = result.restoreOption;
    
            const sensorObj = { taskId: taskId, sensorId: sensorId, restoreOption: restoreOption, restoreRequestReason: restoreRequestReason };
            this.apiService.restoreRequest(sensorObj).subscribe(async (res: any) => { });
            localStorage.setItem('restore-request', sensorId);
            this.restoreRequestedSensors.push(localStorage.getItem('restore-request'));
          }
          else {
            return
          }
        });
      }
    });
  }

  shouldDownload(collectionIndex,sensorIndex,scene: any,InfoService) {
   const selectedSceneIS = InfoService?.insightDatas?.filter(scene => scene.sceneId === this.sceneSelected);
   const isData = selectedSceneIS?.length > 0;
   const isSameSensor =  this.collections[collectionIndex]?.sensors[sensorIndex]?.id === InfoService.sensorId;
   const isLength = isData && selectedSceneIS[0]?.insightFootprint?.features?.length > 0;
   const selectedSensorIS = InfoService?.insightDatas?.length > 0;
   let isDownload: boolean = true;
   if (this.collections[collectionIndex]?.sensors[sensorIndex]?.operatorKey != OPERATORS.AXELSPACE) {
    isDownload = scene.id && scene?.status != 'failed' && !this.sensorsIS && isSameSensor && isData && !this.isVisible && isLength;
  } else {
     const isChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.checked;
     const allSceneChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.scenes.every(scene => scene.checked);
     isDownload = isChecked && allSceneChecked && selectedSensorIS;
   }
   
   return isDownload;
  }

  shouldNotDownload(collectionIndex,sensorIndex,scene: any,InfoService) {
    const selectedSceneIS = InfoService?.insightDatas?.filter(scene => scene.sceneId === this.sceneSelected);
    const isData = selectedSceneIS?.length > 0;
    const isSameSensor =  this.collections[collectionIndex]?.sensors[sensorIndex]?.id === InfoService.sensorId;
    const isLength = isData && (selectedSceneIS[0]?.insightFootprint?.features?.length === 0 || !selectedSceneIS[0]?.insightFootprint);
    const selectedSensorIS = InfoService?.insightDatas?.length > 0;
    
    let isDownload: boolean = false;
    if (this.collections[collectionIndex]?.sensors[sensorIndex]?.operatorKey != OPERATORS.AXELSPACE) {
      isDownload = !scene.id || scene?.status === 'failed' || (this.sensorsIS && isSameSensor) || !isData || this.isVisible || isLength
    } else {
      const isChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.checked;
      const allSceneChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.scenes.every(scene => scene.checked);
      isDownload = !isChecked || !allSceneChecked || !selectedSensorIS;
    }
    return isDownload;
   }

   zoomToLocation() {
    const mode = this.viewer.scene.mode;
    var entities = this.viewer.entities;
    var entityArray = entities.values;
    var myEntity = entityArray[0];      

    if (myEntity) {        
      for (var i = 0; i < entityArray.length; i++) {
        var entity = entityArray[i];
        if (entity.name === 'legend' && entity.billboard && mode != 2) {
          entity.billboard.verticalOrigin = new Cesium.ConstantProperty(Cesium.VerticalOrigin.BOTTOM);
          entity.billboard.horizontalOrigin = new Cesium.ConstantProperty(Cesium.HorizontalOrigin.LEFT);
          entity.billboard.disableDepthTestDistance = new Cesium.ConstantProperty(Number.POSITIVE_INFINITY);
      }
       if (entity.show && entity.name != 'legend') {
        this.viewer.flyTo(entity);
      }
      }
    } else {
      var dataSourceCollection = this.viewer.dataSources;
      var dataSource = dataSourceCollection.get(0);

      if (dataSource) {
        this.viewer.flyTo(dataSource);
      }
    }
  }
  
   clearBandLegends () {
    this.viewer.dataSources.removeAll(true);
    this.leftBand = false;
    this.rightBand = false;
    this.feedLoaded = false;
    if(this.legendContainer) this.legendContainer.style.display = 'none';
   }

   shouldDisableIS(collectionIndex,sensorIndex,scene: any,InfoService) {
    const selectedSceneIS = InfoService?.insightDatas?.filter(scene => scene.sceneId === this.sceneSelected);
    const isData = selectedSceneIS?.length > 0;
    const isSameSensor =  this.collections[collectionIndex]?.sensors[sensorIndex]?.id === InfoService.sensorId;
    const checkedSensors = this.collections[collectionIndex]?.sensors.filter(scene => scene.checked === true);
    let checkedInfo: boolean = true;

    if (checkedSensors?.length > 0) {
      checkedInfo = checkedSensors[0].id === InfoService.sensorId;
    }
    

    let isDisable: boolean = true;
    if (this.collections[collectionIndex]?.sensors[sensorIndex]?.operatorKey != OPERATORS.AXELSPACE) {
      isDisable = this.sensorsIS || !isSameSensor || !scene.id || scene?.status === 'failed' || !isData || !checkedInfo;
    } else {
      const isChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.checked;
      const allSceneChecked = this.collections[collectionIndex]?.sensors[sensorIndex]?.scenes.every(scene => scene.checked);
      isDisable = !isChecked || (isChecked && !allSceneChecked);
    }

    return isDisable;
   }

   getFeedInfo(info: any) {
    const checkedInfo = info.filter(data => data.checked === true);
    return checkedInfo.length > 0
   }

   openFilter() {
    let filteredFeeds: any = JSON.parse(JSON.stringify(this.displayedIS));
    const dialogRef = this.matdialog.open(FilterPopupComponent, {
      width: '35%', backdropClass: 'blurr',
      disableClose: true,
      data: {type:'vessel'}
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if (result) {
        if (this.displayedIS && this.displayedIS.insightFootprint.features) {
          if (result.isGreater) {
            filteredFeeds.insightFootprint.features = this.displayedIS.insightFootprint.features.filter(feature => {
              const length = parseFloat(feature.properties?.vessel_length);
              return !isNaN(length) && length > result.length;
            });
          } else {
            filteredFeeds.insightFootprint.features = this.displayedIS.insightFootprint.features.filter(feature => {
              const length = parseFloat(feature.properties?.vessel_length);
              return !isNaN(length) && length < result.length;
            });
          }
          
        if (filteredFeeds.insightFootprint.features.length === 0) {
          const message = `There are no vessels meeting the search criteria.`;
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        }
        }

        this.clearBandLegends();
        this.feedLoaded = true;
        if (filteredFeeds?.insightFootprint.features?.length > 0) {
        this.displayIS(filteredFeeds, filteredFeeds);
        }
      } else {
      }
    });
   }

   handleClick(entity: any, isHistorical: boolean,taskId: any,sensor: any) {
    this.clearSilder();
    this.dateRangeArray = [];
    let historicalFetchCount: any;
    let operator: any;
    let type;
    
    if(sensor.operatorKey === OPERATORS.SPIRE) {    
      operator = sensor.operatorKey;    
      historicalFetchCount = sensor?.updatedFetches == 0 ? 0 : sensor?.updatedFetches || sensor?.noOfHistoricalData;
      type = 'ais'
    }

    if (isHistorical && entity?.name === 'vessel') {
      if (historicalFetchCount > 0) {
        const dialogRef = this.matdialog.open(FilterPopupComponent, {
          width: '35%',
          backdropClass: 'blurr',
          disableClose: true,
          data: {type: type, historicalDays: historicalFetchCount}
        });
        dialogRef.afterClosed().subscribe((result) => {
          
          let timestamp = entity.properties['Timestamp UTC'].getValue();
          let mmsi = entity.properties['MMSI'].getValue();
          if (result) {
            if(operator == OPERATORS.SPIRE) { 
              let vesselObject = {
                mmsi: mmsi,
                endTime: timestamp,
                inputMinutes: result?.duration,
                eeTaskId: taskId
              }
              this.spinnerService.show();
              this.mapLoad = true;
              let endDate: any = new Date(timestamp)
              const millisecondsBack = result?.duration * 60 * 1000;
              const newDateObj = new Date(endDate - millisecondsBack);
              let startDate = newDateObj.toISOString();          
    
              this.apiService.getHistoricalData(vesselObject).subscribe((response: any) => {
                this.spinnerService.hide();
                this.mapLoad = false;
                if (response?.data?.length > 0) {
                  this.collection.sensors.map((sensor) => {
                    if (sensor.operatorKey === OPERATORS.SPIRE) {
                      if(sensor.updatedFetches) sensor.updatedFetches -= 1
                      else sensor.updatedFetches = sensor.noOfHistoricalData - 1;                    
                    }
                  })
                  this.historicalVesselData = response?.data;
                  let dateRangeArray = this.generateSliderDateRange(timestamp,startDate);
                  this.groupVesselsByDate(dateRangeArray);
                  this.sliderSelected = sensor.operatorKey;
                  this.sliderView = false;
                  this.hideVessels('spireDataSource',entity,false);
                } else {
                  const message = `There are no vessels in the duration requested. Please change the historical period to try again.`;
                  this.popupService.openPopup(
                    CollectionPopupComponent,
                    message,
                    '',
                    '40%'
                  );
                  this.resetPreviousEntity()
                }
              });
            }
          } else {
          }
        });
      } else if (sensor.operatorKey === OPERATORS.SPIRE) {
        const message = `Your historical fetches for this task has been exhausted. Please retask.`;
        this.popupService.openPopup(
          CollectionPopupComponent,
          message,
          '',
          '40%'
        );
        if (this.clickedEntity && this.clickedEntity?.name === 'vessel') {
          this.clickedEntity.billboard.width = 20;
          this.clickedEntity.billboard.height = 20;
        }
      }
    }
  }

  handleClydeClick(entity: any, isHistorical: boolean,taskId: any,sensor: any) {
    this.clearSilder();
    this.dateRangeArray = [];
    let historicalFetchCount: any;
    let operator: any;
    let type;
    
   if(sensor.operatorKey === OPERATORS.CLYDE) {   
      operator = sensor.operatorKey;     
      historicalFetchCount = sensor?.noOfHistoricalData;
      type = 'clyde'
    }

    if (isHistorical && entity?.name === 'vessel') {
      if (historicalFetchCount > 0) {
        const dialogRef = this.matdialog.open(FilterPopupComponent, {
          width: '35%',
          backdropClass: 'blurr',
          disableClose: true,
          data: {type: type, historicalDays: historicalFetchCount}
        });
        dialogRef.afterClosed().subscribe((result) => {
          
          let timestamp = entity.properties['Timestamp UTC'].getValue();
          let mmsi = entity.properties['MMSI'].getValue();
          if (result) {
            if(operator == OPERATORS.CLYDE) {  
              let endDate: any = new Date(timestamp);
              let startDate: any ;

              if (result?.type === 'minutes') {
                const millisecondsBack = result?.duration * 60 * 1000;
                const newDateObj = new Date(endDate - millisecondsBack);
                startDate = newDateObj.toISOString();
              } else {
                const previousDate = endDate;
                previousDate.setDate(endDate.getDate() - (result?.duration - 1));
                startDate = previousDate.toDateString();
              }
 
              let vesselObject = {
                mmsi: mmsi,
                endTime: timestamp,
                inputMinutes: result?.duration,
                eeTaskId: taskId,
                startTime: startDate,
                polygon: this.vesselPolygon,
                startTimeType: result?.type,
                duration: result?.duration
              }

              const sliderStart = new Date(timestamp);
              const sliderEnd = new Date(startDate);
              const differenceInMilliseconds = sliderStart.getTime() - sliderEnd.getTime();
              const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    
              this.apiService.getClydeHistoricalData(vesselObject).subscribe((response: any) => {
                this.spinnerService.hide();
                this.mapLoad = false;
                if (response?.data?.length > 0) {
                  this.historicalVesselData = response?.data;
                  let dateRangeArray = this.generateSliderDateRange(timestamp, startDate);
                  if (differenceInDays <= 10) this.groupVesselsByDate(dateRangeArray);
                  else this.groupVesselsByDateRange(dateRangeArray);
                  this.sliderView = false;
                  this.sliderSelected = sensor.operatorKey;
                  this.hideVessels('clydeDataSource',entity,false);
                } else {
                  const message = `There are no vessels in the duration requested. Please change the historical period to try again.`;
                  this.popupService.openPopup(
                    CollectionPopupComponent,
                    message,
                    '',
                    '40%'
                  );
                  this.resetPreviousEntity()
                }
              });
            }
          } else {
          }
        });
      }
    }
  }

  generateSliderDateRange(end,start) {
    this.dateRangeArray = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateArray: string[] = [];
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    if(differenceInDays <= 10) {
      const normalizedStartDate = new Date(startDate.toISOString().split('T')[0]);
      const normalizedEndDate = new Date(endDate.toISOString().split('T')[0]);

      for (let dt = normalizedStartDate; dt <= normalizedEndDate; dt.setDate(dt.getDate() + 1)) {
        dateArray.push(new Date(dt).toISOString().split('T')[0]);
      }
    } else {
      const step = differenceInDays / 9;
      for (let i = 0; i < 10; i++) {
        const newDate = new Date(startDate.getTime() + step * i * (1000 * 60 * 60 * 24));
        dateArray.push(newDate.toISOString().split('T')[0]);
      }
    }

    return dateArray;
  }

  groupVesselsByDate(dateRangeArray) {
    this.vesselData = {};
    this.dateRangeArray = dateRangeArray.reverse();
    this.sliderShow = true;
    for (const date of this.dateRangeArray) {
      this.vesselData[date] = this.historicalVesselData.filter(data => {
        const dataDate = new Date(data.timestamp).toISOString().split('T')[0];
        return dataDate === date;
      });
    }
  }

  groupVesselsByDateRange(dateRangeArray: string[]) {
    this.vesselData = {};
    this.sliderShow = true;

    for (let i = 0; i < dateRangeArray.length - 1; i++) {
      const startDate = new Date(dateRangeArray[i]);
      const endDate = new Date(dateRangeArray[i + 1]);

      this.vesselData[dateRangeArray[i]] = this.historicalVesselData.filter(data => {
        const dataDate = new Date(data.timestamp);
        return dataDate >= startDate && dataDate < endDate;
      });
    }

    // Handle the last date separately to include any remaining data
    const lastDate = new Date(dateRangeArray[dateRangeArray.length - 1]);
    this.vesselData[dateRangeArray[dateRangeArray.length - 1]] = this.historicalVesselData.filter(data => {
      const dataDate = new Date(data.timestamp);
      return dataDate >= lastDate;
    });
    
    this.dateRangeArray = dateRangeArray.reverse();

  }

  getSelectedDateVessels(event: MatSliderChange): void {
    const index = event.value;
    if (index !== null && index !== undefined) { 
      let currentIndex = index - 1;
      if (currentIndex > this.previousIndex) {
        // Moving forward
        for (let i = this.previousIndex + 1; i <= currentIndex; i++) {
          const date = this.dateRangeArray[i];
          let convertedVessels = this.convertToGeoJSONData(this.vesselData[date]);
          this.displayHistoricalData(date, convertedVessels);
        }
      } else {
        // Moving backward
        for (let i = this.previousIndex; i > currentIndex; i--) {
          const date = this.dateRangeArray[i];
          this.removeHistoricalData(date);
        }
      }
      this.previousIndex = currentIndex;
    }
  }

  displayHistoricalData(date,vesselData: any) {
    const selectData = Cesium.GeoJsonDataSource.load(vesselData);
    const currentCrossImage = 'assets/images/green-cross.png';
    selectData.then((dataSource) => {
      const entities = dataSource.entities.values;
      for (let j = 0; j < entities.length; j++) {
        const entity = entities[j] as CustomEntity;
        entity.billboard = new Cesium.BillboardGraphics({
          image: currentCrossImage,
          show: true,
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO,
          width: 20,
          height: 20,
        });
        entity.isOriginal = false;
      }

      this.viewer.dataSources.add(dataSource);
      this.currentDataSources[date] = dataSource; 
    });
  }

  removeHistoricalData(date: string): void {
    const dataSource = this.currentDataSources[date];
    if (dataSource) {
      this.viewer.dataSources.remove(dataSource);
      delete this.currentDataSources[date];
    }
  }

  convertToGeoJSONData(vesselsData) { 
    if(this.sliderSelected == OPERATORS.SPIRE) {
      const features = vesselsData.map((vessel) => ({
        type: 'Feature',
        id: vessel.id,
        geometry: {
          type: 'Point',
          coordinates: [
            vessel.longitude,
            vessel.latitude,
          ],
        },
        properties: {
          ID: vessel.id,
          MMSI: vessel.mmsi,
          Name : vessel?.name ? vessel.name : 'N/A',
          'Timestamp UTC': vessel.timestamp,
          Lng: vessel.longitude.toString(),
          Lat: vessel.latitude.toString(),
          Flag: vessel?.flag,
          'Ship Type': vessel?.shipType ? vessel.shipType : 'N/A',
          IMO: vessel?.staticData?.imo,
          'ETA UTC': vessel?.eta ? vessel.eta : 'N/A',
          'Port Name': vessel?.port?.name ? vessel.port.name : 'N/A',
           Speed: vessel?.speed
        },
      }));
  
      return {
        type: 'FeatureCollection',
        features: features
    };
    } else {
      const features = vesselsData.map((vessel) => ({       
        type: 'Feature',
        id: vessel.id,
        geometry: {
          type: 'Point',
          coordinates: [
            vessel.location.longitude,
            vessel.location.latitude,
          ],
        },
        properties: {
          ID: vessel.id,
          MMSI: vessel.mmsi,
          'Timestamp UTC': vessel.timestamp,
          Lng: vessel.location.longitude.toString(),
          Lat: vessel.location.latitude.toString(),
        },
      }));
  
      return {
        type: 'FeatureCollection',
        features: features
    };
    }

}

resetPreviousEntity() {
  if (this.clickedEntity && this.clickedEntity?.name === 'vessel') {
    this.clickedEntity.billboard.width = 20;
    this.clickedEntity.billboard.height = 20;
  }

  if(this.timeseries) {
    this.sliderView = true;
    this.sliderShow = false;    
    this.removeImages();
    this.clearBandLegends();
    this.viewer.entities.removeAll();
    const slider: any = document.querySelector('#slider');
    if (slider) {
      slider.value = this.currentSliderValue;
    }
    this.overlayData(this.currentIndex + 1, 'reset');
  }
}

clearSilder() {
  this.sliderShow = false;
  this.hideVessels('spireDataSource','',true);
  this.hideVessels('clydeDataSource','',true);
  for (const date in this.currentDataSources) {
    if (this.currentDataSources.hasOwnProperty(date)) {
      this.removeHistoricalData(date);
    }
  }
}

highlightEntity(entity) {
  if (entity?.name === 'vessel') {
  entity.billboard.width = 35;
  entity.billboard.height = 35;
  }
}

resetEntity(entity) {
  if (entity?.name === 'vessel') {
  entity.billboard.width = 20;
  entity.billboard.height = 20;
  this.clearSilder();
}
}

  clickSliderView(event: any) {
    for (const [sensorIndex, sensor] of this.collection.sensors.entries()) {
      sensor.checked = false;
      for (const [sceneIndex, scene] of sensor.scenes.entries()) {
        scene.checked = false;
      }
    }

    this.sliderView = event.checked;
    this.timeseries = event.checked;
    this.sliderShow = false;
    this.sliderDateRangeArray = [];
    this.clearBandLegends();
    this.removeImages();
    this.viewer.entities.removeAll();
    this.isVisible = false;
    this.splitPosition = 0.0;
    this.overlayVessel = [];
    this.operatorArray = [];
    this.overlayClydeVessel = [];
    this.currentSliderValue = 0;
    this.showDailyRecurrencyCheckboxes = false;
    this.sourceRateArray = [];
    this.clearAnnotations();
    this.imageLoaded = false;
    if (!event.checked) {
      this.showDailyRecurrencyCheckboxes = true;
      this.toggleDailyRecurrency(event);
    }
  }

  getDateArray(start: string, end: string): string[] {
    const dateArray: string[] = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  overlayData(event: any, type: any) {
    const index = event;
    let sceneId: any;
    let sensorId: any;
    let action: any;
    const taskId = this.collection?.taskId;
    let isHistorical: any, scene: any;

    if (index !== null && index !== undefined) {
      this.currentIndex = index - 1;
      this.currentSliderValue = index;
      if (this.currentIndex > this.previousIndex) {
        for (const sensor of this.collection.sensors) {
          if (sensor.operatorKey === OPERATORS.SPIRE && sensor.checked) {
            isHistorical = sensor?.isHistorical;
            scene = sensor.scenes[0];
            this.overlayAIS(scene,type,isHistorical,sensor);
          } else if (sensor.operatorKey === OPERATORS.CLYDE && sensor.checked) {
            isHistorical = sensor?.isHistorical;
            scene = sensor.scenes[0];
            this.overlayAIS(scene,type,isHistorical,sensor);
          }
        }


        // Moving forward
        const date = this.sliderDateRangeArray[this.currentIndex];
        const selectedScenes = this.groupedScenes[date];

        if (selectedScenes?.length > 0) {
          for (const [index, scn] of selectedScenes.entries()) {
            if (scn?.id && scn?.status !== 'failed') {
              if (scn.footprint) {
                sensorId = 'this.selectedSensors[j]?.id';
                action = "scene"
                sceneId = scn?.id;
                this.spinnerService.show();
                this.mapLoad = true;
                const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
                this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
                  if (res?.msg === 'Failed') {
                    this.spinnerService.hide();
                    this.mapLoad = false;
                    const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                    this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                  } else if (res?.msg === 'user storage') {
                    this.fail = this.fail + 1;
                    if (this.fail === 1) {
                      const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                    }
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  }
                  if (scn?.thumbnailUrl) {
                    scn.thumbnailUrl = res.result?.imageUrl;
                  }
                  scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
                  this.displayFootPrint(scn, 0, res.result,sensorId,taskId);
                  this.spinnerService.hide();
                  this.mapLoad = false;
                });
              }
            }

          }
        }
        
        if (selectedScenes?.length === 0) {
          this.removeImages();
        }
      } else {
        // Moving backward
        for (let i = this.previousIndex; i > this.currentIndex; i--) {
          const date = this.sliderDateRangeArray[i];
          this.removeImages();
          if (this.overlayVessel) {
            const date = this.sliderDateRangeArray[i];
            const overlayData = this.currentVessels[date];
            if (overlayData) {
              this.viewer.dataSources.remove(overlayData);
              delete this.currentVessels[date];
            }
          }

          if (this.overlayClydeVessel) {
            const date = this.sliderDateRangeArray[i];
            const overlayData = this.currentClydeVessels[date];
            if (overlayData) {
              this.viewer.dataSources.remove(overlayData);
              delete this.currentClydeVessels[date];
            }
          }
        }
      }
      this.previousIndex = this.currentIndex;
    }
  }

  overlayAIS(scene: any,type: any, isHistorical: any,sensor: any ) {
    let  USLcoverage: any, BBpoints: any;
    const date = this.sliderDateRangeArray[this.currentIndex];

    if ((sensor.operatorKey === OPERATORS.SPIRE || sensor.operatorKey === OPERATORS.CLYDE) && scene?.footprint) {
      USLcoverage = scene?.footprint?.coordinates[0]
      BBpoints = USLcoverage.toString().split(',').map(Number);

      this.uslBB = this.viewer.entities.add({
        name: 'spireBB',
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(BBpoints),
          clampToGround: true,
          material: new Cesium.PolylineOutlineMaterialProperty({
            outlineWidth: 3,
            outlineColor: Cesium.Color.fromRandom({
              alpha: 1.0,
            }),
          }),
        },
      });
      this.uslBB.index = scene?.sceneIdx;
      this.USLscnLyr.push(this.uslBB);
    }
    if (type === 'overlay') {      
      if ((sensor?.operatorKey === OPERATORS.SPIRE) && this.overlayVessel && this.overlay) {            
        for (let i = this.previousIndex + 1; i <= this.currentIndex; i++) {
          const date = this.sliderDateRangeArray[i];
          if (this.overlayVessel[date]?.length > 0) {
            this.displayAISData(date, this.overlayVessel[date], isHistorical,sensor);
          }
        }
      } else if ((sensor?.operatorKey === OPERATORS.CLYDE) && this.overlayClydeVessel) {    
        for (let i = this.previousIndex + 1; i <= this.currentIndex; i++) {
          const date = this.sliderDateRangeArray[i];
          if (this.overlayClydeVessel[date]?.length > 0) {
            this.displayClydeData(date, this.overlayClydeVessel[date], isHistorical,sensor);
          }
        }
      }
    } else {
      if (sensor?.operatorKey === OPERATORS.SPIRE) {
        for (let i = 0; i <= this.currentIndex; i++) {
          const date = this.sliderDateRangeArray[i];
          this.displayAISData(date, this.overlayVessel[date], isHistorical,sensor);
         }
      } else if (sensor?.operatorKey === OPERATORS.CLYDE) {
        for (let i = 0; i <= this.currentIndex; i++) {
          const date = this.sliderDateRangeArray[i];
          this.displayClydeData(date, this.overlayClydeVessel[date], isHistorical,sensor);
         }
      }
    }
  }

  groupCollections() {
    const groupedScenes = {};
    this.groupedScenes = {};
    this.sliderDateRangeArray = [];
    this.AISdata = false;
    this.recurrentSlider = false;
    let startDate = this.collection?.openWindow;
    let endDate = this.collection?.closeWindow;

    if (this.filterStart && this.filterEnd) {
     startDate = this.filterStart;
     endDate = this.filterEnd;
    }

    let dateRangeArray = this.getDateArray(startDate, endDate);
    this.sliderDateRangeArray = dateRangeArray;

    for (const date of this.sliderDateRangeArray) {
      groupedScenes[date] = [];
    }

    for (const sensor of this.collection.sensors) {
      if (sensor.checked) {
        this.sliderView = true;
        if (sensor.scenes[0].operatorKey === OPERATORS.SPIRE || sensor.scenes[0].operatorKey === OPERATORS.CLYDE) {
          this.getAISData(sensor.scenes[0], "display",sensor);
        } else {
        for (const scene of sensor.scenes) {
          const sceneDate = new Date(scene.predictedDate).toISOString().split('T')[0];
          if (groupedScenes.hasOwnProperty(sceneDate)) {
            groupedScenes[sceneDate].push(scene);
            this.AISdata = true;
          }
        }
      }
      }
    }

    return groupedScenes;
  }

  groupRecurrencyCollections(recurrencyArray) {
    let siteId = this.currentSites?.siteId ? this.currentSites?.siteId : 1;
    if (siteId) {
      const filteredRecurrency = recurrencyArray.map(item => {
        return {
          ...item,
          scenes: item.scenes.filter(scene => !scene.siteId || scene.siteId === siteId)
        };
      });

      recurrencyArray = filteredRecurrency;
    }

    this.recurrentSlider = true;
    let sliderDates: any;
    sliderDates = this.createSliderData(recurrencyArray);
    const groupedScenes = {};
    this.groupedScenes = {};
    this.sliderDateRangeArray = [];
    this.sliderDateRangeArray = sliderDates;    

    for (const range of this.sliderDateRangeArray) {
      const key = `${range.start} to ${range.end}`;
      groupedScenes[key] = [];
    }

    for (const sensor of recurrencyArray) {        
        for (const scene of sensor.scenes) {
          const sceneDate = scene.predicted_date.split('T')[0];

          for (const range of this.sliderDateRangeArray) {
            if (sceneDate >= range.start && sceneDate <= range.end) {
              const key = `${range.start} to ${range.end}`;
              groupedScenes[key].push(scene);
              break;
            }
          }
        }
    }

    return groupedScenes;
  }

  createSliderData(data: any[]): { start: string, end: string }[] {
    return data.map(item => ({
      start: item.start.split('T')[0],
      end: item.end.split('T')[0]
    }));
  }
  
  overlayRecurrentData(event: any, type: any) {
    const index = event;
    let sceneId: any;
    let sensorId: any;
    let action: any;
    const taskId = this.collection?.taskId;

    if(this.legendContainer && index === 0) this.legendContainer.style.display = 'none';
    
    if (index !== null && index !== undefined) {
      this.currentIndex = index - 1;
      this.currentSliderValue = index;
      if (this.currentIndex > this.previousIndex) {
        // Moving forward
        const selectedRange = this.sliderDateRangeArray[this.currentIndex];
        const rangeKey = `${selectedRange.start} to ${selectedRange.end}`;
        const selectedScenes = this.groupedScenes[rangeKey];

        if (selectedScenes?.length > 0) {
          const promises: Promise<void>[] = [];

          for (const [index, scn] of selectedScenes.entries()) {
            if (scn?.id && scn?.status !== 'failed') {
              if (scn.footprint) {
                sensorId = this.selectedSensors[0]?.id;
                action = "scene"
                sceneId = scn?.id;
                this.spinnerService.show();
                this.mapLoad = true;
                const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
                const apiPromise = firstValueFrom(this.apiService.getCollectionImage(taskId, sensorObj))
                .then((res: any) => {
                  if (res?.msg === 'Failed') {
                    this.spinnerService.hide();
                    this.mapLoad = false;
                    const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                    this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                  } else if (res?.msg === 'user storage') {
                    this.fail = this.fail + 1;
                    if (this.fail === 1) {
                      const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                    }
                    this.spinnerService.hide();
                    this.mapLoad = false;
                  }
                  if (scn?.thumbnailUrl) {
                    scn.thumbnailUrl = res.result?.imageUrl;
                  }
                  scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
                  this.displayFootPrint(scn, 0, res.result,sensorId,taskId);
                  this.spinnerService.hide();
                  this.mapLoad = false;
                });

                promises.push(apiPromise);
              }
            }

          }

          Promise.all(promises).then(() => {
            if (this.selectedMetric === 'kg') {
              const entities = this.viewer.entities.values;
              for (const entity of entities) {
                if (entity?.name === 'trendline') {
                  this.viewer.entities.remove(entity);
                }
              }
              // Add multiple polyline entities, each connecting two consecutive points
              for (let i = 0; i < this.sourceRateArray.length - 1; i++) {
                const polylineEntity = this.viewer.entities.add({
                  name: 'trendline',
                  polyline: {
                    positions: [this.sourceRateArray[i].centroid, this.sourceRateArray[i + 1].centroid],
                    material: new Cesium.PolylineOutlineMaterialProperty({
                      outlineWidth: 5,
                      outlineColor: Cesium.Color.GREENYELLOW,
                      color: Cesium.Color.GREENYELLOW
                    }),
                    // material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.GREENYELLOW)
                  }
                });
                polylineEntity.sceneId = this.sourceRateArray[i + 1].sceneId;
              }
            }
          });
        }
      } else {
        // Moving backward
        for (let i = this.previousIndex; i > this.currentIndex; i--) {          
          const dateRange = this.sliderDateRangeArray[i];
          const rangeKey = `${dateRange.start} to ${dateRange.end}`;          
          this.removeImagesByDateRange(rangeKey);
        }
      }
      this.previousIndex = this.currentIndex;
    }
  }

  removeImagesByDateRange(rangeKey: string): void {
    const allImageryLayers = this.viewer.imageryLayers._layers;
    const entities = this.groupedScenes[rangeKey];

    for (let i = allImageryLayers.length - 1; i >= 0; i--) {
      const layer = allImageryLayers[i];
      const layerUrl = layer.imageryProvider?._source?.source?.source?.client?.url || layer.imageryProvider?.url;

      if (layerUrl) {
        for (const entity of entities) {
          const thumbnailUrl = entity?.thumbnailUrl;

          if (thumbnailUrl && layerUrl === thumbnailUrl) {
            this.viewer.imageryLayers.remove(layer);
          }
        }
      }
    }

    const entityData = this.groupedScenes[rangeKey];
    const polyLineEntities = this.viewer.entities.values;

    for (let indexToRemoveEntity = this.scnLyr?.length - 1; indexToRemoveEntity >= 0; indexToRemoveEntity--) {
      const item = this.scnLyr[indexToRemoveEntity];

      for (const entity of entityData) {
        if (item.sceneId === entity?.id) {
          this.scnLyr[indexToRemoveEntity].show = false;
          this.scnLyr.splice(indexToRemoveEntity, 1);
        }

        for (let i = polyLineEntities.length - 1; i >= 0; i--) {
          const polyLineEntity = polyLineEntities[i];

          if (polyLineEntity?.sceneId === entity?.id && polyLineEntity?.name === 'trendline') {
            this.viewer.entities.remove(polyLineEntity);
          }
        }

        for (let i = this.sourceRateArray.length - 1; i >= 0; i--) {
          if (this.sourceRateArray[i].sceneId === entity?.id) {
            this.sourceRateArray.splice(i, 1);
          }
        }
      }
    }
  }

  getAISData(scene: any, type: any, sensor: any) {    
    let refreshCount;
    let timeExpired;
    let sensorId;
    let taskId;

    if(sensor.operatorKey === OPERATORS.SPIRE) {        
      sensorId = sensor.id;
      timeExpired = sensor.timeExpired;
      refreshCount = sensor.updatedRefreshes == 0 ? 0 : sensor.updatedRefreshes || sensor.noOfRefreshes;
    } else if(sensor.operatorKey === OPERATORS.CLYDE) {  
      sensorId = sensor.id;
      timeExpired = sensor.timeExpired;
    }

    if(!scene.aisAvailable) {
      const message = "AIS data is not available until the start of the time of interest (TOI).";
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      for (const item of this.collection.sensors) {
        if (item.id === sensor.id) {
          item.checked = false;
        }
      }
      return
    }

    else if(timeExpired && sensor.operatorKey === OPERATORS.SPIRE) {
      const message = 'The time of interest (TOI), along with the seven days of access to the historical data, has ended. Please re-task if the area of interest (AOI) continues to be the same.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      for (const item of this.collection.sensors) {
        if (item.id === sensor.id) {
          item.checked = false;
        }
      }
    }

    else if(refreshCount > 0 && sensor.operatorKey === OPERATORS.SPIRE) {
      const coordinates = scene?.footprint?.coordinates[0]
      taskId = this.collection?.taskId;
  
      let newCoordinates = coordinates.map(coord => {
        return [coord[0], coord[1]];
      });
  
      newCoordinates = [newCoordinates];
      newCoordinates = JSON.stringify(newCoordinates);
  
      const encodedCoordinates = encodeURIComponent(newCoordinates);
      const startTime = encodeURIComponent(scene.openWindow)
      const endTime = encodeURIComponent(scene.closeWindow)
  
  
      const aisReq = {
        polygon: encodedCoordinates,
        startTime: startTime,
        endTime: endTime,
        type: type
      }

      this.spinnerService.show();
      this.mapLoad = true;
  
      this.apiService.getAISData(aisReq, taskId).subscribe((res: any) => {
        if (res?.msg === 'failed') {
          this.spinnerService.hide();
          this.mapLoad = false;
          this.freeze = false;
          this.disable = false;
          const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
          this.downloadService.setProgress(sensorObj);
          const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          for (const item of this.collection.sensors) {
            if (item.id === sensor.id) {
              item.checked = false;
            }
          }
        } else if (res?.msg === 'success') {
          if(res?.data?.vessels?.nodes?.length === 0) {
            this.spinnerService.hide();
            this.mapLoad = false;
            this.freeze = false;
            this.disable = false;
            const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
            this.downloadService.setProgress(sensorObj);           
            const message = 'There is no AIS data in the area tasked. If there were to be vessels during the time of interest (TOI), those observations would become available when refreshed.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            
            for (const item of this.collection.sensors) {
              if (item.id === sensor.id) {
                item.checked = false;
              }
            }
          } else {
            this.groupVessels(res?.data,sensor);
            this.collection.sensors.map((sensor) => {
              if (sensor.operatorKey === OPERATORS.SPIRE) {
                if(sensor.updatedRefreshes) sensor.updatedRefreshes -= 1
                else sensor.updatedRefreshes = sensor.noOfRefreshes - 1

                  if (!sensor.refreshHistory) {
                    sensor.refreshHistory = []; // Initialize if not present
                  }
                  sensor.refreshHistory.push(res.newRefreshHistory);
              }
            });
            this.AISdata = true;
          }
        }
      });
    } else if(sensor.operatorKey === OPERATORS.CLYDE) {
      const coordinates = scene?.footprint?.coordinates[0]
      taskId = this.collection?.taskId;
  
      let newCoordinates = coordinates.map(coord => {
        return [coord[1], coord[0]];
      });
  
      newCoordinates = [newCoordinates];
      newCoordinates = JSON.stringify(newCoordinates);
  
      const encodedCoordinates = encodeURIComponent(newCoordinates);
      const startTime = encodeURIComponent(scene.openWindow)
      const endTime = encodeURIComponent(scene.closeWindow)
  
  
      const aisReq = {
        polygon: encodedCoordinates,
        startTime: startTime,
        endTime: endTime,
        type: type
      }

      this.spinnerService.show();
      this.mapLoad = true;

      if(timeExpired) {
        const message = 'The time of interest (TOI) has ended. Please re-task if the area of interest (AOI) continues to be the same.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        this.spinnerService.hide();
        this.mapLoad = false;
        this.freeze = false;
        for (const item of this.collection.sensors) {
          if (item.id === sensor.id) {
            item.checked = false;
          }
        }
        return
      }
      
        const input = JSON.parse(decodeURIComponent(aisReq.polygon)); 
        this.vesselPolygon = input[0]
          .map(pair => pair.reverse().join(' '))
          .join(', '); 
        aisReq.polygon = this.vesselPolygon  
  
        this.apiService.getClydeAISData(aisReq, taskId).subscribe((res: any) => {           

        if (res?.msg === 'failed') {
          const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.spinnerService.hide();
          this.mapLoad = false;
          this.freeze = false;
          this.disable = false;
          const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
          this.downloadService.setProgress(sensorObj);
          for (const item of this.collection.sensors) {
            if (item.id === sensor.id) {
              item.checked = false;
            }
          }
        } else if (res?.msg === 'Success') {
          if(res?.data?.length === 0) {
            this.spinnerService.hide();
            this.mapLoad = false;
            this.freeze = false;
            this.disable = false;
            const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
            this.downloadService.setProgress(sensorObj);
            const message = 'There is no AIS data in the area tasked. If there were to be vessels during the time of interest (TOI), those observations would become available when refreshed.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            
            for (const item of this.collection.sensors) {
              if (item.id === sensor.id) {
                item.checked = false;
              }
            }
          } else {
            for (const item of this.collection.sensors) {
              if (item.id === sensor.id) {
                if (!sensor.refreshHistory) {
                  sensor.refreshHistory = []; // Initialize if not present
                }
                sensor.refreshHistory.push(res.newRefreshHistory);
              }
            }
                      
            this.groupVessels(res?.data,sensor);
            this.AISdata = true;
          }
        }
        });  
  } else if(timeExpired) {
      const message = 'The time of interest (TOI), along with the seven days of access to the historical data, has ended. Please re-task if the area of interest (AOI) continues to be the same.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      for (const item of this.collection.sensors) {
        if (item.id === sensor.id) {
          item.checked = false;
        }
      }
    } else {
      const message = 'Your refresh count has been exhausted. Please re-task to continue receiving latest AIS information.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      for (const item of this.collection.sensors) {
        if (item.id === sensor.id) {
          item.checked = false;
        }
      }
    }
  }

  displayAISData(date:any,convertedData: any,isHistorical: any,sensor: any) {
    let taskId: any, type: any, imgUrl: any;
    taskId = this.collection?.taskId;
    type = 'overlay';
    imgUrl = 'assets/images/yellow-cross.png';  
    const updatedAis = this.convertToGeoJSON(convertedData,type);
      const selectData = Cesium.GeoJsonDataSource.load(updatedAis);
      const currentCrossImage = imgUrl;
      selectData.then((dataSource) => {
        dataSource.name = 'spireDataSource';
        const entities = dataSource.entities.values;
        for (let j = 0; j < entities.length; j++) {
          const entity = entities[j] as CustomEntity;
          entity.name = 'vessel';
          entity.type = 'spire';
          entity.billboard = new Cesium.BillboardGraphics({
            image: currentCrossImage,
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 20,
          });
        }

        this.viewer.dataSources.add(dataSource);
        this.viewer.scene.globe.depthTestAgainstTerrain = false;
        this.viewer.flyTo(dataSource, { duration: 3 });
        this.currentVessels[date] = dataSource; 
      });

      let tooltip = document.getElementById('feedTooltip');
      if (this.handler) {
        this.handler.destroy();
      }
      // Add click event listener
      let previousEntity = null;
      this.handler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

      this.handler.setInputAction((click) => {
        if (isHistorical) {
          const pickedObject = this.viewer.scene.pick(click.position);
          if (Cesium.defined(pickedObject)) {
            const entity = pickedObject.id;
            if (entity && entity.isOriginal !== false && entity?.type === 'spire') {
              if (previousEntity) {
                this.resetEntity(previousEntity);
              }
              this.previousIndex = -1;
              this.handleClick(entity,isHistorical,taskId,sensor);
              this.highlightEntity(entity);
              this.clickedEntity = entity;
              previousEntity = entity;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      this.displayProperties('')
  }

  displayClydeData(date:any,convertedData: any,isHistorical: any,sensor: any) {
    let taskId: any, type: any, imgUrl: any;
    taskId = this.collection?.taskId;
    type = 'CLYDE';
    imgUrl = 'assets/images/orange-cross.png';  
    const updatedAis = this.convertToGeoJSON(convertedData,type);
      const selectData = Cesium.GeoJsonDataSource.load(updatedAis);
      const currentCrossImage = imgUrl;
      selectData.then((dataSource) => {
        dataSource.name = 'clydeDataSource';
        const entities = dataSource.entities.values;
        for (let j = 0; j < entities.length; j++) {
          const entity = entities[j] as CustomEntity;
          entity.name = 'vessel';
          entity.type = 'clyde';
          entity.billboard = new Cesium.BillboardGraphics({
            image: currentCrossImage,
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 20,
          });
        }

        this.viewer.dataSources.add(dataSource);
        this.viewer.scene.globe.depthTestAgainstTerrain = false;
        this.viewer.flyTo(dataSource, { duration: 3 });
        this.currentClydeVessels[date] = dataSource; 
      });

      let tooltip = document.getElementById('feedTooltip');
      if (this.handlers) {
        this.handlers.destroy();
      }
      // Add click event listener
      let previousEntity = null;
      this.handlers = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

      this.handlers.setInputAction((click) => {        
        if (isHistorical) {
          const pickedObject = this.viewer.scene.pick(click.position);
          if (Cesium.defined(pickedObject)) {
            const entity = pickedObject.id;
            if (entity && entity.isOriginal !== false && entity?.type === 'clyde') {
              if (previousEntity) {
                this.resetEntity(previousEntity);
              }
              this.previousIndex = -1;
              this.handleClydeClick(entity,isHistorical,taskId,sensor);
              this.highlightEntity(entity);
              this.clickedEntity = entity;
              previousEntity = entity;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // Add hover event listener for tooltip
      this.handlers.setInputAction((movement) => {
        const pickedObject = this.viewer.scene.pick(movement.endPosition);
        const entity = pickedObject?.id;
        if (entity && entity.properties && entity?.name != 'annotation' && entity?.name != 'signal') {
          if (tooltip) {                    
          let content = '';
          entity.properties.propertyNames.forEach(propertyName => {                      
            content += `<strong>${propertyName} : </strong> ${entity.properties[propertyName]}<br>`;
          });
          tooltip.innerHTML = content;
          tooltip.style.left = movement.endPosition.x + 10 + 'px';
          tooltip.style.top = movement.endPosition.y + 10 + 'px';
          tooltip.style.fontSize = '15px';
          tooltip.style.fontFamily = 'Barlow", sans-serif';
          tooltip.style.lineHeight = '1.8';
          tooltip.style.display = 'block';
        }
      } else if (tooltip) {
        tooltip.style.display = 'none';
      }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  removeImages() {
    let entitiesToRemove: any = [];
    this.sourceRateArray = [];

    this.viewer.entities.values.forEach((entity) => {
      if (entity.name === undefined || entity.name !== 'spireBB') {
        entitiesToRemove.push(entity);
      }
    });

    entitiesToRemove.forEach((entity) => {
      this.viewer.entities.remove(entity);
    });
    
    var allImageryLayers = this.viewer.imageryLayers._layers;
    var leftImageryLayers: any = [];
    for (var index = 1; index < allImageryLayers.length; index++) {
      var url = allImageryLayers[index].imageryProvider?._source?.source?.source?.client?.url ? allImageryLayers[index].imageryProvider?._source.source.source.client.url : allImageryLayers[index]?.imageryProvider?.url
      if (url !== undefined) {
        leftImageryLayers.push(allImageryLayers[index]);
      }
    }

    for (var leftImageryLayersIndex = 0; leftImageryLayersIndex < leftImageryLayers.length; leftImageryLayersIndex++) {
      this.viewer.imageryLayers.remove(leftImageryLayers[leftImageryLayersIndex]);
    }
  }

  groupVessels(responseData: any, sensor: any) {
    let vessels: any;

    if (sensor.operatorKey === OPERATORS.SPIRE) {
      this.overlayVessel = {};
      vessels = responseData?.vessels?.nodes;
      for (const date of this.sliderDateRangeArray) {      
        this.overlayVessel[date] = vessels.filter(data => {
          const dataDate = new Date(data.lastPositionUpdate.timestamp).toISOString().split('T')[0];
          return dataDate === date;
        });      
      }
    } 
    else if (sensor.operatorKey === OPERATORS.CLYDE) {
      this.overlayClydeVessel = {};
      vessels = responseData;
      for (const date of this.sliderDateRangeArray) {      
        this.overlayClydeVessel[date] = vessels.filter(data => {
          const dataDate = new Date(data.timestamp).toISOString().split('T')[0];
          return dataDate === date;
        });      
      }
    }
    this.spinnerService.hide();
    this.mapLoad = false;  
  }

  removeDataSourceBySensorId(sensorId: any) {    
    const dataSources = this.viewer.dataSources;
    for (let i = 0; i < dataSources.length; i++) {
      const dataSource = dataSources.get(i) as CustomGeoJsonDataSource;
      if (dataSource.sensorId === sensorId) {
        dataSources.remove(dataSource, true);
        break;
      }
    }

    this.viewer.entities.values.forEach((entity) => {      
      if (entity.sensorId === sensorId) {
        this.viewer.entities.remove(entity);
      }
    });
  }
  resetBackgroundWithChangeInBaseLayer(){
    let imageryLayers = this.viewer.imageryLayers;

    // Get the number of layers
    let numLayers = imageryLayers.length;

    // Remove all layers except the first one (index 0)
    for (let i = numLayers - 1; i > 0; i--) {
        let layer = imageryLayers.get(i);
        imageryLayers.remove(layer);
    }
  }

  selectMetric(unit: string) {
    this.selectedMetric = unit;
    const index = event;
    let sceneId: any;
    let sensorId: any;
    let action: any;
    const taskId = this.collection?.taskId;
    this.removeImages();
    this.viewer.entities.removeAll();
    if (this.slider) this.slider.value = 0;
    this.currentSliderValue = 0;
    this.previousIndex = -1;
    this.clearAnnotations();
    if(this.legendContainer) this.legendContainer.style.display = 'none';

    for (const sensor of this.collection.sensors) {
      for (const [index, scn] of sensor.scenes.entries()) {
        if (scn?.id && scn?.status !== 'failed' && scn.checked) {
          if (scn.footprint) {
            sensorId = this.selectedSensors[0]?.id;
            action = "scene"
            sceneId = scn?.id;
            this.spinnerService.show();
            this.mapLoad = true;
            const sensorObj = { sensorId: sensorId, sceneId: sceneId, action: action, type: this.imageType };
            this.apiService.getCollectionImage(taskId, sensorObj).subscribe((res: any) => {
              if (res?.msg === 'Failed') {
                this.spinnerService.hide();
                this.mapLoad = false;
                const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
                this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              } else if (res?.msg === 'user storage') {
                this.fail = this.fail + 1;
                if (this.fail === 1) {
                  const message = 'We cannot display or download the image as the delivery was made to your storage. You can access the image via your storage.';
                  this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
                }
                this.spinnerService.hide();
                this.mapLoad = false;
              }
              if (scn?.thumbnailUrl) {
                scn.thumbnailUrl = res.result?.imageUrl;
              }
              scn.thumbnailUrl = res.result?.COGUrl ? res.result?.COGUrl : res.result?.imageUrl;
              this.displayFootPrint(scn, 0, res.result,sensorId,taskId);
              this.spinnerService.hide();
              this.mapLoad = false;
            });
          }
        }

      }
    }
  }

  getAdditionalData(taskId: any, sensor: any) {
    if(this.demoView) return;
    let message: any;
    let sensorObj = {
      sensorId: sensor.id,
      taskId: taskId
    }
    this.apiService.addCollectionData(sensorObj).subscribe((res: any) => {
      if (res) {
        if (res.msg === 'success') {
          const type = 'download'
          message = 'Thank you for submitting additional files for download. You will be notified via email when the files are ready.';
          this.popupService.openPopup(WarningPopupComponent, message, type, '40%');
        } else if (res.msg === 'already requested') {
          message = 'The download request for additional files has either already been made and is being processed.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        } else if (res?.msg === 'user storage') {
          const message = 'We cannot download the image as the delivery was made to your storage. You can access the image via your storage.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        } else {
          message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        }
      } else {
        message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      }    
    });

  }

  releaseVADP(taskId: any, sensor: any) {
    let productName: any,productId: any, product: any;
    if (sensor?.superResolutions?.length > 0) {
      productName = 'superResolutions';
      product = 'Super Resolution'
    }
    else {
      productName = 'valueAddedOption';
      productId = sensor?.valueAddedOption[0]?.product_id;
      product = 'vadp';
    }

    let message: any;
    let sensorObj = {
      sensorId: sensor.id,
      taskId: taskId,
      vadpId: productId,
      productName: productName
    }

    let action = 'all';
    if (sensor?.superResolutions?.length > 0) product = 'Super Resolution';

    let params = {
      taskId, sensorId: sensor.id, product, action
    }

    this.apiService.returnRefundStatus(params).subscribe((resp: any) => {
      if (resp.status != 'found' || (resp.status === 'found' && resp?.refund)) {
        message = 'You have requested a refund for the value-added data products. You cannot request the products anymore.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        return
      } else {
        this.apiService.processVADP(sensorObj).subscribe((res: any) => {
          if (res) {
            if (res.msg === 'success') {
              const type = 'download'
              message = 'Thank you for submitting value added products for releasing. You will be notified via email when the files are ready.';
              this.popupService.openPopup(WarningPopupComponent, message, type, '40%');
              for (const sensor of this.collection.sensors || []) {
                if (sensor?.valueAddedOption?.length > 0) {
                  sensor.valueAddedOption.releaseRequest = true;
                  for (const option of sensor.valueAddedOption || []) {
                    option.releaseRequest = true;
                  }
                }

                if (sensor.superResolutions?.length > 0) {
                  sensor.superResolutions.releaseRequest = true;
                  for (const option of sensor.superResolutions || []) {
                    option.releaseRequest = true;
                  }
                }    
               
            }
              
            } else if (res.msg === 'already requested') {
              message = 'The release request for value added products has either already been made and is being processed.';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            } else {
              message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            }
          } else {
            message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          }    
        });
      }
      
    });

  }

  handleAnnotation() {
    this.checkSeceneSelected();

    const annotateHandler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

    annotateHandler.setInputAction((click) => {
      const mousePosition = this.viewer.camera.pickEllipsoid(click.position, this.viewer.scene.globe.ellipsoid);
      const position: Cesium.Cartesian2 = click.position;
    
      if (Cesium.defined(mousePosition) && this.annotateClicked) {
        // Iterate through imagery layers to check if the mouse position is within any layer's rectangle
        for (const [layer, data] of this.imageryLayerMap.entries()) {
          if (this.isPositionInsideRectangle(mousePosition, data.rectangle)) {
            this.annotateCollection(position,data.sensorId,data.taskId,data.sceneId);
            break;
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  handleJsonAnnotation() {
    this.checkSeceneSelected();
    
    const annotateHandler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
    annotateHandler.setInputAction((click) => {
      const mousePosition = this.viewer.camera.pickEllipsoid(click.position, this.viewer.scene.globe.ellipsoid);
      const position: Cesium.Cartesian2 = click.position;

      if (Cesium.defined(mousePosition) && this.annotateClicked) {
        // Iterate through bounding boxes to check if the mouse position is within any bounding box
        for (const [bbox, data] of this.boundingBoxMap.entries()) {          
          if (this.isPositionInsideRectangle(mousePosition, bbox)) {
            this.annotateCollection(position,data.sensorId,data.taskId,data.sceneId);
            break;
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  isPositionInsideRectangle(position, rectangle) {
    const cartographic = Cesium.Cartographic.fromCartesian(position);
    return Cesium.Rectangle.contains(rectangle, cartographic);
  }

  annotateImage() {
    this.annotateClicked = !this.annotateClicked;
    if (this.annotateClicked) {
      this.getAnnotations();
    } else this.clearAnnotations();
  }

  annotateCollection(position: Cesium.Cartesian2, sensorId: any, taskId: any, sceneId: any) {
    const cartesian = this.viewer.camera.pickEllipsoid(position);
    if (cartesian) {
      const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      const cartesianPosition = Cesium.Cartesian3.fromDegrees(longitude, latitude);

      var boxEntity = this.viewer.entities.add({
        name: 'annotationPoint',
        position: cartesianPosition,
        billboard: {
          image: 'assets/images/notes.png',
          show: true,
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO,
          width: 20,
          height: 20,
        },
        // point: {
        //   pixelSize: 10,
        //   color: Cesium.Color.YELLOW,
        // },
      });

      this.createAnnotationPopup(boxEntity,longitude,latitude)
      this.currentLongitude = longitude;
      this.currentLatitude = latitude;
      this.currentTaskId = taskId;
      this.currentSensorId = sensorId;
      this.sceneId = sceneId;
    } 
  }

  getAnnotations() {
    // Create a promise chain to sequentially handle API calls and display annotations
    let promise = Promise.resolve();
    let sensorsObj = {"sensors": []}
    let filterOptions:any = {};
    this.scenePos = ["right", "left"];
    let siteIdsArray:any = [];

  if((this.selectedCheckboxes.filter(item => item.checked).length === 0) && this.selectedSites.length == 0) {
      this.collectionContainer = sensorsObj;
      if(this.collection.sensors.filter(item => item.checked).length >= 1) filterOptions = {"type": "sensor"};
      (this.collection.sensors.filter(item => item.checked).length >= 1) ? this.collectionContainer.sensors = this.collection.sensors : this.collectionContainer.sensors[0] = this.collection.sensors[0];
  }

  if (this.showDailyRecurrencyCheckboxes 
      && this.showSubDailyList 
      && this.collection.subDailyCount == null) {
        this.collectionContainer = sensorsObj;        
        for (const [sensorIndex, subDailyData] of this.subDailyFinalArray.entries()) {
          siteIdsArray.push(subDailyData.sequenceNumber.value);
          this.collectionContainer.sensors[sensorIndex] = subDailyData;
        }
        if(this.selectedSites.length >= 1) filterOptions = {"type": "site"};
  }

  if(this.selectedCheckboxes.filter(item => item.checked).length >= 1 && this.selectedSites.length == 0) {
    filterOptions = {"type": "sensor"};
      this.collectionContainer = sensorsObj;
      for (const [sensorIndex, sensors] of this.selectedCheckboxes.entries()) {
        sensors["id"] = this.collection.sensors[0].id;
        this.collectionContainer.sensors[sensorIndex] = sensors;
      }
  }

  for (const sensor of this.collectionContainer.sensors) {
    for (const scene of sensor.scenes) {
      if (scene.checked) {
        promise = promise.then(() => {
          return new Promise<void>((resolve) => {
            this.apiService.getAnnotations(this.taskid, sensor.id, scene.id).subscribe((res: any) => {
              if (res?.msg === 'Success') {
                scene.identifiers = {
                  taskId: this.taskid,
                  sensorId: sensor.id,
                  sceneId: scene.id,
                  type: filterOptions?.type,
                  siteIds: siteIdsArray
                }
                scene.annotation = res.data;
              }
              resolve(); // Resolve the promise to continue to the next iteration
            });
          });
        });
      }
    }
  }

    // After all promises are resolved (i.e., all API calls are completed), execute displayAnnotations
    promise.then(() => {
      this.displayAnnotations();
    });
  }

  clearAnnotations() {
    let entitiesToRemove: any = [];
    this.annotateClicked = false;
    this.newTaskButtonClicked = false;

    this.viewer.entities.values.forEach((entity) => {
      if (entity.name === 'annotation' || entity.name === 'annotationPoint') {
        entitiesToRemove.push(entity);
      }
    });

    entitiesToRemove.forEach((entity) => {
      this.viewer.entities.remove(entity);
    });

    this.activePopups.forEach((popup) => {
      popup.element.remove();
    });

    this.activeTooltips.forEach((tooltip) => {
      tooltip.remove();
    });
  }

  displayAnnotations(): void {
    this.clearAnnotations();
   let sensorsIndexes:any = [];
   this.collectionContainer.sensors.forEach(item => {sensorsIndexes.push(item.index)});

    let tempId:any;
    for (const sensor of this.collectionContainer.sensors) {
      let pos: any;
      for (const scene of sensor.scenes) {
        if (scene.checked) {
          this.annotateClicked = true;

          if (scene.identifiers.type === "sensor") {
            pos = sensorsIndexes[0] === sensor.index ? "left" : "right";
            (tempId === (undefined)) ? tempId = sensor.id : (tempId === sensor.id) ? pos : pos == "right" ? "right" : "left";
          }
          if (scene.identifiers.type === "site") {
            pos = scene.identifiers.siteIds[0] === scene.siteId ? "left" : "right";
            (tempId === (undefined)) ? tempId = scene.siteId : (tempId === scene.siteId) ? pos : pos == "right" ? "right" : "left";
          }

          scene.identifiers["scenePos"] = pos;
          scene?.annotation?.features.forEach(feature => {
            this.createAnnotationEntity(feature, scene.identifiers);
          });
        };
      }
    }
  }

  createAnnotationEntity(feature: any, identifiers: any) {
    let featureProperties = {
      feature: feature,
      identifiers: identifiers
    }
    this.uslBB = this.viewer.entities.add({
      name: 'annotation',
      properties: featureProperties,
      position: Cesium.Cartesian3.fromDegrees(feature.coordinates.longitude, feature.coordinates.latitude),
      billboard: {
        image: 'assets/images/notes.png',
        show: true,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO,
        width: 20,
        height: 20,
      },
      // point: {
      //   pixelSize: 10,
      //   color: Cesium.Color.YELLOW,
      // },
    });

    this.uslBB.index = featureProperties.feature._id;
    this.uslBB.sceneId = featureProperties.identifiers.sceneId;
    featureProperties.identifiers.scenePos == "left" ? this.scnLyr.push(this.uslBB) : this.scnLyr2.push(this.uslBB);
    this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);

    this.viewer.screenSpaceEventHandler.setInputAction((click) => {
      const pickedObject = this.viewer.scene.pick(click.position);
      if (Cesium.defined(pickedObject) && pickedObject.id) {
        const entity = pickedObject.id;
        this.openAnnotationPopup(entity, click.position);
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    this.viewer.scene.postRender.addEventListener(this.updatePopups.bind(this));

  }

  createAnnotationPopup(entity: any,long,lat) {

    if (this.lastAnnotation) {
      this.viewer.entities.remove(this.lastAnnotation);
      this.lastAnnotation = null;
    }
    if (this.lastTooltip) {
      this.lastTooltip.remove();
      this.lastTooltip = null;
    }
    
    const cesiumContainer = document.getElementById('cesiumContainer1');
    const tooltip = document.createElement('div') as AnnotatedDivElement;
    tooltip.style.height = '140px';
    this.applyPopupStyles(tooltip);

    // Create the arrow element
    const arrow = document.createElement('div');
    this.applyArrowStyles(arrow);
    tooltip.appendChild(arrow);

    tooltip.style.display = 'flex';
    // tooltip.style.justifyContent = 'space-between';

    // Create a textarea for editing
    const textarea = document.createElement('textarea');
    textarea.style.width = '250px';
    textarea.style.height = '95px';
    textarea.style.lineHeight = '1.2';
    // textarea.style.overflow = 'hidden';
    textarea.style.resize = 'none';
    tooltip.innerHTML = '';
    tooltip.appendChild(textarea);

    // Create coordinates container
    const coordinatesContainer = document.createElement('div');
    coordinatesContainer.style.position = 'absolute';
    coordinatesContainer.style.bottom = '0';
    coordinatesContainer.style.padding = '5px';
    coordinatesContainer.style.fontSize = '13px';
    coordinatesContainer.style.fontWeight = '400';
    coordinatesContainer.style.left = '11px'
    coordinatesContainer.innerHTML = `Lng: ${long.toFixed(8)}, Lat: ${lat.toFixed(8)}`;
    tooltip.appendChild(coordinatesContainer);
    

    const buttonsContainer = document.createElement('div');
    buttonsContainer.style.display = 'flex';
    buttonsContainer.style.justifyContent = 'space-between';
    buttonsContainer.style.flexDirection = 'column-reverse';
    buttonsContainer.style.marginTop = '-4px';

    const saveButton = document.createElement('span');
    saveButton.innerHTML = '<i class="material-icons" style="font-size:20px;">save</i>';
    this.buttonStyle(saveButton);

    const cancelButton = document.createElement('span');
    cancelButton.innerHTML =  '<i class="material-icons" style="font-size:20px;">close</i>';
    this.buttonStyle(cancelButton);

    buttonsContainer.appendChild(saveButton);
    buttonsContainer.appendChild(cancelButton);

    tooltip.appendChild(buttonsContainer);
    tooltip.sceneId = this.sceneId;
    this.activeTooltips.push(tooltip);

    // Append the tooltip to the cesiumContainer
    if (cesiumContainer) {
      cesiumContainer.appendChild(tooltip);
    }

    const updateCreateTooltipPosition = () => {
      const screenPosition = this.viewer.scene.cartesianToCanvasCoordinates(entity.position.getValue(this.viewer.clock.currentTime));
      if (screenPosition) {
        // Get the canvas dimensions
        const canvas = this.viewer.scene.canvas;
        const canvasRect = canvas.getBoundingClientRect();
        const canvasWidth = canvasRect.width;
        const canvasHeight = canvasRect.height;
    
        // Get the tooltip dimensions
        const tooltipWidth = tooltip.offsetWidth;
        const tooltipHeight = tooltip.offsetHeight;
    
        // Calculate adjusted positions
        let adjustedLeft = screenPosition.x; // Adjusted for padding or margin
        let adjustedTop = screenPosition.y; // Adjusted for padding or margin
    
        // Check if the annotation point is within the canvas
        if (
          screenPosition.x >= 0 &&
          screenPosition.x <= canvasWidth &&
          screenPosition.y >= 0 &&
          screenPosition.y <= canvasHeight
        ) {
          // Adjust left position if tooltip exceeds right edge
          if (adjustedLeft + tooltipWidth > canvasWidth) {
            // adjustedLeft = canvasWidth - tooltipWidth; // Adjusted for padding or margin
          }
    
          // Adjust top position if tooltip exceeds bottom edge
          if (adjustedTop + tooltipHeight > canvasHeight) {
            // adjustedTop = canvasHeight - tooltipHeight; // Adjusted for padding or margin
          }
    
          // Update tooltip position and display it
          tooltip.style.left = adjustedLeft + 'px';
          tooltip.style.top = adjustedTop + 'px';
          tooltip.style.display = 'flex';
        } else {
          // Hide the tooltip if the annotation point is outside the canvas
          tooltip.style.display = 'none';
        }
      }
    };
    updateCreateTooltipPosition();

    saveButton.addEventListener('click', () => {
      if (textarea.value) {
        const annotation = {
          "customerid": "",
          "taskid": this.currentTaskId,
          "sensorid": this.currentSensorId,
          "sceneid": this.sceneId,
          "property": textarea.value,
          "lat": this.currentLatitude,
          "lng": this.currentLongitude
        }
        tooltip.remove();
        this.apiService.addAnnotation(annotation).subscribe((res: any) => {
          if (res?.msg === 'Success') {
            this.getAnnotations();
          }
        });
      } else {
        this.viewer.entities.remove(entity);
        tooltip.remove();
      }
    });

    cancelButton.addEventListener('click', () => {
      this.viewer.entities.remove(entity);
      tooltip.remove();
    });

    this.lastAnnotation = entity;
    this.lastTooltip = tooltip;

    // Update the position of the tooltip on camera move
    this.viewer.scene.preRender.addEventListener(updateCreateTooltipPosition);
  }

  openAnnotationPopup(entity: any, position: any) {
    const cesiumContainer = document.getElementById('cesiumContainer1');
    const existingPopup = this.activePopups.find(popup => popup.entity === entity);
 
    if (existingPopup) {
      // Remove the existing popup
      existingPopup.element.remove();
      this.activePopups = this.activePopups.filter(popup => popup !== existingPopup);
    } else if (entity.name === 'annotation') {
      // Create a new popup element
      const tooltip = document.createElement('div') as AnnotatedDivElement;
      this.applyPopupStyles(tooltip);

      // Create the arrow element
      const arrow = document.createElement('div');
      this.applyArrowStyles(arrow);

      // Create a container for the close button
      const closeContainer = document.createElement('div');
      closeContainer.classList.add('close-container'); 
      closeContainer.style.display = 'flex';
      closeContainer.style.justifyContent = 'flex-end';

      // Create close button
      const closeIcon = document.createElement('span');
      closeIcon.innerHTML = '<i class="material-icons" style="font-size:20px;">close</i>';
      this.buttonStyle(closeIcon);
      closeIcon.addEventListener('click', () => {
          tooltip.remove();
          this.activePopups = this.activePopups.filter(popup => popup.entity !== entity);
      });

      closeContainer.appendChild(closeIcon);
      tooltip.appendChild(closeContainer);

      // Append the arrow to the tooltip
      tooltip.appendChild(arrow);

      let feature = entity?.properties?.feature.getValue();
      const content = document.createElement('div');
      content.style.overflow = 'auto';
      content.style.maxHeight = '142px';
      content.style.lineHeight = '1.17';
      // content.style.paddingRight = '15px';
      content.style.wordWrap = 'break-word';
      content.innerHTML = feature?.property;
      tooltip.appendChild(content);

      // Create coordinates container
      const coordinatesContainer = document.createElement('div');
      coordinatesContainer.style.position = 'absolute';
      coordinatesContainer.style.bottom = '5px';
      coordinatesContainer.style.padding = '5px';
      coordinatesContainer.style.fontSize = '13px';
      coordinatesContainer.style.fontWeight = '400';
      coordinatesContainer.style.left = '8px'
      coordinatesContainer.innerHTML = `Lng: ${feature.coordinates.longitude.toFixed(8)}, Lat: ${feature.coordinates.latitude.toFixed(8)}`;
      tooltip.appendChild(coordinatesContainer);

      // Create edit and delete icons
      const iconsContainer = document.createElement('div');
      iconsContainer.style.display = 'flex';
      iconsContainer.style.justifyContent = 'end';
      iconsContainer.style.marginTop = '30px';
      // iconsContainer.style.marginTop = '-2px';

      const editIcon = document.createElement('span');
      editIcon.innerHTML = '<i class="material-icons" style="font-size:20px;">edit</i>';
      editIcon.style.paddingRight = '10px'
      this.buttonStyle(editIcon);

      const deleteIcon = document.createElement('span');
      deleteIcon.innerHTML =  '<i class="material-icons" style="font-size:20px;">delete</i>';
      this.buttonStyle(deleteIcon);

      iconsContainer.appendChild(editIcon);
      iconsContainer.appendChild(deleteIcon);
      tooltip.appendChild(iconsContainer);
      tooltip.sceneId = this.sceneId;

      // Append the tooltip to the cesiumContainer
      if (cesiumContainer) {
        cesiumContainer.appendChild(tooltip);
      }

      // Add the new popup to the activePopups array
      this.activePopups.push({ entity, element: tooltip });

      // Initially set the popup's position and clamp to container bounds
      // const screenPosition = this.viewer.scene.cartesianToCanvasCoordinates(entity.position.getValue(this.viewer.clock.currentTime));
      // if (screenPosition) {
      //   tooltip.style.left = screenPosition.x + 1 + 'px';
      //   tooltip.style.top = screenPosition.y + 1 + 'px';
      // }
      const updateTooltipPosition = () => {
        const screenPosition = this.viewer.scene.cartesianToCanvasCoordinates(entity.position.getValue(this.viewer.clock.currentTime));
        if (screenPosition) {
          // Get the canvas dimensions
          const canvas = this.viewer.scene.canvas;
          const canvasRect = canvas.getBoundingClientRect();
          const canvasWidth = canvasRect.width;
          const canvasHeight = canvasRect.height;
      
          // Get the tooltip dimensions
          const tooltipWidth = tooltip.offsetWidth;
          const tooltipHeight = tooltip.offsetHeight;
      
          // Calculate adjusted positions
          let adjustedLeft = screenPosition.x + 10; // Adjusted for padding or margin
          let adjustedTop = screenPosition.y + 10; // Adjusted for padding or margin
      
          // Check if the annotation point is within the canvas
          if (
            screenPosition.x >= 0 &&
            screenPosition.x <= canvasWidth &&
            screenPosition.y >= 0 &&
            screenPosition.y <= canvasHeight
          ) {
            // Adjust left position if tooltip exceeds right edge
            if (adjustedLeft + tooltipWidth > canvasWidth) {
              adjustedLeft = canvasWidth - tooltipWidth - 100; // Adjusted for padding or margin
            }
      
            // Adjust top position if tooltip exceeds bottom edge
            if (adjustedTop + tooltipHeight > canvasHeight) {
              // adjustedTop = canvasHeight - tooltipHeight - 10; // Adjusted for padding or margin
            }
      
            // Update tooltip position and display it
            tooltip.style.left = adjustedLeft + 'px';
            tooltip.style.top = adjustedTop + 'px';
            tooltip.style.display = 'block';
          } else {
            // Hide the tooltip if the annotation point is outside the canvas
            tooltip.style.display = 'none';
          }
        }
      };
  
      // Initially set the popup's position and clamp to container bounds
      updateTooltipPosition();

     // Add event listeners for edit and delete icons
     editIcon.addEventListener('click', () => {
        // Remove the close container when the edit button is clicked
        const closeContainerDiv = tooltip.querySelector('.close-container');
        if (closeContainerDiv) {
            closeContainerDiv.remove();
        }
        this.editAnnotation(entity, content, iconsContainer, closeContainer, tooltip ,coordinatesContainer);
      });
      deleteIcon.addEventListener('click', () => this.deleteAnnotation(entity));
      // Update the position of the tooltip on camera move
      this.viewer.scene.preRender.addEventListener(updateTooltipPosition);
    }
  }

  deleteAnnotation(entity: any): void {
    let feature = entity.properties.feature.getValue();
    let identifiers = entity.properties.identifiers.getValue()

    let deleteObj = {
      "customerid": "",
      "taskid": identifiers.taskId,
      "sensorid": identifiers.sensorId,
      "sceneid": identifiers.sceneId,
      "featureid": feature._id,
    }
    this.apiService.deleteAnnotations(deleteObj).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        this.getAnnotations();
      }
    });
  }

  editAnnotation(entity: any, content: HTMLElement, iconsContainer: HTMLElement, closeContainer: HTMLElement | null = null, tooltip: HTMLElement | null = null, coordinatesContainer: HTMLElement) {
    const feature = entity.properties.feature.getValue();
    iconsContainer.style.display = 'none';
    coordinatesContainer.style.display = 'none';

    // Create a textarea for editing
    const textarea = document.createElement('textarea');
    textarea.value = feature.property;
    textarea.style.width = '250px';
    textarea.style.height = '95px';
    textarea.style.lineHeight = '1.17';
    // textarea.style.overflow = 'hidden';
    textarea.style.resize = 'none';
    content.innerHTML = '';
    content.appendChild(textarea);

    content.style.display = 'flex';
    content.style.justifyContent = 'space-between';

    const buttonsContainer = document.createElement('div');
    buttonsContainer.style.display = 'flex';
    buttonsContainer.style.justifyContent = 'space-between';
    buttonsContainer.style.flexDirection = 'column-reverse';

    const saveButton = document.createElement('span');
    saveButton.innerHTML = '<i class="material-icons" style="font-size:20px;">save</i>';
    this.buttonStyle(saveButton);

    const cancelButton = document.createElement('span');
    cancelButton.innerHTML =  '<i class="material-icons" style="font-size:20px;">close</i>';
    this.buttonStyle(cancelButton);

    buttonsContainer.appendChild(saveButton);
    buttonsContainer.appendChild(cancelButton);

    content.appendChild(buttonsContainer);

    saveButton.addEventListener('click', () => {
      if(textarea.value)
      {
        feature.property = textarea.value;
        content.innerHTML = feature.property;
        iconsContainer.style.display = 'flex';
        coordinatesContainer.style.display = 'flex';

        let identifiers = entity.properties.identifiers.getValue()

        let deleteObj = {
          "customerid": "",
          "taskid": identifiers.taskId,
          "sensorid": identifiers.sensorId,
          "sceneid": identifiers.sceneId,
          "featureid": feature._id,
          "property": textarea.value
        }
        this.apiService.editAnnotations(deleteObj).subscribe((res: any) => {
          if (res?.msg === 'Success') {
            this.getAnnotations();
          }
        });
      }
    });

    cancelButton.addEventListener('click', () => {
      content.innerHTML = feature.property;
      iconsContainer.style.display = 'flex';
      content.style.display = 'block';
      coordinatesContainer.style.display = 'flex';

      // Append the close container back to the tooltip if provided
      if (closeContainer && tooltip) {
        tooltip.insertBefore(closeContainer, content);
    }
    });
  }

  applyPopupStyles(tooltip) {
    tooltip.style.position = 'absolute';
    tooltip.style.fontSize = '15px';
    tooltip.style.fontFamily = 'Barlow, sans-serif';
    tooltip.style.lineHeight = '1.8';
    tooltip.style.backgroundColor = 'white';
    tooltip.style.color = 'black';
    tooltip.style.border = '0 solid white';
    tooltip.style.padding = '15px 10px 15px 15px';
    tooltip.style.zIndex = '1000';
    tooltip.style.minWidth = '300px';
    tooltip.style.maxWidth = '300px';
    tooltip.style.borderRadius = '9px';

    return tooltip;
  }

  applyArrowStyles(arrow) {
    arrow.style.position = 'absolute';
    arrow.style.top = '-5px';
    arrow.style.left = '4px';
    arrow.style.width = '0';
    arrow.style.height = '0';
    arrow.style.borderRight = '20px solid transparent';
    arrow.style.borderBottom = '8px solid white';

    return arrow;
  }

  buttonStyle(button) {
    button.style.cursor = 'pointer';
    button.style.color ='rgb(182 182 186)';

    return button;
  }

  updatePopups() {
    const cesiumContainer = document.getElementById('cesiumContainer1');
    if (!cesiumContainer) return;

    const containerRect = cesiumContainer.getBoundingClientRect();

    this.activePopups.forEach(popup => {
      const entity = popup.entity;
      const element = popup.element;

      // Get the entity's screen position
      const position = this.viewer.scene.cartesianToCanvasCoordinates(entity.position.getValue(this.viewer.clock.currentTime));

      if (position) {
        // Calculate the popup position relative to the container
        let left = position.x + 1;
        let top = position.y + 1;

        // Clamp the position to the container bounds
        left = Math.min(Math.max(left, 0), containerRect.width - element.offsetWidth);
        top = Math.min(Math.max(top, 0), containerRect.height - element.offsetHeight);

        element.style.left = left + 'px';
        element.style.top = top + 'px';
      }
    });
  }
  
  clearAnnotationsByScene(sceneId?: string) {
    let entitiesToRemove: any = [];

    this.viewer.entities.values.forEach((entity) => {
      if ((entity.name === 'annotation' && entity?.properties?.identifiers)) {
        let featureSceneId = entity.properties.identifiers.getValue();

        if (featureSceneId.sceneId === sceneId) {
          entitiesToRemove.push(entity);
        }
      } else if (entity.name === 'annotationPoint') {
        entitiesToRemove.push(entity);
      }
    });

    entitiesToRemove.forEach((entity) => {
      this.viewer.entities.remove(entity);
    });

    this.activePopups.forEach((popup) => {
      if (popup?.entity?.properties?.identifiers) {
        let featureSceneId = popup.entity.properties.identifiers.getValue();
        if (featureSceneId.sceneId === sceneId) {
          popup.element.remove();
        }
      }
    });

    this.activeTooltips.forEach((tooltip) => {
      tooltip.remove();
    });

    this.checkSeceneSelected();
  }

  checkSeceneSelected() {
    let imageryLayers = this.imageryLayerMap;
    let jsonData = this.boundingBoxMap;
    let isAnySceneChecked = false;
    this.imageLoaded = false;
    this.isDisabledAnottationBtn = false;

    for (const sensor of this.collection.sensors) {
      for (const scene of sensor.scenes) {
        if (scene.checked && (checkLayerSceneID(scene.id) || checkJsonSceneID(scene.id))) {
          isAnySceneChecked = true;
          this.imageLoaded = true;
          break;
        }
      }
      if (isAnySceneChecked) {
        break;
      } else {
        this.annotateClicked = false;
        this.newTaskButtonClicked = false;
      }
    }

  if (this.selecetedData != undefined && !this.imageLoaded) {
    this.imageLoaded = true;
  }

    function checkLayerSceneID(sceneId) {
      for (const [layer, data] of imageryLayers.entries()) {
        if (data.sceneId === sceneId) {
          return true;
          break;
        }
      }
      return false
    }

    function checkJsonSceneID(sceneId) {
      for (const [json, data] of jsonData.entries()) {
        if (data.sceneId === sceneId) {
          return true;
          break;
        }
      }
      return false
    }
    if(this.annotateClicked) this.getAnnotations();
  }

  getBoundingBox(BBpoints: any) {
    return Cesium.Rectangle.fromDegrees(
      Math.min(...BBpoints.filter((_, i) => i % 2 === 0)),
      Math.min(...BBpoints.filter((_, i) => i % 2 !== 0)),
      Math.max(...BBpoints.filter((_, i) => i % 2 === 0)),
      Math.max(...BBpoints.filter((_, i) => i % 2 !== 0))
    );
  }

  setJsonData(scenes, taskId, sensorId) {
    let USLcoverage;

    if (scenes?.metadata?.features) {
      USLcoverage = scenes.metadata.features[0].geometry.coordinates[0];
    } else if (scenes?.footprint) {
      USLcoverage = scenes?.footprint.coordinates[0];
    }

    const BBpoints = USLcoverage.flat();
    const sceneId = scenes.id;
    const boundingBox = this.getBoundingBox(BBpoints);
    this.boundingBoxMap.set(boundingBox, {
      sceneId: sceneId,
      taskId: taskId, sensorId: sensorId
    });

    this.handleJsonAnnotation();
  }

  handleSelection(item: any, collectionIndex: number, i: number, event: Event): void {
    this.selectRecurrency(item, 'normal');
    this.saveSelectedData(collectionIndex, i, undefined, event, 'dropdown', 'Month', 'dateSelection');
  }

  handleSiteSelection(item: any, collectionIndex: number, i: number, event: Event): void {
    this.selectSite(item.siteId);
    this.saveSelectedSites(item);
  }

  saveSelectedData(collectionIndex: any, sensorIndex: any, scenesIndex: any, event: any, type: any, task: any, selectedType: string) {
    this.currentCollectionIndex = collectionIndex;
    this.currentSensorIndex = sensorIndex;
    this.currentSceneIndex = scenesIndex;
    this.currentEvent = {
     checked : true
    };
    this.currentType = type;
    this.currentTask = task;
   }

   saveSelectedSites(item: any) {
    this.dateSelected = false;
    this.selectedDropdownType = '';
    this.selectedDropdownType = 'sites';
    let selectedSite = {
      checked: false,
      disabled: false,
      siteId: item.siteId,
      siteName: item.siteName,
      target: item.target,
      value: item.siteId
     }
    this.currentEvent = {
      checked : true
     };
     this.currentSites = selectedSite;
     if (this.timeseries) {
      this.groupedScenes = this.groupRecurrencyCollections(this.selectedRecurrencyArray);
      if (this.slider) this.slider.value = 0;
      this.currentSliderValue = 0;
      this.previousIndex = -1;
      const entities = this.viewer.entities.values;
      for (const entity of entities) {
        if (entity?.name === 'trendline') {
          this.viewer.entities.remove(entity);
        }
      }
      this.sourceRateArray = [];
    }  
 }

 getTooltipMessage(sensor) {
  this.tooltipDisabled = false;
  if (sensor.operatorKey === OPERATORS.CAPELLA) {
    this.downloadTooltip = 'Click here for additional GEO, SLC and SICD files available for download';
  } else if (sensor.operatorKey === OPERATORS.UMBRA && sensor?.isAdditionalProducts) {
    this.downloadTooltip = 'Click here for additional CPHD, SIDD, and SICD files available for download';
  } else if (sensor.operatorKey === OPERATORS.UMBRA && sensor?.isAdditionalCSIProducts) {
    this.downloadTooltip = 'Click here for additional CSI-TIFF and CSI-SIDD files available for download';
  }
}

onMouseOut() {
  this.tooltipDisabled = true;
  this.downloadTooltip = '';
}

checkDownloaded(scene: any): boolean {
  const isDownloaded = this.downloadedScene.includes(scene.id) || scene.downloaded;

  return isDownloaded;
}

checkSensorDownloaded(sensor: any): boolean {
  const isDownloaded = this.downloadedSensor.includes(sensor.id) || sensor.downloaded;

  return isDownloaded;
}

createNewTask() {
  this.newTaskButtonClicked = !this.newTaskButtonClicked;
  if (this.newTaskButtonClicked) {
    this.handleNewTaskCreation();
  }
}

handleNewTaskCreation() {
  this.checkSelectedSecene();

  if (!this.createNewTaskHandler) {
    this.createNewTaskHandler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

    this.createNewTaskHandler.setInputAction((click) => {
      const mousePosition = this.viewer.camera.pickEllipsoid(click.position, this.viewer.scene.globe.ellipsoid);
      const position: Cesium.Cartesian2 = click.position;
      if (!this.newTaskButtonClicked) {
        // Prevent the default right-click action
        click.cancel = true;
    } else {
    
      if (Cesium.defined(mousePosition) && this.newTaskButtonClicked) {
        this.handleNewTaskClick(position);
      }
    }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

    // Remove the event handler if the task button is not clicked
    if (!this.newTaskButtonClicked && this.createNewTaskHandler) {
      this.createNewTaskHandler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      this.createNewTaskHandler.destroy();
      this.createNewTaskHandler = null;
    }
}

handleNewTaskClick(position: Cesium.Cartesian2) {
  const cartesian = this.viewer.camera.pickEllipsoid(position);
  if (cartesian) {
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    const cartesianPosition = Cesium.Cartesian3.fromDegrees(longitude, latitude);

    // Add a marker at the clicked position
    const marker = this.viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
      billboard: {
        image: 'assets/images/marker-icon.png',
        show: true,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO,
        width: 20,
        height: 30,
      },
    });

    setTimeout(() => {
      const message = `Do you want to create a new task with the selected point?`;
    
      const dialogRef = this.dialog.open(CreateTaskConfirmDialog, {
        backdropClass: 'blurr', disableClose: true,
        data: { message: message, longitude: longitude, latitude: latitude, task: this.collection, user: this.currentUser },
      });
    
      dialogRef.afterClosed().subscribe(result => {
        this.viewer.entities.remove(marker);
      });
    }, 1);

  } else {
    console.error('Could not convert screen position to geographic coordinates.');
  }
}

loadUserDetails(userId): void {
  if (userId) {
    this.userService.personalInfo(userId).subscribe({
      next: (res: any) => {
        this.currentUser = res;
        this.getProjects();
      },
      error: (error) => {
      }
    });
  }
}
// List of options
  getValueAddedOption(dataArray: any) {
    let productsData: any;
    productsData = dataArray[0];
    
    const productsInfo = ((productsData || {}).products || []).map(product => ({
      ...product,
      product_id: productsData.product_id,
      sensorid: productsData.sensorId,
      releaseRequest: dataArray[0]?.releaseRequest
    }));

    productsInfo.sort((a, b) => {
      const actionA = a.product || '';
      const actionB = b.product || '';
      return actionA.localeCompare(actionB);
    });

    return productsInfo;
  }

  // Disabling Options
  shouldDisableValueAddedOption(sensor, type) {
    if(!this.isSensorInList(sensor.id)) return true;
    if (this.selectedSensors.length > 0) {
      if(this.selectedSensors.length > 1) return true;
      if(this.selectedSensors.length === 1) {
        if(sensor.scenes.filter(scene => scene.checked === true).length === 0) return true;
        return sensor.scenes.filter(scene => scene.checked === true).length > 1
      };
    }
    return true;
  }
  
  // Check if sensor exist in selected sensor list
  isSensorInList(sensorId: number): boolean {
    return this.selectedSensors.some(sensor => sensor.id === sensorId);
  }

  // setting selcted value added options
  setValueAddedOption(valAdOption, sensor, event, collectionIndex, sensorIndex) { 
    this.handleOptionError(event, `Value-added data products can only be downloaded now and cannot be visualised on the map here.`);
  }

  handleOptionError(event, message) {
      event.source.checked = false;
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
  }
  hasPendingOptions(sensor: any, currentScene: any): boolean {
    return sensor.valueAddedOption.some((opt: any) => {
      return opt.sceneid === currentScene.id && opt.product_status === 'PENDING';
    });
  }
  isValueAddedOptionChecked(sensor, option) {
    if (this.selectedSensors.length !== 1) {
      return false;
    }

    return this.isSensorInList(sensor.id) && this.isOptionInList(option);
  }
  isOptionInList(option: string): boolean {
    return this.selectedOptions.some(item => item === option);
  }

  getSelectedSensorSingle(collectionIndex, sensorIndex)
  {
    return this.collections[collectionIndex].sensors[sensorIndex].scenes.find((i) => i.checked === true);
  }
  downloadValueAddedData(valAdOption, collectionIndex, sensor, sensorIndex)
  {
    if (this.freeze) {
      const message = 'A download is in progress. Please wait until the current download finishes to continue downloading others.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else if (valAdOption?.isDownloaded) {
      const message = 'download_vadp';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else {
      this.currentDownloadingVADP = { collectionIndex: collectionIndex, sensorIndex:  sensorIndex, product: valAdOption.product};
      this.spinnerService.show();
      this.freeze = true;
      this.fail = 0;
      this.progress = 0;
      this.downloadClick = true;
  
      let sensorObj = {
        product: valAdOption.product,
        id: valAdOption.product_id
      }
      
      this.apiService.getValueAddedData(sensorObj).subscribe(async (res: any) => {
        let url = res.response.imageUrl;

        if(url) {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.responseType = 'blob';
    
          xhr.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentComplete = ((event.loaded / event.total) * 100);
              this.progress = percentComplete.toFixed(2);
              const sensorObj = { progress: this.progress, taskId: this.collections[collectionIndex].taskId, sensorId: sensor.id, download: this.downloadClick, product: valAdOption.product};
              this.downloadService.setProgress(sensorObj);
            }
          };
    
          xhr.onload = () => {
            if (xhr.status === 200) {
              const blob = xhr.response;
              const fileName = this.getFileNameFromS3SignedUrl(url);
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
    
              // Reset progress after download
              this.progress = 0;
              this.spinnerService.hide();
              this.freeze = false;
              this.disable = false;
            }
          };
    
          xhr.onerror = () => {
            // Handle errors
          };
          xhr.send();
        }
      }) 
    }
  }
  refundValueAddedData(collectionIndex, sensorIndex, valAdOption)
  {
    this.OpenReturnRequestVADP(collectionIndex,
      sensorIndex,
      null,
      null,
      valAdOption.product,
      valAdOption
    )
  }
  refundValueAddedStatus(valAdOption: any) {
    if (valAdOption?.refundRequest && (valAdOption?.returnRefundAccept || valAdOption?.returnRefundReject)) {
      let message = 'return-refund-accepted';
      if (valAdOption?.returnRefundReject) {
        message = 'return-refund-rejected';
      }
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    }
  }
  downloadedWarning()
  {
    const message = 'download_vadp';
    this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
  }
  setDownloadedVADP()
  {
    this.collections[this.currentDownloadingVADP.collectionIndex].sensors[this.currentDownloadingVADP.sensorIndex].valueAddedOption.forEach((row) => {
      if(row.product == this.currentDownloadingVADP.product) {
        row.isDownloaded = true;
      }
    })
    this.currentDownloadingVADP = {};
  }
  OpenReturnRequestVADP(i: any, j: any, k: any, scene: any, product: string = '', vadpData:any) {
    const sensor = this.collections[i].sensors[j];
    const taskId = this.collections[i]?.taskId;
    let sceneId: any = scene?.id;
    let sensorId: any = sensor?.id;

    let sensorObj = {
      taskId, sensorId, sceneId, product
    }
  
    this.apiService.returnRefundStatus(sensorObj).subscribe((res: any) => {
      if (res?.status === "found") {
        let message =  'return-refund-accepted';
        if (res?.returnRefundReject) {
          message = 'return-refund-rejected';
        }
  
        if (res?.returnRefundReject || (res?.returnRefundAccept)) {
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          return
        } else {
          this.handleRefundRequestVADP(scene, taskId, sensorId, sceneId, product, vadpData);
        }
      } else {
        const message = 'return-refund-vadp';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        return
      }
    });
  }
  
  handleRefundRequestVADP(scene: any, taskId: any, sensorId: any, sceneId: any, product: any = '', vadpData:any) {
      const dialog = this.matdialog.open(ReturnRefundComponent, {
        minWidth: '50%',
        data: {type: 'return', taskId: taskId, sensorId: sensorId, sceneId: sceneId, product: product},
        backdropClass: 'blurred',
        disableClose: true
      });
  
      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          const message = 'return-refund-success';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.refundRequestVADP.push({sensorId: sensorId, product: vadpData?.product});
          this.valueAddedProductLocalUpdate(sensorId, vadpData?.product);
        }
      });
  }
  valueAddedProductLocalUpdate(sensorId:any, product:any)
  {
    (this.collections || []).forEach((item) => {
      (item.sensors || []).forEach(sensor => {
        if(sensor.id === sensorId)
        {
          (sensor.valueAddedOption || []).forEach(element => {
            if(element.product === product)
            {
              element.refundRequest = true;
            }
          });
        }
      });
    })
  }
  
  getSignals(signalOfInterest) {
    let signals;
    if (signalOfInterest) {
      signals = [
        {
          features: [],
          product: "All",
          type: "FeatureCollection"
        },
        ...signalOfInterest
      ];
    }

    return signals;
  }

  selectSignals(sensor: any, signals: any) {
    let geoJsonFiles;
    this.signalSelected = signals;
    this.removeDataSourceByName('signal');
    this.removeEntitiesByName('audio');
    this.removeEntitiesByName('signal');
    this.audioAvailable = false;
    this.currentRfSignals = []

    if (!this.showDailyRecurrencyCheckboxes && this.signalBands) {
      this.setJsonData(this.signalBands,this.bandTaskId,this.signalSensorId);  
    }
     
    let collectionData: any = {};

    if (this.showDailyRecurrencyCheckboxes && !this.showSubDailyList) {
      collectionData.sensors = this.selectedCheckboxes;
    } else if (this.showDailyRecurrencyCheckboxes && this.showSubDailyList) {
      collectionData.sensors = this.subDailyFinalArray;
    } else collectionData = this.collection;

    const features = this.getFeaturesByProduct(collectionData,this.signalSelected);
    geoJsonFiles = features;
    this.loadSignals(geoJsonFiles,this.signalSelected);
  }

  getFeaturesByProduct(collectionData: any, targetProduct: string): any[] {
    const features: any[] = [];
  if (collectionData?.sensors) {
    for (const [sensorIndex, sensor] of collectionData.sensors.entries()) {
      for (const scene of sensor.scenes) {
        if(scene.checked && scene.operatorKey === OPERATORS.LBAND) {
          for (const signal of scene.signalOfInterest) {
            const signalWithSceneId = { ...signal, sceneId: scene.id, sensorIndex: sensorIndex };
            if (targetProduct === 'All') {
              features.push(signalWithSceneId);
            }
            else if (signal.product === targetProduct) {
              features.push(signalWithSceneId);
            }
          }
        }
      }
    }
  }
  
    return features;
  }

  loadSignals (geoJsonFiles,signal) {
    const signalImageMap = {
      VHF: 'YELLOW',
      UHF: 'DARKGRAY',
      'GPS L1': 'GREEN',
      'GPS L2': 'HOTPINK',
      'GPS L5': 'YELLOWGRAY',
      'L-band (ADS-B)': 'MAGENTA',
      'L-band (GNSS)': 'BLUE',
      'L-band (SATCOM)': 'DARKGREEN'
    };

    let signalColor: any;
    this.audioAvailable = false;
    this.currentRfSignals = [];
    this.currentSignal = signal;

    for (let i = 0; i < geoJsonFiles.length; i++) {
      const currentGeoJsonFile = geoJsonFiles[i];
      const polygonColor = signalImageMap[currentGeoJsonFile.product];
      const multiPolygons = currentGeoJsonFile.features.filter(feature => (feature.geometry?.type === 'MultiPolygon' || 
        feature.geometry?.type === 'Polygon'
      ));
      signalColor = this.recurrentColorCode[geoJsonFiles[i].sensorIndex];

      const geoJsonData = {
        type: 'FeatureCollection',
        features: multiPolygons,
        product: currentGeoJsonFile.product
      };
      const selectData = Cesium.GeoJsonDataSource.load(geoJsonData);
      selectData.then((dataSource) => {
        dataSource.name = 'signal';
        const entities = dataSource.entities.values;
      
        for (let j = 0; j < entities.length; j++) {
          const entity = entities[j];
          entity.name = 'signal';
          if (entity.polygon) {
            const properties = entity.properties;
            
            if (properties) {
              const title = properties['title'] ? properties['title'].getValue() : '';
              const description = properties['description'] ? properties['description'].getValue() : '';
      
              if (title === "Estimated Emitter and Error Ellipse") {
                entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color[polygonColor]); // Set fill color
                if (entity.properties) entity.properties[signal] = currentGeoJsonFile.product
                
                // Get the coordinates of the polygon
                if (entity.polygon.hierarchy) {

                  const positions = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions;

                  // Calculate the centroid of the polygon
                  const centroid = Cesium.BoundingSphere.fromPoints(positions).center;
                  const cartographic = Cesium.Cartographic.fromCartesian(centroid);
                  const longitude = Cesium.Math.toDegrees(cartographic.longitude);
                  const latitude = Cesium.Math.toDegrees(cartographic.latitude);
  
                  // Add a point at the centroid of the polygon
                  this.viewer.entities.add({
                    name: 'signal',
                    position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
                    point: {
                      pixelSize: 8,
                      color: Cesium.Color[signalColor],
                    },
                    properties: entity.properties
                  });  
                }
              } else {
                entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.1)); // Set fill color

                if (entity.polygon && entity.polygon.hierarchy) {
                  // Get the coordinates of the polygon and calculate the bounding box
                  const polygonHierarchy = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now());
                  const positions = polygonHierarchy.positions;

                  const degreesArray: any = [];
                  for (let pos of positions) {
                    const cartographic = Cesium.Cartographic.fromCartesian(pos);
                    degreesArray.push(Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude));
                  }

                  // Compute and store the bounding box for the blue circle polygon
                  const boundingBox = this.getBoundingBox(degreesArray);
                  this.boundingBoxMap.set(boundingBox, {
                    sceneId: currentGeoJsonFile.sceneId,
                    taskId: this.bandTaskId,
                    sensorId: this.signalSensorId
                  });
                }
              }
            } else {
                entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.5)); // Set fill color
                // Enable the outline and set its color
                entity.polygon.outline = new Cesium.ConstantProperty(true); // Enable outline
                entity.polygon.outlineColor = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.1)); // Set outline color to red
                
                if (entity.polygon && entity.polygon.hierarchy) {
                  // Get the coordinates of the polygon and calculate the bounding box
                  const polygonHierarchy = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now());
                  const positions = polygonHierarchy.positions;

                  const degreesArray: any = [];
                  for (let pos of positions) {
                    const cartographic = Cesium.Cartographic.fromCartesian(pos);
                    degreesArray.push(Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude));
                  }

                  // Compute and store the bounding box for the blue circle polygon
                  const boundingBox = this.getBoundingBox(degreesArray);
                  this.boundingBoxMap.set(boundingBox, {
                    sceneId: currentGeoJsonFile.sceneId,
                    taskId: this.bandTaskId,
                    sensorId: this.signalSensorId
                  });
                }
            }
          }
        }
      
        this.viewer.dataSources.add(dataSource);
        // this.viewer.scene.globe.depthTestAgainstTerrain = false;
        this.viewer.flyTo(dataSource, { duration: 3 });
        if (currentGeoJsonFile?.product === 'VHF') {
          this.audioAvailable = true;
          this.currentRfSignals.push(currentGeoJsonFile);
        }
      }).catch(error => {
        console.error('Error loading GeoJSON data:', error);
      });
      
    }
    this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.displayProperties(this.currentSignal);
  }

  openAudioSignals() {
    const hasAudioFiles = this.currentRfSignals.some(item => item.audioFiles && item.audioFiles.length > 0);
    if (hasAudioFiles) {
      const sensorId = this.signalSensorId;
      const action = "audioDownload"
      const taskId = this.bandTaskId;
      const sensorObj = { sensorId: sensorId, sceneId: this.currentRfSignals.sceneId, action: action, fileType: 'audio', taskId: taskId };
      const dialogRef = this.matdialog.open(FilterPopupComponent, {
        width: '30%',
        backdropClass: 'blurr',
        disableClose: true,
        data: { type: 'audio', signals: this.currentRfSignals, sensorData: sensorObj }
      });
    } else {
      const message = 'There is no audio recording within the AOI for this task.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    }
  }

  removeDataSourceByName(name: string) {
    let dataSourcesToRemove: any = [];

    const dataSourceCollection = this.viewer.dataSources;
    for (const dataSource of dataSourceCollection._dataSources) {
      if (dataSource?.name === name) {
        dataSourcesToRemove.push(dataSource);
      }
    }

    for (let i = 0; i < dataSourcesToRemove.length; i++) {      
      dataSourceCollection.remove(dataSourcesToRemove[i]);
    }
  }

  removeEntitiesByName(name) {
    let entitiesToRemove: any = [];

    this.viewer.entities.values.forEach((entity) => {
      if (entity.name === name) {
        entitiesToRemove.push(entity);
      }
    });

    entitiesToRemove.forEach((entity) => {
      this.viewer.entities.remove(entity);
    });
  }

  removeDataSourceById(type: string, id: any) {
    let datasourceoRemove: any = [];
    const dataSources = this.viewer.dataSources;
    
    for (let i = 0; i < dataSources.length; i++) {
      const dataSource = dataSources.get(i) as CustomGeoJsonDataSource;
      if (type === 'sensor' && dataSource.sensorId === id) {
        datasourceoRemove.push(dataSource);
      }     
      else if (type === 'scene' && dataSource.sceneId === id) {
        datasourceoRemove.push(dataSource);
      }
      else if (type === 'aisData' && dataSource.refreshId === id) {
        datasourceoRemove.push(dataSource);
      }
    }

    datasourceoRemove.forEach((dataSource) => {      
      dataSources.remove(dataSource, true);
    });
  }
    
  checkSelectedSecene() {
    let isAnySceneChecked = false;
    this.noScene = true;
    let collectionData: any = {};

    if (this.showDailyRecurrencyCheckboxes && !this.showSubDailyList) {
      collectionData.sensors = this.selectedCheckboxes;
    } else if (this.showDailyRecurrencyCheckboxes && this.showSubDailyList) {
      collectionData.sensors = this.subDailyFinalArray;
    } else collectionData = this.collection;    

    if (collectionData?.sensors) {
      for (const sensor of collectionData.sensors) {
        if (sensor.checked && this.timeseries) {
          isAnySceneChecked = true;
          this.noScene = false;
          break;
        }
        for (const scene of sensor.scenes) {
          if (scene.checked) {
            isAnySceneChecked = true;
            this.noScene = false;
            break;
          }
        }
      }
      if (isAnySceneChecked) {
        this.noScene = false;
      } else {
        this.noScene = true;
        this.newTaskButtonClicked = false;
      }
      return this.noScene;
    }
    return true;
  }
  
  selectSuperResolution(
    collectionIndex: number,
    sensorIndex: number,
    superResolutionIndex: number,
    event,
    superResolution: any
  ): void {
    const sensor = this.collections[collectionIndex].sensors[sensorIndex];
    if(event.checked) {
      this.collections[collectionIndex].sensors[sensorIndex].superResolutions[superResolutionIndex].checked = true;
      this.spinnerService.show();
      this.mapLoad = true;
      this.displaySR(superResolution, sensor, event, collectionIndex, sensorIndex)
    } else {
        this.selectedSR = null;
        if (this.currentProductLayer) {
            this.viewer.imageryLayers.remove(this.currentProductLayer);
            this.currentProductLayer = null;
        }

        if (this.currentEntity) {
            this.viewer.entities.remove(this.currentEntity);
            this.currentEntity = null;
        }

        // Handle toggling the sensor state
        const newEvent = { ...event, checked: true };
        const currentSceneIndex = sensor.scenes.findIndex(scene => scene.checked === true);
        this.toggleSensor(
            collectionIndex,
            sensorIndex,
            currentSceneIndex,
            newEvent,
            sensor.scenes[currentSceneIndex]
        );
    }
  }

  shouldDisableSuperResolution(collectionIndex,sensorIndex,scene: any,superResolution) {
    let isDisable: boolean = true;
    const selectedScenes =  this.collections[collectionIndex].sensors[sensorIndex].scenes.filter((i) => i.checked === true);
    if((selectedScenes || []).length === 1)
    {
      isDisable = false;
    }
    return isDisable;
  }

  getFilteredData(jsonData: any, className: string) {
    const filteredFeatures = jsonData.features.filter(feature => feature?.properties?.class != className);
    return {
      type: jsonData.type,
      crs: jsonData.crs,
      features: filteredFeatures
    };
  }
  displaySR(superResolution, sensor, event, collectionIndex, sensorIndex) {
    this.selectedSR = superResolution;
    this.selectedScene  = '';
    //Removing last added layer
    var allImageryLayers = this.viewer.imageryLayers._layers;
    var lastLayer = allImageryLayers[allImageryLayers.length - 1];
    this.viewer.imageryLayers.remove(lastLayer);

    const currentScene = sensor.scenes.find(scene => scene.checked === true);
    this.selectedScene  = currentScene;

    let sensorObj = {
        taskId: this.taskid,
        sensorId: superResolution.sensorId,
        sceneId: currentScene.id,
        product: superResolution.product,
        type: 'display'
    };

    this.apiService.getValueAddedDataImgUrl(sensorObj).subscribe(async (res: any) => {
        let scnRectangle: any;
        let pngFp: any;
        var bbox = turf.bbox(currentScene.footprint);

        if (currentScene?.png_footprint) {
            pngFp = currentScene?.png_footprint[0];
            scnRectangle = Cesium.Rectangle.fromDegrees(
                pngFp[0],
                pngFp[1],
                pngFp[2],
                pngFp[3]
            );
        } else {
            scnRectangle = Cesium.Rectangle.fromDegrees(
                bbox[0],
                bbox[1],
                bbox[2],
                bbox[3]
            );
        }

        const productProvider = new Cesium.ImageryLayer(
            new Cesium.SingleTileImageryProvider({
                url: res.response.imageUrl,
                rectangle: scnRectangle,
            }), {
            splitDirection: Cesium.SplitDirection.LEFT,
            alpha: 1
        });

        this.viewer.imageryLayers.add(productProvider);
        this.imageryLayerMap.set(productProvider, {
            sceneId: currentScene.id,
            rectangle: scnRectangle,
            taskId: this.taskid,
            sensorId: superResolution.sensorId
        });

        // Store the current product layer to remove it later
        this.currentProductLayer = productProvider;

        this.spinnerService.hide();
        this.mapLoad = false;
    });
  }
  downloadSR(superResolution, collectionIndex, sensor, sensorIndex)
  {
    const currentScene = sensor.scenes.find(scene => scene.checked === true);
    let isDownloaded = superResolution.products.some(element => {
      return element.sceneId === currentScene.id && element.isDownloaded;
    });
    if (this.freeze) {
      const message = 'A download is in progress. Please wait until the current download finishes to continue downloading others.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else if (isDownloaded) {
      const message = 'download_vadp';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else {
      this.spinnerService.show();
      this.freeze = true;
      this.fail = 0;
      this.progress = 0;
      this.downloadClick = true;
      this.currentDownloadingSR = { 
        collectionIndex: collectionIndex, 
        sensorIndex:  sensorIndex,
        sceneId : currentScene.id,
        product: superResolution.product
      };
  
      let sensorObj = {
        taskId: this.taskid,
        sensorId: superResolution.sensorId,
        sceneId: currentScene.id,
        product: superResolution.product,
        type: 'download'
    };

    this.apiService.getValueAddedDataImgUrl(sensorObj).subscribe(async (res: any) => {
        let url = res.response.imageUrl;

        if(url) {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.responseType = 'blob';
    
          xhr.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentComplete = ((event.loaded / event.total) * 100);
              this.progress = percentComplete.toFixed(2);
              const sensorObj = { 
                progress: this.progress, 
                taskId: this.collections[collectionIndex].taskId, 
                sensorId: sensor.id,
                sceneId: currentScene.id, 
                download: this.downloadClick, 
                product: superResolution.product
              };
              this.downloadService.setProgress(sensorObj);
            }
          };
    
          xhr.onload = () => {
            if (xhr.status === 200) {
              const blob = xhr.response;
              const fileName = this.getFileNameFromS3SignedUrl(url);
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
    
              // Reset progress after download
              this.progress = 0;
              this.spinnerService.hide();
              this.freeze = false;
              this.disable = false;
            }
          };
    
          xhr.onerror = () => {
            // Handle errors
          };
          xhr.send();
        }
      }) 
    }
  }
  setDownloadedSR()
  {
    this.collections[this.currentDownloadingSR.collectionIndex].sensors[this.currentDownloadingSR.sensorIndex].superResolutions.forEach((row) => {
      if(row.product == this.currentDownloadingSR.product) {
        (row.products || []).forEach((item) =>  {
          if(item.sceneId === this.currentDownloadingSR.sceneId) {
            item.isDownloaded = true;
          }
        });
      }
    })
    this.currentDownloadingSR = {};
  }
  isDownloadedSR(superResolution, sensor, scene) {
    let currentScene = sensor.scenes.filter(scene => scene.checked === true);
    if(currentScene.length > 1) return false;
    return superResolution.products.some(element => {
      return element.sceneId === currentScene[0]?.id && element.isDownloaded;
    });
  }

  isEnableSR(superResolution, sensor, scene) {
    let currentScene = sensor.scenes.filter(scene => scene.checked === true);
    if(currentScene.length > 1) return false;
    return superResolution.products.some(element => {
      return element.sceneId === currentScene[0]?.id;
    });
  }

  resetSROptions(collectionIndex, sensorIndex, event) {
    this.collections[collectionIndex]?.sensors[sensorIndex].superResolutions.forEach((i) => {
      i.checked = false;
    })
    this.selectedSR = null;
    // Handle toggling the sensor state
    const newEvent = { ...event, checked: event.checked };
    const sensor = this.collections[collectionIndex]?.sensors[sensorIndex];
    const currentSceneIndex = sensor.scenes.findIndex(scene => scene.checked === event.checked);
    this.toggleSensor(
        collectionIndex,
        sensorIndex,
        currentSceneIndex,
        newEvent,
        sensor.scenes[currentSceneIndex]
    );
  }
  refundStatusCheckSR(superResolution, sensor) {
    let currentScene = this.getCurrentScene(sensor);
    
    // Use the some method to return true if any product matches the condition
    return superResolution.products.some((i) => {
      return i.sceneId === currentScene?.id && (i?.returnRefundAccept || i?.returnRefundReject);
    });
  }
  getCurrentScene(sensor)
  {
    let currentScene;
    currentScene = sensor.scenes.find(scene => scene.checked === true);
    return currentScene;
  }
  refundSRStatus(superResolution: any, sensor:any) {
    let currentScene = this.getCurrentScene(sensor);
    let message = '';

    superResolution.products.forEach((i) => {
      if(i.sceneId === currentScene?.id) {
        if (i?.refundRequest && (i?.returnRefundAccept || i?.returnRefundReject)) {
          let message = 'return-refund-accepted';
          if (i?.returnRefundReject) {
            message = 'return-refund-rejected';
          }
        }
      }
    })
    if(message) {
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    }
    return
  }
  OpenReturnRequestSR(collectionIndex, sensorIndex, superResolution) {
    const sensor = this.collections[collectionIndex].sensors[sensorIndex];
    const taskId = this.collections[collectionIndex]?.taskId;
    let currentScene = this.getCurrentScene(sensor);
    let sceneId: any = currentScene?.id;
    let sensorId: any = sensor?.id;
    let product = superResolution.product;

    let sensorObj = {
      taskId, sensorId, sceneId, product
    }
  
    this.apiService.returnRefundStatus(sensorObj).subscribe((res: any) => {
      if (res?.status === "found") {
        let message =  'return-refund-accepted';
        if (res?.returnRefundReject) {
          message = 'return-refund-rejected';
        }
  
        if (res?.returnRefundReject || (res?.returnRefundAccept)) {
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          return
        } else {
          this.handleRefundRequestSR(currentScene, taskId, sensorId, sceneId, product, superResolution);
        }
      } else {
        const message = 'return-refund-vadp';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      }
    });
  }
  handleRefundRequestSR(scene, taskId, sensorId, sceneId, product, superResolution) {
      const dialog = this.matdialog.open(ReturnRefundComponent, {
        minWidth: '50%',
        data: {type: 'return', taskId: taskId, sensorId: sensorId, sceneId: sceneId, product: product,
          action: 'vadp'
        },
        backdropClass: 'blurred',
        disableClose: true
      });
  
      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          const message = 'return-refund-success';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.refundRequestSR.push({sensorId: sensorId, sceneId: sceneId, product: superResolution?.product});
          this.valueAddedProductLocalUpdateSR(sensorId, sceneId, superResolution?.product);
        }
      });
  }
  valueAddedProductLocalUpdateSR(sensorId:any, sceneId:any, product:any)
  {
    (this.collections || []).forEach((item) => {
      (item.sensors || []).forEach(sensor => {
        if(sensor.id === sensorId)
        {
          (sensor.superResolutions || []).forEach(element => {
            if(element.product === product)
            {
              (element.products || []).forEach((row) => {
                if(row.sceneId === sceneId) {
                  row.refundRequest = true;
                }
              })
            }
          });
        }
      });
    })
  }
  
  hideVessels(datasourceName,clickedEntity,showValue) {    
      const dataSources = this.viewer.dataSources;
      for (let i = 0; i < dataSources.length; i++) {
        const dataSource = dataSources.get(i);
        if (dataSource.name === datasourceName) { // Filter by data source name
          const entities = dataSource.entities.values;
          if (showValue) {
            for (let j = 0; j < entities.length; j++) {
              entities[j].billboard.show = true; // Show all entities in this data source
            }
          } else {
            for (let j = 0; j < entities.length; j++) {
              if (entities[j] !== clickedEntity) {
                entities[j].billboard.show = false; // Keep only the clicked entity visible
              }
            }
          }
        }
      }
  }

  shouldDisableIndices(collectionIndex, sensorIndex, scene, indices) {
    let isDisable: boolean = true;
    let operator = this.collection?.sensors[sensorIndex]?.operatorKey;
    const selectedScenes =  this.collection.sensors[sensorIndex].scenes.filter((i) => i.checked === true);
    if(((selectedScenes || []).length === 1) || (selectedScenes || []).length > 0 && operator === OPERATORS.AXELSPACE)
    {
      isDisable = false;
    }
    return isDisable;
  }
  selectIndices(collectionIndex, sensorIndex, indicesIndex, event, indices) {
    const sensor = this.collection?.sensors[sensorIndex];
    if(event.checked) {
      // Uncheck all items in the current group before checking the selected one
      this.collections[collectionIndex].sensors[sensorIndex].indicesDetails.forEach(
        (item, index) => {
          item.checked = index === indicesIndex ? event.checked : false;
        }
      );
      this.collection.sensors[sensorIndex].indicesDetails[indicesIndex].checked = true;
      this.spinnerService.show();
      this.mapLoad = true;
      this.displayIndices(indices, sensor, event, collectionIndex, sensorIndex)
    } else {
        this.selectedIndices = null;
        if (this.currentProductLayer) {
          for (let layer of this.currentProductLayer) {
            this.viewer.imageryLayers.remove(layer);
         }
            this.currentProductLayer = [];
        }
        this.removeBoundingBoxesAndLegends()

      // Handle toggling the sensor state
      const newEvent = { ...event, checked: true };
      // Find indices of all selected scenes
      const selectedIndices = sensor.scenes
        .map((scene, index) => (scene.checked ? index : -1)) // Map to index if selected, else -1
        .filter(index => index !== -1);

      for (let currentSceneIndex of selectedIndices) {
        this.toggleSensor(
          collectionIndex,
          sensorIndex,
          currentSceneIndex,
          newEvent,
          sensor.scenes[currentSceneIndex]
        );
      }
    }
  }
  displayIndices(indices, sensor, event, collectionIndex, sensorIndex) {
    this.selectedIndices = indices;
    this.selectedScene  = '';

    //Removing last added layers
    this.resetBackgroundWithChangeInBaseLayer();

    const currentScene = sensor.scenes.find(scene => scene.checked === true);
    this.selectedScene  = currentScene;
    const selectedScenes = sensor.scenes.filter(scene => scene.checked === true);
    const sceneIds = selectedScenes.map(scene => scene.id);
    
    let sensorObj = {
        taskId: this.taskid,
        sensorId: indices.sensorId,
        sceneId: sceneIds,
        product: indices.product,
        type: 'display'
    };

    let ndviObj = this.collections[collectionIndex].sensors[sensorIndex].indicesDetails
          .find((item: any) => item.product === indices.product);
          
    this.apiService.getIndicesDataImgUrl(sensorObj).subscribe(async (res: any) => {
      for (const data of res?.response?.signedUrls) {

        let scnRectangle: any;
        let pngFp: any;
        var bbox = turf.bbox(currentScene.footprint);
        let ndviScene = ndviObj.products.find((scene: any) => scene.sceneId === data.sceneId); 
         let indicesScale: any;
         if (indices.product === 'NDVI') indicesScale = ndviScene.ndviScale;
         else indicesScale = ndviScene.ndreScale;

         let indicesItem = indices.product.toLowerCase();         

        let entitiesToRemove: any = [];
    
        this.viewer.entities.values.forEach((entity) => {
          if (entity.name === 'legend') {
            entitiesToRemove.push(entity);
          }
        });
    
        entitiesToRemove.forEach((entity) => {
          this.viewer.entities.remove(entity);
        });

        if (ndviScene?.[`${indicesItem}_png_footprint`]) {
            pngFp = ndviScene?.[`${indicesItem}_png_footprint`][0];
            scnRectangle = Cesium.Rectangle.fromDegrees(
                pngFp[0],
                pngFp[1],
                pngFp[2],
                pngFp[3]
            );
        } else {
            scnRectangle = Cesium.Rectangle.fromDegrees(
                bbox[0],
                bbox[1],
                bbox[2],
                bbox[3]
            );
        }

        const productProvider = new Cesium.ImageryLayer(
            new Cesium.SingleTileImageryProvider({
                url: data.imageUrl,
                rectangle: scnRectangle,
            }), {
            splitDirection: Cesium.SplitDirection.LEFT,
            alpha: 1
        });

        this.viewer.imageryLayers.add(productProvider);
        this.imageryLayerMap.set(productProvider, {
            sceneId: currentScene.id,
            rectangle: scnRectangle,
            taskId: this.taskid,
            sensorId: indices.sensorId
        });

        // Store the current product layer to remove it later
        this.currentProductLayer.push(productProvider);

        

        // Legend
        let legendRight: any;
        let legendLeft: any;
        let entitylLeft: any
        const currentMode = this.viewer.scene.mode;
        
        const legendData = {
          minValue: -1.0,
          maxValue: 1.0,
          items: [
            { range: '-1.0 to 0', color: indicesScale[0].color },
            { range: '0 to 0.2', color: indicesScale[0].color },
            { range: '0.2 to 0.4', color: indicesScale[1].color },
            { range: '0.4 to 0.6', color: indicesScale[2].color },
            { range: '0.6 to 0.8', color: indicesScale[3].color },
            { range: '0.8 to 1.0', color: indicesScale[4].color }
          ]
        };
        
        // Manually set the display values from -1.0 to 1.0, including both ends
        const displayValues = [-1.0, 0, 0.2, 0.4, 0.6, 0.8, 1.0];
        
        this.scaleArray = [];
        let legendItems: any = [];
        
        // Create the scale array based on the legend data
        for (let i = 0; i < legendData.items.length; i++) {
          const item = legendData.items[i];
          
          // Push each item with range, color, and display value from displayValues array
          this.scaleArray.push({
            range: item.range,
            color: item.color,
            displayValue: displayValues[i] // Correct index to align with displayValues
          });
        
          // Collect colors for the gradient
          legendItems.push(item.color);
        }
        this.scaleArray.push({
          range: '1.0 to 1.0',
          color: indicesScale[5].color,
          displayValue: '1.0'
        });
        
        // Reverse the array to ensure the order is correct (if needed)
        this.scaleArray.reverse();
        legendItems.reverse();
        
        // Generate the linear gradient from the legend items
        const gradientColors = legendItems.join(', ');
        this.legendColor = `linear-gradient(to bottom, ${gradientColors})`;

          let boundingBox = ndviScene?.[`${indicesItem}_tif_footprint`];
          let entitylLeftBB: any;
          if (boundingBox) {
            entitylLeftBB = this.viewer.entities.add({
              polyline: {
                positions: Cesium.Cartesian3.fromDegreesArray(boundingBox),
                clampToGround: true,
                material: new Cesium.PolylineOutlineMaterialProperty({
                  outlineWidth: 8,
                  outlineColor: Cesium.Color.YELLOW
                }),
              }
            });
            entitylLeftBB.sceneId = ndviScene?.sceneId;
            this.scnLyr.push(entitylLeftBB);
          }


          if (indicesScale) {
            const longitudes = boundingBox.filter((_, index) => index % 2 === 0);
            const latitudes = boundingBox.filter((_, index) => index % 2 !== 0);
            
    
            // Calculate the center latitude of the bounding box
            const minLatitude = Math.min(...latitudes);
            const maxLatitude = Math.max(...latitudes);
            const centerLatitude = ((minLatitude + maxLatitude) / 2) - 0.06;
    
    
            // Calculate the rightmost longitude of the bounding box
            const maxLongitude = Math.max(...longitudes);
    
            // Calculate the position for the legend
            const longitudeOffset = 0.02; // Smaller offset to bring it closer
            const legendLongitude = maxLongitude + longitudeOffset; // Place the legend just next to the right edge of the bounding box

            const legendLatitude = centerLatitude;
            
            if (selectedScenes?.length === 1) {
            // Create the legend entity
            legendLeft = this.viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(legendLongitude, minLatitude),
              name: 'legend',
              billboard: {
                image: '',
                show: true,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                horizontalOrigin: Cesium.HorizontalOrigin.RIGHT,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
            });
            }
    
          //creating legend image
          let htmlContent = `
            <style>
              .legend-containers {
                display: flex;
                flex-direction: column;
                height: 280px;
                width: 25px;
              }
              .color-stop {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center; /* Ensure the text is centered vertically */
                color: white;
                padding-bottom: 47px; /* Adjust padding to prevent cut-off */
              }

              .scales-value {
                position: absolute;
                font-size: 14px; /* Adjust font size for better fit */
                color: white;
                left: 30px;
                font-weight: 600;
                padding-bottom: 15px;
              }

              .legend-unit {
                font-size: 13px;
                color: white;
                font-weight: 500;
                margin-bottom: 10px; /* Add some margin for better spacing */
              }

              .unit {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            </style>
            <div class="unit">
              <div class="legend-unit">(${indices.product})</div>
              <div style="display: flex">
                <!-- Color Stops Container -->
                <div class="legend-containers">
                  ${this.scaleArray.map(item => `
                    <div class="color-stop" style="background-color: ${item.color};"></div>
                  `).join('')}
                </div>
                <!-- Scale Values Container -->
                <div class="legend-containers">
                  ${this.scaleArray.map(item => `
                    <div class="color-stop" style="background-color: transparent">
                      <span class="scales-value">${item.displayValue}</span>
                    </div>
                  `).join('')}
                </div>
              </div>
            </div>
          `;

          if (selectedScenes?.length > 1) {
            this.legendContainer = document.getElementById('legend-container');

            if(this.legendContainer) {              
              this.legendContainer.style.display = 'block';
              this.legendContainer.innerHTML = htmlContent;
            }
          } else {
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = htmlContent;
            tempContainer.style.position = 'absolute';
            document.body.appendChild(tempContainer);
                  
            html2canvas(tempContainer,{backgroundColor: null}).then((canvas) => {
              const imageDataUrl = canvas.toDataURL('image/png');
              legendLeft.billboard.image = imageDataUrl;
              document.body.removeChild(tempContainer);
            });
    
          legendLeft.index = ndviScene?.sceneIdx;
          legendLeft.sceneId = ndviScene?.id;
          this.scnLyr.push(legendLeft); 
          }
        }
        
        
        this.viewer.zoomTo(entitylLeftBB);
        this.spinnerService.hide();
        this.mapLoad = false;
      }
    });
  }
  
  isDownloadedIndices(indices, sensor, scene) {
    let currentScene = sensor.scenes.filter(scene => scene.checked === true);
    if(currentScene.length > 1) return false;
    return indices.products.some(element => {
      return element.sceneId === currentScene[0]?.id && element.isDownloaded;
    });
  }
  refundStatusCheckIndices(indices, sensor) {
    let currentScene = this.getCurrentScene(sensor);
    
    // Use the some method to return true if any product matches the condition
    return indices.products.some((i) => {
      return i.sceneId === currentScene?.id && (i?.returnRefundAccept || i?.returnRefundReject);
    });
  }
  refundIndicesStatus(indices: any, sensor:any) {
    let currentScene = this.getCurrentScene(sensor);
    let message = '';

    indices.products.forEach((i) => {
      if(i.sceneId === currentScene?.id) {
        if (i?.refundRequest && (i?.returnRefundAccept || i?.returnRefundReject)) {
          let message = 'return-refund-accepted';
          if (i?.returnRefundReject) {
            message = 'return-refund-rejected';
          } 
        }
      }
    })
    if(message) {
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
    }
    return
  }
  OpenReturnRequestIndices(collectionIndex, sensorIndex, indices) {
    const sensor = this.collections[collectionIndex].sensors[sensorIndex];
    const taskId = this.collections[collectionIndex]?.taskId;
    let currentScene = this.getCurrentScene(sensor);
    let sceneId: any = currentScene?.id;
    let sensorId: any = sensor?.id;
    let product = indices.product;
    let sensorObj = {
      taskId, sensorId, sceneId, product
    }
  
    this.apiService.returnRefundStatus(sensorObj).subscribe((res: any) => {
      if (res?.status === "found") {
        let message =  'return-refund-accepted';
        if (res?.returnRefundReject) {
          message = 'return-refund-rejected';
        }
  
        if (res?.returnRefundReject || (res?.returnRefundAccept)) {
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          return
        } else {
          this.handleRefundRequestSR(currentScene, taskId, sensorId, sceneId, product, indices);
        }
      } else {
        this.handleRefundRequestSR(currentScene, taskId, sensorId, sceneId, product, indices);
      }
    });
  }

  downloadIndices(indices, collectionIndex, sensor, sensorIndex)
  {
    const currentScene = sensor.scenes.find(scene => scene.checked === true);
    let isDownloaded = indices.products.some(element => {
      return element.sceneId === currentScene.id && element.isDownloaded;
    });
    if (this.freeze) {
      const message = 'A download is in progress. Please wait until the current download finishes to continue downloading others.';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else if (isDownloaded) {
      const message = 'download_vadp';
      this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      return
    } else {
      this.spinnerService.show();
      this.freeze = true;
      this.fail = 0;
      this.progress = 0;
      this.downloadClick = true;
      this.currentDownloadingIndices = { 
        collectionIndex: collectionIndex, 
        sensorIndex:  sensorIndex,
        sceneId : currentScene.id,
        product: indices.product
      };
  
      let sensorObj = {
        taskId: this.taskid,
        sensorId: indices.sensorId,
        sceneId: currentScene.id,
        product: indices.product,
        type: 'download'
    };

    this.apiService.getIndicesDataImgUrl(sensorObj).subscribe(async (res: any) => {
        let url = res.response.imageUrl;

        if(url) {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.responseType = 'blob';
    
          xhr.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentComplete = ((event.loaded / event.total) * 100);
              this.progress = percentComplete.toFixed(2);
              const sensorObj = { 
                progress: this.progress, 
                taskId: this.collections[collectionIndex].taskId, 
                sensorId: sensor.id,
                sceneId: currentScene.id, 
                download: this.downloadClick, 
                product: indices.product
              };
              this.downloadService.setProgress(sensorObj);
            }
          };
    
          xhr.onload = () => {
            if (xhr.status === 200) {
              const blob = xhr.response;
              const fileName = this.getFileNameFromS3SignedUrl(url);
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              URL.revokeObjectURL(link.href);
    
              // Reset progress after download
              this.progress = 0;
              this.spinnerService.hide();
              this.freeze = false;
              this.disable = false;
            }
          };
    
          xhr.onerror = () => {
            // Handle errors
          };
          xhr.send();
        }
      }) 
    }
  }
  setDownloadedIndices()
  {
    this.collections[this.currentDownloadingIndices.collectionIndex].sensors[this.currentDownloadingIndices.sensorIndex].indicesDetails.forEach((row) => {
      if(row.product == this.currentDownloadingIndices.product) {
        (row.products || []).forEach((item) =>  {
          if(item.sceneId === this.currentDownloadingIndices.sceneId) {
            item.isDownloaded = true;
          }
        });
      }
    })
    this.currentDownloadingIndices = {};
  }
  resetIndicesOptions(collectionIndex, sensorIndex, event) {
    this.collections[collectionIndex]?.sensors[sensorIndex].indicesDetails.forEach((i) => {
      i.checked = false;
    })
    this.selectedIndices = null;
    if (this.currentProductLayer) {
      for (let layer of this.currentProductLayer) {
        this.viewer.imageryLayers.remove(layer);
     }
        this.currentProductLayer = [];
    }

    this.removeBoundingBoxesAndLegends();

    // Handle toggling the sensor state
    const sensor = this.collections[collectionIndex]?.sensors[sensorIndex];
    const currentSceneIndex = sensor.scenes.findIndex(scene => scene.checked === event.checked);
    // Find indices of all selected scenes
    const selectedIndices = sensor.scenes
    .map((scene, index) => (scene.checked ? index : -1)) // Map to index if selected, else -1
    .filter(index => index !== -1);

    if (selectedIndices?.length > 0) {
      const newEvent = { ...event, checked: true };
      for (let currentSceneIndex of selectedIndices) {
        this.toggleSensor(
          collectionIndex,
          sensorIndex,
          currentSceneIndex,
          newEvent,
          sensor.scenes[currentSceneIndex]
        );
      }
    }
  }
  // Function to remove bounding boxes and legends
  removeBoundingBoxesAndLegends() {
    if(this.legendContainer) this.legendContainer.style.display = 'none';
    // Loop through the array and remove each entity
    for (let entity of this.scnLyr) {
        this.viewer.entities.remove(entity);
    }
    // Clear the array after removing entities
    this.scnLyr = [];
  }

  refundVADP(collectionIndex, sensorIndex,sensor) {
    const taskId = this.collections[collectionIndex]?.taskId;
    let sensorId: any = sensor?.id;
    let product = 'vadp';
    let action = 'all';
    if (sensor?.superResolutions?.length > 0) product = 'Super Resolution';

    let sensorObj = {
      taskId, sensorId, product, action
    }

    this.apiService.returnRefundStatus(sensorObj).subscribe((res: any) => {
      if (res?.status === "found") {
        if (res?.refund) {
          let message =  'return-refund-accepted';
          if (res?.returnRefundReject) {
            message = 'return-refund-rejected';
          }
    
          if (res?.returnRefundReject || (res?.returnRefundAccept)) {
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            return
          }
        } else {
          const dialog = this.matdialog.open(ReturnRefundComponent, {
            minWidth: '50%',
            data: {type: 'return', taskId: taskId, sensorId: sensorId, product: product,
              action: action
            },
            backdropClass: 'blurred',
            disableClose: true
          });

          dialog.afterClosed().subscribe((result) => {
            if (result != 'close') {
              const message = 'return-refund-success';
              this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
              for (const sensor of this.collection.sensors || []) {
                for (const option of sensor.valueAddedOption || []) {
                  option.refundRequest = true;
                }
                
                for (const option of sensor.superResolutions || []) {
                  option.refundRequest = true;
                }
              }
            }
          });
        }
      } else {
        const message = 'return-refund-vadp';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
        return
      }
    });
  }

  checkForDelivered(sensor) {
   let vadpRefund: boolean = false;
   let srRefund: boolean = false;

   if (sensor?.valueAddedOption?.length > 0) {
    vadpRefund = sensor.valueAddedOption.every(item => item.product_status === "DELIVERED");
   } else if (sensor?.superResolutions?.length > 0){
      if (sensor?.superResolutions[0]?.product_status === 'DELIVERED') {
        srRefund = true;
      }
   }

   return srRefund || vadpRefund;
  }

  checkForRelease (sensor) {
    let vadp: boolean = false;
    let sr: boolean = false;
 
    if (sensor?.valueAddedOption?.length > 0) {
      vadp = sensor.valueAddedOption.every(item => item?.releaseRequest == true);
    } else if (sensor?.superResolutions?.length > 0){
       if (sensor?.superResolutions[0]?.releaseRequest == true) {
        sr = true;
       }
    }
 
    return vadp || sr;
  }

  checkForRefund (sensor) {
    let vadpRefund: boolean = false;
    let srRefund: boolean = false;
 
    if (sensor?.valueAddedOption?.length > 0) {
     vadpRefund = sensor.valueAddedOption.every(item => item.returnRefundReject) || 
     sensor.valueAddedOption.every(item => item.returnRefundAccept);
    } else if (sensor?.superResolutions?.length > 0){
      srRefund = sensor?.superResolutions[0].products.some((i) => i.returnRefundReject) ||
      sensor?.superResolutions[0].products.some((i) => i.returnRefundAccept);
    }
 
    return srRefund || vadpRefund;
  }
  
  selectSite(item: number) {    
    this.filteredCollection = [];
    const collection = this.fullCollection;
    this.subDailyCountarray = []
    this.defaultSubdailyCount = item;
    let scenesList: any = [];
    this.collection.siteData = [];
    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false;
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    this.newTaskButtonClicked = false;
    this.selectedRecurrency = null;   
    
    for (const [sensorIndex, sensors] of collection.sensors.entries()) {
      let sceneArray: any = [];
      for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
        scenes.checked = false;
        if (scenes?.siteId == item) {
          sceneArray.push(scenes);
        }
      }

      sceneArray.forEach((element, index) => {
        element.sceneIdx = index + 1;
        if (element?.id != null && element?.status != 'failed') {
          scenesList.push({ id: element.id });
        }
      });

      this.collection.sensors[sensorIndex].scenesList = scenesList;
      this.collection.sensors[sensorIndex].scenes = sceneArray;

      if (sensors.siteData) {
        this.collection.siteData = sensors.siteData;
      }
    }
    this.filteredCollection = JSON.parse(JSON.stringify(this.collection));

    for (const [siteIndex, site] of this.collection?.siteData.entries()) {
      site.checked = false;
      site.value = siteIndex + 1;
      site.siteId = siteIndex + 1;
    }
    this.subDailyCountarray = this.collection?.siteData;
    this.filteredCollection = JSON.parse(JSON.stringify(this.collection));

    this.splitPosition = 1;
    this.clearBandLegends();
    this.USLscnLyr = [];
    if (this.selectedType != 'dateDropdown') {
    this.viewer.entities.removeAll();
    this.resetBackgroundWithChangeInBaseLayer();
    }

    this.selectRecurrency(this.collection?.recurrencyArray[0], 'normal');
    this.selectedRecurrency = this.collection?.recurrencyArray[0] || null;
  }

  selectRecurrency(event, type: any) {
    let scenesList: any = [];
    const collection = this.fullCollection;
    this.sceneBands = '';
    this.signalBands = '';
    this.bandTaskId = '';
    this.bandSensorId = '';
    this.signalSensorId = '';
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.imageryLayerMap.clear();
    this.boundingBoxMap.clear();
    
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    this.selectionChange.next(event);

    this.selectedStartDate = event.start;
    this.selectedEndDate = event.end;

    this.clearAnnotations();
    this.annotateClicked = false;
    this.imageLoaded = false;
    this.newTaskButtonClicked = false;

      for (const [sensorIndex, sensors] of this.filteredCollection.sensors.entries()) {
        let sceneArray: any = [];

        for (const [sceneIndex, scenes] of sensors.scenes.entries()) {
          let datePredicted = new Date(scenes.predictedDate);
          const predictedDate = scenes.predictedDate;

          scenes.checked = false;
          if (event?.repeatFrequency === 'Daily' && (datePredicted >= startDate && datePredicted <= endDate)) {
            sceneArray.push(scenes);
          } else if (event?.repeatFrequency === 'Sub-daily' && predictedDate !== undefined) {
            const PredictedDateOnly = new Date(scenes.predictedDate).toISOString().split('T')[0];
            const startDateOnly = new Date(startDate).toISOString().split('T')[0];
            const endDateOnly = new Date(endDate).toISOString().split('T')[0];
            if (PredictedDateOnly == startDateOnly && PredictedDateOnly == endDateOnly) {
              sceneArray.push(scenes);
            }
          } else if (datePredicted >= startDate && datePredicted <= endDate) {
            sceneArray.push(scenes);
          }
        }

        sceneArray.forEach((element, index) => {
          element.sceneIdx = index + 1;
          if (element?.id != null && element?.status != 'failed') {
            scenesList.push({ id: element.id });
          }
        });

        this.collection.sensors[sensorIndex].scenesList = scenesList;
        this.collection.sensors[sensorIndex].scenes = sceneArray;
      }

    if ((type !== 'split' && ((this.previousSelection !== null && JSON.stringify(this.previousSelection) !== JSON.stringify(event)) || this.previousSelection === null)) || type === 'split') {
      this.previousSelection = event;
      if (type != 'split') {
        this.splitPosition = 1;
        this.viewer.entities.removeAll();
        this.clearBandLegends();
        this.USLscnLyr = [];
      
        this.resetBackgroundWithChangeInBaseLayer();
      }
    }
  }

  displayProperties(signal) {
    // Unified hover logic for all entities
    let tooltip = document.getElementById('feedTooltip');
    if (this.signalhHandlers) {
      this.signalhHandlers.destroy();
    }

    this.signalhHandlers = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

    this.signalhHandlers.setInputAction((movement) => {
      const pickedObject = this.viewer.scene.pick(movement.endPosition);
      const entity = pickedObject?.id;
     
      if (entity && entity.properties && (entity?.type === 'spire' || entity.type === 'clyde' ||
        entity?.name === 'broRf' ||
        (entity?.name === 'signal' && entity.properties['title'] && entity.properties['title'].getValue() === "Estimated Emitter and Error Ellipse"))) {
        if (tooltip) {
          let content = '';

          // Check entity type and display relevant properties
          if (entity?.type === 'spire' || entity?.type === 'clyde') {
            entity.properties.propertyNames.forEach(propertyName => {
              content += `<strong>${propertyName} : </strong> ${entity.properties[propertyName]}<br>`;
            });
          } else if ((entity?.name === 'signal')) {
            const displayProperties = {
              lon: 'Lng',
              lat: 'Lat',
              title: 'Title',
            };
            const signalValue = entity.properties[signal]?.getValue ? entity.properties[signal].getValue() : entity.properties[signal];
            content += `<strong>Signal: </strong> ${signalValue}<br>`;
            Object.keys(displayProperties).forEach(key => {
              const displayName = displayProperties[key];
              const value = entity.properties[key]?.getValue();
              if (value) {
                content += `<strong>${displayName}: </strong> ${value}<br>`;
              }
            });
          } else if (entity?.name === 'broRf') {
            entity.properties.propertyNames.forEach(propertyName => {
              content += `<strong>${propertyName} : </strong> ${entity.properties[propertyName]}<br>`;
            });
          }

          // Display tooltip
          tooltip.innerHTML = content;
          tooltip.style.left = movement.endPosition.x + 10 + 'px';
          tooltip.style.top = movement.endPosition.y + 10 + 'px';
          tooltip.style.fontSize = '15px';
          tooltip.style.fontFamily = 'Barlow", sans-serif';
          tooltip.style.lineHeight = '1.8';
          tooltip.style.display = 'block';
        }
      } else if (tooltip) {
        // Hide tooltip when no entity is hovered
        tooltip.style.display = 'none';
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

  }

  displayRefreshData(event,scene,sensor,action,refreshData) {
    if (event?.checked || action === 'download') {
      this.displaySpireRefreshData(scene, action,sensor,refreshData);
    } else {
      this.removeDataSourceById("aisData", refreshData.refreshId);
      this.removeEntitiesByName(refreshData.refreshId);
    }
  }

  displaySpireRefreshData(scene: any, type: any, sensor: any, refreshData: any) {
    let sensorId;
    let taskId;
    let currentCrossImage: any,boundingBox,item;

    sensorId = sensor.id;

    if (sensor.operatorKey === OPERATORS.SPIRE) {
      currentCrossImage = 'assets/images/hot-pink.png';
      item = 'SPIRE';
    }
    else {
      currentCrossImage = 'assets/images/light-blue.png';
      item = 'CLYDE'
    }

      taskId = this.collection?.taskId;
      const aisReq = {
        sensorId: sensorId,
        refreshId: refreshData?.refreshId,
        taskid: taskId,
        action: type
      }
      if (type !== 'download') {
        this.spinnerService.show();
        this.mapLoad = true;
      }

      this.apiService.getAISRefreshData(aisReq).subscribe((res: any) => {

        if (type !== 'download') {
          this.spinnerService.show();
          this.mapLoad = true;
        }

        if (res?.msg === 'failed') {
          const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.spinnerService.hide();
          this.mapLoad = false;
          this.freeze = false;
        } else {
          this.httpClient.get(res?.result?.imageUrl).subscribe(
            (data) => {
              const updatedAis = this.convertToGeoJSON(data, item);
              // Add the bounding box as a new feature to the existing GeoJSON FeatureCollection
              let BBpoints = scene?.footprint?.coordinates[0];
              if (type === 'download') {
                // Check if the bounding-box feature is already added
                const boundingBoxExists = updatedAis.features.some(feature => feature.id === 'bounding-box');
              
                if (!boundingBoxExists) {
                  updatedAis.features.push({
                    type: 'Feature',
                    id: 'bounding-box',
                    geometry: {
                      type: 'Polygon',
                      coordinates: [BBpoints], // Directly use BBpoints as it is an array of [lon, lat] pairs
                    },
                    properties: {
                      description: 'Bounding Box',
                    },
                  });
                }
              } else if (type === 'display') {
                // Remove the bounding box feature if it exists
                updatedAis.features = updatedAis.features.filter(feature => feature.id !== 'bounding-box');
              }

              if (type === 'display') {
                this.resetBackgroundWithChangeInBaseLayer();
                const selectData = Cesium.GeoJsonDataSource.load(updatedAis);
                selectData.then((dataSource: CustomGeoJsonDataSource) => {
                  dataSource.refreshId = refreshData?.refreshId;
                  dataSource.name = 'refreshDataSource';
                  const entities = dataSource.entities.values;
                  for (let j = 0; j < entities.length; j++) {
                    const entity = entities[j] as CustomEntity;
                    entity.name = 'refreshData';
                    entity.type = 'spire';
                    entity.billboard = new Cesium.BillboardGraphics({
                      image: currentCrossImage,
                      show: true,
                      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
                      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                      alignedAxis: Cesium.Cartesian3.ZERO,
                      width: 20,
                      height: 20,
                    });
                  }

                    boundingBox = BBpoints.toString().split(',').map(Number);
              
                    this.viewer.entities.add({
                      name: refreshData?.refreshId,
                      polyline: {
                        positions: Cesium.Cartesian3.fromDegreesArray(boundingBox),
                        clampToGround: true,
                        material: new Cesium.PolylineOutlineMaterialProperty({
                          outlineWidth: 3,
                          outlineColor: Cesium.Color.fromRandom({
                            alpha: 1.0,
                          }),
                        }),
                      },
                    });

                  this.spinnerService.hide();
                  this.mapLoad = false;

                  this.viewer.dataSources.add(dataSource);
                  this.viewer.scene.globe.depthTestAgainstTerrain = false;
                  this.viewer.flyTo(dataSource, { duration: 3 });
                });

                let tooltip = document.getElementById('feedTooltip');
                // Add click event listener                
                let previousEntity = null;
                this.handler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);

                this.viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);

                // Add hover event listener for tooltip
                this.handler.setInputAction((movement) => {
                  const pickedObject = this.viewer.scene.pick(movement.endPosition);
                  const entity = pickedObject?.id;

                  if (entity && entity.properties && entity?.name != 'annotation' && entity?.name != 'signal') {
                    if (tooltip) {
                      let content = '';
                      entity.properties.propertyNames.forEach(propertyName => {
                        content += `<strong>${propertyName} : </strong> ${entity.properties[propertyName]}<br>`;
                      });
                      tooltip.innerHTML = content;
                      tooltip.style.left = movement.endPosition.x + 10 + 'px';
                      tooltip.style.top = movement.endPosition.y + 10 + 'px';
                      tooltip.style.fontSize = '15px';
                      tooltip.style.fontFamily = 'Barlow", sans-serif';
                      tooltip.style.lineHeight = '1.8';
                      tooltip.style.display = 'block';
                    }
                  } else if (tooltip) {
                    tooltip.style.display = 'none';
                  }
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);


              } else if (type === 'download') {
                const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: scene.id, download: this.downloadClick, action: 'aisDownload' };
                this.downloadService.setProgress(sensorObj);
                const blob = new Blob([JSON.stringify(updatedAis)], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const fileName = res?.result?.imageUrl.split('/').pop().split('?')[0];
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                this.progress = 0;
                this.spinnerService.hide();
                this.freeze = false;
                this.disable = false;
              }
            },
            (error) => {
              console.error("Error fetching GeoJSON:", error);
            }
          );
        }
      });
    }

  filterByDate() {
    const dialogRef = this.matdialog.open(FilterPopupComponent, {
      width: '35%', backdropClass: 'blurr',
      disableClose: true,
      data: {type:'date', startDate: this.collection?.openWindow, endDate: this.collection?.closeWindow}
    });
    dialogRef.afterClosed().subscribe((result) => {         
      if (result) {        
        this.filterStart = this.formatDates(result.start);
        this.filterEnd = this.formatDates(result.end);
        this.dateRangeArray = [];
        this.clearBandLegends();
        this.removeImages();
        this.viewer.entities.removeAll();
        this.currentSliderValue = 0;
        this.sliderShow = false;
        this.previousIndex = -1;
        this.filterEnd = this.formatDates(result.end);
        this.groupedScenes = this.groupCollections();
      } else {
      }
    });
   }

   formatDates(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  AISDownload(sensor: any) {    
    let sensorId;
    let taskId;
    let currentCrossImage: any;
    let downloadAction: any;

    sensorId = sensor.id;
    taskId = this.collection?.taskId;
      const aisReq = {
        sensorId: sensorId,
        taskid: taskId,
        action: 'zipDownload'
      }
     
     if (sensor?.operatorKey === OPERATORS.CLYDE) downloadAction = 'aisDownload';
     else downloadAction = '';

      this.apiService.getAISRefreshData(aisReq).subscribe((res: any) => {        
        if (res?.msg === 'failed') {
          const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
          this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
          this.spinnerService.hide();
          this.mapLoad = false;
          this.freeze = false;
        } else {
                let url = res.result?.imageUrl;
        
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                xhr.responseType = 'blob';
        
                xhr.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percentComplete = ((event.loaded / event.total) * 100);
                    this.progress = percentComplete.toFixed(2);
                    const sensorObj = { progress: 100, taskId, sensorId: sensorId, sceneId: sensor.scenes[0].id, download: this.downloadClick, action: downloadAction };
                    this.downloadService.setProgress(sensorObj);
                  }
                };
        
                xhr.onload = () => {
                  if (xhr.status === 200) {
                    const blob = xhr.response;
                    const fileName = this.getFileNameFromS3SignedUrl(url);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
        
                    // Reset progress after download
                    this.progress = 0;
                    this.spinnerService.hide();
                    this.freeze = false;
                    this.disable = false;
                  }
                };
        
                xhr.onerror = () => {
                  // Handle errors
                };
                xhr.send();
        }
      });
  }
}
 