<div class="container task">
  <div class="set">
    <div class="sidebar-search">
      <mat-form-field appearance="outline" style="width: 285px;margin-left: -3px;">
        <input
          matInput
          type="text"
          #filter
          (keyup)="applyFilter($event)"
          placeholder="Search Project/Task Name"
          [(ngModel)]="filterName"
          class="no-outline"
        />
        <button
          mat-stroke-button
          matSuffix
          (click)="filter.focus()"
          style="background-color: #1059a91a"
          class="icon-search-button"
        >
          <mat-icon class="icon-search">search</mat-icon>
        </button>
      </mat-form-field>

      <div class="filter-panel" (click)="toggleFilterPanel()" fxlayoutGap="5px">
        <mat-icon class="icon-filter" matTooltip="Filters">tune</mat-icon>
      </div>
      </div>

      <div class="tabset">
        <!-- Tab 1 -->
        <input
        type="radio"
        name="tabset"
        id="recent"
        aria-controls="marzen"
        checked
        (change)="onItemChange($event.target)"
      />
      <label for="recent">Recent</label>
      <!-- Tab 2 -->
      <input
        type="radio"
        name="tabset"
        id="received"
        aria-controls="rauchbier"
        (change)="onItemChange($event.target)"
      />
      <label for="received">Completed</label>
      <!-- Tab 3 -->
      <input
        type="radio"
        name="tabset"
        id="canceled"
        aria-controls="dunkles"
        (change)="onItemChange($event.target)"
      />
      <label for="canceled">Inactive</label>
      <!-- Tab 4 -->
      <input
        type="radio"
        name="tabset"
        id="all"
        aria-controls="luese"
        (change)="onItemChange($event.target)"
      />
      <label for="all">All</label>
   </div>

    <div class="btns">
      <a fxHide.lt-md routerLink="/newtask" class="add">
        <span class="material-icons-outlined"> add </span>New
      </a>
      <a fxHide fxShow.lt-md class="add" (click)="desktopVersion()">
        <span class="material-icons-outlined"> add </span>New
      </a>
    </div>
  </div>
  
  <div [ngClass]="{'show-filter': isFilterPanelExpanded}" class="filter-container" style="display: flex;">
    <div fxLayoutGap="10px" fxLayout="row"  fxLayoutAlign="center center">
      <div class="head-menu" [matMenuTriggerFor]="menu" style="cursor: pointer;width: 188px;">
        <span class="menu-item">{{this.projectName ? this.projectName : 'Filter by Project Name'}}</span>
        <mat-icon class="material-icons-outlined">
          expand_more
        </mat-icon>
      </div>
      <mat-menu #menu="matMenu" class="collection-list-menu filter-mat-menu">
        <div class="collection-buttons" *ngFor="let projects of projectData">
          <button mat-menu-item (click)="selectProject(projects)">
            <span [matTooltip]="projects?.project?.length > 20 ? projects?.project : ''">{{
              projects?.project }}</span></button>
        </div>
      </mat-menu>

      <div *ngIf="selectedProjectId" class="head-menu" [matMenuTriggerFor]="task" style="cursor: pointer;width: 188px;">
        <span class="menu-item">{{this.taskName ? this.taskName : 'Filter by Task Name'}}</span>
        <mat-icon class="material-icons-outlined">
          expand_more
        </mat-icon>
      </div>
      <div *ngIf="!selectedProjectId" class="head-menu" style="width: 188px;">
        <span class="menu-item">{{this.taskName ? this.taskName : 'Filter by Task Name'}}</span>
        <mat-icon class="material-icons-outlined">
          expand_more
        </mat-icon>
      </div>
      <mat-menu #task="matMenu" class="collection-list-menu filter-mat-menu">
        <div class="collection-buttons" *ngFor="let tasks of taskList">
          <button mat-menu-item (click)="selectTask(tasks)">
            <span [matTooltip]="tasks?.taskName?.length > 20 ? tasks?.taskName : ''">{{
              tasks?.taskName }}</span></button>
        </div>
      </mat-menu>

        <mat-icon class="icon-filter material-icons-outlined" [ngClass]="{'icon-filter-disable': (!selectedProjectId && !selectedTaskId)}"
        (click)="clearFilter()" [matTooltip]="(selectedProjectId || selectedTaskId) ? 'Clear filters' : '' ">restart_alt</mat-icon>
    </div>
  </div>

  <!-- <div style="color: red;font-size: 17px;
    font-weight: 700; margin-bottom: -11px;">
    Alert: Capella will be performing scheduled maintenance on Monday, December 2, 2024 at 10:00 PM UTC for 60 minutes.
  </div> -->

  <div>
    <div class="card mt-4" *ngIf="tableDataLength > 0">
      <mat-card class="mat-head">
        <span>Tasks</span>
        <span style="font-size: 12px"
          >Please use the 'Proceed to Pay' option if your approved tasks are
          within the next 24hrs.
        </span>
      </mat-card>
    </div>
    <div class="tab-panels scrollbar" id="scrollcustom" [ngClass]="{'scrollbar-expanded' : isFilterPanelExpanded }"
      appScrollTracker (scrolledBottom)="loadMoreData()">
      <section class="tab-panel">
        <table
          *ngIf="tableDataLength > 0"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="announceSortChange($event)"
          class="mat-elevation-z8"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="position">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by number"
              style="padding-right: 1.5%;"
            >
              Project Name
            </mat-header-cell>

            <mat-cell
              class="frequency-cell"
              *matCellDef="let element"
              [style.color]="color"
              style="padding-right: 1.5%;"
            >
              <span
                [matTooltip]="
                  element?.project?.length > 12 ? element?.project : ''
                "
                >{{ element.project }}</span
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="taskname">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by name"
              style="padding-right: 1%"
            >
              Task Name
            </mat-header-cell>
            <mat-cell class="frequency-cell" *matCellDef="let element"
            style="padding-right: 1%"
            >
              <span
                [matTooltip]="
                  element?.taskname?.length > 12 ? element?.taskname : ''
                "
                >{{ element.taskname }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createddate">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by symbol"
              class="align"
            >
            Submission Date (UTC)
            </mat-header-cell>
            <mat-cell class="frequency-cell" *matCellDef="let element"
            class="align"
            >
              <span
                >{{
                  element?.createdAt | date : "dd/MM/yyyy - hh.mm a" : "GMT"
                }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="startdue">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by symbol"
              class="align"
            >
              Start Date (UTC)
            </mat-header-cell>
            <mat-cell class="frequency-cell align" *matCellDef="let element">
              <span
                >{{
                  element.openWindow | date : "dd/MM/yyyy - hh.mm a" : "GMT"
                }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enddue">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by enddue"
              class="align"
            >
              End Date (UTC)
            </mat-header-cell>
            <mat-cell class="frequency-cell align" *matCellDef="let element">
              <span>{{
                element.closeWindow | date : "dd/MM/yyyy - hh.mm a" : "GMT"
              }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="taskcost">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by taskcost"
            >
              <ng-container *ngIf="!currentUser?.pointsEligible && currentUser?.allocated; else otherLabel">
                Task Number
              </ng-container>
              <ng-template #otherLabel>
                Task Cost
              </ng-template>
            </mat-header-cell>
            <mat-cell class="taskcost-cell" *matCellDef="let element">
              <span style="justify-content: center;padding-right: 15px;" *ngIf="!currentUser?.pointsEligible && currentUser?.allocated">{{ element?.totalTaskNumber }}</span>
              <span *ngIf="!currentUser?.pointsEligible && !currentUser?.allocated">
                $ {{ element.grandTotal | customNumberFormat }}
              </span>
              <span *ngIf="currentUser?.pointsEligible">
                <span>{{ checkIndicesExist(element) ? (element.taskPointsWithIndices) : (element.taskPoint ?? 0) + (element.totalVaoPoints ?? 0) }} points</span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="frequency">
            <mat-header-cell *matHeaderCellDef> Frequency </mat-header-cell>
            <mat-cell class="frequency-cell" *matCellDef="let element">
              <ng-container
                *ngIf="element.frequency === 0; else recurrentBlock"
              >
                <span>Specific Date</span>
              </ng-container>
              <ng-template #recurrentBlock>
                <ng-container *ngIf="element.frequency === 1; else periodBlock">
                  <span>Recurrent</span>
                </ng-container>
              </ng-template>
              <ng-template #periodBlock>
                <ng-container *ngIf="element.frequency === 2; else nullBlock">
                  <span> Period</span>
                </ng-container>
              </ng-template>
              <ng-template #nullBlock></ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="repeatfrequency">
            <mat-header-cell class="mat-head-cells" *matHeaderCellDef>
              Repeat Frequency
            </mat-header-cell>
            <mat-cell class="mat-head-cells" *matCellDef="let element">
              <ng-container *ngIf="element.frequency === 1; else nullBlock">
                <span
                  style="margin-left: 0"
                  >{{ element.repeatFrequency | titlecase }} ({{
                    element.noOfRepeats
                  }})</span
                >
              </ng-container>
              <ng-template #nullBlock>{{ element.noOfRepeats }}</ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ISTask">
            <mat-header-cell class="mat-head-cells" *matHeaderCellDef
            style="justify-content: left !important;">
              <span style="margin-left: 35px;">
                Feeds
                <img
                  style="margin-left: 4px; margin-top: -2px"
                  width="14px"
                  src="../../../assets/images/union-white.svg"
                  matTooltip="Automatic extraction of information of interest"
                  matTooltipClass="tooltips"
                />
              </span>
            </mat-header-cell>
            <mat-cell class="mat-head-cells" *matCellDef="let element"
             style="justify-content: left !important;">
              <ng-container *ngIf="element.isISTask; else nullBlock">
                <span *ngIf="element.isISTask" style="margin-left: 35px;">{{
                  "Yes"
                }}

                <!-- <img
                    (mouseover)="getIsTooltipText(element.mlTasks)"
                    style="margin-left: 4px; margin-top: -2px"
                    width="14px"
                    src="../../../assets/images/union.svg"
                    [matTooltip]="isServices"
                    matTooltipClass="tooltips" /> -->
              </span>
              </ng-container>
              <ng-template #nullBlock>
                <span style="margin-left: 35px;">{{ "No" }}</span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="button">
            <mat-header-cell
              class="mat-head-cells"
              *matHeaderCellDef
              sortActionDescription="Sort by button"
              style="width: 520px !important;"
            >
              Status
            </mat-header-cell>
            <mat-cell class="status-cell" *matCellDef="let element"
            style="width: 520px !important;">
              <span *ngIf="!element?.outcomeCheck" fxLayoutGap="15px">
              <span
                class="badge rounded-pill task-canceled waiting text-center"
                *ngIf="element.yellowCount > 0"
                >{{ element.yellowCount }}</span
              >
              <span
                class="badge rounded-pill task-canceled cancelled text-center"
                *ngIf="element.redCount > 0"
              >
                {{ element.redCount }}</span
              >
              <span
                class="badge rounded-pill task-canceled success text-center"
                *ngIf="element.greenCount > 0"
              >
                {{ element.greenCount }}</span
              >
              <!-- <span
                class="badge rounded-pill task-canceled waiting text-center"
                *ngIf="element.delivering > 0"
              >
                {{ element.delivering }}</span
              > -->
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="preview">
            <mat-header-cell
              class="mat-head-cells"
              *matHeaderCellDef
              sortActionDescription="Sort by button"
              >Preview
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="preview-cell overlay"
            >
              <span
                (click)="OpenPopup(element);handleButtonClick($event)"
                [ngClass]="
                  element.redCount > 0 &&
                  element.greenCount < 1 &&
                  element.yellowCount === 0 &&
                  !element?.outcomeCheck
                    ? 'material-icons-outlined preview-disabled'
                    : 'material-icons-outlined map-active'
                "
              >
                map
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell
              class="mat-head-cells"
              *matHeaderCellDef
              sortActionDescription="Sort by button"
            >
              Action
            </mat-header-cell>
            <mat-cell
              class="preview-cell"
              *matCellDef="let element; let i = index"
            >
              <span>
                <button (click)="getTask(element);handleButtonClick($event)" mat-icon-button [matMenuTriggerFor]="menu"
                  aria-label="Toggle menu">
                  <mat-icon><span class="material-icons"
                    [ngClass]="!element.retask ? 'menuIcon' : 'more-button'">
                      more_vert
                    </span></mat-icon>
                </button>
              </span>
              <mat-menu class="action-card" #menu="matMenu" xPosition="before">
                <div class="button-align">
                  <button class="btn btn-gray retask-button"  (click)="getRetask(element)" [disabled]="retaskDisable"
                  style="border: none;" [class.disabledButton]="retaskDisable">
                    Re-Task
                  </button>
                </div>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="rating">
            <mat-header-cell
              class="mat-head-cells"
              *matHeaderCellDef
              style="margin-left: -20px;"
            >
            </mat-header-cell>
            <mat-cell
              class="preview-cell"
              *matCellDef="let element; let i = index"
              style="margin-left: -20px;"
            >
            <span *ngIf="element.taskCompleted" (click)="rateTask(element);handleButtonClick($event)"
              class="material-icons-outlined map-active" [matTooltip]="!element?.feedback ? 'Rate your task' : ''"
              [ngClass]="{'feedback-disable': element?.feedback}">
              reviews
            </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail" class="expanded">
            <mat-cell
              *matCellDef="let detail; let i = index"
              style="display: block"
            >
              <table
                mat-table
                [dataSource]="detail.request"
                class="mat-elevation-z8"
                *ngIf="!detail?.outcomeCheck"
              >
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <ng-container matColumnDef="check">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index" 
                    [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <mat-checkbox
                      class="example-margin"
                      [checked]="element.checked"
                      color="primary"
                      (change)="getTaskCost($event.checked, detail, i)"
                      [disabled]="
                        !element['status'] ||
                        element['status'] === 'canceled' ||
                        element['status'] === 'rejected' ||
                        element['status'] === 'failed' ||
                        element['status'] === 'cancel requested' ||
                        element['status'] === 'completed' ||
                        element['status'] === 'archived' ||
                        element['status'] === 'restored'
                      "
                    >
                      <span
                        *ngIf="element.checked"
                        style="color: #026fc2; font-weight: bold"
                      >
                      </span>
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sensorname">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="text-align: left"
                  >
                    Sensor Name
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: left"
                    [ngClass]="{ 'custom-name-display' : element?.feedData?.length > 0 && element.status != 'canceled' }"
                  >
                    <span [ngClass]="{'cell-content': hasValueAddedOptionKeys(element)}">
                      {{ (currentUser?.obfuscated && element.obfuscateName) ? element.obfuscateName : element.name }} {{ element.sensor }}
                      <span style="margin-left: 2px;" *ngIf="element?.sensorType">  ({{ element.sensorType }}) </span>
                      <img
                        style="margin-left: 4px; margin-top: -4px"
                        width="14px"
                        [src]="tooltipImagePath"
                        [matTooltipDisabled]="tooltipDisabled"
                        [matTooltip]="this.detailsText"
                        matTooltipClass="tooltips"
                        (mouseover)="selections(element)"
                        (mouseout)="onMouseOut()"
                        [ngStyle]="{'margin-top': hasValueAddedOptionKeys(element) ? '0px' : '-4px'}"
                      />
                      <mat-icon
                        class="custom-dem-icon emails"
                        *ngIf="hasValueAddedOptionKeys(element)"
                        [appCustomTooltip]="tooltipTemplate"
                        [appCustomTooltipContext]="element"
                      >
                      3d_rotation
                      </mat-icon>
                      <mat-icon
                      class="custom-dem-icon"
                      *ngIf="element?.isAudio"
                      matTooltip="The task includes audio files."
                    >
                    volume_up
                  </mat-icon>
                  <img *ngIf="element.name == 'Multi'"
                  style="margin-left: 4px; margin-top: -4px"
                  width="14px"
                  src="../../../assets/images/signalcost.png"
                  [appCustomTooltip]="tooltipTemplate1"
                  [appCustomTooltipContext]="element"
                />
                    </span>
                    <div *ngIf="element?.feedData?.length > 0 && element.status != 'canceled'" style="margin-top: 10px;">
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{element.feedData[0]?.feedName}}</span>
                    </div>
                  </td>
                </ng-container>
                
                <ng-template #tooltipTemplate let-element>
                  <div class="custom-tooltip">
                    <mat-table [dataSource]="getDataRows(element)" class="custom-tooltip-table">
                      <!-- Columns definition -->
                      <ng-container matColumnDef="actionProvider">
                        <mat-header-cell *matHeaderCellDef class="action-provider-column dark-bg"> Value Added Data Products </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="action-provider-column"> {{ item.actionProvider }} </mat-cell>
                      </ng-container>
                      
                      <ng-container matColumnDef="sensorResolution">
                        <mat-header-cell *matHeaderCellDef class="sensor-resolution-column center-align dark-bg"> Resolution </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="sensor-resolution-column center-align">
                          {{ item?.valueAddedDataProduct === 'indices' ? 'NA' : item.providerResolution }} 
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="deliveryTime">
                        <mat-header-cell *matHeaderCellDef class="delivery-time-column center-align dark-bg"> Delivery Time </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="delivery-time-column center-align">
                          {{ item?.valueAddedDataProduct === 'indices' ? 'NA' : item.deliveryTime + 'hrs' }} 
                        </mat-cell>
                      </ng-container>
                      
                      <ng-container matColumnDef="cost">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Cost </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> 
                          ${{ item.totalareacost }}
                        </mat-cell>
                      </ng-container>
                      
                      <ng-container matColumnDef="point">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Points </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> {{ item?.valueAddedDataProduct !== 'indices' ? item.point : item.totalareapoints }} points </mat-cell>
                      </ng-container>
                      
                      <!-- Header and row definitions -->
                      <mat-header-row *matHeaderRowDef="customTooltipColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: customTooltipColumns;"></mat-row>
                    </mat-table>
                  </div>
                </ng-template>

                <ng-template #tooltipTemplate1 let-element>
                  <div class="custom-tooltip">
                    <mat-table [dataSource]="element.signalOfInterest" class="custom-tooltip-table">
                      <!-- Columns definition -->
                      <ng-container matColumnDef="Signal">
                        <mat-header-cell *matHeaderCellDef class="action-provider-column dark-bg"> Signals </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="action-provider-column"> {{ item?.value }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Collection Duration">
                        <mat-header-cell *matHeaderCellDef class="sensor-resolution-column center-align dark-bg"> Collection Duration </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="sensor-resolution-column center-align">
                          {{ item.collectionDuration }}sec 
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Cost">
                        <mat-header-cell *matHeaderCellDef class="delivery-time-column center-align dark-bg"> Cost </mat-header-cell>
                        <mat-cell *matCellDef="let item" class="delivery-time-column center-align">
                          {{ item?.cost ? '$'+item?.cost : 'N/A' }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Audio">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Audio </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> 
                          {{ item.isAudio ? 'Yes' : 'N/A'}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Audio Cost">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Audio Cost </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> {{ item.isAudio && item?.audioCost ? '$'+item?.audioCost : 'N/A' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Point">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Points </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> {{ item?.point ? item?.point+' points' : 'N/A' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Audio Point">
                        <mat-header-cell class="cost-align cost-column center-align dark-bg" *matHeaderCellDef> Audio Points </mat-header-cell>
                        <mat-cell class="cost-align cost-column center-align" *matCellDef="let item"> {{ item.isAudio && item?.audioPoint ? item?.audioPoint +' points' : 'N/A' }}  </mat-cell>
                      </ng-container>



                      <!-- Header and row definitions -->
                      <mat-header-row *matHeaderRowDef="customTooltipColumns1"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: customTooltipColumns1;"></mat-row>
                    </mat-table>
                  </div>
                </ng-template>

                <ng-container matColumnDef="taskid">
                  <th mat-header-cell *matHeaderCellDef>Sensor ID</th>
                  <td class="frequency-cell" mat-cell *matCellDef="let element"
                    [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <span
                      [matTooltip]="element.uuid"
                      style="text-align: center"
                    >
                      {{ element.uuid }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="resolution">
                  <th mat-header-cell *matHeaderCellDef>Resolution</th>
                  <td mat-cell *matCellDef="let element"
                    [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <span
                        *ngIf="element?.operatorKey != OPERATORS.SPIRE && element?.operatorKey != OPERATORS.CLYDE"
                      >
                        {{element.resolution  }}
                        <img
                          *ngIf="element?.resolutionTooltip"
                          style="margin-left: 5px; margin-top: -4px"
                          width="14px"
                          [src]="tooltipImagePath"
                          [matTooltip]="element.resolutionTooltip"
                        />
                        <img
                        *ngIf="element?.collectionMode && element?.operatorKey === OPERATORS.BLACKSKY"
                        style="margin-left: 5px; margin-top: -2px"
                        width="14px"
                        [src]="tooltipImagePath"
                        [matTooltip]="element?.collectionMode[0]?.captureMode"
                      />
                      </span>
                      <span
                        *ngIf="element?.operatorKey === OPERATORS.SPIRE || 
                        element?.operatorKey === OPERATORS.CLYDE ||
                        element?.operatorKey === OPERATORS.LBAND"
                        style="text-align: center"
                      >
                        < not applicable >
                      </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="unit">
                  <th mat-header-cell *matHeaderCellDef>Tasked Area (sqkm)</th>
                  <td mat-cell *matCellDef="let element"
                  [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                  {{element.isPoint? element.minimumArea :(!element?.isPoint && element.isCalculatedAreaSmaller ? formatTaskArea(element.areacalc,element.minimumArea)  :element.areacalc) }}</td>
                </ng-container>

                <ng-container matColumnDef="tasking">
                  <th mat-header-cell *matHeaderCellDef>Tasking Tier</th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-transform: capitalize"
                    [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }"
                  >
                    <span style="text-align: center">{{
                      element?.latency?.name
                        ? element?.latency.name
                        : element?.latency
                    }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cloudcover">
                  <th mat-header-cell *matHeaderCellDef>Cloud Cover</th>
                  <td mat-cell *matCellDef="let element" 
                  [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                     <span
                        *ngIf="element?.operatorKey != OPERATORS.SPIRE && element?.operatorKey != OPERATORS.CLYDE"
                      >
                        {{ element.cloudcover }}
                      </span>
                      <span
                        *ngIf="element?.operatorKey === OPERATORS.SPIRE ||
                        element?.operatorKey === OPERATORS.CLYDE || 
                        element?.operatorKey === OPERATORS.LBAND"
                        style="text-align: center"
                      >
                        < not applicable >
                      </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstcapture">
                  <th mat-header-cell *matHeaderCellDef>First Capture</th>
                  <td mat-cell *matCellDef="let element"
                  [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <span *ngIf="element.status != 'rejected'">
                      <span
                        *ngIf="
                          element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey != OPERATORS.SATELLOGIC
                        "
                        style="text-align: center"
                        >{{
                          element?.firstCapture
                            | date : "dd/MM/yyyy - hh.mm a" : "GMT"
                        }}</span
                      >
                      <span
                        *ngIf="
                          element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey === OPERATORS.SATELLOGIC
                        "
                        style="text-align: center"
                        >{{
                          element?.firstCapture | date : "dd/MM/yyyy - hh.mm a"
                        }}</span
                      >
                      <span
                        *ngIf="
                          !element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.SPIRE &&
                          element?.operatorKey != OPERATORS.CLYDE &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element.status != 'canceled' &&
                          element.status != 'failed'
                        "
                        style="text-align: center"
                      >
                        < pending >
                      </span>
                      <span
                        *ngIf="
                          !element?.firstCapture &&
                          (element?.operatorKey === OPERATORS.AT21 ||
                            element?.operatorKey === OPERATORS.KOMPSAT ||
                            element?.operatorKey === OPERATORS.SPIRE ||
                            element?.operatorKey === OPERATORS.CLYDE)
                        "
                        style="text-align: center"
                      >
                        < not applicable >
                      </span>
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastcapture">
                  <th mat-header-cell *matHeaderCellDef>Last Capture</th>
                  <td mat-cell *matCellDef="let element" 
                  [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <span *ngIf="element.status != 'rejected'">
                      <span
                        *ngIf="
                          element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey != OPERATORS.SATELLOGIC
                        "
                        style="text-align: center"
                        >{{
                          (element?.lastCapture
                            ? element?.lastCapture
                            : element?.firstCapture
                          )
                            | date
                              : "dd/MM/yyyy -
                        hh.mm a"
                              : "GMT"
                        }}</span
                      >
                      <span
                        *ngIf="
                          element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element?.operatorKey === OPERATORS.SATELLOGIC
                        "
                        style="text-align: center"
                        >{{
                          (element?.lastCapture
                            ? element?.lastCapture
                            : element?.firstCapture
                          ) | date : "dd/MM/yyyy - hh.mm a"
                        }}</span
                      >
                      <span
                        *ngIf="
                          !element?.firstCapture &&
                          element?.operatorKey != OPERATORS.AT21 &&
                          element?.operatorKey != OPERATORS.SPIRE &&
                          element?.operatorKey != OPERATORS.CLYDE &&
                          element?.operatorKey != OPERATORS.KOMPSAT &&
                          element.status != 'canceled' &&
                          element.status != 'failed'
                        "
                        style="text-align: center"
                      >
                        < pending >
                      </span>
                      <span
                        *ngIf="
                          !element?.firstCapture &&
                          (element?.operatorKey === OPERATORS.AT21 ||
                            element?.operatorKey === OPERATORS.KOMPSAT || 
                            element?.operatorKey === OPERATORS.SPIRE ||
                            element?.operatorKey === OPERATORS.CLYDE)
                        "
                        style="text-align: center"
                      >
                        < not applicable >
                      </span>
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sensorcost">
                  <th mat-header-cell *matHeaderCellDef>
                    <ng-container *ngIf="!currentUser?.pointsEligible && currentUser.allocated; else otherLabel">
                      Sensor Task Number
                    </ng-container>
                    <ng-template #otherLabel>
                      Sensor Cost
                    </ng-template>
                  </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{ 'custom-name-display' : element?.feedData?.length > 0 && element.status != 'canceled' }">
                    <div *ngIf="!currentUser?.pointsEligible && !currentUser.allocated">
                      <span>$ {{ element.cost | customNumberFormat }}</span>
                      <div *ngIf="element?.feedData?.length > 0 && element.status != 'canceled'" style="margin-top:10px">
                        <span>$ {{element.feedData[0]?.price | customNumberFormat}}</span>
                      </div>
                    </div>
                    <div *ngIf="currentUser?.pointsEligible">
                      <span>{{ element?.point }} points</span>
                      <div *ngIf="element?.feedData?.length > 0 && element.status != 'canceled'" style="margin-top:10px">
                        <span>{{element.feedData[0]?.point}} points</span>
                      </div>
                    </div>
                    <div *ngIf="!currentUser?.pointsEligible && currentUser.allocated">
                      <span>{{ element.taskNumber }}</span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="oprtyTask">
                  <th *matHeaderCellDef mat-header-cell></th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    style="padding-left: 0px !important; margin-left: -6px"
                    [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }"
                  >
                    <span
                      *ngIf="
                        element?.feasibility && element.status === 'approved'
                      "
                      (click)="OpenFeasibilty(element, i, detail)"
                    >
                      <!-- <mat-icon class="material-icons-outlined settings-active" style="cursor: pointer" matTooltip="Capture Opportunities">
                        query_stats
                      </mat-icon> -->
                      <img
                        class="globe"
                        width="40px"
                        [src]="orbitImage"
                        matTooltip="Capture Opportunities"
                      />
                    </span>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td class="status-color" [ngClass]="{ 'custom-feed-display' : element?.feedData?.length > 0 && element.status != 'canceled' }"  mat-cell *matCellDef="let element">
                    <span style="text-align: center">
                      <button
                        class="task-btn approved btn"
                        *ngIf="
                          element['orderStatus'] &&
                          element.status != 'rejected' &&
                          element.status != 'canceled' &&
                          element.status != 'failed' &&
                          element['status'] !== 'completed' &&
                          element['status'] !== 'delivering' &&
                          element['status'] !== 'cancel requested'
                        "
                      >
                        {{ element.orderStatus }}
                      </button>
                      <button
                        class="task-btn approved btn"
                        *ngIf="
                          !element['orderStatus'] &&
                          (element['status'] === 'received' ||
                            element['status'] === 'approved' ||
                            element['status'] === 'Ordered' ||
                            element['status'] === 'submitted' ||
                            element['status'] === 'active' ||
                            element.status === 'approved' ||
                            element['status'] === 'accepted')
                        "
                      >
                        {{ element.status }}
                      </button>
                      <button
                        class="task-btn approved btn"
                        *ngIf="element['status'] === 'completed'"
                      >
                        {{ element.status }}
                      </button>

                      <button
                        class="task-btn cancel btn"
                        *ngIf="
                          element.status === 'rejected' ||
                          element.status === 'canceled' ||
                          element.status === 'failed' ||
                          element.status === 'error'
                        "
                      >
                        {{ element.status }}
                      </button>
                      <button
                        class="task-btn awaiting btn"
                        *ngIf="
                          !element['orderStatus'] &&
                          element.status === 'awaiting'
                        "
                      >
                        Awaiting
                      </button>
                      <button
                        class="task-btn awaiting btn"
                        *ngIf="
                          (element['orderStatus'] &&
                          element.status === 'delivering') || (!element['orderStatus'] && element.status === 'pending') 
                        "
                      >
                        {{ element.status }}
                      </button>
                      <button
                        class="task-btn awaiting btn"
                        *ngIf="
                          element['orderStatus'] &&
                          element.status === 'cancel requested'
                        "
                      >
                        {{ element.status }}
                      </button>
                    </span>
                    <img *ngIf="element?.retaskingTooltip?.length > 0"
                    style="margin-left: 4px; margin-top: -1px"
                    width="14px"
                    [src]="tooltipImagePath"
                    [matTooltip]="this.detailsText"
                    (mouseover)="formatTooltip(element?.retaskingTooltip)"
                    (mouseout)="onMouseOut()"
                    [matTooltipDisabled]="tooltipDisabled"
                  />
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="expectedcollectiondate">
                  <th mat-header-cell *matHeaderCellDef>
                    Expected Collection Date
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.expectedcollectiondate | date: "short" }}
                  </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="innerNameDisplay"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: innerNameDisplay"
                ></tr>
              
              </table>

              <table mat-table [dataSource]="detail.request" class="mat-elevation-z8" *ngIf="detail?.outcomeCheck">

                <ng-container matColumnDef="check">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index">
                  </td>
                </ng-container>

                <ng-container matColumnDef="sensorname">
                  <th mat-header-cell *matHeaderCellDef style="text-align: left;">Sensor Name</th>
                  <td mat-cell *matCellDef="let element" style="text-align: left;">
                  </td>
                </ng-container>

                <ng-container matColumnDef="taskid">
                  <th mat-header-cell *matHeaderCellDef>Sensor ID</th>
                  <td class="frequency-cell" mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="resolution">
                  <th mat-header-cell *matHeaderCellDef>Resolution</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="unit">
                  <th mat-header-cell *matHeaderCellDef>Tasked Area (sqkm)</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="tasking">
                  <th mat-header-cell *matHeaderCellDef>Tasking Tier</th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <span *ngIf="detail?.outcomeCheck && !detail?.outcomeRejected">Awaiting for operator approvals.</span>
                    <span *ngIf="detail?.outcomeCheck && detail?.outcomeRejected && !detail?.insufficientBalance">
                      There are no operators to deliver to your requirements. 
                      <span *ngIf="detail?.taskStrategy != 'Task From Collection'">Please increase your budget,<br/>
                      change the resolution, increase your data request time, or do all the above to improve the chance of approval.
                     </span>
                     <span *ngIf="detail?.taskStrategy === 'Task From Collection'">Please change the resolution,<br/>
                      increase your data request time, or do all the above to improve the chance of approval.
                     </span>
                    </span>
                    <span *ngIf="detail?.outcomeCheck && detail?.outcomeRejected && detail?.insufficientBalance">
                      The deposit amount balance needs to be increased to cover the confirmed cost of this task.<br/> Please top up and try again.
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cloudcover">
                  <th mat-header-cell *matHeaderCellDef>Cloud Cover</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstcapture">
                  <th mat-header-cell *matHeaderCellDef>First Capture</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastcapture">
                  <th mat-header-cell *matHeaderCellDef>Last Capture</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="sensorcost">
                  <th mat-header-cell *matHeaderCellDef>Sensor Cost</th>
                  <td mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <ng-container matColumnDef="oprtyTask">
                  <th *matHeaderCellDef mat-header-cell>
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index" style="padding-left: 0px !important;margin-left: -6px;">
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td class="status-color" mat-cell *matCellDef="let element">
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="innerNameDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: innerNameDisplay"></tr>
              </table>

              
             
              <div class="footer-style">
                <div class="text-style">
                </div>
                <div class="inner-table-footer">
                  <span *ngIf="detail?.feasibilityTask">
                    <button (click)="cancelOpportunities(detail)" class="btn btn-gray"
                      [disabled]="selectedTasks?.length === 0 || cancelFeasibility">
                      <span *ngIf="selectedTasks.length <= 1">Cancel Opportunity</span>
                      <span *ngIf="selectedTasks.length > 1">Cancel Opportunities</span>
                    </button>
                  </span>
                  <span *ngIf="detail.status !== 'canceled'">
                    <button (click)="cancelTask(detail, i,'sensor')" class="btn btn-gray" [disabled]="selectedTasks?.length === 0 || detail?.taskStrategy === 'Task From Collection'">
                      <span *ngIf="selectedTasks.length <= 1">Cancel Sensor</span>
                      <span *ngIf="selectedTasks.length > 1">Cancel Sensors</span>
                    </button>
                  </span>
                  <button [disabled]="
                              selectedSensors.length === 0 ||
                                  detail.orderStatus ||
                                  detail.greenCount === 0 || detail?.feasibilityTask || orderPending ||
                                  detail?.taskStrategy === 'Task From Collection'
                                " (click)="addToCart(detail,'cart')" class="btn btn-gray">
                    Add to Cart
                  </button>
                  <button (click)="checkout(detail, i)" [ngClass]="
                  (selectedSensors.length === 0 || detail.orderStatus || detail.completed === 0 || orderPending || detail?.taskStrategy === 'Task From Collection') ? 'btn btn-gray' : 'btn btn-black'
                                " [disabled]="
                                selectedSensors.length === 0 ||
                                  detail.orderStatus ||
                                  detail.greenCount === 0 || orderPending ||
                                  detail?.taskStrategy === 'Task From Collection'
                                ">
                    Proceed to Pay
                  </button>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            matRipple
            class="element-row"
            [class.expanded]="expandedElement == row.id"
            (click)="deselect(row); toggleRow(row)"
          ></mat-row>
          <mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            [@detailExpand]="
              row.id == expandedElement ? 'expanded' : 'collapsed'
            "
            style="overflow: hidden"
          >
          </mat-row>
        </table>
        <app-notask
          *ngIf="tableDataLength === 0"
          [data]="load"
          [allTask]="allTask"
        ></app-notask>
      </section>

    </div>
  </div>
</div>
