import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { CancelinvoiceComponent } from '../orders/cancelinvoice/cancelinvoice.component';
import { environment } from 'src/environments/environment';
import { OPERATORS } from 'src/app/services/constants';

@Component({
  selector: 'app-cancelsensor',
  templateUrl: './cancelsensor.component.html',
  styleUrls: ['./cancelsensor.component.scss']
})
export class CancelsensorComponent {

  title = 'Confirmation';
  cancelBtn = 'Cancel Task';
  btnParams = 'CANCEL';
  successMsg: any;
  currentUser: any;
  uuid: '';
  taskid = '';
  taskMap: any;
  operators: any;
  btnEnabled = true;
  newRes: any;
  operatorPolicy: any;
  operatorsPolicy: any;
  order: boolean = false;
  doNotCancel: boolean = false;
  penalty: any = [];
  clicked: boolean = false;
  uniqueOperators: any = [];
  uniqueOperator: any = [];
  penaltyOption: number;
  sensorRefundAmount: any = 0;
  ISRefundAmount: any = 0;
  refundAmount: any = 0;
  sensor: any = [];
  queuedTime: any;
  queuedDate: any;
  noQueue: boolean = false;
  operatorRefund: any = [];
  demoView: boolean;
  operatorRefundAmount: number;
  USL: boolean = false;
  uslRefund: number = 0;
  USLname: any;
  uslPenalty: number = 0;
  tippedSensorCost: number = 0;
  sensorRefundPoint: any = 0;
  ISRefundPoint: any = 0;
  refundPoint: any = 0;
  OPERATOR = OPERATORS;
  clyde: boolean = false;
  totalRefundTaskNumber:number = 0;
  isCancelEnabled: boolean = true;
  totalVaoRefund:any = 0;
  totalVaoPoint:any = 0;
  totalSRRefund:any = 0;
  totalSRPoint: any = 0;
  
  constructor(
    private apiService: ApiService, private userService: UserService, private authService: AuthService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<CancelsensorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      taskaction: any,
      user: any,
      task: any,
      index: any,
      type: any
    }
  ) {
    if (environment.demoView === 'true') {
      this.demoView = true;
    }
    else {
      this.demoView = false;
    }
    this.taskid = JSON.stringify({ index: this.data.index, taskId: this.data.task.id });
    this.operators = [];
    this.operatorPolicy = [];
    let userSubs = 'standard';
    const startDate = new Date(this.data.task.openWindow);
    const endDate = new Date(this.data.task.closeWindow);
    const currentDate = new Date();
    const timeDiffrence = (startDate.getTime() - currentDate.getTime()) / 3600 / 1000; //in hours;
    const startDatePlusTwoMonths = new Date(startDate);
    startDatePlusTwoMonths.setMonth(startDatePlusTwoMonths.getMonth() + 2);

    const taskBody = { taskid: this.data.task.id, operators: this.data.task.request, userId: this.data.user?.id, startDate: startDate,
      endDate: endDate
     };
    this.currentUser = this.data.user;
    this.apiService
      .getCancelPolicy(taskBody)
      .subscribe((cancelPolicy: any) => {
        this.sensor = cancelPolicy?.sensors
        this.operators = cancelPolicy?.sensors
        this.operators.forEach((operator, index) => {
          operator.taskedArea = operator.isPoint? operator.minimumArea :(!operator?.isPoint && operator.isCalculatedAreaSmaller ? this.formatTaskArea(operator.areacalc,operator.minimumArea)  :operator.areacalc)
        });
        this.sensorRefundAmount = cancelPolicy.totalSensorRefund
        this.ISRefundAmount = cancelPolicy.totalISRefund
        this.refundAmount = cancelPolicy.totalRefund;
        this.tippedSensorCost = cancelPolicy?.tippedSensorCost;
        this.sensorRefundPoint = cancelPolicy.totalSensorPoint;
        this.ISRefundPoint = cancelPolicy.totalISPoint
        this.refundPoint = cancelPolicy.totalRefundPoint;
        this.totalRefundTaskNumber = cancelPolicy.totalRefundTaskNumber;
        let penaltyAmount = 0;
        this.totalVaoRefund = parseFloat(cancelPolicy.totalVaoRefund ?? 0) + parseFloat(cancelPolicy.totalIndicesRefund ?? 0) + parseFloat(cancelPolicy.totalSRRefund ?? 0);
        this.totalVaoPoint = cancelPolicy.totalVaoPoint + cancelPolicy.totalSrPoint + cancelPolicy.totalIndicesPoint;
        
        this.sensor.forEach((oprtr) => {
          penaltyAmount += oprtr.penaltyAmount;
          if (this.data.type === 'task') {
            if (oprtr.checked && (oprtr.orderStatus === 'ordered' || oprtr.orderStatus === 'delivering')) {
              const firstCapture = new Date(oprtr?.firstCapture);
              this.order = true
              if (oprtr.operatorKey === OPERATORS.CLYDE) {
                if (currentDate < startDatePlusTwoMonths) {
                  this.doNotCancel = true;
                  this.clyde = true;
                } else if (endDate.getTime() < currentDate.getTime()) {
                  this.doNotCancel = true;
                  this.clyde = false;
                }
              }
              else if (startDate.getTime() < currentDate.getTime()) {
                if (oprtr.operatorKey === OPERATORS.BLACKSKY) {
                  this.doNotCancel = false;
                }
                else if (this.data.task.request?.length > 1 && (oprtr.operatorKey === OPERATORS.USL || oprtr.operatorKey === OPERATORS.GHG)) {
                  this.doNotCancel = true
                  this.USL = true;
                  this.USLname = oprtr?.name + " " + oprtr?.sensor;
                } else if (oprtr?.firstCapture && firstCapture.getTime() < currentDate.getTime() &&
                  (oprtr.operatorKey === OPERATORS.CAPELLA || oprtr.operatorKey === OPERATORS.SATELLOGIC)) {
                  this.doNotCancel = true
                  this.USL = false;
                } else if (oprtr?.firstCapture && firstCapture.getTime() > currentDate.getTime() &&
                  (oprtr.operatorKey === OPERATORS.CAPELLA || oprtr.operatorKey === OPERATORS.SATELLOGIC)) {
                  this.doNotCancel = false
                  this.USL = false;
                } else if (oprtr.operatorKey != OPERATORS.USL && oprtr.operatorKey != OPERATORS.GHG) {
                  this.doNotCancel = true
                  this.USL = false;
                }
              }
            }
          }


          let taskArray: any = this.data.task.request.filter(el => el.checked && (el.orderStatus === 'ordered' || el.orderStatus === 'delivering'));

          if (this.order && !this.noQueue) {
            if (this.data.user.id) {

            }
          }


            let isOk = this.isTrue(this.data.index, oprtr.orderStatus ? oprtr.orderStatus : oprtr.status);

          if (isOk && this.data.index === 999) {
            if (oprtr.status !== 'rejected') oprtr.checked = true;
          }
         
        });

        this.isCancelEnabled = this.currentUser?.allocated 
        ? (penaltyAmount > 0 ? false : true) 
        : true;

          this.uniqueOperators = [...new Set(this.operators.map((res) => ({
            operator: res.operator,
            sensor: res.sensor,
            status: res.status,
            queueTime: res?.isQueuedTime
          })))];
          this.uniqueOperators.forEach(val => {
            if (val?.status) {
              const operatorPolicy = this.operators.filter(el => el.operator === val.operator)[0];
              val.policy = operatorPolicy.policy
              const operatoravailable = this.uniqueOperator.filter(el => el.operator === val.operator);
              if (operatoravailable.length === 0)
                this.uniqueOperator.push(val);
            }
          });
      });

    // return
    this.cancelBtn = 'Ok';
    this.btnParams = 'OK';
    this.title = 'Confirmation';
    if (this.operators.length === 0) this.title = 'Information';
    if (this.data.taskaction === 'taskcancel') {
      this.cancelBtn = 'Yes, Cancel';
      this.btnParams = 'CANCEL';
    } else if (this.data.taskaction === 'taskstatus') {
      this.title = 'Task Status';
      this.btnEnabled = false;
      // this.cancelBtn = 'Close';
      const taskBody = { taskid: this.data.task.id, operators: this.operators };
      this.apiService
        .taskIdResponse(this.data.taskaction, taskBody)
        .subscribe((res: any) => {
          this.btnEnabled = true;
          this.newRes = res;
          this.successMsg = JSON.stringify(res);
        });
    } else if (this.data.taskaction === 'orderstatus') {
      this.title = 'Task Order Status';
      this.btnEnabled = false;
      // this.cancelBtn = 'Close';
      const taskBody = { taskid: this.data.task.id, operators: this.operators };
      this.apiService
        .taskIdResponse(this.data.taskaction, taskBody)
        .subscribe((res: any) => {
          this.btnEnabled = true;
          this.newRes = res;
          this.successMsg = JSON.stringify(res);
        });

    } else if (this.data.taskaction === 'taskdelete') {
      this.title = 'Delete Task';
    } else if (this.data.taskaction === 'retask') {
      this.title = 'Re Task';
    } else if (this.data.taskaction === 'capturestatus') {
      this.title = 'Capture Status';
      const taskBody = { taskid: this.data.task.id, operators: this.operators };
    }
  }
  isTrue(id: number, status) {
    if (
      status === undefined ||
      status === '' ||
      status === 'canceled' ||
      status === 'rejected'
    ) {
      return false;
    } else return true;
  }
  valChng(keyInword: string): void {
    this.btnEnabled = false;
    const keyword = this.data.task.id.slice(this.data.task.id.length - 4);
    if (keyword === keyInword) this.btnEnabled = true;
  }
  onKeyPress(e: any, msg: any) {
    if (e.key === 'Enter') {
      this.onNoClick(msg);
    }
  }
  async onNoClick(action: any) {
    this.clicked = true;
    this.successMsg = undefined;
    if (action === 'CANCEL') {
      const action = this.data.taskaction;

      const taskBody = { taskid: this.data.task.id, operators: this.operators, refundAmount: this.refundAmount, refundTaskNumber: this.totalRefundTaskNumber };

      if (this.order) {
        const res = await this.apiService
          .cancelSensor(action, taskBody)
          .subscribe((res: any) => {
            this.newRes = res;
            this.successMsg = res.msg;
            this.cancelBtn = 'Ok';
            this.btnParams = 'OK';
            this.dialogRef.close(this.successMsg);
          });
      }
      else {
        const res = await this.apiService
          .cancelSensor(action, taskBody)
          .subscribe((res: any) => {
            this.newRes = res;
            this.successMsg = res.msg;
            this.cancelBtn = 'Ok';
            this.btnParams = 'OK';
            this.dialogRef.close(this.successMsg);
          });
      }
    } else {
      this.dialogRef.close(this.newRes);
    }
  }

  deletefromCart(taskid: any) {
    this.apiService.delCartdata(taskid).subscribe((res: any) => { });
  }

  onPress(e: any, msg: any) {
    if (e.key === 'Enter') {
      this.cancelSensor(msg);
    }
  }

  async cancelSensor(action: any) {
    this.clicked = true;
    this.successMsg = undefined;
    if (action === 'CANCEL') {
      const action = this.data.taskaction;
      const taskBody = { taskid: this.data.task.id, operators: this.operators };
      
      const res = await this.apiService
        .cancelSensor(action, taskBody)
        .subscribe((res: any) => {
          this.newRes = res;
          this.successMsg = res.msg;
          this.cancelBtn = 'Ok';
          this.btnParams = 'OK';
          this.dialogRef.close(this.successMsg);
          if (this.order) {

          }
        });
    } else {
      this.dialogRef.close(this.newRes);
    }
  }

  filterLastByName(array: any[]): any[] {
    const filteredArray = array.reduce((accumulator, current) => {
      accumulator[current.operator && current.resolution] = current;
      return accumulator;
    }, {});

    return Object.values(filteredArray);
  }

  addKeyValueByMatchingName(firstArray: any[], secondArray: any[], key: string): any[] {
    return firstArray.map(item => {
      const matchingItem = secondArray.find(secondItem => secondItem.operator === item.operator && secondItem.resolution === item.resolution);
      if (matchingItem) {
        return {
          ...item,
          [key]: matchingItem.refundAmount
        };
      }
      return item;
    });
  }
  formatTaskArea(x: number, y: number): string {
    // return `${x} (${y})`;
    if (x > y) {
      return `${x}`;
    } else {
      return `${x} (${y})`;
    }
  }

}
